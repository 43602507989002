<template>
  <div class="statistics">
    <c-title :hide="false"
      text="统计"
      tolink=""
      :totext="``">
    </c-title>
    <div class="top">
      <div class="top-item" :class="is_active == 1 ? 'active' : ''" @click="qiehuan(1)">乡村统计</div>
      <div class="top-item" :class="is_active == 2 ? 'active' : ''" @click="qiehuan(2)">数据统计</div>
    </div>
    <div class="middle">
      <van-list
        v-model="loading"
        :finished="finished"
        :immediate-check="false"
        finished-text="没有更多了"
        @load="onLoad"
        :offset="10"
      >
      <div class="mid-item" v-for="(item,index) in list_v" :key="index">
        <div class="mid-left">
          <img :src="item.icon_image ? item.icon_image : require('../assets/images/village_cover.jpg')" class="mid-left-img">
        </div>
        <div class="mid-right">
          <div class="mid-right-top">{{item.village}} <van-icon name="eye-o" class="dq"/>{{item.views}}</div>
          <div class="mid-right-content">
            <div class="one">
              <p class="one-top">村民数</p>
              <p class="one-bottom">{{item.user_count}}</p>
            </div>
            <div class="one">
              <p class="one-top">党员数</p>
              <p class="one-bottom">{{item.party_count}}</p>
            </div>
            <div class="one">
              <p class="one-top">管理员</p>
              <p class="one-bottom">{{item.name}}</p>
            </div>
          </div>
        </div>
      </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import cTitle from '../components/title.vue';
export default {
  data(){
    return {
      is_active:1,
      list_v:[],
      page:1,
      limit:10,
      loading: false,
      total: 0,//总共的数据条数
      finished: false,
    };
  },
  created(){
    this.init();
  },
  methods:{
    init(){
      if(!window.localStorage.getItem("site_id")){
        this.$router.go(-1);
        return false;
      }else{
        this.getroadList();
      }
    },
    onLoad() {
      this.page++;
      this.getroadList();
    },
    getroadList(){

      let that = this;
      that.$axios
        .get(that.baseUrl+"/api/county/statistics", {
          params: {
            site_id:window.localStorage.getItem('site_id'),
            page:that.page,
            limit:that.limit
          }
        })
        .then(function(res) {
          if(res.data.code==1){
            that.loading = false;
            that.total = res.data.data.count;

            var list = res.data.data.list;

            if (list == null || list.length === 0) {
              // 加载结束
              that.finished = true;
              return;
            }
            // 将新数据与老数据进行合并
            that.list_v = that.list_v.concat(list);

            //如果列表数据条数>=总条数，不再触发滚动加载
            if (that.list_v.length >= that.total) {
              that.finished = true;
            }

          }
        });
    },
    qiehuan(n){
      this.is_active = n;
    }

  },
  components: {
    cTitle
  }
};
</script>

<style lang="less" scoped>
@import "~@/assets/styles/var.less";
  .statistics{
    padding:10px;
    .top{
      width:100%;display:flex;justify-content: space-around;align-items: center;
      .top-item{width:50%;text-align:center;font-size:15px;color:#222;font-weight:bold;height:40px;line-height:40px;}
      .active{color:@primary;background:url(../assets/images/bottom-line-green.png) no-repeat;background-size:30% 3px;background-position:bottom center;}
    }
    .middle{
      .mid-item{
        padding:10px;background:#fff;margin-top:10px;margin-bottom:10px;border-radius:5px;box-shadow: 1px 1px 2px rgba(0,0,0,0.1);
        display:flex;justify-content: space-between;align-items: center;
        .mid-left{
          width:30%;
          .mid-left-img{width:80px;height:80px;border-radius:5px;}
        }
        .mid-right{
          width:70%;
          .mid-right-top{width:100%;color:#333;font-size:14px;}
          .mid-right-content{
            width:100%;display:flex;justify-content: space-between;align-items: center;
            .one{
              width:33%;
              text-align:center;
              margin-top:10px;
              .one-top{color:@lightWords;font-size:14px;}
              .one-bottom{
                width:100%;
                color:@primary;font-size:14px;font-weight:bold;line-height:20px;
                overflow: hidden;
                text-overflow:ellipsis;
                white-space: nowrap;
              }
            }
          }
        }
      }
    }
  }
  .dq{vertical-align: middle;}
</style>
