<template>
  <div class="comment-container">
    <div class="com-item">
        <van-row>
          <van-col span="4">
            <img :src="userinfo.avatar" alt="" class="com-useravatar">
          </van-col>
          <van-col span="20">
            <div class="com-user">
              {{userinfo.nickname}}<span class="sf">({{userinfo.position}})</span>
              <van-icon :name="is_zan ? 'thumb-circle' : 'thumb-circle-o'" class="fr f16 icon-dq active" @click="comment_dz"/>
            </div>
            <div class="dat">
              {{comment.date}} <span class="sf">来自{{userinfo.province}}{{userinfo.city}}{{userinfo.area}}</span>
            </div>
            <div class="subtxt">
               {{comment.content}}
            </div>
          </van-col>
        </van-row>
      </div>
  </div>
</template>

<script>
export default {
  props:['comment'],
  data(){
    return{
      userinfo:'',
      is_zan:false
    };
  },
  methods:{
    comment_dz(){
      var that = this;
      console.log(that.comment.id);
      //调用验证码接口
      that.$axios
        .get(that.baseUrl+"/api/comments/commentslike", {
          params: {
            comment_id:that.comment.id
          }
        })
        .then(function(res) {
          console.log(res);
          if(res.data.code == 1){
            that.$toast(res.data.msg);
            that.is_zan = !that.is_zan;
          }
        });
    }
  },
  created(){
    this.userinfo = this.comment.userinfo;
    //console.log(this.userinfo);
  }
};
</script>

<style lang="less" scoped>
@import "~@/assets/styles/var.less";
  .com-item{
    padding:10px 10px 6px;
    border-bottom:dashed 1px #ddd;
    .com-useravatar{
      width:44px;
      height:44px;
      border-radius:50%;
      display:block;
    }
    .com-user{
      line-height:24px;font-size:14px;color:#333;
      .sf{
        font-size:14px;
        color:@lightWords;
        margin-left:5px;
      }
    }
    .dat{padding-top:0px;padding-bottom:5px;font-size:14px;color:@lightWords;}
    .subtxt{line-height:20px;font-size:14px;color:@words;}
  }
  .fr{float:right;}
  .f16{font-size:20px;}
  .icon-dq{
    padding-top:2px;
  }
  .active{
    color:@primary;
  }
</style>
