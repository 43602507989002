<template>
  <div class="workspack">
    <div class="top">
      <div class="info">
        <div class="info_1">
          <img :src="admininfo.bg_image ? admininfo.bg_image : require('../../assets/images/gzt/cunlogo.png')" />
        </div>
        <div class="info_2">
          <div class="info_name">{{ admininfo.town }}</div>
          <div class="info_address ycyc2">
            {{ admininfo.address }}
          </div>
        </div>
        <div class="info_3">
          <div class="info_xx">形象</div>
        </div>
      </div>
      <div class="shuju">
        <div class="shuju_item">
          <div class="num tx-c">{{ admininfo.cmsl }}</div>
          <div class="num_name tx-c">村民</div>
        </div>
        <div class="shuju_item">
          <div class="num tx-c">{{ admininfo.dysl }}</div>
          <div class="num_name tx-c">党员</div>
        </div>
        <div class="shuju_item">
          <div class="num tx-c">{{ admininfo.gbsl }}</div>
          <div class="num_name tx-c">干部</div>
        </div>
        <div class="shuju_item">
          <div class="num tx-c">{{ admininfo.qzlx }}</div>
          <div class="num_name tx-c">群众来信</div>
        </div>
        <div class="shuju_item">
          <div class="num tx-c">{{admininfo.myl}}%</div>
          <div class="num_name tx-c">满意率</div>
        </div>
      </div>
    </div>
    <div class="middle">
      <div class="mid_box">
        <div class="mid_box_tit">宣传介绍</div>
        <div class="mid_box_cen">
          <div class="cen_item" @click="xcjs('cqgk')">
            <div class="cen_icon tx-c">
              <img src="../../assets/images/gzt/gzt01.png" />
            </div>
            <div class="cen_name tx-c">镇情概况</div>
          </div>
          <div class="cen_item" @click="xcjs('cwfc')">
            <div class="cen_icon tx-c">
              <img src="../../assets/images/gzt/gzt02.png" />
            </div>
            <div class="cen_name tx-c">镇委风采</div>
          </div>
          <div class="cen_item" @click="xcjs('crcz')">
            <div class="cen_icon tx-c">
              <img src="../../assets/images/gzt/gzt03.png" />
            </div>
            <div class="cen_name tx-c">镇容镇志</div>
          </div>
          <div class="cen_item" @click="xcjs('zbfc')">
            <div class="cen_icon tx-c">
              <img src="../../assets/images/gzt/gzt04.png" />
            </div>
            <div class="cen_name tx-c">支部风采</div>
          </div>
        </div>
      </div>
      <div class="mid_box">
        <div class="mid_box_tit">事务操作</div>
        <div class="mid_box_cen">
          <div class="cen_item" @click="worklog('log')">
            <div class="cen_icon tx-c">
              <img src="../../assets/images/gzt/gzt05.png" />
            </div>
            <div class="cen_name tx-c">工作日记</div>
          </div>
          <div class="cen_item" @click="worklog('open')">
            <div class="cen_icon tx-c">
              <img src="../../assets/images/gzt/gzt06.png" />
            </div>
            <div class="cen_name tx-c">公示公开</div>
          </div>
          <div class="cen_item" @click="worklog('worknotice')">
            <div class="cen_icon tx-c">
              <img src="../../assets/images/gzt/gzt07.png" />
            </div>
            <div class="cen_name tx-c">通知公告</div>
          </div>
          <div class="cen_item" @click="worklog('sjxx')">
            <div class="cen_icon tx-c">
              <img src="../../assets/images/gzt/gzt08.png" />
            </div>
            <div class="cen_name tx-c">书记信箱</div>
          </div>
          <div class="cen_item" @click="worklog('guide')">
            <div class="cen_icon tx-c">
              <img src="../../assets/images/gzt/gzt09.png" />
            </div>
            <div class="cen_name tx-c">办事指南</div>
          </div>
          <div class="cen_item" @click="goJob">
            <div class="cen_icon tx-c">
              <img src="../../assets/images/gzt/gzt10.png" />
            </div>
            <div class="cen_name tx-c">就业通</div>
          </div>
          <div class="cen_item" @click="worker">
            <div class="cen_icon tx-c">
              <img src="../../assets/images/gzt/gzt11.png" />
            </div>
            <div class="cen_name tx-c">劳动力采集</div>
          </div>
          <div class="cen_item" @click="villager">
            <div class="cen_icon tx-c">
              <img src="../../assets/images/gzt/gtz12.png" />
            </div>
            <div class="cen_name tx-c">返乡登记</div>
          </div>
        </div>
      </div>
      <div class="mid_box">
        <div class="mid_box_tit">宣传介绍</div>
        <div class="mid_box_cen">
          <div class="cen_item" @click="managingVillagers">
            <div class="cen_icon tx-c">
              <img src="../../assets/images/gzt/gzt12.png" />
            </div>
            <div class="cen_name tx-c">村民管理</div>
          </div>
          <div class="cen_item" @click="managingPartyMember">
            <div class="cen_icon tx-c">
              <img src="../../assets/images/gzt/gzt13.png" />
            </div>
            <div class="cen_name tx-c">党员管理</div>
          </div>
          <div class="cen_item" @click="managingOrganizational">
            <div class="cen_icon tx-c">
              <img src="../../assets/images/gzt/gzt14.png" />
            </div>
            <div class="cen_name tx-c">村委干部</div>
          </div>
        </div>
      </div>
    </div>
    <foot-bar></foot-bar>
  </div>
</template>

<script>
import footBar from "../../components/w_foot.vue";

export default {
  components: {
    footBar
  },
  data() {
    return {
      admininfo: ""
    };
  },
  created() {
    this.getAdminInfo();
  },
  methods: {
    getAdminInfo() {
      var that = this;
      that.$axios.get(that.baseUrl + "/api/town_manager/index", {}).then(function(res) {
        //当请求完成时候执行then回调函数
        console.log(res);
        if (res.data.code == 1) {
          window.localStorage.setItem("admin_site_id", res.data.data.id);
          that.admininfo = res.data.data;
        }
      });
    },
    xcjs(type) {
      this.$router.push({
        name: "TownWorkArticleEdit",
        params: {
          type: type
        }
      });
    },
    worklog(type) {
      this.$router.push({
        name: "TownWorkLog",
        params: {
          type: type
        }
      });
    },
    worknotice() {
      this.$router.push({ name: "TownWorkNotice", params: {} });
    },
    goJob() {
      this.$router.push({ name: "TownWorkJob", params: {} });
    },
    worker() {
      this.$router.push({ name: "TownWorkGather", params: {} });
    },
    villager() {
      this.$router.push({ name: "TownWorkVillager", params: {} });
    },
    managingVillagers() {
      this.$router.push({ name: "TownManagingVillagers", params: {} });
    },
    managingPartyMember() {
      this.$router.push({ name: "TownManagingPartyMember", params: {} });
    },
    managingOrganizational() {
      this.$router.push({ name: "TownManagingOrganizational", params: {} });
    }
  }
};
</script>

<style lang="less" scoped>
.workspack {
  .top {
    width: 100%;
    height: 210px;
    background: linear-gradient(to right, #fa6a5f, #f8866b);
    .info {
      padding: 20px;
      display: flex;
      justify-content: flex-start;
      .info_1 {
        width: 25%;
        img {
          width: 60px;
          height: 60px;
          border-radius: 5px;
        }
      }
      .info_2 {
        width: 50%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        .info_name {
          color: #fff;
          font-size: 16px;
          line-height: 24px;
        }
        .info_address {
          color: #fff;
          font-size: 14px;
        }
      }
      .info_3 {
        width: 25%;
        display: flex;
        justify-content: center;
        align-items: center;
        .info_xx {
          width: 60px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          border: solid 1px #fff;
          color: #fff;
          font-size: 14px;
          border-radius: 30px;
        }
      }
    }
    .shuju {
      padding: 20px;
      display: flex;
      .shuju_item {
        width: 20%;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .num {
          color: #fff;
          font-size: 16px;
          font-weight: bold;
        }
        .num_name {
          color: #fff;
          font-size: 14px;
        }
      }
    }
  }
  .middle {
    width: 100%;
    margin-top: -30px;
    padding-bottom: 60px;
    .mid_box {
      margin: 0 20px 20px;
      padding: 10px;
      background: #fff;
      border-radius: 10px;
      box-shadow: 0 1px 6px 2px rgba(0, 0, 0, 0.1);
      .mid_box_tit {
        height: 30px;
        line-height: 30px;
        font-size: 14px;
        color: #888;
        border-bottom: solid 1px #ddd;
        margin-bottom: 10px;
      }
      .mid_box_cen {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: flex-start;
        .cen_item {
          width: 25%;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          .cen_icon {
            margin-bottom: 5px;
            margin-top: 5px;
            img {
              width: 36px;
              height: 36px;
            }
          }
          .cen_name {
            color: #333;
            font-size: 14px;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}
.tx-c {
  text-align: center;
}
.ycyc2{
  overflow: hidden;
  -overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
