<template>
  <div class="baseform-container">
    <c-title :hide="false"
      :text="`发布`"
      tolink=""
      :totext="``">
    </c-title>
    <div class="formbox">
      <!--选择分类-->
      <van-field
        v-if="!is_k"
        v-model="fieldValue"
        is-link
        readonly
        label="分类"
        placeholder="请选择帖子分类"
        @click="show = true"
      />

      <van-field v-model="fieldValue" v-if="is_k" label="帖子分类" disabled/>

      <van-field v-model="bbstitle" label="帖子标题" placeholder="请输入帖子标题" />

      <!--正文-->
      <van-field
        v-model="message"
        rows="5"
        autosize
        label=""
        type="textarea"
        maxlength="300"
        placeholder="请输入帖子正文"
        show-word-limit
      />
      <!--配图-->
      <van-row class="upimg">
        <van-col span="24">图片上传</van-col>
      </van-row>
      <van-row class="upimg">
        <van-col span="24">
          <van-uploader v-model="fileList" multiple :after-read="afterRead"/>
        </van-col>
      </van-row>


    </div>

    <div class="btnbox">
      <van-button type="primary" size="small" :disabled="is_click" block @click="submit">发布</van-button>
    </div>

    <van-popup v-model="show" round position="bottom">
      <van-cascader
        v-model="cascaderValue"
        title="请选择帖子分类"
        :options="options"
        @close="show = false"
        @finish="onFinish"
      />
    </van-popup>
    <foot-bar></foot-bar>
  </div>
</template>

<script>
import cTitle from '../../components/title.vue';
import footBar from '../../components/foot.vue';
export default {
  data() {
    return {
      is_k:true,
      show: false,
      fieldValue: '',
      categroy_ids:'',
      cascaderValue: '',
      bbstitle:'',
      message:'',
      is_click:false,
      // 选项列表，children 代表子选项，支持多级嵌套
      options: [],
      fileList: [],
      img_arr:''
    };
  },
  created(){
    this.get_options();
  },
  methods: {
    get_options(){
      var that = this;
      let id = this.$route.params.id;
      that.$axios
        .get(that.baseUrl+"/api/news/category", {
          params: {
            site:window.localStorage.getItem('site_id'),
            pid:id
          }
        })
        .then(function(res) {
          //console.log(res);
          if(res.data.code == 1){
            if(res.data.data.length > 0){
              var cate_arr = res.data.data;
              cate_arr.forEach((element,i) => {
                //console.log(element);
                var newobj = {};
                newobj.text = element.title;
                newobj.value = element.id;
                if(element.children.length > 0){
                  var child_arr = element.children;
                  child_arr.forEach((item,j) => {
                    var new_child_obj = {};
                    new_child_obj.text = item.title;
                    new_child_obj.value = item.id;
                    newobj.children.push(new_child_obj);
                  });

                }

                that.options.push(newobj);

              });

              that.is_k = false;
            }else{
              that.is_k = true;
              that.getcate_name();

              that.categroy_ids=that.$route.params.id;
            }

          }
        });

    },
    getcate_name(){

      var that = this;
      let id = this.$route.params.id;
      that.$axios
        .get(that.baseUrl+"/api/news/categoryDetail", {
          params: {
            id:id
          }
        })
        .then(function(res) {
          console.log(res);
          if(res.data.code == 1){
            that.fieldValue=res.data.data.title;
          }
        });
    },
    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      var that = this;
      that.img_arr = [];
      if(that.fileList.length>0){
        for (var i=0;i<that.fileList.length;i++){
          var img_url = that.fileList[i].content;
          that.$axios
            .post(that.baseUrl+"/api/common/uploadBase64", {
              params:{
                base64:img_url
              }
            })
            .then(function(res) {//当请求完成时候执行then回调函数

              if(res.data.code == 1){
                that.img_arr += res.data.data.fullurl+',';
              }
            });
        }
      }
    },
    // 全部选项选择完毕后，会触发 finish 事件
    onFinish({ selectedOptions }) {
      this.show = false;
      this.fieldValue = selectedOptions.map((option) => option.text).join('/');
      this.categroy_ids = selectedOptions.map((option) => option.value).join('/');
      console.log(this.categroy_ids);
    },
    submit(){
      var that = this;
      that.is_click = true;

      var categroy_ids = that.categroy_ids;
      if(categroy_ids == ''){
        that.$toast('请选择帖子分类');
        that.is_click = false;
        return false;
      }
      var bbstitle = that.bbstitle;
      if(bbstitle == ''){
        that.$toast('请填写帖子标题');
        that.is_click = false;
        return false;
      }
      var message = that.message;
      if(message == ''){
        that.$toast('请填写帖子内容');
        that.is_click = false;
        return false;
      }

      that.$axios
        .get(that.baseUrl+"/api/news/add", {
          params: {
            site:window.localStorage.getItem('user_village_id'),
            cid:that.categroy_ids,
            huatiid:0,
            title:that.bbstitle,
            cover:that.img_arr,
            content:that.message,
          }
        })
        .then(function(res) {
          console.log(res);
          if(res.data.code == 1){

            that.$toast('发布成功');
            setTimeout(function(){
              that.$router.push("/myrelease");
            },2000);
          }else{
            that.is_click=false;
          }
        });

    }
  },
  components: {
    cTitle,
    footBar
  },
};
</script>

<style lang="less" scoped>

.baseform-container{
  min-height:100vh;
  padding-bottom:60px;
  .formbox{
    padding-bottom:20px;
    .upimg{
      padding:10px 20px;background:#fff;color:#646566;font-size:14px;
    }
    .subbox{
      padding:20px 20px;background:#fff;
      .subbtn{
        margin-top:20px;
      }
    }

  }

}
.tx-r{
  text-align:right;
}
.mr10{
  margin-right:10px;
}
.btnbox{
  padding:0 20px 70px;;
  text-align:center;
}
</style>
