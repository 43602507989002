<template>
  <div class="mymessage-container">
    <c-title :hide="false"
      :text="`我的消息`"
      tolink=""
      :totext="``">
    </c-title>
    <div class="content">
      <van-list
        v-model="loading"
        :finished="finished"
        :immediate-check="false"
        finished-text="没有更多了"
        @load="onLoad"
        :offset="10"
      >
      <div class="item" v-for="(item,index) in messageList" :key="index">
        <van-row class="title">
          <van-col span="24">
            {{item.title}}
          </van-col>
        </van-row>
        <van-row class="fot">
          <van-col span="12" class="dat">
            {{news.createtime}}
          </van-col>
          <van-col span="12" class="tx-r">
            <van-button type="primary" size="mini"  v-if="item.isread==0" @click="read(item.id,index)">未读</van-button>
            <van-button type="default" size="mini"  v-if="item.isread==1">已读</van-button>
          </van-col>
        </van-row>
      </div>



      </van-list>

    </div>
  </div>
</template>

<script>
import cTitle from '../../components/title.vue';
export default {
  data(){
    return {
      messageList:[],
      page:1,
      limit:10,
      loading: false,
      total: 0,//总共的数据条数
      finished: false,
    };
  },
  created(){
    this.getroadList();
  },
  methods:{
    read(id,index){
      let that = this;
      that.$axios
        .get(that.baseUrl+"/api/comments/isreadmessage", {
          params: {
            id:id
          }
        })
        .then(function(res) {
          console.log(res);
          if(res.data.code==1){
            that.messageList[index].isread= 1;
          }
        });
    },
    initMessageList(){
      let that = this;
      that.$axios
        .get(that.baseUrl+"/api/comments/messagelist", {
          params: {
            // page:that.page,
            // limit:that.limit
          }
        })
        .then(function(res) {
          //console.log(res);
          if(res.data.code==1){
            that.messageList= res.data.data.list;
          }
        });
    },
    onLoad() {
      this.page++;
      this.getroadList();
    },
    getroadList(){

      let that = this;
      that.$axios
        .get(that.baseUrl+"/api/comments/messagelist", {
          params: {
            site:window.localStorage.getItem('site_id') ? window.localStorage.getItem('site_id') : '',
            page:that.page,
            limit:that.limit
          }
        })
        .then(function(res) {
          if(res.data.code==1){
            that.loading = false;
            that.total = res.data.data.count;

            var list = res.data.data.list;

            if (list == null || list.length === 0) {
              // 加载结束
              that.finished = true;
              return;
            }
            // 将新数据与老数据进行合并
            that.messageList = that.messageList.concat(list);

            //如果列表数据条数>=总条数，不再触发滚动加载
            if (that.messageList.length >= that.total) {
              that.finished = true;
            }

          }
        });
    }
  },
  components:{
    cTitle
  }
};
</script>

<style lang="less" scoped>
@import "~@/assets/styles/var.less";
.mymessage-container{
  padding:10px;
  .content{
    .item{
      padding:10px;
      background:#fff;
      border-radius:4px;
      margin-bottom:8px;
      .title{
        color:@dark;
        font-size:14px;
        padding-top:5px;
        padding-bottom:4px;
        border-bottom:solid 1px #eee;
      }
      .fot{
        padding-top:4px;
        .dat{
          color:@gray;
          font-size:13px;
        }
      }
    }
  }
}
.t-red{
  color:#f30;
  font-size:20px;
  vertical-align: middle;
}
</style>
