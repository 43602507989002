<template>
  <div class="my-container">
    <!--顶部个人信息-->
    <div class="top">
      <van-row>
         <div class="tx-c van-col van-col--6" @click="setup">
          <img :src="avatar" alt="" class="useravatar">
        </div>
        <van-col span="10">
          <p class="t-wh f14 pt05" v-if="userinfo.verification == 2">
            <van-icon name="award-o" class="icon-dq"/> 认证村民
          </p>
          <p class="t-wh f14 pt05" v-else>
            <van-icon name="award-o" class="icon-dq"/> 身份审核中
          </p>
          <p class="t-wh f14 pt05">{{userinfo.village}}村民 ID{{userinfo.id}}</p>
        </van-col>
        <van-col span="8 tx-r f16"  @click="gohomepage">

          <!-- <van-icon name="friends-o" class="t-wh mr10" @click="goto('/my/grid')"/> -->
          <!-- <van-icon name="service-o" class="t-wh mr10"/>
          <van-icon name="setting-o" class="t-wh mr10" @click="setup"/> -->
          <div class="grzy">个人主页</div>

        </van-col>
      </van-row>
      <van-row class="pb1 pt05">
        <van-col span="6" class="tx-c t-wh f14">
          {{username}}
        </van-col>
        <van-col span="18">
          <p class="t-wh f14 ycyc">{{userinfo.province}}{{userinfo.city}}{{userinfo.area}}</p>
        </van-col>
      </van-row>
    </div>
    <!--我的积分-->
    <div class="integral" v-show="false">
      <van-row>
        <div class="tx-c van-col van-col--6" @click="waitfor">
          <!-- to="/myintegral" -->
            <p class="jf">0</p>
            <p class="zjf pt05">总积分</p>
        </div>
        <van-col span="8" class="tx-c"  @click="waitfor">
            <p class="jf">0.00</p>
            <p class="zjf pt05">积分余额</p>
        </van-col>
        <van-col span="8" class="tx-c"  @click="waitfor">
            <p class="dh">
              <span class="duihuan mr10">兑换</span>
              <span class="qixian">期限</span>
            </p>
            <p class="zjf pt05">积分商城</p>
        </van-col>
      </van-row>
    </div>
    <!--我的订单-->
    <div class="order">
        <!-- <h4>我的订单</h4> -->
        <van-row>
          <router-link tag="div" class="tx-c van-col van-col--5" to="/myrelease">
            <p><img src="../../assets/images/user/my_fabu.png" class="icon1"></p>
            <p class="sbt pt05">我的发布</p>
          </router-link>
          <router-link tag="div" class="tx-c van-col van-col--5" to="/myfollow">
            <p><img src="../../assets/images/user/my_guanzhu.png" class="icon1"></p>
            <p class="sbt pt05">我的关注</p>
          </router-link>
          <van-col span="4" class="tx-c"  @click="waitfor">
            <p><img src="../../assets/images/user/my_jifen.png" class="icon1"></p>
            <p class="sbt pt05">我的积分</p>
          </van-col>
          <van-col span="5" class="tx-c"  @click="waitfor">
            <p><img src="../../assets/images/user/my_huiyi.png" class="icon1"></p>
            <p class="sbt pt05">我的会议</p>
          </van-col>

          <router-link tag="div" class="tx-c van-col van-col--5" to="/maillist">
            <p><img src="../../assets/images/user/my_txl.png" class="icon1"></p>
            <p class="sbt pt05">村民通讯录</p>
          </router-link>

        </van-row>

    </div>
    <!--常用功能-->
    <div class="comfunction">
      <h3 class="pb10">常用功能</h3>
      <van-row class="pb1">
        <van-col span="8"  @click="waitfor">
          <p class="wdxx">积分乐园</p>
          <!-- <p class="wdxx_s">家庭环境 活动 积分</p> -->
        </van-col>
        <van-col span="3" class="tx-c"  @click="waitfor">
          <img src="../../assets/images/user/cy_01.png" alt="" class="gnimg">
        </van-col>
        <van-col span="2"></van-col>
        <van-col span="8"  @click="waitfor">
          <p class="wdxx">邀请村民</p>
          <!-- <p class="wdxx_s">咨询和问答的反馈</p> -->
        </van-col>
        <van-col span="3" class="tx-c"  @click="waitfor">
          <img src="../../assets/images/user/cy_02.png" alt="" class="gnimg">
        </van-col>
      </van-row>
      <van-row class="pb1">
        <van-col span="8"  @click="waitfor">
          <p class="wdxx">意见反馈</p>
          <!-- <p class="wdxx_s">平台各类问题反馈</p> -->
        </van-col>
        <van-col span="3" class="tx-c"  @click="waitfor">
          <img src="../../assets/images/user/cy_03.png" alt="" class="gnimg">
        </van-col>
        <van-col span="2"></van-col>
        <div class="van-col van-col--8"  @click="waitfor">
           <!-- to="/member/myzixun" -->
          <p class="wdxx">商家服务</p>
          <!-- <p class="wdxx_s">申请成为商家开店</p> -->
        </div>
        <van-col span="3" class="tx-c">
          <img src="../../assets/images/user/cy_04.png" alt="" class="gnimg">
        </van-col>
      </van-row>
      <van-row class="pb1">
        <van-col span="8" @click="workspace">
          <p class="wdxx">工作台</p>
          <!-- <p class="wdxx_s">管理工作入口</p> -->
        </van-col>
        <van-col span="3" class="tx-c"   @click="waitfor">
          <img src="../../assets/images/user/cy_05.png" alt="" class="gnimg">
        </van-col>
        <van-col span="2"></van-col>
        <van-col span="8"   @click="waitfor">
          <p class="wdxx">党务服务</p>
          <!-- <p class="wdxx_s">平台帮助中心</p> -->
        </van-col>
        <van-col span="3" class="tx-c"   @click="waitfor">
          <img src="../../assets/images/user/cy_06.png" alt="" class="gnimg">
        </van-col>
      </van-row>
    </div>

    <div class="tools">
        <h3 class="pb10">更多工具</h3>
        <van-row class="pb1 pt05">
          <van-col span="6" class="tx-c" @click="waitfor">
            <p><img src="../../assets/images/user/gj_01.png"  class="gdgj_icon"></p>
            <p class="sbt pt05">户联码</p>
          </van-col>
          <van-col span="6" class="tx-c" @click="waitfor">
            <p><img src="../../assets/images/user/gj_02.png"  class="gdgj_icon"></p>
            <p class="sbt pt05">我的奖章</p>
          </van-col>
          <van-col span="6" class="tx-c" @click="waitfor">
            <p><img src="../../assets/images/user/gj_03.png"  class="gdgj_icon"></p>
            <p class="sbt pt05">我的礼物</p>
          </van-col>
          <van-col span="6" class="tx-c" @click="waitfor">
            <p><img src="../../assets/images/user/gj_04.png"  class="gdgj_icon"></p>
            <p class="sbt pt05">我的订单</p>
          </van-col>
        </van-row>
        <van-row class="pb1 pt05">
          <!-- <van-col span="6" class="tx-c" @click="waitfor">
            <p><img src="../../assets/images/user/gj_05.png"  class="gdgj_icon"></p>
            <p class="sbt pt05">邀请村民</p>
          </van-col> -->
          <van-col span="6" class="tx-c" @click="waitfor">
            <p><img src="../../assets/images/user/gj_11.png"  class="gdgj_icon"></p>
            <p class="sbt pt05">智能手表</p>
          </van-col>
          <router-link tag="div" class="tx-c van-col van-col--6"  to="/myplant">
            <p><img src="../../assets/images/user/gj_06.png"  class="gdgj_icon"></p>
            <p class="sbt pt05">我的种植</p>
          </router-link>
          <router-link tag="div" class="tx-c van-col van-col--6"  to="/mybreed">
            <p><img src="../../assets/images/user/gj_07.png"  class="gdgj_icon"></p>
            <p class="sbt pt05">我的养殖</p>
          </router-link>
          <router-link tag="div" class="tx-c van-col van-col--6"  to="/mysubsidy">
            <p><img src="../../assets/images/user/gj_12.png"  class="gdgj_icon"></p>
            <p class="sbt pt05">我的补贴</p>
          </router-link>
        </van-row>
        <van-row class="pb1 pt05">
          <van-col span="6" class="tx-c" @click="waitfor">
            <p><img src="../../assets/images/user/gj_09.png"  class="gdgj_icon"></p>
            <p class="sbt pt05">我的收藏</p>
          </van-col>
          <van-col span="6" class="tx-c" @click="setup">
            <p><img src="../../assets/images/user/gj_10.png"  class="gdgj_icon"></p>
            <p class="sbt pt05">个人信息</p>
          </van-col>
          <div class="tx-c van-col van-col--6" @click="waitfor">
            <p><img src="../../assets/images/user/gj_15.png"  class="gdgj_icon"></p>
            <p class="sbt pt05">认领村庄</p>
          </div>
          <van-col span="6" class="tx-c"   @click="waitfor">
            <p><img src="../../assets/images/user/gj_08.png"  class="gdgj_icon"></p>
            <p class="sbt pt05">我的活动</p>
          </van-col>
        </van-row>
        <van-row class="pt05">
          <!-- <div class="tx-c van-col van-col--6" @click="waitfor">
            <p><img src="../../assets/images/user/gj_13.png"  class="gdgj_icon"></p>
            <p class="sbt pt05">种养计划</p>
          </div> -->
          <!-- <router-link tag="div" class="tx-c van-col van-col--6"  to="/myindustry">
            <p><img src="../../assets/images/user/gj_14.png"  class="gdgj_icon"></p>
            <p class="sbt pt05">我的产业</p>
          </router-link> -->

          <div class="tx-c van-col van-col--6" @click="signout">
            <p><van-icon name="browsing-history-o" class="toolicon"/></p>
            <p class="sbt pt05">退出登录</p>
          </div>
        </van-row>

     </div>

    <div style="height:60px;"></div>
     <foot-bar></foot-bar>
  </div>
</template>

<script>
import footBar from '../../components/foot.vue';
import { mapMutations } from 'vuex';
export default {
  data(){
    return {
      user_id:'',
      avatar:'',
      username:'',
      mobile:'',
      userinfo:''
    };
  },
  created(){
    if(window.localStorage.getItem("userinfo")){
      this.userinfo = JSON.parse(window.localStorage.getItem("userinfo"));
    }
    //console.log(this.userinfo);
  },
  mounted(){
    if(window.localStorage.getItem("Authorization")){
      this.initData();
    }else{
      that.$router.push("/login");
    }

  },
  methods:{
    ...mapMutations(['changeLogin']),
    initData(){
      this.user_id = localStorage.getItem("user_id");
      this.avatar = localStorage.getItem("avatar");
      this.username = localStorage.getItem("username");
      this.mobile = localStorage.getItem("mobile");

    },

    goto(name){
      this.$router.replace(name);
    },
    gohomepage(){
      this.$router.push({name:'HomePage',params:{
        id:window.localStorage.getItem('user_id')
      }});
    },
    signout(){
      var that = this;
      that.$axios
        .get(that.baseUrl+"/api/user/logout", {//地址是get请求地址
          params: {

          }
        })
        .then(function(res) {//当请求完成时候执行then回调函数
          console.log(res);
          // that.$toast(res.data.msg);
          if(res.data.code == 1){
            that.$toast(res.data.msg);
            //清除缓存登录数据
            window.localStorage.removeItem("Authorization");
            window.localStorage.removeItem("user_id");
            window.localStorage.removeItem("avatar");
            window.localStorage.removeItem("username");
            window.localStorage.removeItem("mobile");
            window.localStorage.removeItem("userinfo");
            // window.localStorage.removeItem("user_village_id");
            // window.localStorage.removeItem("user_town_id");
            window.localStorage.removeItem("verification");
            window.localStorage.removeItem("is_admin");
            window.localStorage.removeItem("admin_type");

            that.changeLogin({ Authorization: '' });
            var type = window.localStorage.getItem("type");
            setTimeout(function(){
              if(type == '镇'){
                that.$router.push("/townhome");
              }else if(type == '村'){
                that.$router.push("/home");
              }else if(type == '县'){
                that.$router.push("/countyhome");
              }else{
                that.$router.push("/login");
              }

            },2000);
          }
        });
    },
    setup(){
      this.$router.replace('/my/setup');
    },
    waitfor(){
      this.$toast('数据对接中，即将开放');
    },
    workspace(){
      var is_admin =  window.localStorage.getItem('is_admin');
      var admin_type =  window.localStorage.getItem('admin_type');
      if(is_admin){
        if(admin_type == 'village'){
          this.$router.push({name:'WorkIndex',params:{
            type:'WorkIndex'
          }});
        }else if(admin_type == 'town'){
          this.$router.push({name:'TownWorkIndex',params:{
            type:'TownWorkIndex'
          }});
        }

      }else{
        this.$toast('您无权限');
      }

    }
  },
  components: {
    footBar,
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/styles/var.less";
.grzy{
  margin-right:10px;height:30px;line-height:30px;padding-right:33px;background:url(../../assets/images/user/grzy.png) no-repeat;background-size:30px 30px;
  background-position:right center;
  color:#fff;font-size:14px;
}
.my-container{
  padding:20px 0;
  background:linear-gradient(to bottom,@primary,#f5f5f5);
  .top{
    .tx-c{
      .useravatar{width:50px;height:50px;border:solid 1px #fff;border-radius:50%;}
    }

  }
  .integral{
    background:#fff;
    margin:5px 15px;
    padding:30px 0;
    border-radius:4px;
  }
  .order{
    background:#fff;
    margin:10px 15px;
    padding:15px 0 20px;
    border-radius:4px;
    h4{padding-left:15px;margin-bottom:15px;}
    .myord{
      font-size:30px;color:@primary;
    }
    .sbt{
      color:@words;
      font-size:14px;
    }
  }
  .comfunction{
    background:#fff;
    margin:10px 15px;
    padding:10px;
    border-radius:4px;
    h4{padding-left:5px;margin-bottom:15px;}
  }
  .tools{
    background:#fff;
    margin:10px 15px;
    padding:15px 10px 20px;
    border-radius:4px;
    h4{padding-left:15px;margin-bottom:15px;}
    .toolicon{
      font-size:30px;color:@primary;
    }
    .sbt{
      color:@words;
      font-size:14px;
    }
  }
}
.t-wh{color:#fff;}
.tx-c{text-align:center;}
.icon-dq{vertical-align:-14%}
.f14{font-size:14px;}
.f16{font-size:16px;}
.pt05{padding-top:5px;}
.mr10{margin-right:10px;}
.jf{font-size:20px;color:#f80;font-weight:bold;height:28px;line-height:28px;}
.dh{font-size:16px;color:#f80;height:28px;line-height:28px;}
.zjf{color:#787878;font-size:13px;}
.pb1{padding-bottom:15px;}
.gnimg{width:30px;height:30px;}
.wdxx{font-size:13px;font-weight:bold;color:@primary}
.wdxx_s{
  font-size:14px;color:@lightWords;
}
.pb10{padding-bottom:10px;}
.gdgj_icon{width:30px;height:30px;}
.tx-r{text-align:right;}
.ycyc{overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;}
  .icon1{width:30px;height:30px;}
</style>
