<template>
  <div class="userinfo-container">
    <c-title :hide="false"
      :text="`编辑个人信息`"
      tolink=""
      :totext="``">
    </c-title>
    <div class="formbox">

      <van-row class="utxbox">
        <van-col span="4" class="utx_tit">头像</van-col>
        <van-col span="20" class="tx-r dw">
          <img :src="user_avatar" class="utx"/>
          <input class="input-loc-img"  name="imgLocal" type='file' accept="image/*" @change='onchangeImgFun' />
        </van-col>
      </van-row>

      <van-field v-model="user_name" label="用户名" placeholder="请输入用户名" />
      <van-field v-model="user_nc" label="昵称" placeholder="请输入您的昵称" />

      <!--正文-->
      <van-field
        v-model="user_jianjie"
        rows="5"
        autosize
        label=""
        type="textarea"
        maxlength="300"
        placeholder="请输入个人简介"
        show-word-limit
      />



    </div>

    <div class="btnbox">
      <van-button type="primary" size="small" block @click="submit">提交<u> </u></van-button>
    </div>



  </div>
</template>

<script>
import cTitle from '../../components/title.vue';

export default {
  data() {
    return {
      user_name: localStorage.username ? localStorage.username : '',
      user_nc: localStorage.username ? localStorage.username : '',
      user_jianjie:'',
      user_avatar:localStorage.avatar ? localStorage.avatar : require('../../assets/images/user.png')

    };
  },
  methods: {
    submit(){
      if(this.user_name == ''){
        this.$toast('真实姓名不能为空');
        return false;
      }
      if(this.user_nc == ''){
        this.$toast('昵不能为空');
        return false;
      }
      if(this.user_jianjie == ''){
        this.$toast('个人简介不能为空');
        return false;
      }
      var that = this;
      that.$axios
        .get(that.baseUrl+"/api/user/profile", {//地址是get请求地址
          params: {
            avatar:that.user_avatar,
            username:that.user_name,
            nickname:that.user_nc,
            bio:that.user_jianjie,
            token:that.$store.state.Authorization.Authorization
          }
        })
        .then(function(res) {//当请求完成时候执行then回调函数
          console.log(res);
          if(res.data.code == 1){
            window.localStorage.setItem("avatar",that.user_avatar);
            window.localStorage.setItem("username",that.user_name);
            that.$toast('提交成功');
            setTimeout(function(){
              that.$router.push("/my");
            },2000);
          }else{
            that.$toast(res.data.msg);
            return false;
          }

        });

    },
    onchangeImgFun (e) {
      var file = e.target.files[0];
      var _this = this; // this指向问题，所以在外面先定义
      // base64方法 2
      var reader = new FileReader();
      reader.readAsDataURL(file); // 读出 base64
      reader.onloadend = function () {
      // 图片的 base64 格式, 可以直接当成 img 的 src 属性值
        var dataURL = reader.result;
        //console.log(dataURL);
        _this.user_avatar = dataURL;
        // 下面逻辑处理
        //console.log(dataURL);

        _this.$axios
          .post(_this.baseUrl+"/api/common/uploadBase64", {
            params:{
              base64:dataURL
            }
          })
          .then(function(res) {
            console.log(res);
            if(res.data.code == 1){
              _this.user_avatar = res.data.data.fullurl;
            }
          });


      };

    }

  },
  components: {
    cTitle,
  },
};
</script>

<style lang="less" scoped>
.dw{position: relative;}
.input-loc-img{width:50px;height:50px;opacity: 0;position:absolute;right:0;top:0;}
.img-radius{border-radius:50%;}
.van-uploader__upload{width:50px !important;height:50px !important;float:right !important;}
.utx_tit{color:#646566;font-size:0.38rem;line-height:50px;}
.tx-r{text-align:right;}
.utxbox{
  padding:10px 16px;background:#fff;height:50px;border-bottom:solid 1px #f5f5f5;
}
.utx{width:50px;height:50px;border-radius:50%;}
.userinfo-container{
  min-height:100vh;
  padding-bottom:60px;
  .formbox{
    padding-bottom:20px;
    .upimg{
      padding:10px 20px;background:#fff;color:#646566;font-size:14px;
    }
    .subbox{
      padding:20px 20px;background:#fff;
      .subbtn{
        margin-top:20px;
      }
    }

  }

}
.tx-r{
  text-align:right;
}
.mr10{
  margin-right:10px;
}
.btnbox{
  padding:0 20px;
  text-align:center;
}
</style>
