<template>
  <div class="myintegral-container">
    <c-title :hide="false"
      :text="`我的积分`"
      tolink=""
      :totext="``">
    </c-title>
    <div class="intbox">

      <div class="int-item">
        <van-row class="top">
          <van-col span="16" class="dat">发布帖子</van-col>
          <van-col span="8" class="addnum tx-r">
            + 10
          </van-col>
        </van-row>
        <van-row class="fot">
          <van-col span="24" class="dat">2021-09-22 11:18</van-col>
        </van-row>
      </div>

      <div class="int-item">
        <van-row class="top">
          <van-col span="16" class="dat">发布帖子</van-col>
          <van-col span="8" class="addnum tx-r">
            + 10
          </van-col>
        </van-row>
        <van-row class="fot">
          <van-col span="24" class="dat">2021-09-22 11:18</van-col>
        </van-row>
      </div>

      <div class="int-item">
        <van-row class="top">
          <van-col span="16" class="dat">评论帖子</van-col>
          <van-col span="8" class="addnum tx-r">
            + 1
          </van-col>
        </van-row>
        <van-row class="fot">
          <van-col span="24" class="dat">2021-09-22 11:18</van-col>
        </van-row>
      </div>


    </div>


  </div>
</template>

<script>
import cTitle from '../../components/title.vue';
export default {
  components: {
    cTitle
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/styles/var.less";
.myintegral-container{
  height:100%;
  .intbox{
    padding:10px;
    .int-item{
      padding:10px;
      background:#fff;
      margin-bottom:5px;
      .top{
        padding-top:5px;padding-bottom:5px;border-bottom:dashed 1px #ddd;
        .dat{
          color:@dark;
          font-size:14px;
        }
        .addnum{
          color:@primary;
          font-weight:bold;
        }
      }
      .fot{
        padding-top:5px;
        padding-bottom:5px;
        .dat{
          color:@lightWords;
          font-size:14px;
        }
      }
    }
  }
}
.tx-r{text-align:right;}
</style>
