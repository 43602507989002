<template>
  <div class="microwish-container">
    <div class="content">
      <van-field
        v-model="wish_name"
        name="wish_name"
        label="心愿名称"
        placeholder="请填写心愿名称"
        :rules="[{ required: true, message: '心愿名称不能为空' }]"
      />
      <van-field
        v-model="wish_contacts"
        name="wish_contacts"
        label="联系人"
        placeholder="请填写联系人"
        :rules="[{ required: true, message: '联系人不能为空' }]"
      />

      <van-field
          v-model="telphone"
          name="telphone"
          label="联系电话"
          placeholder="请输入您的联系电话"
          :rules="[{ required: true, message: '联系电话不能为空' }]"
        />
      <van-field
        v-model="message"
        rows="5"
        autosize
        label=""
        type="textarea"
        mINlength="20"
        maxlength="300"
        placeholder="在此输入心愿内容"
        show-word-limit
      />
      <div class="upload-img">
        <van-uploader v-model="fileList" multiple :after-read="afterRead"/>
      </div>



    </div>
     <div class="btnbox">
        <van-button round type="info" color="#ccc" class="mr10" @click="goback"> 取消 </van-button>
        <van-button round type="info" color="#00a752" :disabled="is_click" @click="submit"> 发布 </van-button>
      </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      wish_name:'',
      wish_contacts:'',
      telphone:'',
      message:'',
      fileList: [],
      img_arr:'',
      is_click:false,
    };
  },
  methods:{
    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      var that = this;
      that.img_arr = [];
      if(that.fileList.length>0){
        for (var i=0;i<that.fileList.length;i++){
          var img_url = that.fileList[i].content;
          that.$axios
            .post(that.baseUrl+"/api/common/uploadBase64", {
              params:{
                base64:img_url
              }
            })
            .then(function(res) {//当请求完成时候执行then回调函数
              if(res.data.code == 1){
                that.img_arr += res.data.data.fullurl+',';
              }
            });
        }
      }
    },
    submit(){
      var that = this;
      that.is_click = true;

      var wish_name = that.wish_name;
      if(wish_name == ''){
        that.$toast('请填写心愿名称');
        that.is_click = false;
        return false;
      }
      var wish_contacts = that.wish_contacts;
      if(wish_contacts == ''){
        that.$toast('请填写联系人');
        that.is_click = false;
        return false;
      }
      var telphone = that.telphone;
      if(telphone == ''){
        that.$toast('请填写联系电话');
        that.is_click = false;
        return false;
      }
      var message = that.message;
      if(message == ''){
        that.$toast('请填写心愿内容');
        that.is_click = false;
        return false;
      }

      var extend = {};
      extend[0] = '联系人：'+wish_contacts;
      extend[1] = '联系电话：'+telphone;
      var new_extend = JSON.stringify(extend);
      // console.log(new_extend);
      // console.log(typeof JSON.stringify(extend));

      that.$axios
        .get(that.baseUrl+"/api/news/add", {
          params:{
            site:localStorage.getItem('user_village_id'),
            cid:that.$route.params.id,
            huatiid:0,
            title:that.wish_name,
            cover:that.img_arr,
            content:that.message,
            extra:new_extend
          }
        })
        .then(function(res) {
          console.log(res);
          if(res.data.code == 1){

            that.$toast('发布成功');
            setTimeout(function(){
              that.$router.push("/myrelease");
            },2000);
          }else{
            that.is_click=false;
          }
        });
    },

    goback(){
      this.$router.go(-1);
    },
  }
};
</script>

<style lang="less" scoped>

.upload-img{
    padding:15px;
    background:#fff;
  }
  .mr10{margin-right:10px;}
  .btnbox{
    padding:20px;
    text-align:center;
}
</style>
