<template>
  <div class="signin-container">
    <div class="top">
      <div class="btnbg"></div>
      <div class="btn">签 到</div>
    </div>
    <div class="qiandao">
      <van-row>
        <van-col span="12" class="f14b">
          已连续签到 <span class="t-org">2</span> 天
        </van-col>
        <van-col span="12" class="tx-r f14b">
          签到提醒 <van-switch v-model="checked" size="24px" class="switch-tx" inactive-color="#dcdee0" active-color="#00a752"/>
        </van-col>
      </van-row>
      <div class="dtlist">
        <div class="list-item active">
          <p><span class="jia1">+1</span></p>
          <p><span class="riqi">1日</span></p>
        </div>
        <div class="list-item active">
          <p><span class="jia1">+1</span></p>
          <p><span class="riqi">2日</span></p>
        </div>
        <div class="list-item">
          <p><span class="jia1">+1</span></p>
          <p><span class="riqi">3日</span></p>
        </div>
        <div class="list-item">
          <p><span class="jia1">+1</span></p>
          <p><span class="riqi">4日</span></p>
        </div>
        <div class="list-item">
          <p><span class="jia1">+1</span></p>
          <p><span class="riqi">5日</span></p>
        </div>
        <div class="list-item">
          <p><span class="jia1">+1</span></p>
          <p><span class="riqi">6日</span></p>
        </div>
        <div class="list-item">
          <p><span class="jia1">+1</span></p>
          <p><span class="riqi">7日</span></p>
        </div>
        <div class="list-item">
          <p><span class="jia1">&nbsp;+&nbsp;</span></p>
          <p><span class="riqi">更多</span></p>
        </div>
      </div>
    </div>

    <div class="renwu">
      <van-row>
        <van-col span="12" class="f14b">
          今日任务
        </van-col>
        <van-col span="12" class="tx-r f14 t-gay">
          已完成2/4
        </van-col>
      </van-row>
      <van-row class="pt10">
        <van-col span="24" class="jdt_text">今天完成所有任务可以额外获得1积分</van-col>
      </van-row>
      <van-row class="ptb20">
        <van-col span="24">
          <van-progress pivot-text="50%" color="#00a752" :percentage="50" stroke-width="15"/>
        </van-col>
      </van-row>
    </div>

    <div class="rwlist">
      <div class="record ptb10">
        <van-row>
          <van-col span="4" class="tx-c">
            <span class="qd">
              <van-icon name="notes-o" size="50"/>
            </span>
          </van-col>
          <van-col span="12">
            <p class="f14b pl05 pt05">每日签到</p>
            <p class="sbt pl05">每日签到可得海量积分</p>
          </van-col>
          <van-col span="8" class="tx-r">
            <van-button round type="info" color="linear-gradient(to right, #d3d3d3, #f6f6f5)">已完成</van-button>
          </van-col>
        </van-row>
      </div>

      <div class="record ptb10">
        <van-row>
          <van-col span="4" class="tx-c">
            <span class="dl">
              <van-icon name="user-o" size="50"/>
            </span>
          </van-col>
          <van-col span="12">
            <p class="f14b pl05 pt05">每日登录</p>
            <p class="sbt pl05">每日签到可得海量积分</p>
          </van-col>
          <van-col span="8" class="tx-r">
            <van-button round type="info" color="linear-gradient(to right, #d3d3d3, #f6f6f5)">已完成</van-button>
          </van-col>
        </van-row>
      </div>

      <div class="record ptb10">
        <van-row>
          <van-col span="4" class="tx-c">
            <span class="fb">
              <van-icon name="edit" size="50"/>
            </span>
          </van-col>
          <van-col span="12">
            <p class="f14b pl05 pt05">发表帖子</p>
            <p class="sbt pl05">每日发表帖子可得1个积分</p>
          </van-col>
          <van-col span="8" class="tx-r">
            <van-button round type="info" color="linear-gradient(to right, #44b97b, #f3ed71)">去完成</van-button>
          </van-col>
        </van-row>
      </div>

      <div class="record ptb10">
        <van-row>
          <van-col span="4" class="tx-c">
            <span class="hf">
              <van-icon name="chat-o" size="50"/>
            </span>
          </van-col>
          <van-col span="12">
            <p class="f14b pl05 pt05">回复帖子</p>
            <p class="sbt pl05">每日回复帖子可加1个积分</p>
          </van-col>
          <van-col span="8" class="tx-r">
            <van-button round type="info" color="linear-gradient(to right, #44b97b, #f3ed71)">去完成</van-button>
          </van-col>
        </van-row>
      </div>

    </div>
    <foot-bar></foot-bar>
  </div>

</template>

<script>
import footBar from '../../components/foot.vue';
export default {
  data(){
    return {
      checked: true,
      pcolor:'#00a752',
    };
  },
  components: {
    footBar,
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/styles/var.less";
.signin-container{
  background:linear-gradient(to bottom, @primary, #f5f5f5);
  padding:10px;
  padding-bottom:60px;
  .top{
    width:100%;height:140px;
    overflow:hidden;
    position:relative;
    z-index:1;
    .btnbg{
      width:60px;height:60px;border:solid 2px #fff;border-radius:50%;
      position:absolute;
      top:50%;margin-top:-32px;
      left:50%;margin-left:-32px;
      background:#fff;
      z-index:2;
      animation: breathing 2s linear infinite ;
      //nimation-delay: -2s;
    }
    .btn{
      width:60px;height:60px;border:solid 1px #fff;border-radius:50%;
      position:absolute;
      top:50%;margin-top:-31px;
      left:50%;margin-left:-31px;
      background:#fff;
      z-index:3;
      text-align:center;
      line-height:60px;
      color:@primary;
      font-size:16px;
    }
    @keyframes breathing{
    0%{
        transform: scale(.9);
        opacity: 1;
    }
    100%{
        transform: scale(2);
        opacity:0;
    }
}
  }
  .qiandao{
    padding:15px;
    background:#fff;
    border-radius:10px;
    .dtlist{
      width:100%;
      display:flex;
      justify-content: space-between;
      .list-item{
        width:11%;
        height:80px;
        background:#d3d3d3;
        border-radius:5px;
        text-align:center;
        margin-top:10px;
        .jia1{padding:5px;color:@dark;font-size:14px;background:#9a9a9a;border-radius:15px;display:inline-block;margin-top:15px;margin-bottom:10px;}
        .riqi{color:@dark;}
      }
      .list-item.active{
        background:@primary;
        .jia1{
          background:#008c44;color:#fff;
        }
        .riqi{
          color:#fff;
        }
      }
    }
  }
  .renwu{
    margin-top:10px;
    padding:15px;
    background:#fff;
    border-radius:10px;
    .jdt_text{
      color:@lightWords;
      font-size:14px;
    }
  }
  .rwlist{
    margin-top:10px;
    padding:15px;
    background:#fff;
    border-radius:10px;
    .record{
      width:100%;
      .qd{
        width:46px;
        height:46px;
        border-radius:50%;
        background:#00aeef;
        color:#fff;
        display:inline-block;
        text-align:center;
        line-height:60px;
      }
      .dl{
        width:46px;
        height:46px;
        border-radius:50%;
        background:#23a3a1;
        color:#fff;
        display:inline-block;
        text-align:center;
        line-height:60px;
      }
      .fb{
        width:46px;
        height:46px;
        border-radius:50%;
        background:#e3636d;
        color:#fff;
        display:inline-block;
        text-align:center;
        line-height:60px;
      }
      .hf{
        width:46px;
        height:46px;
        border-radius:50%;
        background:#df493e;
        color:#fff;
        display:inline-block;
        text-align:center;
        line-height:60px;
      }
    }
  }
}
.t-org{color:#f60;}
.f14b{font-size:14px;font-weight:bold;color:@dark;}
.sbt{font-size:14px;color:@lightWords;}
.tx-r{text-align:right;}
.switch-tx{vertical-align: -2px;}
.t-gay{color:@lightWords;}
.pt10{padding-top:10px;}
.ptb20{padding-top:20px;padding-bottom:20px;}
.pl05{padding-left:5px;}
.pt05{padding-top:5px;}
.ptb10{padding-bottom:10px;padding-top:10px;}
</style>
