<template>
  <div class="myrelease-container">
    <c-title :hide="false"
      :text="`我的发布`"
      tolink="/fabu"
      :totext="`我要发布`">
    </c-title>

    <van-list
      v-model="loading"
      :finished="finished"
      :immediate-check="false"
      finished-text="没有更多了"
      @load="onLoad"
      :offset="10"
    >

      <van-tabs v-model="activeName">
        <van-tab title="我的发布" name="a">

          <van-row class="item-list" v-for="(item,index) in myPosts" :key="index">
            <van-col span="4" v-if="item.cover_arr.length > 0">
              <img  :src="item.cover_arr[0]" class="fbimg"/>
            </van-col>
            <van-col :span="item.cover_arr.length > 0 ? '18' : '22'"  @click="goDetail(item.id)">
              <p class="tit">{{item.title}}</p>
              <p class="dat">{{news.createtime}}</p>
            </van-col>
            <van-col span="2" class="flex" style="height:38px;"  @click="del_bbs(item.id,index)">
              <van-icon name="delete-o" size="24"/>
            </van-col>
          </van-row>
          <van-empty
            v-show="show1"
            class="custom-image"
            image="https://img.yzcdn.cn/vant/custom-empty-image.png"
            description="暂无数据">
          </van-empty>

        </van-tab>
        <van-tab title="我收到的回复" name="b">
          <van-row class="item-list" v-for="(item,index) in myReply" :key="index">
            <van-col span="4" v-if="item.news.cover_arr.length > 0">
              <img :src="item.news.cover_arr[0]" class="fbimg"/>
            </van-col>
            <van-col :span="item.news.cover_arr.length > 0 ? '20' : '24'">
              <p class="tit">{{item.news.title}}</p>
              <p class="com">清风回复了我：{{item.content}}</p>
              <p class="dat">{{item.createtime_text}}</p>
            </van-col>
          </van-row>
          <van-empty
            v-show="show2"
            class="custom-image"
            image="https://img.yzcdn.cn/vant/custom-empty-image.png"
            description="暂无数据">
          </van-empty>

        </van-tab>
        <van-tab title="我的评论" name="c">
          <van-row class="item-list" v-for="(item,index) in myComment" :key="index" @click="gotonews(item.news.id)">
            <van-col span="4" v-if="item.news.cover_arr.length > 0">
              <img  :src="item.news.cover_arr[0]" class="fbimg"/>
            </van-col>
            <van-col :span="item.news.cover_arr.length > 0 ? '20' : '24'">
              <p class="tit">{{item.news.title}}</p>
              <p class="com">我的评论：{{item.content}}</p>
              <p class="dat">点赞：{{item.news.ups}} 发布时间：{{item.createtime_text}}</p>
            </van-col>
          </van-row>
          <van-empty
            v-show="show3"
            class="custom-image"
            image="https://img.yzcdn.cn/vant/custom-empty-image.png"
            description="暂无数据">
          </van-empty>
        </van-tab>
      </van-tabs>

    </van-list>
  </div>
</template>

<script>
import cTitle from '../../components/title.vue';
import Vue from 'vue';
import { Empty } from 'vant';
Vue.use(Empty);

export default {
  data() {
    return {
      activeName: 'a',
      myPosts:[],
      myReply:[],
      myComment:[],
      page1:1,
      page2:1,
      page3:1,
      limit:10,
      loading: false,
      total1: 0,//总共的数据条数
      total2: 0,//总共的数据条数
      total3: 0,//总共的数据条数
      finished: false,
      show1:false,
      show2:false,
      show3:false
    };
  },
  created(){
    this.getMyPosts();
    this.getMyReply();
    this.getMyComment();
  },
  methods:{
    goDetail(id){
      this.$router.push({name:'PostsDetail',params:{id:id}});
    },
    getMyPosts(){
      let that = this;
      that.$axios
        .get(that.baseUrl+"/api/news/mylist", {
          params: {
            page:that.page1,
            limit:that.limit
          }
        })
        .then(function(res) {
          //console.log(res);
          if(res.data.code==1){
            if(res.data.data.list.length > 0){
              that.myPosts= res.data.data.list;
              that.total1= res.data.data.count;
              that.show1 = false;
            }else{
              that.show1 = true;
            }

          }
        });
    },
    getMyReply(){
      let that = this;
      that.$axios
        .get(that.baseUrl+"/api/news/myRecomment", {
          params: {
            page:that.page2,
            limit:that.limit
          }
        })
        .then(function(res) {
          console.log(res);
          if(res.data.code==1){
            if(res.data.data.list.length > 0){
              that.myReply= res.data.data.list;
              that.total2= res.data.data.count;
              that.show2 = false;
            }else{
              that.show2 = true;
            }
          }
        });
    },
    getMyComment(){
      let that = this;
      that.$axios
        .get(that.baseUrl+"/api/news/myComment", {
          params: {
            page:that.page3,
            limit:that.limit
          }
        })
        .then(function(res) {
          console.log(res);
          if(res.data.code==1){
            if(res.data.data.list.length > 0){
              that.myComment= res.data.data.list;
              that.total3= res.data.data.count;
              that.show3 = false;
            }else{
              that.show3 = true;
            }

          }
        });
    },
    onLoad() {
      if(this.activeName == 'a'){
        this.page1++;
      }else if(this.activeName == 'b'){
        this.page2++;
      }else if(this.activeName == 'c'){
        this.page3++;
      }

      this.getroadList();

    },
    getroadList(){

      let that = this;
      if(that.activeName == 'a'){
        that.$axios
          .get(that.baseUrl+"/api/news/mylist", {//地址是get请求地址
            params: {
              page:that.page1,
              limit:that.limit
            }
          })
          .then(function(res) {//当请求完成时候执行then回调函数
            if(res.data.code==1){
              var newlist = res.data.data.list;
              if (newlist == null || newlist.length === 0) {
                // 加载结束
                that.finished = true;
                return;
              }

              that.myPosts = that.myPosts.concat(newlist);
              if (that.myPosts.length >= that.total1) {
                that.finished = true;
              }
            }
          });
      }else if(that.activeName == 'b'){
        that.$axios
          .get(that.baseUrl+"/api/news/myRecomment", {
            params: {
              page:that.page2,
              limit:that.limit
            }
          })
          .then(function(res) {
            if(res.data.code==1){
              var newlist = res.data.data.list;
              if (newlist == null || newlist.length === 0) {
                // 加载结束
                that.finished = true;
                return;
              }

              that.myReply = that.myReply.concat(newlist);
              if (that.myReply.length >= that.total2) {
                that.finished = true;
              }
            }
          });
      }else if(that.activeName == 'c'){
        that.$axios
          .get(that.baseUrl+"/api/news/myComment", {
            params: {
              page:that.page3,
              limit:that.limit
            }
          })
          .then(function(res) {
            if(res.data.code==1){
              var newlist = res.data.data.list;
              if (newlist == null || newlist.length === 0) {
                // 加载结束
                that.finished = true;
                return;
              }

              that.myComment = that.myComment.concat(newlist);
              if (that.myComment.length >= that.total3) {
                that.finished = true;
              }
            }
          });
      }

    },
    gotonews(id){
      this.$router.push({name:'PostsDetail',params:{id:id}});
    },
    del_bbs(id,index){
      let that = this;
      var r = confirm("确定要删除这条记录吗？");
      if (r == true) {
        that.$axios
          .get(that.baseUrl+"/api/news/delMyNews", {
            params: {
              news_id:id
            }
          })
          .then(function(res) {
            console.log(res);
            if(res.data.code == 1){
              that.myPosts.splice(index, 1);
            }

          });
      } else {
        console.log('取消');
      }


    }
  },
  components: {
    cTitle
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/styles/var.less";
.item-list{
  margin-top:5px;
  padding:10px;
  background:#fff;
  .tit{font-size:14px;color:@dark;font-weight:bold;}
  .com{
    padding-top:5px;font-size:14px;color:@words;
  }
  .dat{
    padding-top:5px;font-size:14px;color:@lightWords;
  }
}
.fbimg{
  width:40px;
  height:40px;
}
.flex{
  display:flex;justify-content: center;align-items: center;
}
.custom-image{
  opacity: 0.3;
}
</style>
