<template>
  <div>
    <div class="empty">
      <img src="../../assets/images/0.png" class="empty_img">
      <span>功能升级维护中，请稍后再来</span>
    </div>
    <foot-bar></foot-bar>
  </div>
</template>

<script>
import footBar from "../../components/w_foot.vue";
export default {
  components: {
    footBar
  }
};
</script>

<style lang="less" scoped>
.view{
  background:#fff;
}
.empty{
  display:flex;justify-content: center;align-items: center;flex-direction: column;
}
.empty_img{
  width:80%;
}
</style>
