<template>
  <div class="register-container">
    <div class="top">
      <img src="../assets/images/top2.png" alt="" class="topimg">
      <img src="../assets/images/topbg.png" alt="" class="topbg">
      <router-link to="/home">
        <van-icon name="wap-home" size="40" class="sy"/>
      </router-link>
    </div>
    <div class="formbox">
      <div class="zc">村民注册</div>

      <!--城市选择-->
      <div class="f-item">
        <van-field
          v-model="cityValue"
          is-link
          readonly
          label="城市"
          placeholder="请选择所在城市"
          @click="cityshow = true"
        />
        <van-popup v-model="cityshow" round position="bottom">
          <van-area
            title="标题"
            :area-list="areaList"
            :columns-placeholder="['请选择', '请选择', '请选择']"
            @cancel="cityshow = false"
            @confirm="onFinishcity"
          />
        </van-popup>
      </div>
      <!--村镇选择-->
      <div class="f-item">
        <van-field
          v-show="is_village_show"
          v-model="villageValue"
          is-link
          readonly
          label="村镇"
          placeholder="请选择所在村镇"
          @click="villageshow = true"
        />
        <van-popup v-model="villageshow" round position="bottom">
          <van-cascader
            v-model="villagecascaderValue"
            title="请选择所在村镇"
            :options="villageoptions"
            @close="villageshow = false"
            @finish="onFinishvillage"
          />
        </van-popup>
      </div>

      <!--真实姓名-->
      <van-cell-group>
        <van-field v-model="realname" label="真实姓名" placeholder="请输入真实姓名" />
      </van-cell-group>
      <!--登录密码-->
      <van-field
        v-model="password"
        type="password"
        name="登录密码"
        label="登录密码"
        placeholder="请输入登录密码"
        :rules="[{ required: true, message: '请填写密码' }]"
      />
      <!--确认密码-->
      <van-field
        v-model="password2"
        type="password"
        name="确认密码"
        label="确认密码"
        placeholder="请确认登录密码"
        :rules="[{ required: true, message: '请确认密码' }]"
      />
      <!--手机号码-->
      <van-field
        v-model="mobile"
        name="pattern"
        label="手机号码"
        placeholder="请输入手机号码"
      />

      <div class="f-item dw">
        <van-cell-group>
          <van-field v-model="code" label="验证码" placeholder="请输入手机验证码" />
        </van-cell-group>
        <van-button type="primary" size="small" class="yzm"  @click="getCode" >{{code_text}}</van-button>
      </div>

      <van-field
        v-model="idcard"
        name="id_card"
        label="身份证号"
        placeholder="请输入您的身份证号码"
      />

      <!-- <div class="f-item">
        <van-field
          v-model="roleValue"
          is-link
          readonly
          label="身份"
          placeholder="请选择您的身份"
          @click="roleValue_show = true"
        />
        <van-popup v-model="roleValue_show" round position="bottom">
          <van-cascader
            title="请选择用户身份"
            :options="role_List"
            @close="roleValue_show = false"
            @finish="onFinishrole"
          />
        </van-popup>
        </div> -->

      <!--注册协议-->
      <van-row class="ptb15">
        <van-col span="2" offset="2" class="tx-r">
          <van-checkbox v-model="checked" checked-color="#00a752"></van-checkbox>
        </van-col>
        <van-col span="20" class="rzxy">
          我已经同意村民认证协议
        </van-col>
      </van-row>

      <van-row class="ptb15">
        <van-col span="24" class="tx-c">
          <van-button round type="info" color="linear-gradient(to right, #0dbade, #00a752)" size="small" class="regbtn cbg" v-if="is_click" @click="submit">注册</van-button>
          <van-button round type="info" color="linear-gradient(to right, #0dbade, #00a752)" size="small" class="regbtn cbg" v-else >提交中...</van-button>
        </van-col>
      </van-row>

      <van-row class="ptb15">
        <router-link to="/login">
          <van-col span="24" class="tx-c zjdl">
          已有账号，直接登录
          </van-col>
        </router-link>
      </van-row>
    </div>
  </div>
</template>

<script>
import { areaList } from '@vant/area-data';
import { mapMutations } from 'vuex';
export default {
  data() {
    return {
      is_village_show:false,
      site_id:'',
      areaList,
      checked: true,
      code:'',
      realname:'',
      password:'',
      password2:'',
      mobile:'',
      code_mobile:'',//请求验证码的手机号码
      pattern: /^1(3[0-9]|5[0-3,5-9]|7[1-3,5-8]|8[0-9])\d{8}$/,
      cityshow: false,
      cityValue: '',
      citycascaderValue: '',
      // 选项列表，children 代表子选项，支持多级嵌套
      cityoptions: [],
      villageshow: false,
      villageValue: '',
      villagecascaderValue: '',
      roleValue: '',//角色权限ID
      roleId:'',
      roleValue_show:false,
      role_List:[],
      // 选项列表，children 代表子选项，支持多级嵌套
      villageoptions: [],
      town_id:'',
      village_id:'',
      code_text:'获取验证码',
      code_issend:false,

      province:'',
      city:'',
      area:'',
      province_code:'',
      city_code:'',
      area_code:'',
      is_click:true,
      idcard:''
    };
  },
  created() {
    this.getAreaList();
    this.getRoleList();

  },
  methods: {
    ...mapMutations(['changeLogin']),
    getRoleList(){
      var that = this;
      that.$axios
        .get(that.baseUrl+"/api/user/role", {
          params: {}
        })
        .then(function(res) {
          if(res.data.code == 1){
            var list_r = res.data.data.role;
            var new_list_r = [];
            if(list_r.length > 0){
              list_r.forEach(element => {
                var new_o = {};
                new_o.text = element.role_name;
                new_o.value = element.id;
                new_list_r.push(new_o);
              });
            }
            that.role_List = new_list_r;
          }
        });
    },
    getAreaList(){
      var that = this;
      that.$axios
        .get(that.baseUrl+"/api/common/area", {
          params: {}
        })
        .then(function(res) {
          if(res.data.code == 1){
            that.cityoptions = res.data.data;
          }
        });
    },

    // 城市全部选项选择完毕后，会触发 finish 事件
    onFinishcity(arr) {
      console.log(arr);
      this.cityshow = false;
      this.cityValue = arr.map((selectedOptions) => selectedOptions.name).join('/');
      const cityArr = arr;
      this.province_code = cityArr[0].code;
      this.city_code = cityArr[1].code;
      this.area_code = cityArr[2].code;
      this.province = cityArr[0].name;
      this.city = cityArr[1].name;
      this.area = cityArr[2].name;
      if(this.area_code == ''){
        this.$toast('请选择区');
        return false;
      }else{
        //触发村镇的查寻
        this.getTV();
      }


    },
    getTV(){
      var that = this;
      that.villageoptions = [];
      that.$axios
        .get(that.baseUrl+"/api/common/site", {//地址是get请求地址
          params: {
            province:that.province,
            city:that.city,
            area:that.area
          }
        })
        .then(function(res) {//当请求完成时候执行then回调函数
          if(res.data.code == 1 && res.data.data.site.length > 0){
            var tv_list=res.data.data.site[0].children;
            if(tv_list.length > 0){
              tv_list.forEach((element,index) => {
                var item_obj = {};
                item_obj.text = element.site;
                item_obj.value = element.id;
                if(element.children.length > 0){
                  item_obj.children = [];
                  var child_list = element.children;
                  child_list.forEach((it,i) => {
                    var new_child = {};
                    new_child.text = it.site;
                    new_child.value = it.id;
                    item_obj.children.push(new_child);
                  });
                }
                that.villageoptions.push(item_obj);
              });
            }
            that.is_village_show = true;
          }else{
            that.$toast("暂无下属村镇数据");
          }
        });


    },
    // 村镇全部选项选择完毕后，会触发 finish 事件
    onFinishvillage({ selectedOptions }) {
      console.log(selectedOptions);
      this.villageshow = false;
      this.villageValue = selectedOptions.map((selectedOptions) => selectedOptions.text).join('/');
      this.town_id = selectedOptions[0].value;
      this.site_id = selectedOptions[1].value;
      console.log(this.site_id);
      if(this.site_id == ''){
        that.$toast("请选择所属村");
        return false;
      }
    },

    // 全部选项选择完毕后，会触发 finish 事件
    onFinishrole({ selectedOptions }) {
      //console.log(selectedOptions);
      this.roleValue_show = false;
      this.roleValue = selectedOptions.map((selectedOptions) => selectedOptions.text).join('/');
      this.roleId = selectedOptions.map((selectedOptions) => selectedOptions.value).join('/');
      //console.log(this.roleId);
    },

    onFailed(errorInfo) {
      console.log('failed', errorInfo);
    },
    //获取验证码
    getCode(){
      if (this.code_issend) {
        return;
      }
      this.code_issend = true;

      //验证手机号码
      var mobile_num = this.mobile;
      if(!(/^1[3456789]\d{9}$/.test(mobile_num))){
        alert("手机号码不合法，请重新输入");
        this.code_issend = false;
        return false;
      }

      let t = 10;
      let si = setInterval(() => {
        this.code_text = t + 's';
        t = t - 1;
        if (t == 0) {
          this.is_send = false;
          this.code_text = '获取手机验证码';
          clearInterval(si);
        }
      }, 1000);
      var that = this;
      //调用验证码接口
      that.$axios
        .get(that.baseUrl+"/api/sms/send",{
          params:{
            mobile:mobile_num,
            event:'register'
          }
        })
        .then(function(res) {//当请求完成时候执行then回调函数
          if(res.data.code == 1){
            that.$toast(res.data.msg);
            that.code_mobile = mobile_num;
          }else{
            that.$toast(res.data.msg);
            clearInterval(si);
            that.code_text = '获取验证码';
            that.code_issend = false;
            return false;
          }
        });


    },
    //提交
    submit(){
      this.is_click=false;

      if(this.checked == false){
        this.$toast('请同意村民认证协议');
        this.is_click=true;
        return false;
      }

      //验证省市区的选择
      if(this.province == '' || this.city=='' || this.area=='' ){
        this.$toast('请选择省市区');
        this.is_click=true;
        return false;
      }
      //验证镇村的选择
      if(this.site_id == ''){
        this.$toast('请选择所属村庄');
        this.is_click=true;
        return false;
      }
      //验证用户名
      if(this.username == ''){
        this.$toast('真实姓名不能为空');
        this.is_click=true;
        return false;
      }
      //验证登录密码
      if(this.password == ''){
        this.$toast('密码不能为空');
        this.is_click=true;
        return false;
      }
      if(this.password2 == '' || this.password2 != this.password){
        this.$toast('确认密码不一致');
        this.is_click=true;
        return false;
      }
      //验证手机号及验证码
      if(this.mobile == ''){
        this.$toast('请输入手机号码');
        this.is_click=true;
        return false;
      }
      //验证手机号码的长度和正则
      if(this.code == ''){
        this.$toast('请输入验证码');
        this.is_click=true;
        return false;
      }
      if(!(/^1[3456789]\d{9}$/.test(this.mobile))){
        alert("手机号码不合法，请重新输入");
        return false;
      }
      console.log(this.mobile);
      console.log(this.code_mobile);

      if(this.mobile != this.code_mobile){
        this.$toast('手机号码与发送验码手机号不一致');
        this.is_click=true;
        return false;
      }
      // if(this.roleId == ''){
      //   this.$toast('请选择用户身份');
      //   this.is_click=true;
      //   return false;
      // }
      // var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
      // if(this.idcard == ''){
      //   this.$toast('请输入身份证号');
      //   this.is_click=true;
      //   return false;
      // }else if(reg.test(this.idcard) === false){
      //   this.$toast('身份证输入不合法');
      //   this.is_click=true;
      //   return false;
      // }

      var that = this;
      that.$axios
        .get(that.baseUrl+"/api/user/register", {//地址是get请求地址
          params: {
            site_id:that.site_id,
            username:that.realname,
            password:that.password,
            mobile:that.mobile,
            code:that.code,
            province:that.province,
            city:that.city,
            area:that.area,
            // role_id:that.roleId
            idcard:that.idcard
          }
        })
        .then(function(res) {//当请求完成时候执行then回调函数
          console.log(res);
          that.$toast(res.data.msg);
          if(res.data.code == 0){
            that.is_click=true;
          }else{
            that.$toast(res.data.msg);
            //缓存登录数据
            window.localStorage.setItem("Authorization",res.data.data.userinfo.token);
            window.localStorage.setItem("user_id",res.data.data.userinfo.user_id);
            window.localStorage.setItem("avatar",res.data.data.userinfo.avatar);
            window.localStorage.setItem("username",res.data.data.userinfo.username);
            window.localStorage.setItem("mobile",res.data.data.userinfo.mobile);
            window.localStorage.setItem("site_id",that.site_id);
            window.localStorage.setItem("verification",res.data.data.userinfo.verification);
            window.localStorage.setItem("userinfo",JSON.stringify(res.data.data.userinfo));
            window.localStorage.setItem("user_village_id",res.data.data.userinfo.site_id);
            window.localStorage.setItem("user_town_id",res.data.data.userinfo.town_id);
            window.localStorage.setItem("county_id",res.data.data.userinfo.area_id);

            that.changeLogin({ Authorization: res.data.data.userinfo.token });
            setTimeout(function(){
              that.$router.push("/my");
            },2000);

          }
        });
    }
  },

};
</script>

<style lang="less" scoped>
@import "~@/assets/styles/var.less";
.register-container{
  height:100%;
  background:#fff;
  .top{
    height:220px;
    overflow:hidden;
    position:relative;
    z-index:2;
    .topimg{
      position:absolute;
      width:100%;height:auto;
      left:0;
      top:0;
      z-index:3;
    }
    .topbg{
      position:absolute;
      width:100%;height:auto;
      left:0;
      bottom:0;
      z-index:4;
    }
    .sy{
      position:absolute;
      left:10px;top:10px;
      color:#fff;
      z-index:5;

    }
  }
  .formbox{
    padding:20px;
    padding-top:0;
    .zc{
      font-size:16px;font-weight:bold;
      text-align:center;
      margin-bottom:20px;
      background:-webkit-linear-gradient(90deg,@primary,#0dbade);
      -webkit-background-clip:text;
      -webkit-text-fill-color:transparent;
    }
    .f-item{
      border-bottom:solid 1px #ebedf0;
      border-radius:5px;
    }
  }
}
.ptb15{padding-top:15px;padding-bottom:15px;}
.dw{position:relative;}
.yzm{position:absolute;top:6px;right:0px;}
.icon-dq{vertical-align: -3px;;}
.t-green{color:@primary}
.tx-r{text-align:right;}
.rzxy{height:20px;line-height:20px;font-size:14px;color:#666;}
.regbtn{width:90%;}
.cbg{box-shadow:0 0 5px rgba(0,0,0,0.5)}
.zjdl{
  color:@primary;font-size:14px;
}
</style>
