<template>
  <div class="det-container">
    <c-title :hide="false" text="就业通" tolink="" :totext="``"> </c-title>
    <div class="content">
      <div class="searchbox">
        <van-search v-model="searchvalue" placeholder="请输入搜索关键词" />
      </div>
      <div class="tab">
        <van-button round size="small" type="warning" class="mr10">通知内容</van-button>
        <van-button round size="small" type="default">通知名单</van-button>
      </div>
      <div class="cont">
        <div class="cont-item">
          <div class="item-top">
            <div class="item-top-left">
              <img src="../../assets/images/user.png">
            </div>
            <div class="item-top-right">
              <div class="jobname">营业员</div>
              <div class="jobfl">
                <span>五险一金</span>
                <span>包住</span>
                <span>包吃</span>
              </div>
            </div>
          </div>
          <div class="item-foot">
            <div>报名<van-icon name="arrow" class="icon"/></div>
            <div>查看报名记录<van-icon name="arrow"  class="icon"/></div>
          </div>
        </div>

      </div>
      <div class="foot">
        <van-button type="primary" size="small" @click="fabu">发布</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import cTitle from "../../components/title.vue";
export default {
  data() {
    return {
      searchvalue: "",
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      page: 1,
      limit: 10,
      count: 0,
      apiUrl: "",
      params: ""
    };
  },
  created(){
    this.apiUrl = "/api/village_manager/job";
    this.params = {
      keyword: this.searchvalue,
      date: '',
      type:'',
      page: this.page,
      limit: this.limit
    };
    this.onLoad();

  },
  methods:{
    onLoad() {
      if (this.refreshing) {
        this.list = [];
        this.refreshing = false;
      }

      var that = this;
      that.$axios.post(that.baseUrl + that.apiUrl, that.params).then(function(res) {
        console.log(res);
        if (res.data.code == 1) {
          that.count = res.data.data.count;

          var l = res.data.data.list;
          if (l && l.length > 0) {
            l.forEach(element => {
              that.list.push(element);
            });
          }
        }
      });

      that.loading = false;

      if (that.list.length >= that.count) {
        that.finished = true;
      } else {
        that.page += 1;
      }
    },
    fabu() {
      this.$router.push({
        name: "AddJob",
        params: {
          mname: this.typename
        }
      });
    }
  },
  components: {
    cTitle
  }
};
</script>

<style lang="less" scoped>
.det-container {

  .content {
    .tab{
      padding:10px;
    }
    .cont {
      padding: 10px;
      color: #666;
      .cont-item{
        background:#fff;padding:10px;margin-bottom:10px;
        .item-top{
          width:100%;display:flex;justify-content: space-between;
          padding-bottom:10px;border-bottom:solid 1px #ddd;
          .item-top-left{
            width:30%;
            img{
              width:80px;height:80px;border-radius:5px;
            }
          }
          .item-top-right{
            width:70%;display:flex;flex-direction: column;
            .jobname{
              font-size:14px;font-weight:bold;color:#000;
            }
            .jobfl{
              display:flex;justify-content: flex-start;align-items: flex-start;
              span{background:#f5f5f5;color:#333;padding:2px 5px;border-radius:2px;margin:5px 10px 5px 0};
            }
          }
        }
        .item-foot{
          height:40px;line-height:40px;display:flex;justify-content: space-between;align-items: center;
          div{
            .icon{
              vertical-align: text-bottom;
            }
          }

        }
      }
    }
  }
}
.mr10 {
  margin-right: 10px;
}
.foot {
    width: 100%;
    height: 50px;
    background: #fff;
    position: fixed;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
