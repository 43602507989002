<template>
  <div class="addherat-container">
    <c-title :hide="false"
      :text="`发布心愿`"
      tolink=""
      :totext="``">
    </c-title>
    <div class="formbox">


      <!--标题-->
      <van-cell-group>
        <van-field v-model="bbstitle" label="心愿标题" placeholder="请输入心愿标题" />
      </van-cell-group>
      <!--正文-->
      <van-field
        v-model="message"
        rows="8"
        autosize
        label="心愿正文"
        type="textarea"
        maxlength="300"
        placeholder="请输入心愿正文"
        show-word-limit
      />
      <!--配图-->
      <van-row class="upimg">
        <van-col span="24">图片上传</van-col>
      </van-row>
      <van-row class="upimg">
        <van-col span="24">
          <van-uploader v-model="fileList" multiple :after-read="afterRead"/>
        </van-col>
      </van-row>
      <!--提交按钮-->
      <van-row class="subbox">
        <van-col span="24" class="tx-c">
          <van-button type="primary" :disabled="is_click" block @click="submit">提交</van-button>
        </van-col>
      </van-row>

    </div>


  </div>
</template>

<script>
import cTitle from '../../components/title.vue';
export default {
  data() {
    return {
      bbstitle:'',
      message:'',
      fileList: [],
      is_click:false,
      img_arr:''
    };
  },
  methods: {
    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      var that = this;
      if(file.length>0){
        for (var i=0;i<that.fileList.length;i++){
          var img_url = that.fileList[i].content;
          let params = new URLSearchParams();
          params.append('base64', img_url);
          that.$axios
            .post(that.baseUrl+"/api/common/uploadBase64", {
              params:{
                base64:img_url
              }
            })
            .then(function(res) {//当请求完成时候执行then回调函数
              if(res.data.code == 1){
                that.img_arr += res.data.data.fullurl+',';
              }
            });
        }
      }
    },
    submit(){
      var that = this;
      that.is_click = true;

      var bbstitle = that.bbstitle;
      if(bbstitle == ''){
        that.$toast('请填写心愿标题');
        that.is_click = false;
        return false;
      }
      var message = that.message;
      if(message == ''){
        that.$toast('请填写心愿内容');
        that.is_click = false;
        return false;
      }

      that.$axios
        .get(that.baseUrl+"/api/news/add", {
          params: {
            cid:that.categroy_ids,
            huatiid:0,
            title:that.bbstitle,
            cover:that.img_arr,
            content:that.message,
          }
        })
        .then(function(res) {
          console.log(res);
          if(res.data.code == 1){

            that.$toast('发布成功');
            setTimeout(function(){
              that.$router.push("/myrelease");
            },2000);
          }else{
            that.is_click=false;
          }
        });

    }
  },
  components: {
    cTitle
  },
};
</script>

<style lang="less" scoped>

.addheart-container{
  min-height:100vh;
  padding-bottom:60px;
  padding:10px;
  .formbox{
    padding-bottom:20px;
  }

}
.upimg{
  padding:10px 20px;background:#fff;color:#646566;font-size:14px;
}
.subbox{
  padding:20px 20px;background:#fff;
  .subbtn{
    margin-top:20px;
    width:200px;
  }
}
.tx-r{
  text-align:right;
}
</style>
