<template>
  <div class="openletter-container">
    <c-title :hide="false"
      :text="`发布`"
      tolink=""
      :totext="``">
    </c-title>
    <van-field
      v-model="lotter_title"
      name="lotter_title"
      label="标题"
      placeholder="标题"
      :rules="[{ required: true, message: '请填写标题' }]"
    />
    <div class="wrapper">
      <quill-editor
        class="ql-editor"
        v-model="content"
        ref="myQuillEditor"
        :options="editorOption"
        @blur="onEditorBlur($event)" @focus="onEditorFocus($event)"
        @change="onEditorChange($event)">
    </quill-editor>

    </div>
    <div class="h60"></div>
    <div class="footer">
      <div class="fbox">
         <van-button type="primary" size="small" block :disabled="is_click" @click="submit">发布</van-button>
      </div>
    </div>

  </div>
</template>

<script>
import cTitle from '../../components/title.vue';
export default {
  data(){
    return{
      content:null,
      editorOption:{
        modules:{
          toolbar:[
            ['bold', 'italic', 'underline', 'strike'],    //加粗，斜体，下划线，删除线
            ['blockquote', 'code-block'],     //引用，代码块
            [{ 'header': 1 }, { 'header': 2 }],        // 标题，键值对的形式；1、2表示字体大小
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],     //列表
            [{ 'script': 'sub'}, { 'script': 'super' }],   // 上下标
            [{ 'indent': '-1'}, { 'indent': '+1' }],     // 缩进
            [{ 'direction': 'rtl' }],             // 文本方向
            [{ 'size': ['small', false, 'large', 'huge'] }], // 字体大小
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],     //几级标题
            [{ 'color': [] }, { 'background': [] }],     // 字体颜色，字体背景颜色
            [{ 'font': [] }],     //字体
            [{ 'align': [] }],    //对齐方式
            ['clean'],    //清除字体样式
            ['image','video']    //上传图片、上传视频
          ]
        }
      },
      lotter_title:'',
      is_click:false,
    };
  },
  methods: {
    onEditorBlur() {
      //console.log('blur',this.content);
    },

    onEditorFocus(){
      // console.log('focus',this.content);
    },

    onEditorReady(){
      //console.log('ready',this.content);
    },
    submit(){
      console.log(124);
      var that = this;
      that.is_click = true;

      var lotter_title = that.lotter_title;
      if(lotter_title == ''){
        that.$toast('请填写标题');
        that.is_click = false;
        return false;
      }
      var content = that.content;
      if(content == ''){
        that.$toast('请填写内容');
        that.is_click = false;
        return false;
      }

      that.$axios
        .get(that.baseUrl+"/api/news/add", {
          params: {
            site:window.localStorage.getItem('user_village_id'),
            cid:that.$route.params.id,
            huatiid:0,
            title:lotter_title,
            cover:'',
            content:content
          }
        })
        .then(function(res) {
          console.log(res);
          if(res.data.code == 1){

            that.$toast('发布成功');
            setTimeout(function(){
              that.$router.push("/myrelease");
            },2000);
          }else{
            that.is_click=false;
          }
        });
    }

  },
  mounted() {
    // 富文本提示信息
    this.$nextTick(() => {
      const oToolBar = document.getElementsByClassName('ql-editor')[0];
      const   aButton = oToolBar.querySelectorAll('button');
      const  aSelect =  oToolBar.querySelectorAll('select');
      aButton.forEach(function(item){
        if(item.className === 'ql-script'){
          item.value === 'sub' ? item.title = '下标': item.title = '上标';
        }else if(item.className === 'ql-indent'){
          item.value === '+1' ? item.title ='向右缩进': item.title ='向左缩进';
        }else{
          item.title = titleConfig[item.classList[0]];
        }
      });
      aSelect.forEach(function(item){
        item.parentNode.title = titleConfig[item.classList[0]];
      });
    });
  },
  components:{
    cTitle
  }
};
</script>

<style lang="less">
.openletter-container{
  padding:10px;

  .wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    background:#fff;

    .input-wrapper {
      display: flex;
      flex-direction: row;
      width: 100%;
      margin: 5px 0;
      box-sizing: border-box;

      .editor{
        width: 100%;
        height:200px;
      }

      .sub-title {
        font-size: 14px;
        color: #444;
      }

      .field {
        flex: 1;
      }
    }
  }
  div.ql-container.ql-snow{
    height: 400px;
    border:0;
  }
  div.ql-toolbar.ql-snow{
    border:0;
  }
  div.ql-editor.ql-blank{
    height:50px;
    border:0;
  }


}
.footer{
  width:100%;
  height:50px;
  position:fixed;
  left:0;
  bottom:0;
  background:#fff;
  .fbox{
    padding:10px 15px;
  }

}
.h60{
  height:60px;
}

</style>
