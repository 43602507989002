<template>
  <div class="empty-container">
    <img src="../assets/images/empty.png" class="empty_img">
  </div>
</template>

<script>
export default {

};
</script>

<style scoped>
.empty-container{width:100%;min-height:200px;display:flex;justify-content: center;align-items: center;}
.empty_img{height:200px;}
</style>
