var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"notice-container"},[_c('c-title',{attrs:{"hide":false,"text":_vm.typename,"tolink":"","totext":``}}),_c('div',{staticClass:"topmenu"},[_c('van-button',{staticClass:"mr10",attrs:{"plain":"","hairline":"","round":"","size":"small","type":"success"}},[_vm._v("全部 0")]),_c('van-button',{staticClass:"mr10",attrs:{"plain":"","hairline":"","round":"","size":"small","type":"default"}},[_vm._v("支部会议")]),_c('van-button',{staticClass:"mr10",attrs:{"plain":"","hairline":"","round":"","size":"small","type":"default"}},[_vm._v("两委会议")]),_c('van-button',{staticClass:"mr10",attrs:{"plain":"","hairline":"","round":"","size":"small","type":"default"}},[_vm._v("党员会议")]),_c('van-button',{staticClass:"mr10",attrs:{"plain":"","hairline":"","round":"","size":"small","type":"default"}},[_vm._v("村民会议")]),_c('van-button',{staticClass:"mr10",attrs:{"plain":"","hairline":"","round":"","size":"small","type":"default"}},[_vm._v("支部会议")]),_c('van-button',{staticClass:"mr10",attrs:{"plain":"","hairline":"","round":"","size":"small","type":"default"}},[_vm._v("两委会议")]),_c('van-button',{staticClass:"mr10",attrs:{"plain":"","hairline":"","round":"","size":"small","type":"default"}},[_vm._v("党员会议")]),_c('van-button',{staticClass:"mr10",attrs:{"plain":"","hairline":"","round":"","size":"small","type":"default"}},[_vm._v("村民会议")])],1),_c('div',{staticClass:"countnum"},[_vm._v("共0人")]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"list-item",on:{"click":_vm.goDetail}},[_vm._m(0),_vm._m(1),_vm._m(2)]),_c('div',{staticClass:"list-item",on:{"click":_vm.goDetail}},[_vm._m(3),_vm._m(4),_vm._m(5)]),_c('div',{staticClass:"list-item",on:{"click":_vm.goDetail}},[_vm._m(6),_vm._m(7),_vm._m(8)])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-1"},[_c('img',{staticClass:"avatar",attrs:{"src":require("../../assets/images/ganbu.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-2"},[_c('div',{staticClass:"uname"},[_vm._v("刘晓")]),_c('div',{staticClass:"usf"},[_vm._v("村干部")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-3"},[_c('div',{staticClass:"f12 t-gay st pl05"},[_vm._v("查看次数：1")]),_c('div',{staticClass:"f12 t-gay st pl05"},[_vm._v("送达时间：2023-04-18 09:00")]),_c('div',{staticClass:"f12 t-gay st pl05"},[_vm._v("最后查看时间：10分钟前")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-1"},[_c('img',{staticClass:"avatar",attrs:{"src":require("../../assets/images/ganbu.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-2"},[_c('div',{staticClass:"uname"},[_vm._v("刘晓")]),_c('div',{staticClass:"usf"},[_vm._v("村干部")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-3"},[_c('div',{staticClass:"f12 t-gay st pl05"},[_vm._v("查看次数：1")]),_c('div',{staticClass:"f12 t-gay st pl05"},[_vm._v("送达时间：2023-04-18 09:00")]),_c('div',{staticClass:"f12 t-gay st pl05"},[_vm._v("最后查看时间：10分钟前")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-1"},[_c('img',{staticClass:"avatar",attrs:{"src":require("../../assets/images/ganbu.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-2"},[_c('div',{staticClass:"uname"},[_vm._v("刘晓")]),_c('div',{staticClass:"usf"},[_vm._v("村干部")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-3"},[_c('div',{staticClass:"f12 t-gay st pl05"},[_vm._v("查看次数：1")]),_c('div',{staticClass:"f12 t-gay st pl05"},[_vm._v("送达时间：2023-04-18 09:00")]),_c('div',{staticClass:"f12 t-gay st pl05"},[_vm._v("最后查看时间：10分钟前")])])
}]

export { render, staticRenderFns }