<template>
  <div class="topicdetail-container">
    <c-title :hide="false"
      :text="`话题`"
      tolink=""
      :totext="``">
    </c-title>
    <div class="content">
      <div class="top">
        <div class="bgimg" :style="{backgroundImage: 'url(' + detail.icon_image + ')',backgroundSize:'100% auto',backgroundRepeat:'no-repeat',backgroundPosition:'center'}"></div>
        <div class="textbox">
          <van-row class="p20 btm">
            <van-col span="6">
              <img :src="detail.icon_image" alt="" class="xyimg">
            </van-col>
            <van-col span="18">
              <p class="xy_tit">#{{detail.title}}#</p>
              <p class="xy_txt">{{news.createtime}} 浏览：{{detail.views}} 参与：{{detail.ups}}</p>
              <p class="xy_fqr">发起人：张小明(长塘镇书记)</p>
            </van-col>
          </van-row>
        </div>
      </div>

      <div class="topic_info">
       {{detail.content}}
      </div>
      <!-- <div class="photo">
        <van-image
          width="100%"
          height="auto"
          fit="cover"
          :src="item"
          v-for="(item,index) in detail.cover_arr"
          :key="index"
        />

      </div> -->
    </div>

    <div class="reply_list" v-show="newsList.length > 0">
      <van-list
      v-model="loading"
      :finished="finished"
      :immediate-check="false"
      finished-text="没有更多了"
      @load="onLoad"
      :offset="10"
    >
    <home-dynamic
      v-for="(item,i) in newsList"
      :key="i"
      :news="item"
    ></home-dynamic>
      </van-list>

    </div>

    <div class="footer">
      <div class="fitem"  @click="guanzhu">
        <van-icon :color="is_gz ? '#33cc66' : ''" :name="is_gz ? 'eye' : 'eye-o'" /> <span v-if="is_gz" class="t-green">已关注</span><span v-else>关注</span>
      </div>
      <div class="fitem t-yellow" @click="onClickButton">
        <van-icon name="chat-o" /> 参与话题
      </div>
      <div class="fitem" @click="goList">
        # 热门话题
      </div>
    </div>
    <!-- <van-goods-action>
      <van-goods-action-icon icon="wap-home-o" text="首页" @click="goHome" />
      <van-goods-action-icon :icon="is_dz ? 'good-job' : 'good-job-o'" :text="is_dz ? '已点赞' : '点赞'" :color="is_dz ? '#33cc66' : ''" @click="dianzan" />
      <van-goods-action-icon :icon="is_gz ? 'eye' : 'eye-o'" :text="is_gz ? '已关注' : '关注'" :color="is_gz ? '#33cc66' : ''" @click="guanzhu" />
      <van-goods-action-button
        type="primary"
        text="立即参与话题"
       @click="onClickButton"
      />
    </van-goods-action> -->
  </div>
</template>

<script>
import cTitle from '../components/title.vue';
import homeDynamic from '../components/home/dynamic.vue';
export default {
  data(){
    return {
      is_gz:false,
      is_dz:false,
      detail:'',
      author:'',
      page:1,
      limit:10,
      loading: false,
      total: 0,//总共的数据条数
      finished: false,
      newsList:[]
    };
  },
  components: {
    cTitle,
    homeDynamic

  },
  created(){
    this.getGzStatus();
    this.getDetail();
    this.getroadList();
  },
  methods:{
    getroadList(){
      let that = this;
      that.$axios
        .get(that.baseUrl+"/api/news/list", {
          params: {
            site:window.localStorage.getItem('site_id'),
            pid:that.$route.params.id,
            all_site:1,
            page:that.page,
            limit:that.limit
          }
        })
        .then(function(res) {
          //console.log(res);
          if(res.data.code==1){
            that.loading = false;
            that.total = res.data.data.count;

            var list = res.data.data.list;
            if(list.length > 0){
              list.forEach(item => {
                if(item.extra){
                  var objet = JSON.parse(item.extra);
                  //console.log(objet);
                  var extra_item = [];
                  for (let key in objet) {
                    const obj = objet[key];
                    extra_item.push(obj);
                  }
                  item.new_extra = extra_item;

                }
              });
            }

            if (list == null || list.length === 0) {
              // 加载结束
              that.finished = true;
              return;
            }
            // 将新数据与老数据进行合并
            that.newsList = that.newsList.concat(list);
            //如果列表数据条数>=总条数，不再触发滚动加载
            if (that.newsList.length >= that.total) {
              that.finished = true;
            }

          }
        });
    },
    onLoad() {
      this.page++;
      this.getroadList();
    },
    getDetail(){
      let that = this;
      that.$axios
        .get(that.baseUrl+"/api/news/huatiDetail", {
          params: {
            id:that.$route.params.id,
          }
        })
        .then(function(res) {
          console.log(res);
          if(res.data.code==1){

            that.detail = res.data.data.news;
            that.author = res.data.data.userinfo;
          }
        }).catch(function (error) {
          that.$toast("请登录后操作");
          setTimeout(function(){
            that.$router.go(-1);
          },1500);
        });
    },
    goHome(){
      var type = window.localStorage.getItem('type');
      var site = this.detail.site;
      var user_town_id = window.localStorage.getItem('user_town_id');
      var user_village_id = window.localStorage.getItem('user_village_id');
      if(type == '县'){
        if(user_town_id){
          this.$router.push({name:'TownHome',params:{site:user_town_id}});
        }else{
          this.$router.push({name:'Login'});
        }
      }else if(type == '镇'){
        if(user_village_id){
          this.$router.push({name:'Home',params:{site:user_village_id}});
        }else{
          this.$router.push({name:'Login'});
        }
      }else if(type == '村'){
        this.$router.push({name:'TownHome',params:{site:user_town_id}});
      }else{
        this.$router.push({name:'Home',params:{
          site:site
        }});
      }
    },
    dianzan(){
      let that = this;
      that.$axios
        .get(that.baseUrl+"/api/comments/like", {
          params: {
            news_id:that.$route.params.id,
          }
        })
        .then(function(res) {
          if(res.data.code==1){
            that.is_dz = true;
          }
        });
    },
    getGzStatus(){
      let that = this;
      that.$axios
        .get(that.baseUrl+"/api/follow/huatiIsFollow", {
          params: {
            news_id:that.$route.params.id,
          }
        })
        .then(function(res) {
          if(res.data.code==1){
            if(res.data.data.is_follow == 1){
              that.is_gz = true;
            }
          }
        });
    },
    guanzhu(){
      let that = this;
      that.$axios
        .get(that.baseUrl+"/api/follow/huatiFollow", {
          params: {
            news_id:that.$route.params.id,
          }
        })
        .then(function(res) {
          if(res.data.code==1){
            that.is_gz = !that.is_gz;
            if(that.is_gz){
              that.$toast('已关注');
            }else{
              that.$toast('已取消关注');
            }
          }
        });
    },
    onClickButton(id){
      this.$router.push({name:'TopicComment',params:{id:this.$route.params.id}});
    },
    goList(){
      this.$router.push({name:'TopicList',params:{}});
    },
  }
};
</script>

<style lang="less" scoped>
@import "~@/assets/styles/var.less";
.footer{
  width:100%;height:40px;line-height:40px;display:flex;justify-content: space-around;align-items: center;
  position:fixed;left:0;bottom:0;z-index:999;background:#fff;
  .fitem{width:33%;font-size:14px;color:#444;text-align:center;}
}
.topicdetail-container{
  .content{
    .top{
      width:100%;
      height:100px;
      background:#fff;
      position:relative;
      overflow: hidden;
      z-index:1;
      .bgimg{
        width:100%;
        height:100%;
        position:absolute;
        left:0;top:0;
        z-index:2;
      }
      .textbox{width:100%;height:100%;position:absolute;z-index:3;left:0;top:0;}
      .xyimg{
        width:60px;
        height:60px;
        border-radius:4px;
      }
      .xy_tit{
        font-size:14px;font-weight:bold;
        color:#fff;
      }
      .xy_txt{
        margin-top:5px;
        font-size:14px;color:#fff;
      }
      .xy_fqr{font-size:14px;color:#fff;}
    }
    .topic_info{
      background:#fff;
      padding:10px;
      font-size:14px;
      color:@words;
    }
    .photo{
      padding:10px;
      background:#fff;
    }
  }
  .reply_list{
    margin-top:10px;
    background:#fff;
    padding:10px;
    padding-bottom:60px;
    .reply-item{
      width:100%;
      display:inline-block;
      .avatar{
        width:40px;
        height:40px;
        border-radius:50%;
      }
      .uicon{
        width:16px;height:16px;border-radius:8px;vertical-align:-16%;margin-right:4px;
      }
      .nickname{
        font-size:16px;
        color:#222;margin-right:4px;
      }
      .sf{
        font-size:14px;
        color:@words;
      }
      .subname{height:20px;line-height:22px;font-size:11px;color:@lightWords;}
      .reply_info{
        padding:10px 0;
        font-size:14px;color:@words;
      }
      .reply_photo{
        .reply_img{
          width:100px;height:100px;
          margin-right:10px;margin-bottom:5px;
        }
      }
    }
  }
}
.p05{padding:5px;}
.dy-tj{
      width:100%;
      float:left;
      display:inline-flex;
      justify-content: space-between;
      div{
        width:33%;text-align:center;
        height:30px;line-height:30px;
      }
    }
    .icon-dq{vertical-align:-16%}
    .p20{padding:20px;}
    .t-yellow{color:#f60 !important;}
    .t-green{color:#33cc66}
    .btm{background:rgba(0,0,0,0.1)}
</style>
