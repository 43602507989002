<template>
  <div class="richtext-container">
    <c-title :hide="false"
      :text="`发布`"
      tolink=""
      :totext="``">
    </c-title>
    <van-field
      v-model="lotter_title"
      name="lotter_title"
      label="标题"
      placeholder="标题"
      :rules="[{ required: true, message: '请填写标题' }]"
    />
    <div class="wrapper">
      <quill-editor v-model="content"
        ref="myQuillEditor"
        @blur="onEditorBlur($event)"
        @focus="onEditorFocus($event)"
        @ready="onEditorReady($event)">
      </quill-editor>
    </div>

    <div class="footer">
      <div class="fbox">
         <van-button type="primary" size="small" block :disabled="is_click" @click="submit">发布</van-button>
      </div>
    </div>

  </div>
</template>

<script>
import cTitle from '../../components/title.vue';
export default {
  data(){
    return{
      content:'',
      lotter_title:'',
      is_click:false,
    };
  },
  methods: {
    onEditorBlur() {
      //console.log('blur',this.content);
    },

    onEditorFocus(){
      //console.log('focus',this.content);
    },

    onEditorReady(){
      //console.log('ready',this.content);
    },
    submit(){
      console.log(124);
      var that = this;
      that.is_click = true;

      var lotter_title = that.lotter_title;
      if(lotter_title == ''){
        that.$toast('请填写标题');
        that.is_click = false;
        return false;
      }
      var content = that.content;
      if(content == ''){
        that.$toast('请填写内容');
        that.is_click = false;
        return false;
      }

      that.$axios
        .get(that.baseUrl+"/api/news/add", {
          params: {
            cid:that.$route.params.id,
            huatiid:0,
            title:lotter_title,
            cover:'',
            content:content
          }
        })
        .then(function(res) {
          console.log(res);
          if(res.data.code == 1){

            that.$toast('发布成功');
            setTimeout(function(){
              that.$router.push("/myrelease");
            },2000);
          }else{
            that.is_click=false;
          }
        });
    }

  },
  components:{
    cTitle
  }
};
</script>

<style lang="less">
.richtext-container{
  padding:10px;

  .wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    background:#fff;

    .input-wrapper {
      display: flex;
      flex-direction: row;
      width: 100%;
      margin: 5px 0;
      box-sizing: border-box;

      .editor{
        width: 100%;
        height:200px;
      }

      .sub-title {
        font-size: 14px;
        color: #444;
      }

      .field {
        flex: 1;
      }
    }
  }
  div.ql-container.ql-snow{
    height: 400px;
    border:0;
  }
  div.ql-toolbar.ql-snow{
    border:0;
  }
  div.ql-editor.ql-blank{
    height:50px;
    border:0;
  }


}
.footer{
  width:100%;
  height:50px;
  position:fixed;
  left:0;
  bottom:0;
  background:#fff;
  .fbox{
    padding:10px 15px;
  }

}

</style>
