<template>
  <div class="topiclist-container">
    <c-title :hide="false"
      :text="`话题`"
      tolink=""
      :totext="``">
    </c-title>
    <van-search v-model="value" placeholder="请输入搜索关键词" @click="search"/>
    <div class="content">
      <van-list
        v-model="loading"
        :finished="finished"
        :immediate-check="false"
        finished-text="没有更多了"
        @load="onLoad"
        :offset="10"
      >
      <template v-if="is_empty">
        <Empty></Empty>
      </template>
      <template v-else>
        <div class="list-item" @click="godetail(item.id)" v-for="(item,index) in topicList" :key="index">
        <van-row>
          <van-col span="4">
            <img :src="item.icon_image" alt="" class="xyimg">
          </van-col>
          <van-col span="20">
            <p class="xy_tit">#{{item.title}}#</p>
            <p class="xy_txt ycyc2">{{item.content}}</p>
          </van-col>
        </van-row>
      </div>

      </template>

      </van-list>



    </div>
  </div>
</template>

<script>
import cTitle from '../components/title.vue';
import Empty from '../components/empty.vue';
export default {
  data(){
    return {
      value: '',
      topicList:[],
      page:1,
      limit:10,
      loading: false,
      total: 22,//总共的数据条数
      finished: false,
      is_empty:true
    };
  },
  created(){
    this.initTopictList();
  },
  methods:{
    search(){
      this.is_empty = true;
      this.topicList= [];
      this.initTopictList();
    },
    initTopictList(){

      let that = this;
      that.$axios
        .get(that.baseUrl+"/api/news/huati", {
          params: {
            keyword:that.value
            // page:that.page,
            // limit:that.limit
          }
        })
        .then(function(res) {
          if(res.data.code==1){
            that.topicList= res.data.data;
            if(that.topicList.length>0){
              that.is_empty = false;
            }


          }
        });
    },
    onLoad() {
      this.page++;
      this.getroadList();
    },
    getroadList(){

      let that = this;
      that.$axios
        .get(that.baseUrl+"/api/news/huati", {
          params: {
            keyword:that.value
            // page:that.page,
            // limit:that.limit
          }
        })
        .then(function(res) {
          if(res.data.code==1){
            var newlist = res.data.data.list;
            if (newlist == null || newlist.length === 0) {
              // 加载结束
              that.finished = true;
              return;
            }

            that.topicList = that.topicList.concat(newlist);
            if (that.topicList.length >= that.total) {
              that.finished = true;
            }
          }
        });
    },
    godetail(id){
      this.$router.push({name:'TopicDetail',params:{id:id}});
    }
  },
  components: {
    cTitle,
    Empty
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/styles/var.less";
.topiclist-container{
  .content{
    .list-item{
      padding:10px 15px;
      background:#fff;
      border-bottom:solid 1px #eee;
      .xyimg{
        width:40px;
        height:40px;
        border-radius:4px;
      }
      .xy_tit{
        font-size:14px;font-weight:bold;
        color:@primary;
      }
      .xy_txt{
        font-size:14px;color:@lightWords
      }
    }
  }
}
.ycyc2{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
</style>
