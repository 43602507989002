<template>
  <div class="receivewish-container">
    <c-title :hide="false"
      :text="`领取微心愿`"
      tolink=""
      :totext="``">
    </c-title>
    <div class="content">
      <div class="wishinfo">
        <p>心愿名称：{{wishDetail.title}}</p>
        <p>心愿内容：{{wishDetail.content}}</p>
      </div>
      <div class="formbox">
        <van-field v-model="username" label="您的姓名" placeholder="请输入您的姓名" />
        <van-field v-model="mobile" type="digit" label="您的手机号码" placeholder="请输入您的手机号码"/>
        <!-- <van-field
          v-model="sms"
          center
          clearable
          label="短信验证码"
          placeholder="请输入短信验证码"
        >
          <template #button>
            <van-button size="small" type="primary">发送验证码</van-button>
          </template>
        </van-field> -->

        <div class="btnbox">
          <van-button type="primary" size="small" :disabled="is_click" block @click="submit">确认领取</van-button>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import cTitle from '../../components/title.vue';
export default {
  data(){
    return {
      wishDetail:'',
      // sms:'',
      mobile:'',
      username:'',
      is_click:false,
    };
  },
  created(){
    this.getWishDetail();
  },
  methods:{
    getWishDetail(){
      let that = this;
      that.$axios
        .get(that.baseUrl+"/api/news/detail", {//地址是get请求地址
          params: {
            news_id:that.$route.params.id,
          }
        })
        .then(function(res) {//当请求完成时候执行then回调函数
          //console.log(res);
          if(res.data.code==1){
            that.wishDetail= res.data.data.news;
          }
        });
    },
    submit(){
      var that = this;
      that.is_click = true;
      var username = that.username;
      if(username == ''){
        that.$toast('请填写您的姓名');
        that.is_click = false;
        return false;
      }
      var mobile = that.mobile;
      if(mobile == ''){
        that.$toast('请填写您的手机号');
        that.is_click = false;
        return false;
      }

      that.$axios
        .get(that.baseUrl+"/api/join/join", {
          params: {
            news_id:that.$route.params.id,
            name:username,
            phone:mobile,
          }
        })
        .then(function(res) {
          console.log(res);
          if(res.data.code == 1){

            that.$toast('领取成功');
            setTimeout(function(){
              that.$router.push("/myheart");
            },2000);

          }else{
            that.is_click=false;
          }
        });

    }
  },
  components: {
    cTitle
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/styles/var.less";
.receivewish-container{
  .content{
    .wishinfo{
      padding:15px;
      color:@words;
      font-size:14px;
      line-height:20px;
    }
    .formbox{
      background:#fff;
      padding:15px 0;
    }
  }
}
.btnbox{
  padding:30px 20px;
  text-align:center;
}
</style>
