<template>
  <div class="branchstyle-container">
    <c-title :hide="false"
      :text="`村务公开`"
      tolink=""
      :totext="``">
    </c-title>
    <home-slide></home-slide>
    <scroll-menu :menuList="menuList"></scroll-menu>
    <home-dynamic></home-dynamic>
    <foot-bar></foot-bar>
  </div>
</template>

<script>
import cTitle from '../../components/title.vue';
import HomeSlide from '../../components/home/slide.vue';
import ScrollMenu from '../../components/ScrollMenu.vue';
import homeDynamic from '../../components/home/dynamic.vue';
import footBar from '../../components/foot.vue';

export default {
  data() {
    return {
      value: '',
      menuList: [
        {
          title: '全部',
          id: 0,
        },
        {
          title: '村委制度',
          id: 1,
        },
        {
          title: '村委通知',
          id: 2,
        },
        {
          title: '村务公开',
          id: 3,
        },
        {
          title: '更多',
          id: 4,
        }
      ],
    };
  },
  methods: {
    onSearch(val) {
      alert(val);
    },
    onCancel() {
      alert('取消');
    },
  },
  components: {
    cTitle,
    HomeSlide,
    ScrollMenu,
    homeDynamic,
    footBar,
  },
};
</script>

<style lang="less" scoped>
.branchstyle-container{
  padding-bottom:50px;
  height:100%;
}
.van-notice-bar{
  background:none;
  color:#333;
}
.dwgk{
  padding:10px;
  margin:10px 0 10px;
  background:#fff;
}
.dwtitle{
  margin-top:5px;
  margin-bottom:5px;
  font-size:15px;
  font-weight:bold;
  color:#ed1b23;
  padding-left:10px;
  border-left:solid 3px #ed1b23;
}
.dwcontent{
  margin-top:10px;
  margin-bottom:10px;
  color:#666;
  font-size:14px;
}
.dwckgd{
  color:#666;
  font-size:14px;
  padding-top:10px;
}
.ptb10{
  padding-top:10px;
  padding-bottom:10px;
}
.line30{
  line-height:30px;
}
.dsj{
  color:#666;
}
</style>
