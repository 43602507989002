<template>
  <div class="setup-container">
    <c-title :hide="false"
      :text="`设置`">
    </c-title>
    <div class="content">
      <router-link tag="div" to="/my/userinfo" class="list-s">
        <van-col span="4" class="set01">&nbsp;</van-col>
        <van-col span="18">个人信息</van-col>
        <van-col span="2" class="tx-r"><van-icon name="arrow" /></van-col>
      </router-link>
      <router-link tag="div" to="/my/editmobile" class="list-s">
        <van-col span="4" class="set02">&nbsp;</van-col>
        <van-col span="18">修改手机</van-col>
        <van-col span="2" class="tx-r"><van-icon name="arrow" /></van-col>
      </router-link>
      <router-link tag="div" to="/my/resetpsd" class="list-s">
        <van-col span="4" class="set03">&nbsp;</van-col>
        <van-col span="18">重置密码</van-col>
        <van-col span="2" class="tx-r"><van-icon name="arrow" /></van-col>
      </router-link>
    </div>
  </div>
</template>

<script>
import cTitle from '../../components/title.vue';
export default {
  components:{
    cTitle
  }
};
</script>

<style scoped>
.content{
  padding:10px;
  background:#fff;
}
.list-s{
  width:100%;height:50px;line-height:50px;font-size:14px;color:#333;border-bottom:solid 1px #f5f5f5;
}
.tx-r{text-align:right;}
.set01{background:url(../../assets/images/set01.png) no-repeat;background-size:24px 24px;background-position:center center;}
.set02{background:url(../../assets/images/set02.png) no-repeat;background-size:24px 24px;background-position:center center;}
.set03{background:url(../../assets/images/set03.png) no-repeat;background-size:24px 24px;background-position:center center;}
</style>
