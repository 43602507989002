<template>
  <div class="village-slide-container">
    <div class="top">

      <div class="top_right">
        乡村排行<van-icon name="arrow" class="yjt"/>
      </div>
    </div>
    <div class="slidebox">
      <van-swipe :loop="false" :width="200">
      <van-swipe-item class="village-item" v-for="(item,index) in list_v" :key="index">
        <img :src="item.bg_image" class="village_cover"  @click="goDetail(item.id)"/>
        <div class="village_name ycyc">{{item.village}}</div>
        <div class="village_address">
          <div class="va-left ycyc">
            <van-icon name="location" class="dq"/>{{item.city}}，{{item.area}}，{{item.town}}
          </div>
          <div class="va-right tx-c" @click="goDetail(item.id)">
            <van-icon name="qr" class="dq"/>
            村庄名片
            <van-icon name="arrow" class="dq"/>
          </div>
        </div>
      </van-swipe-item>

    </van-swipe>


    </div>

    <div class="middle">
      <div class="mid_one" @click="goJcdj">基层党建</div>
      <div class="mid_two" @click="goXczl">乡村治理</div>
      <div class="mid_three b0 jz">
        <van-button round type="primary" size="small" style="margin-right:5px;" color="linear-gradient(to right, #ff6034, #ee0a24)">乡镇</van-button>
        <van-button round type="primary" size="small" color="linear-gradient(to right, #ff6034, #ee0a24)">村庄</van-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:['list_v'],
  methods:{
    goDetail(id){
      this.$emit('toDetail', {id: id});
    },
    goJcdj(){
      this.$router.push({name:'PartyAffairs',params:{
        id:81
      }});
    },
    goXczl(){
      this.$router.push({name:'VillageAffairs',params:{
        id:82
      }});
    }
  }
};
</script>

<style lang="less" scoped>
.village-slide-container{
  width:100%;background:#fff;
  .top{
    padding:5px 10px;height:30px;display:flex;justify-content: space-between;align-items: center;
    .top_left{width:60%;height:30px;font-size:14px;color:#444;line-height:30px;padding-left:25px;background:url(../../assets/images/mlxc.png) no-repeat;background-size:20px 20px;background-position:0 4px;}
    .top_right{
      width:40%;height:30px;font-size:14px;color:#444;line-height:30px;padding-left:25px;background:url(../../assets/images/paihang.png) no-repeat;background-size:20px 20px;background-position:0 4px;
      .yjt{vertical-align: -2px;}
    }
  }
  .slidebox{padding:0 10px;}
  .middle{
    padding:5px 10px;display:flex;justify-content: space-between;align-items: center;
    .mid_one,.mid_two,.mid_three{width:31%;height:30px;line-height:30px;border-radius:5px;text-align:center;font-size:13px;color:#f30;font-weight:bold;border:solid 1px #f30;}
  }
}
.village-item{
  height:100px;margin-right:5px;position:relative;z-index:900;
  .village_cover{
    width:100%;height:100%;position:absolute;left:0;top:0;z-index:901;border-radius:10px;
    object-fit: cover;
  }
  .village_name{width:90%;height:30px;font-size:20px;color:#fff;position:absolute;left:5%;bottom:40px;z-index:902}
  .village_address{
    width:90%;height:30px;font-size:14px;color:#fff;position:absolute;left:5%;bottom:10px;z-index:903;
    display:flex;justify-content: space-between;align-items: center;
    .va-left{width:65%;}
    .va-right{width:35%;}
  }
}
.dq{
  vertical-align: middle;
}
.tx-r{text-align:right;}
.ycyc{
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
.jz{display:flex;justify-content: center;align-items: center;}
.b0{border:0 !important;}
</style>
