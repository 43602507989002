<template>
  <div class="top-container">
    <span class="val-name pl10" :class="is_active == 'jingxuan' ? 'active' : ''" @click="goJingxun">
      精选
    </span>
    <span class="val-name pl10" :class="is_active == 'cunliao' ? 'active' : ''" @click="goCunliao">
      村聊
    </span>
    <span class="val-name pl10" @click="goHongdong">
      活动
    </span>
    <span class="val-name pl10" @click="goYaowen">
      要闻
    </span>
    <span class="follow" @click="guanzhu">
      <van-icon :name="is_follow ? 'like' : 'like-o'" class="icon-dq-new" :color="is_follow ? '#f30' : ''" size="16"/> <span v-if="is_follow ==1">已关注</span><span v-else>关注</span>
    </span>
    <router-link tag="span" class="follow pr10 jz" to="/search">
      <van-icon name="search" class="icon-dq" size="20"/>
    </router-link>
  </div>
</template>

<script>
export default {
  props:['is_active'],
  data() {
    return {
      is_follow: false,
      is_share:true,
      site_name:'',
      is_join:false,
      villageInfo:{}
    };
  },
  mounted(){
    this.getGzStatus();
    this.init();
  },
  methods:{
    init(){
      var village = window.localStorage.getItem('village_info');
      if(village){
        this.is_join = true;
        this.villageInfo = JSON.parse(village);
        //console.log(this.villageInfo);
      }
    },
    goCunliao(){
      this.$router.push({name:'VillageChat',params:{}});
    },
    goJingxun(){
      this.$router.push({name:'CountyHome',params:{}});
    },
    goHongdong(){
      this.$router.push({name:'VoluntaryList',params:{
        id:25
      }});
    },
    goYaowen(){

    },
    guanzhu(){
      var that = this;
      that.$axios
        .get(that.baseUrl+"/api/follow/siteFollow", {
          params: {
            site_id:window.localStorage.getItem("site_id") ? window.localStorage.getItem("site_id") : 0
          }
        })
        .then(function(res) {
          console.log(res);
          that.is_follow= !that.is_follow;
          if(that.is_follow==1){
            that.$toast("已关注");
          }
          if(that.is_follow==0){
            that.$toast("已取消关注");
          }
        });
    },
    getGzStatus(){
      var that = this;
      //调用验证码接口
      that.$axios
        .get(that.baseUrl+"/api/follow/siteIsFollow", {
          params: {
            site_id:window.localStorage.getItem("site_id") ? window.localStorage.getItem("site_id") : ''
          }
        })
        .then(function(res) {
          if(res.data.code == 1){
            that.is_follow=res.data.data.is_follow;
          }

        });
    },
    goVillageInfo(){
      this.$router.push({name:'VillageInfo',params:{}});
    }
  }

};
</script>

<style lang="less" scoped>
@import "~@/assets/styles/var.less";
.top-container{
  width:100%;
  height:40px;
  line-height:40px;
  background:#fff;
  color:#666;
  display:inline-flex;
  justify-content: space-around;
  align-items: center;
  font-weight:bold;
  font-size:16px;
}
.active{color:@primary;font-weight:bold;}
.icon-dq-new{vertical-align:-3px;}
.pl10{padding-left:20px;}
.pr10{padding-right:20px;}
.zb_img{width:20px;height:20px;}
.jz{
  display:flex;justify-content: center;align-items: center;
}
</style>
