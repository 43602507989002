<template>
  <div class="microwishlist-container">
    <c-title :hide="false"
      :text="`微心愿`"
      tolink=""
      :totext="``">
    </c-title>
    <van-row class="xy">
      <van-col span="12" :class="is_join == 2 ? 'active' :''" @click="renling">
        待认领心愿
      </van-col>
      <van-col span="12" :class="is_join == 1 ? 'active' :''" @click="renling">
        已认领心愿
      </van-col>
    </van-row>
    <div class="content">

      <van-list
        v-model="loading"
        :finished="finished"
        :immediate-check="false"
        finished-text="没有更多了"
        @load="onLoad"
        :offset="70"
      >
      <div class="item" v-for="(item,index) in newsList" :key="index">
        <van-row class="pd">
          <van-col span="4">
            <img src="../../assets/images/user.png" alt="" class="useravatar">
          </van-col>
          <van-col span="20">
            <p class="uname">{{item.user_nickname}}<span class="st">({{item.user_area}}{{item.user_position}})</span></p>
            <p class="udat">{{news.createtime}}</p>
          </van-col>
        </van-row>
        <van-row @click="goDetail(item.id)">
          <van-col span="24" class="xyinfo" >
            <p style="color:#222;font-weight:bold;">{{item.title}}</p>
            <p v-for="(ite,i) in item.new_extra" :key="i">{{ite}}</p>
            <div v-html="item.content" style="font-size:14px;color:#333;"></div>

          </van-col>
        </van-row>
        <van-row >
          <van-col span="24" class="lqbtn">
            <van-button type="warning" size="small" block v-if="is_join == 1">『{{item.lqr_name}}』 已领取此心愿</van-button>
            <van-button type="primary" size="small" block @click="lxwish(item.id)"  v-if="is_join == 2">我要领取</van-button>
          </van-col>
        </van-row>
        <van-row class="tx-c ptb10">
          <van-col span="8">
            <div class="browse">
              <van-icon name="eye-o" class="icon-dq"/>
              {{item.views}}
            </div>
          </van-col>
          <van-col span="8">
            <div class="comment" @click="onClickButton(item.id)">
              <van-icon name="chat-o" class="icon-dq"/>
              {{item.comments}}
            </div>
          </van-col>
          <van-col span="8">
            <div class="fabulous" @click="zan(item.id)">
              <van-icon name="good-job-o" class="icon-dq" v-if="item.is_zan"/>
              <van-icon name="good-job" class="icon-dq t-green" v-else/>
              {{item.ups}}
            </div>
          </van-col>
        </van-row>
        <div class="pd">
          <dian-zan></dian-zan>
          <com-ment></com-ment>
        </div>

      </div>
      </van-list>


    </div>
  </div>
</template>

<script>
import cTitle from '../../components/title.vue';
import dianZan from '../../components/home/zan.vue';
import comMent from '../../components/home/comment.vue';
export default {
  data(){
    return {
      newsList:[],
      site:'',
      page:1,
      limit:10,
      loading: false,
      total: 0,//总共的数据条数
      finished: false,
      is_join:2

    };
  },
  created(){
    this.site = localStorage.getItem('site_id');
    console.log(this.site);
    //获取心愿列表
    this.getroadList();
  },

  methods:{

    zan(id,index){
      var token = window.localStorage.getItem("Authorization");
      if( token == ''){
        this.$toast('请登录');
        return false;
      }
      var that = this;
      that.$axios
        .get(that.baseUrl+"/api/comments/like", {//地址是get请求地址
          params: {
            news_id:id
          }
        })
        .then(function(res) {//当请求完成时候执行then回调函数
          //console.log(res);
          if(res.data.code == 1){
            that.$toast('已点赞');
            that.newsList['index'].ups += 1;
            // that.newsList['index'].is_zan = !that.newsList['index'].is_zan;
          }
        });
    },
    renling(){
      if(this.is_join == 2){
        this.is_join = 1;
      }else if(this.is_join == 1){
        this.is_join = 2;
      }
      this.page = 1;
      this.newsList = [];
      this.getroadList();
    },
    getroadList(){
      let that = this;
      that.$axios
        .get(that.baseUrl+"/api/news/list", {
          params: {
            site:that.site,
            all_site:1,
            category_id:that.$route.params.id,
            page:that.page,
            limit:that.limit,
            is_join:that.is_join,
          }
        })
        .then(function(res) {
          //console.log(res);
          if(res.data.code==1){
            that.loading = false;
            that.total = res.data.data.count;

            var list = res.data.data.list;
            if(list.length > 0){
              list.forEach((item,index) => {
                if(item.extra){
                  var objet = JSON.parse(item.extra);
                  //console.log(objet);
                  var extra_item = [];
                  for (let key in objet) {
                    const obj = objet[key];
                    extra_item.push(obj);
                  }
                  item.new_extra = extra_item;
                }

                //查找心愿领取人
                if(that.is_join == 1){
                  that.$axios
                    .get(that.baseUrl+"/api/join/joinList", {
                      params: {
                        news_id:item.id
                      }
                    })
                    .then(function(res) {
                      //console.log(res.data.code);
                      if(res.data.code == 1){
                        list[index].lqr_name = res.data.data.list[0].name;
                      }else{
                        list[index].lqr_name = '';
                      }
                    });
                }

              });
            }

            if (list == null || list.length === 0) {
              // 加载结束
              that.finished = true;
              return;
            }
            // 将新数据与老数据进行合并
            that.newsList = that.newsList.concat(list);

            //如果列表数据条数>=总条数，不再触发滚动加载
            if (that.newsList.length >= that.total) {
              that.finished = true;
            }

          }
        });
    },
    onLoad() {
      this.page++;
      this.getroadList();
    },

    lxwish(id){
      this.$router.push({name:'ReceiveWish',params:{id:id}});
    },
    onClickButton(id){
      this.$router.push({name:'PostsComment',params:{id:id}});
    },
    goDetail(id){
      this.$router.push({name:'PostsDetail',params:{id:id}});
    }
  },
  components: {
    cTitle,
    dianZan,
    comMent,
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/styles/var.less";
.view{
  height:auto;
}

.microwishlist-container{

  .xy{
    padding:10px;
    text-align:center;
    background:#fff;
    font-size:14px;
    color:@words;
    font-weight:bold;
    margin-left:5px;margin-right:5px;
  }
  .content{
    padding:5px;
    .item{
      width:100%;
      display:inline-block;
      background:#fff;
      margin-bottom:5px;
      padding-top:10px;
      padding-bottom:10px;
      .useravatar{
        width:40px;
        height:40px;
        border-radius:50%;
      }
      .uname{
        color:@words;
        font-size:14px;
        padding-top:3px;
      }
      .udat{
        color:@lightWords;
        font-size:14px;
        padding-top:5px;
      }
      .xyinfo{
        padding:10px;
        p{
          color:@words;
          font-size:13px;
          line-height:20px;
          margin-bottom:5px;
        }
      }
      .lqbtn{
        padding:10px;
      }
    }
  }
}
.active{
  color:#f30;
}
.lqw{
  font-size:14px;
  font-weight:bold;
  color:#f30;
}
.icon-dq{vertical-align:-16%}
.dy-tj{
      width:100%;
      height:auto;
      float:left;
      display:inline-flex;
      justify-content: space-between;
      div{
        width:33%;text-align:center;
        height:30px;line-height:30px;
      }
    }
    .ptb10{
      padding-bottom:10px;
      color:@words;
      font-size:14px;
    }
    .pd{
       padding:0 10px;
    }
    .st{font-size:14px;color:#888;}
</style>
