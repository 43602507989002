<template>
  <div class="worklog-container">
    <c-title :hide="false" text="组织架构管理" tolink="" :totext="``"> </c-title>
    <div class="top">
      <div class="toptit">组织架构管理</div>
      <div class="topsearch">
        <van-search v-model="searchvalue" placeholder="请输入搜索关键词" class="cbg" />
      </div>
      <div class="topmenu">
        <van-button plain hairline round size="small" :type="organise_type == 0 ? 'warning' : 'default'" class="mr10" @click="organisetype(0)">全部 {{ allUserNum }}</van-button
        >
        <van-button plain hairline round size="small" :type="organise_type == 2 ? 'warning' : 'default'" class="mr10" @click="organisetype(2)">党务人员</van-button
        >
        <van-button plain hairline round size="small" :type="organise_type == 1 ? 'warning' : 'default'" class="mr10" @click="organisetype(1)">村委人员</van-button
        >
      </div>
    </div>
    <div class="cent">
      <div class="cent-item" v-for="item in userList" :key="item.id">
        <div class="item-top">
          <div class="item-top-left">
            <img :src="item.avatar" />
          </div>
          <div class="item-top-right">
            <div class="flex">
              <span class="item-name">{{ item.name }}</span>
              <span class="item-time">{{ item.jointime }}</span>
            </div>
            <div class="flex">
              <span class="item-shenfen">{{ item.position }}</span>
              <span class="item-phone" @click="tell(item.mobile)">拨打电话</span>
            </div>
          </div>
        </div>
        <div class="item-foot">
          <div class="item-foot-san t-org ubr">
            禁言
          </div>
          <div class="item-foot-san t-org ubr" @click="editUser(item.id)">
            修改
          </div>
          <div class="item-foot-san t-gay" @click="remove(item.id)">
            移出本村
          </div>
        </div>
      </div>
    </div>

    <div class="foot">
      <van-button type="primary" size="small" @click="addUser">新增</van-button>
    </div>
  </div>
</template>

<script>
import cTitle from "../../components/title.vue";
import { Toast } from "vant";
export default {
  data() {
    return {
      searchvalue: "",
      allUserNum:0,
      userList: [],
      organise_type: 0
    };
  },
  created() {
    this.getUserList();
  },
  methods: {
    addUser() {
      this.$router.push({ name: "TownAddOrganizationalUser", params: {} });
    },
    getUserList() {
      var that = this;
      that.$axios
        .get(that.baseUrl + "/api/town_manager/departmentUser", {
          //地址是get请求地址
          params: {
            organise_id: that.organise_type
          }
        })
        .then(function(res) {
          //当请求完成时候执行then回调函数
          console.log(res);
          if (res.data.code == 1) {
            if(that.organise_type == 0){
              that.allUserNum = res.data.data.lenght;
            }
            that.userList = res.data.data;
          }
        });
    },
    tell(mobile) {
      window.location.href = "tel:" + mobile;
    },
    organisetype(n){
      this.organise_type = n;
      this.getUserList();
    },
    editUser(id){
      this.$router.push({name:'TownAddOrganizationalUser',params:{
        id:id
      }});
    },
    remove(id){
      var that = this;
      that.$axios
        .get(that.baseUrl + "/api/town_manager/removeDepartmentMember", {
          params: {
            id: id,
          }
        })
        .then(function(res) {
          console.log(res);
          if (res.data.code == 1) {
            Toast(res.data.msg);
            that.getUserList();
          }
        });
    }
  },
  components: {
    cTitle
  }
};
</script>

<style lang="less" scoped>
.worklog-container {
  padding-top: 180px;
  padding-bottom: 50px;
  background: #f8f8f8;
  .top {
    width: 100%;
    height: 180px;
    background: #fff url(../../assets/images/gzt/logbg.png) no-repeat;
    background-size: 100% 100px;
    background-position: top center;
    position: fixed;
    left: 0;
    top: 40px;
    .toptit {
      padding: 20px;
      height: 36px;
      line-height: 40px;
      font-size: 20px;
      color: #fff;
    }
    .topsearch {
      padding: 0 20px;
    }
    .topmenu {
      padding: 10px 20px;
      height: 40px;
      white-space: nowrap;
      overflow-x: scroll;
      botton {
        display: inline-block;
      }
    }
  }
  .cent {
    padding: 15px;
    .cent-item {
      padding: 10px;
      background: #fff;
      margin-bottom: 15px;
      .item-top {
        width: 100%;
        display: flex;
        .item-top-left {
          width: 25%;
          img {
            width: 60px;
            height: 60px;
            border-radius: 50%;
          }
        }
        .item-top-right {
          width: 75%;
          .flex {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 5px;
            margin-bottom: 5px;
            .item-name {
              font-size: 14px;
              color: #111;
              font-weight: bold;
            }
            .item-time {
              font-size: 14px;
              color: #888;
            }
            .item-shenfen {
              font-size: 14px;
              color: #fff;
              background: green;
              padding: 0 10px;
              border-radius: 20px;
              line-height: 16px;
            }
            .item-phone {
              font-size: 14px;
              color: green;
              padding: 0 10px;
              border: solid 1px green;
              border-radius: 20px;
            }
          }
        }
      }
      .item-foot {
        height: 30px;
        line-height: 30px;
        border-top: solid 1px #ddd;
        padding-top: 10px;
        margin-top: 10px;
        display: flex;
        .item-foot-san {
          width: 33%;
          text-align: center;
          font-size: 14px;
        }
      }
    }
  }

  .foot {
    width: 100%;
    height: 50px;
    background: #fff;
    position: fixed;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mr10 {
    margin-right: 10px;
  }
  .mb10 {
    margin-bottom: 10px;
  }
  .mr05 {
    margin-right: 5px;
  }
  .cbg {
    box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.1);
  }
  .t-gay {
    color: #888;
  }
  .t-org {
    color: #f60;
  }
  .ubr {
    border-right: solid 1px #ddd;
  }
}
</style>
