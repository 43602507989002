<template>
  <div class="default">
    <img src="../assets/404.jpg" class="default_img">
  </div>
</template>

<script>
export default {
  data(){
    return {

    };
  }
};
</script>

<style lang="less" scoped>
.default{
    height:100vh;
    background:#7dcdc0;
    display:flex;
    justify-content: center;
    align-items: center;
    .default_img{width:100%;}
}
</style>
