<template>
  <div class="microwish-container">
    <c-title :hide="false"
      :text="`新增产业`"
      tolink=""
      :totext="``">
    </c-title>
    <div class="content">

      <van-field
        v-model="fieldValue"
        is-link
        readonly
        label="产业类型"
        placeholder="请选择产业类型"
        @click="show = true"
      />
      <van-field
          v-model="address"
          name="address"
          label="产业地址"
          placeholder="请输入产业详细地址"
      />
      <van-field
        v-model="message1"
        rows="5"
        autosize
        label=""
        type="textarea"
        maxlength="300"
        placeholder="请输入具体地址"
        show-word-limit
      />
      <van-field
        v-model="message2"
        rows="5"
        autosize
        label=""
        type="textarea"
        maxlength="300"
        placeholder="请输入产业介绍"
        show-word-limit
      />
      <div class="upload-img">
        <van-uploader v-model="fileList" multiple :after-read="afterRead"/>
      </div>

    </div>
    <div class="btnbox">
      <van-button round type="info" color="#ccc" class="mr10" @click="goback"> 取消 </van-button>
      <van-button round type="info" color="#00a752" :disabled="is_click" @click="submit"> 发布 </van-button>
    </div>
    <van-popup v-model="show" round position="bottom">
      <van-cascader
        v-model="cascaderValue"
        title="请选择产业类型"
        :options="options"
        @close="show = false"
        @finish="onFinish"
      />
    </van-popup>
  </div>
</template>

<script>
import cTitle from '../../components/title.vue';
export default {
  data(){
    return{
      show: false,
      fieldValue:'',
      options: [],
      cascaderValue:'',
      categroy_ids:'',
      fileList: [],
      img_arr:'',
      is_click:false,
      address:'',
      message1:'',
      message2:''

    };
  },
  created(){
    this.getOptions();
  },
  methods:{
    getOptions(){
      var that = this;
      that.$axios
        .get(that.baseUrl+"/api/user_config/industry", {
          params:{

          }
        })
        .then(function(res) {//当请求完成时候执行then回调函数
          if(res.data.code == 1){
            var result = [];
            var list = res.data.data;
            if(list.length > 0){
              list.forEach(element => {
                var obj = {};
                obj.value = element.id;
                obj.text = element.name;
                result.push(obj);
              });
            }
            that.options = result;
            console.log(that.options);
          }
        });
    },
    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      var that = this;
      that.img_arr = [];
      if(that.fileList.length>0){
        for (var i=0;i<that.fileList.length;i++){
          var img_url = that.fileList[i].content;
          that.$axios
            .post(that.baseUrl+"/api/common/uploadBase64", {
              params:{
                base64:img_url
              }
            })
            .then(function(res) {//当请求完成时候执行then回调函数

              if(res.data.code == 1){
                that.img_arr += res.data.data.fullurl+',';
              }
            });
        }
      }
    },
    // 全部选项选择完毕后，会触发 finish 事件
    onFinish({ selectedOptions }) {
      this.show = false;
      this.fieldValue = selectedOptions.map((option) => option.text).join('/');
      this.categroy_ids = selectedOptions.map((option) => option.value).join('/');
      console.log(this.categroy_ids);
    },
    submit(){
      var that = this;
      that.is_click = true;
      var extend = [];
      extend[0] = {
        name:"产业类型",
        value:that.fieldValue
      };
      extend[1] = {
        name:"产业地址",
        value:that.address
      };
      extend[2] = {
        name:"具体地址",
        value:that.message1
      };
      extend[3] = {
        name:"产业介绍",
        value:that.message2
      };
      extend[4] = {
        name:"产业图片",
        value:that.img_arr
      };


      var new_extend = JSON.stringify(extend);
      console.log(typeof new_extend);
      that.$axios
        .get(that.baseUrl+"/api/user_config/saveConfig", {
          params: {
            id:0,
            type:"产业",
            extra:new_extend,
          }
        })
        .then(function(res) {
          console.log(res);
          if(res.data.code == 1){

            that.$toast('发布成功');
            setTimeout(function(){
              that.$router.push("/myindustry");
            },2000);
          }else{
            that.is_click=false;
          }
        });

    },

    goback(){
      this.$router.go(-1);
    },

  },
  components: {
    cTitle
  },
};
</script>

<style lang="less" scoped>

.upload-img{
    padding:15px;
    background:#fff;
  }
  .mr10{margin-right:10px;}
  .btnbox{
    padding:20px;
    text-align:center;
}
</style>
