<template>
  <div class="selectsite-container">
    <c-title :hide="false"
      text="选择村镇"
      tolink=""
      :totext="``">
    </c-title>
    <div class="lev1" v-for="(item,i) in siteList" :key="i">
      <div class="lev1-btn">
        <van-button type="primary" size="small" @click="goSite(item.id,item.type)">{{item.site}}</van-button>
      </div>
      <div class="lev2" v-if="item.children.length > 0">
        <div class="lev2-btn" v-for="(it, index) in item.children" :key="index">
          <div class="lev2-box">
            <div class="lev2-box-left">
              <van-button plain type="primary" size="small" @click="goSite(it.id,it.type)" >{{it.site}}</van-button>
            </div>
            <div class="lev2-box-right" v-if="it.children.length > 0">
               <van-button type="default" size="small" v-for="(ite,ii) in it.children" :key="ii" style="margin-right:5px;" @click="goSite(ite.id,ite.type)">{{ite.site}}</van-button>
            </div>
          </div>


        </div>
      </div>
    </div>
    <div style="padding-bottom:60px;"></div>
    <foot-bar></foot-bar>
  </div>

</template>

<script>
import cTitle from '../components/title.vue';
import footBar from '../components/foot.vue';
export default {
  data(){
    return {
      siteList:[]
    };
  },
  created(){
    this.getSiteList();
  },
  methods:{
    goSite(id,type){
      window.localStorage.setItem('type',type);
      if(type == '县'){
        this.$router.push({name:'CountyHome',params:{site:id}});
      }else if(type == '镇'){
        this.$router.push({name:'TownHome',params:{site:id}});
      }else if(type == '村'){
        this.$router.push({name:'Home',params:{site:id}});
      }
    },
    getSiteList(){
      var that = this;
      that.$axios
        .get(that.baseUrl+"/api/common/site", {
          params: {
          }
        })
        .then(function(res) {
          console.log(res);
          if(res.data.code == 1){
            that.siteList = res.data.data.site;
          }
        });

    }
  },
  components: {
    cTitle,
    footBar,
  },
};
</script>

<style lang="less" scoped>
  .selectsite-container{

    .lev1{margin:10px;padding:10px;background:#fff;border-radius:5px;}
    .lev2{
      padding:10px 0 0;
      .lev2-btn{
        margin-bottom:15px;
        .lev2-box{width:100%;display:flex;justify-content: space-between;}
        .lev2-box-left{width:20%}
        .lev2-box-right{width:80%;}
      }
    }

  }

</style>
