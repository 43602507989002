<template>
  <div class="fabu-container">
    <div class="topbox">
      <div class="left">
        <div class="l">
          <img src="../assets/images/logo.png" class="cun_logo">
        </div>
        <div class="r" @click="govillage">
          <div class="t">{{userinfo.village}}</div>
          <div class="b">点击进入</div>
        </div>

      </div>
      <div class="right">
        <p><span class="day">{{day}}</span><span class="xq">{{xingqi}}</span></p>
        <p class="ny">{{year}}</p>
      </div>
    </div>
    <div class="tcbox">
      <div class="navlist">
        <div class="item"
          v-for="item in category_list"
          :key="item.id"
          @click="next_fabu(item.id,item.style)"
          >
          <img :src="item.icon_image" alt="" class="menu_icon">
          <p class="menu_txt">{{item.title}}</p>
        </div>

      </div>
      <div class="fbbtn">
        发布
      </div>
      <div class="cancel">
        <van-icon name="cross" class="xuanzhuang" size="20" @click="goback"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      day:'',
      year:'',
      xingqi:'',
      category_list:[],
      village_name:'',
      village_icon:'',
      userinfo:'',
    };
  },
  created(){
    if(window.localStorage.getItem('userinfo')){
      this.userinfo = JSON.parse(window.localStorage.getItem('userinfo'));
    }
    console.log(this.userinfo);
    this.init();
    this.getTime();

  },
  methods:{
    init(){
      if(window.localStorage.getItem('user_village_id')){
        this.getCategory();
        this.getVillage_info();
      }else{
        that.$router.push("/login");
      }

    },
    getTime(){
      var myDate=new Date();
      this.day = myDate.getDate();
      this.year = myDate.getMonth()+'/'+myDate.getFullYear();
      var xingqi_num = myDate.getDay();
      switch(xingqi_num) {
      case 1:
        this.xingqi = '星期一';
        break;
      case 2:
        this.xingqi = '星期二';
        break;
      case 3:
        this.xingqi = '星期三';
        break;
      case 4:
        this.xingqi = '星期四';
        break;
      case 5:
        this.xingqi = '星期五';
        break;
      case 6:
        this.xingqi = '星期六';
        break;
      case 0:
        this.xingqi = '星期日';
        break;
      }
    },
    goback(){
      this.$router.go(-1);
    },
    govillage(){
      this.$router.push({name:'Home',params:{
        site:this.userinfo.site_id
      }});
    },
    getVillage_info(){
      var site_id = window.localStorage.getItem('user_village_id');
      var that = this;
      that.$axios
        .get(that.baseUrl+"/api/site/index", {
          params: {
            site_id:site_id
          }
        })
        .then(function(res) {
          //console.log(res);
          if(res.data.code == 1){
            that.village_name = res.data.data.site;
            that.village_icon = res.data.data.icon_image;
          }else{
            //console.log(res.data.msg);
          }
        });

    },
    getCategory(){
      var that = this;
      that.$axios
        .get(that.baseUrl+"/api/news/addcategory", {
          params: {
            site:window.localStorage.getItem('user_village_id') ? window.localStorage.getItem('user_village_id') : ''
          }
        })
        .then(function(res) {
          //console.log(res);
          if(res.data.code == 1){
            that.category_list=res.data.data;
          }else{
            //console.log(res.data.msg);
          }
        });
    },
    next_fabu(id,style){
      var routername = '';
      if(style == 1){
        //普通帖子
        routername = "BaseForm";
      }else if(style == 2){
        //富文帖子
        routername = "RichText";
      }else if(style == 3){
        //微心愿
        routername = "MicroWish";
      }else if(style == 4){
        //咨询建议
        routername = "ConsultingService";
      }else if(style == 5){
        //书记公开信
        routername = "OpenLetter";
      }else if(style == 6){
        //书记信箱
        routername = "SecretaryMailbox";
      }else if(style == 7){
        //供求信息
        routername = "SupplyDemand";
      }else if(style == 8){
        //发起志愿活动
        routername = "VoluntaryActivities";
      }
      this.$router.push(
        {
          name:routername,
          params:{
            id:id
          }
        });
    }
  }
};
</script>

<style lang="less" scoped>
@import "~@/assets/styles/var.less";
.fabu-container{
  height:100vh;
  position:relative;
  .topbox{
    padding:15px;
    display:flex;
    justify-content: space-between;
    background:#fff;
    box-shadow: 0 0 5px rgba(0,0,0,0.1);
    .left{
      width:40%;display:flex;
      .r{
        .t{
          font-size:16px;font-weight:bold;color:@primary;margin-top:5px;
        }
        .b{
          margin-top:5px;
          padding:0px 6px 2px;background:#f60;color:#fff;
          border-radius:6px;font-size:14px;
        }
      }
      .cun_logo{
        width:50px;height:50px;vertical-align: middle;margin-right:5px;
      }

    }
    .right{
      width:60%;
      text-align:right;
      .day{
        font-size:24px;
        font-weight:bold;
        color:@words;
        margin-right:5px;
      }
      .xq{
        color:@dark;
        font-size:16px;
      }
      .ny{
        padding-top:5px;
        font-size:14px;
        color:@words;
        text-align:right;
      }
    }
  }
  .tcbox{
    width:100%;
    height:400px;
    background:#fff;
    position:absolute;
    left:0;
    bottom:0;
    opacity:1;
    animation: mymove 0.5s linear;
    .navlist{
      height:320px;
      overflow-y: scroll;
      .item{
        width:25%;
        height:80px;
        text-align:center;
        float:left;
        .menu_icon{
          width:36px;
          height:36px;
          margin-top:14px;
          border-radius:50%;
        }
        .menu_txt{
          font-size:14px;
          color:@dark;
        }
      }
    }
    .fbbtn{
      text-align:center;
      padding-top:10px;
      padding-bottom:10px;
      color:#444;
      font-size:15px;
      font-weight:bold;
      border-top:solid 1px #f2f2f2;
    }
    .cancel{

      text-align:center;
      color:#666;
      padding-top:5px;
      padding-bottom:15px;
    }
    .xuanzhuang{
       animation: xuanzhuang 1s linear;
    }
  }
}
@keyframes mymove {
  0%   {opacity:0;bottom:-400px;}
  100% {opacity:1;bottom:0}
}
@keyframes xuanzhuang {
  0%   {transform:rotate(0deg);}
  100% {transform:rotate(360deg);}
}
.line{
  width:100%;float:left;
  padding-top:5px;margin-bottom:5px;border-bottom:dashed 1px #ddd;
}

</style>
