<template>
  <div class="getrich-container">
    <c-title :hide="false"
      :text="`致富经`"
      tolink=""
      :totext="``">
    </c-title>
    <home-slide :banner="bannerList"></home-slide>
    <div class="searchbox mt05 mb05">
      <van-search v-model="value" placeholder="请输入搜索关键词" class="ss"/>
    </div>
    <div class="menubox flex">
      <div class="menuitem"
        v-for="(item,index) in menuList"
        :key="index"
        :class="is_active == item.id ? 'active' : ''"
        @click="qhid(item.id)"
      >
        <div class="icon"><img :src="item.icon_image2" alt=""></div>
        <div class="menutit">{{item.title}}</div>
      </div>
    </div>

    <van-list
      v-model="loading"
      :finished="finished"
      :immediate-check="false"
      finished-text="没有更多了"
      @load="onLoad"
      :offset="10"
    >
    <home-dynamic
      v-for="(item,i) in newsList"
      :key="i"
      :news="item"
      :is_count="5"
    ></home-dynamic>
    </van-list>
    <foot-bar></foot-bar>
  </div>
</template>

<script>
import cTitle from '../../components/title.vue';
import homeSlide from '../../components/home/slide.vue';
import homeDynamic from '../../components/home/dynamic.vue';

import footBar from '../../components/foot.vue';
export default {
  data(){
    return{
      value:'',
      bannerList:[],
      menuList:[],
      is_active:'',
      newsList:[],//帖子列表
      page:1,
      limit:10,
      loading: false,
      total: 0,//总共的数据条数
      finished: false,
    };
  },
  created(){
    this.getBanner();
    this.getCategory();
  },
  methods:{
    getBanner(){
      let that = this;
      that.$axios
        .get(that.baseUrl+"/api/banner/index", {
          params: {
            site:localStorage.getItem('county_id'),
            type:'致富经',
          }
        })
        .then(function(res) {
          console.log(res);
          if(res.data.code==1){
            var arr = res.data.data;
            if(arr.length > 0){
              arr.forEach(element => {
                that.bannerList.push(element.imgurl);
              });
            }
          }
        });
    },
    getCategory(){
      var that = this;
      that.$axios
        .get(that.baseUrl+"/api/news/categoryDetail", {
          params: {
            'id':that.$route.params.id
          }
        })
        .then(function(res) {
          console.log(res);
          if(res.data.code == 1){
            that.category_name = res.data.data.title;
            if(res.data.data.children.length > 0){
              that.menuList = res.data.data.children;
              that.is_active = that.menuList[0].id;
              that.getroadList();
            }else{
              that.menuList = [];
            }
          }else{
            //console.log(res.data.msg);
          }
        });
    },
    qhid(id){
      this.is_active = id;
      this.newsList = [];
      this.page=1;
      this.loading=false;
      this.total=0;//总共的数据条数
      this.finished=false;
      this.getroadList();
    },
    onLoad() {
      this.page++;
      this.getroadList();
    },
    getroadList(){
      var site = window.localStorage.getItem('county_id');
      let that = this;
      that.$axios
        .get(that.baseUrl+"/api/news/list", {
          params: {
            site:site,
            all_site:1,
            category_id:that.is_active,
            page:that.page,
            limit:that.limit
          }
        })
        .then(function(res) {
          if(res.data.code==1){
            that.loading = false;
            that.total = res.data.data.count;

            var list = res.data.data.list;

            if (list == null || list.length === 0) {
              // 加载结束
              that.finished = true;
              return;
            }
            // 将新数据与老数据进行合并
            that.newsList = that.newsList.concat(list);

            //如果列表数据条数>=总条数，不再触发滚动加载
            if (that.newsList.length >= that.total) {
              that.finished = true;
            }

          }
        });
    },
  },
  components: {
    cTitle,
    homeSlide,
    footBar,
    homeDynamic
  },
};
</script>

<style lang="less" scoped>
.view{height:100%;background:linear-gradient(to bottom,#effaeb,#f5f5f5);}
.list-item{
  padding:10px 15px;background:#fff;margin-bottom:5px;
  .item-top{

    .top-left{
      width:15%;
      .usertx{width:40px;height:40px;border-radius:50%;}
    }
    .top-right{
      width:85%;
      .l{
        width:80%;
        .uname{font-size:13px;font-weight:bold;color:#333;}
        .usf{font-size:14px;color:#777;}

      }
      .r{
        width:20%;
      }
      .address{font-size:14px;color:#888;}
    }
  }
}
.bohao{
  width:100%;height:36px;line-height:36px;text-align:center;font-size:14px;color:#fff;background:#5eb06e;
  margin-top:15px;margin-bottom:15px;border-radius:10px;
}
.content{line-height:24px;}
.getrich-container{
  padding-bottom:50px;
}
.blockline{
  height:10px;
}
.menubox{background:#fff;margin-bottom:5px;padding-bottom:5px;}
.menuitem{
  width:25%;text-align:center;
  padding-bottom:10px;padding-top:15px;
  .icon img{width:36px;height:36px;}
  .menutit{font-size:13px;color:#666;}
}
.active{
  background:url(../../assets/images/green.png) no-repeat;
  background-size:35% 3px;
  background-position:bottom center;
  .menutit{
    color:#5eb06e !important;font-weight:bold;
  }
}
.it{display:inline-flex;justify-content:center;}
.flex{display:flex;justify-content:space-between;}
.uhide{display:none;}
.tx-c{text-align:center;}
.pb10{padding-bottom:10px;}
.mt05{margin-top:5px;}
.mb05{margin-bottom:5px;}
.mt10{margin-top:10px;}
.mb10{margin-bottom:10px;}
.tx-r{text-align:right;}
.mr05{margin-right:5px;}
.van-list__error-text, .van-list__finished-text, .van-list__loading{color:#fff;}
</style>
