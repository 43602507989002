<template>
  <div class="postslist-container">
    <c-title :hide="false"
      :text="`帖子列表`"
      tolink=""
      :totext="``">
    </c-title>
    <div class="height10"></div>
    <home-dynamic></home-dynamic>
    <home-dynamic></home-dynamic>
    <foot-bar></foot-bar>
  </div>
</template>

<script>
import cTitle from '../../components/title.vue';
import homeDynamic from '../../components/home/dynamic.vue';
import footBar from '../../components/foot.vue';
export default {
  components: {
    cTitle,
    homeDynamic,
    footBar
  },
};
</script>

<style lang="less" scoped>
.postslist-container{
  padding-bottom:44px;
  .height10{height:10px;}
}
</style>
