<template>
  <div class="menu-container">
      <div tag="div" class="menu-item"
        v-for="item in menu"
        :key="item.id"
        @click="gotoList(item.id,item.style,item.title)"
      >
        <img :src="item.icon_image2" class="menu-icon"/>
        <p class="pt02 fb t-sbla">{{item.title}}</p>
      </div>
      <!-- <div class="menu-item" v-if="menu.length =7" @click="goMore">
        <img src="../assets/images/sy04.png" class="menu-icon"/>
        <p class="pt02">更多</p>
      </div> -->
  </div>
</template>

<script>
export default {
  props:['menuList','type'],
  created(){
    //console.log(this.menuList);
  },
  computed:{
    menu:function(){
      return this.menuList.slice(0,4);
    }
  },
  methods:{
    goMore(){
      this.$router.push({name:'Service',params:{}});
    },
    gotoList(id,style,title){
      var routername = '';
      if(style == 1){
        //普通帖子分类
        routername = "NewsList";
        this.$router.push(
          {
            name:routername,
            params:{
              // type:this.type,
              // type1:title,
              id:id
            }
          });
        return false;
      }else if(style == 10){
        //村委风采
        routername = "BranchStyle_v";
      }else if(style == 9){
        //支部风采
        routername = "BranchStyle_p";
      }else if(style == 8){
        //志愿活动
        routername = "VoluntaryList";
      }else if(style == 3){
        //微心愿
        routername = "MicroWishList";
      }else if(style == 6){
        //书记信箱
        routername = "ShuJiEmail";
      }
      this.$router.push(
        {
          name:routername,
          params:{
            id:id
          }
        });
    }
  }
};
</script>

<style lang="less" scoped>
@import "~@/assets/styles/var.less";
.menu-container{
  margin:0 0 5px;
  background:#fff;
  padding:10px 0;

  .menu-item{
    width:25%;
    padding-top:10px;
    padding-bottom:10px;
    display:inline-block;
    text-align:center;
    .menu-icon{width:30px;height:30px;}
  }
}

.pt02{padding-top:4px;color:@words;}
.t-sbla{color:#222;}
.fb{font-weight:bold;}
</style>
