<template>
  <div class="dynamic-container">
    <div class="dy-list">
      <div class="dy-top">
        <div class="avatar">
          <img :src="user.avatar" alt="">
        </div>
        <div class="userinfo ">
          <div class="name mui-ellipsis">
            <img src="../../assets/images/dang.png" alt="" v-if="news.is_party_member == 1">
            <span class="nickname">{{user.nickname}}</span>
            <span class="sf">({{user.town}}{{user.village}}{{user.position}})</span>
          </div>
          <div class="subname mui-ellipsis">
            {{news.createtime}} <span>来自{{user.province}}{{user.city}}{{user.area}}</span>
          </div>
        </div>
        <div class="gzbox">
          <van-button round  type="primary" size="mini" :color="bascC" v-if="is_gz" @click="guanzhu">已关注</van-button>
          <van-button round  plain  type="primary" size="mini" :color="bascC" v-else @click="guanzhu">关注</van-button>

          <!-- <div class=" gz active tx-c" :style="`background:`+bascC+`;border-color:`+bascC" v-if="is_gz" @click="guanzhu">已关注</div>
          <div class=" gz tx-c"  :style="`;color:`+bascC+`;border-color:`+bascC" v-else  @click="guanzhu">关注</div> -->
        </div>
      </div>
      <router-link tag="div" class="dy-title" :to="`/posts/detail/`+news.id">
        <div class="title  mui-ellipsis">
          <span class="dy-type" :style="`background:`+bascC">{{newstype}}</span>
        {{news.title}}
        </div>
        <div class="content ycyc2" v-html="news.content" style="font-size:14px !important;color:#666 !important;">
        </div>
      </router-link>
       <router-link tag="div" class="dy-photo" :to="`/posts/detail/`+news.id">
        <div class="dy-photo-item"
          v-for="(item_img,i) in news.cover_arr"
          v-if="i<3"
          :key="i"
        >
          <img :src="item_img" class="dy-photo-img" />
        </div>
      </router-link>
      <div class="dy-tj">
        <div class="browse">
          <van-icon name="eye-o" class="icon-dq" size="16"/>
         {{news.views}}
        </div>
        <div class="comment"  @click="gotoComment()">
          <van-icon name="chat-o" class="icon-dq"  size="16"/>
         {{news.comments}}
        </div>
        <div class="fabulous" @click="zan" >
          <van-icon name="good-job" class="icon-dq" :color="basecolor" v-if="is_zan"  size="16"/>
          <van-icon name="good-job-o" class="icon-dq" v-else  size="16"/>
          {{news.ups}}
        </div>
      </div>
      <div class="pd" v-if="zanList.length>0">
         <dian-zan  :zanList="zanList" :num="news.ups" :basecolor="bascC"></dian-zan>
      </div>
      <div class="pd" v-if="comment_list.length>0">
         <com-ment :comment_list = "comment_list" :basecolor="bascC" :is_count="is_count"></com-ment>
      </div>

    </div>
  </div>
</template>

<script>
import dianZan from './zan.vue';
import comMent from './comment.vue';

export default {
  props:['news','basecolor','is_count'],
  data(){
    return{
      comment_list:[],
      zanList:[],
      is_zan:false,
      is_gz:false,
      user:'',
      id:'',
      // avatar_mr:require('../../assets/images/user.png'),
    };
  },
  created(){
    this.getZan();
    this.getCommentList();
    this.getGzStatus();
    this.getZanStatus();
    this.getUserInfo();
    this.id = this.news.id;

  },

  computed:{
    newstype:function(){
      var name = '';
      if(this.news.type2 !=''){
        name = this.news.type2;
      }else if(this.news.type1 !=''){
        name = this.news.type1;
      }else{
        name = this.news.type;
      }
      return name;
    },
    bascC:function(){
      var bcolor = '#33cc66';
      if(this.basecolor){
        bcolor = this.basecolor;
      }else if(this.news.type=='党务'){
        bcolor = '#ec1d25';
      }else if(this.news.type=='村务'){
        bcolor = '#3e65b0';
      }

      return bcolor;
    }
  },
  methods:{
    getUserInfo(){
      var userid = this.news.user_id;
      let that = this;
      that.$axios
        .get(that.baseUrl+"/api/user/user", {
          params: {
            user_id:userid
          }
        })
        .then(function(res) {
          //console.log(res);
          if(res.data.code==1){
            that.user = res.data.data.user;
          }
        });
    },
    getGzStatus(){
      if(this.news.is_follow == 1){
        this.is_gz = true;
      }
    },
    getZanStatus(){
      //console.log(this.news.is_ups);
      if(this.news.is_ups == 1){
        this.is_zan = true;
        //console.log(this.is_zan);
      }
    },
    guanzhu(){
      let that = this;
      var userid = that.news.user_id;
      that.$axios
        .get(that.baseUrl+"/api/follow/userFollow", {
          params: {
            user_id:userid,
          }
        })
        .then(function(res) {
          if(res.data.code==1){
            that.is_gz = !that.is_gz;
            if(that.is_gz == true){
              that.$toast('已关注');
            }else{
              that.$toast('已取消关注');
            }
          }
        });
    },
    gotoComment(){
      this.$router.push({name:'PostsComment',params:{id:this.news.id}});
    },
    getCommentList(){
      var news_id = this.news.id;
      let that = this;
      that.$axios
        .get(that.baseUrl+"/api/comments/list", {
          params: {
            news_id:news_id,
            page:1,
            limit:10
          }
        })
        .then(function(res) {
          if(res.data.code==1){
            that.comment_list= res.data.data.list;
            //console.log(that.comment_list);
          }
        });
    },
    getZan(){
      var that = this;
      var id = that.news.id;
      that.$axios
        .get(that.baseUrl+"/api/comments/likelog", {
          params: {
            news_id:id
          }
        })
        .then(function(res) {
          //console.log(res);
          if(res.data.code == 1){
            that.zanList=res.data.data;
          }
        });
    },
    zan(){
      var token = window.localStorage.getItem("Authorization");
      if( token == ''){
        this.$toast('请登录');
        return false;
      }
      var that = this;
      var id = that.news.id;
      that.$axios
        .get(that.baseUrl+"/api/comments/like", {//地址是get请求地址
          params: {
            news_id:id
          }
        })
        .then(function(res) {//当请求完成时候执行then回调函数
          //console.log(res);
          if(res.data.code == 1){

            if(that.is_zan == true){
              that.$toast('已取消赞');
              that.news.ups -= 1;
              that.is_zan = !that.is_zan;
            }else if(that.is_zan == false){
              that.$toast('已点赞');
              that.news.ups += 1;
              that.is_zan = !that.is_zan;
            }

          }
        });
    },

  },
  components: {
    dianZan,
    comMent,
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/styles/var.less";
.pd{width:100%;float:left;background:#f5f5f5;}
.dynamic-container{
  padding:0 0 0px;
  .dy-list{
    padding:10px;
    background:#fff;
    border-radius:0px;
    margin-bottom:5px;
    overflow:auto;
    .dy-top{
      width:100%;
      float:left;
      .avatar{
        width:15%;
        height:40px;
        float:left;
        img{
          width:40px;
          height:40px;
          border-radius:20px;
        }
      }
      .userinfo{
        width:70%;
        float:left;
        .name{
          height:20px;line-height:20px;
          .nickname{
            font-size:16px;
            color:#222;margin-right:4px;
          }
          .sf{
            font-size:14px;
            color:@words;
          }
          img{width:16px;height:16px;border-radius:8px;vertical-align:-16%;margin-right:4px;}
        }
        .subname{height:20px;line-height:22px;font-size:11px;color:@lightWords;}
      }
      .gzbox{
        width:15%;
        float:left;
        text-align:right;
      }
    }
    .dy-title{
      width:100%;
      margin-top:5px;
      margin-bottom:5px;
      float:left;
      .title{
        height:30px;line-height:30px;font-size:14px;font-weight:bold;
        .dy-type{
          font-size:10px;background:@primary;color:#fff;padding:3px 4px;
          border-radius:4px;vertical-align:8%;
        }
        font-size:14px;color:#111;
      }
      .content{
        font-size:14px;color:@lightWords;line-height:20px;height:40px;
      }

    }
    .dy-photo{
      width:100%;
      margin-bottom:5px;
      float:left;
      display:inline-flex;
      justify-content: flex-start;
      .dy-photo-item{
        width:32%;height:100px;
        margin-right:1.3%;
        overflow:hidden;
        display:flex;
        justify-content: center;
        align-items: center;
        .dy-photo-img{
          width:100%;
        }
        img{min-height:100%;}
      }
    }
    .dy-tj{
      width:100%;
      float:left;
      display:inline-flex;
      justify-content: space-between;
      div{
        width:33%;text-align:center;
        height:30px;line-height:30px;
        font-size:14px;
      }
    }
  }
}
.mui-ellipsis{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.mr05{margin-right:5px;}
.van-grid-item__content--center{padding:0;}
.icon-dq{vertical-align:-16%}
.tx-r{text-align:right;}
.t-green{
  color:@primary
}
.gz{
  border:solid 1px @primary;
  padding:2px 4px;
  font-size:10px;
  border-radius:10px;
  transform:scale(0.7, 0.7);
}
.gz.active{
  background:@primary;
  color:#fff;
}
.ycyc2{
  overflow: hidden;
  -overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.t-green{
  color:@primary
}
</style>
