<template>
  <div class="search-container">
    <c-title :hide="false"
      :text="`搜索`"
      tolink=""
      :totext="``">
    </c-title>
    <van-search v-model="value" placeholder="请输入搜索关键词" />
    <div class="hostory" v-if="is_history">
      <div class="tit">
        搜索历史
        <van-icon name="delete-o" class="fr sc"/>
      </div>
      <div class="his-list">
        <span>跑步机</span><span>钙镁锌</span><span>卡宾</span><span>书架</span><span>呼啦圈</span>
      </div>
    </div>
    <div class="sslist">
      <div class="list">
        <van-row>
          <van-col span="4">
            <img src="../assets/images/user.png" alt="" class="com-useravatar">
          </van-col>
          <van-col span="20">
            <div class="com-user">
              流浪吉它<span class="sf">(社区驻村干部)</span>
            </div>
            <div class="dat">
              09月22日 <span class="sf">来自广东省河源市连平县三角镇</span>
            </div>
            <div class="subtxt">
               今天是中秋节假后的第一个工作日，相信不少朋友都有把昨天没吃完的....
            </div>
          </van-col>
        </van-row>
      </div>
    </div>
  </div>
</template>

<script>
import cTitle from '../components/title.vue';
export default {
  data(){
    return{
      value:'',
      is_history:false
    };
  },
  components: {
    cTitle
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/styles/var.less";
.hostory{
  background:#fff;
  padding:10px;
  padding-top:0;
  .tit{
    color:@dark;
    font-size:14px;
  }
  .his-list{
    padding-top:15px;
    span{
      padding:4px 10px;
      background:#f5f5f5;
      border-radius:15px;
      margin:0px 10px 5px 0;
      font-size:14px;
      color:@words
    }
  }
}
.fr{float:right;}
.sc{

  font-size:19px;
}
.list{
  padding:10px 10px 6px;
  border-bottom:dashed 1px #ddd;
  background:#fff;
}
    .com-useravatar{
      width:44px;
      height:44px;
      border-radius:50%;
      display:block;
    }
    .com-user{
      line-height:24px;font-size:14px;color:#333;
      .sf{
        font-size:14px;
        color:@lightWords;
        margin-left:5px;
      }
    }
    .dat{padding-top:0px;padding-bottom:5px;font-size:14px;color:@lightWords;}
    .subtxt{line-height:20px;font-size:14px;color:@words;}
</style>
