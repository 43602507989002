<template>
  <div class="slide-container">
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item v-for="item in banner" :key="item.id">
        <img :src="item.imgurl" class="imgurl">
      </van-swipe-item>

    </van-swipe>
  </div>
</template>

<script>
export default {
  props:['banner'],
  created(){
    console.log(this.banner);
  }
};
</script>

<style lang="less" scoped>
.slide-container{
  background:#fff;
}
.my-swipe .van-swipe-item {
    img{width:100%;display:block;}
  }
  .imgurl{
    width:97%;margin:0px auto;height:160px;border-radius:10px;margin-bottom:5px;
  }
</style>
