<template>
  <div class="voluntaryactivities-container">
    <div class="content">
      <van-field
        v-model="act_name"
        name="act_name"
        label="活动名称"
        placeholder="请填写活动名称"
        :rules="[{ required: true, message: '活动名称不能为空' }]"
      />
      <van-field
        v-model="act_address"
        name="act_address"
        label="活动地址"
        placeholder="请填写活动地址"
        :rules="[{ required: true, message: '活动地址不能为空' }]"
      />
      <van-row class="dh">
        <van-col span="7">开始时间</van-col>
        <van-col span="17" class="tx-r" @click="showPopup_stime" >
          {{ stime ? stime : '请选择' }}
        </van-col>
      </van-row>
      <van-row class="dh">
        <van-col span="7">结束时间</van-col>
        <van-col span="17" class="tx-r" @click="showPopup_etime" >
          {{ etime ? etime : '请选择' }}
        </van-col>
      </van-row>
      <!-- <van-field
          readonly
          clickable
          label="选择部门"
          :value="department"
          placeholder="选择部门"
          @click="showPicker_department = true"
      /> -->
      <van-field
          v-model="telphone"
          name="telphone"
          label="*手机"
          placeholder="请输入您的手机号码"
          :rules="[{ required: true, message: '请填写您的手机号码' }]"
        />
      <van-field
        v-model="message"
        rows="5"
        autosize
        label=""
        type="textarea"
        mINlength="20"
        maxlength="300"
        placeholder="在此输入活动内容"
        show-word-limit
      />
      <div class="upload-img">
        <van-uploader v-model="fileList" multiple :after-read="afterRead"/>
      </div>



    </div>
     <div class="btnbox">
        <van-button round type="info" color="#ccc" class="mr10" @click="goback"> 取消 </van-button>
        <van-button round type="info" color="#00a752"  :disabled="is_click" @click="submit"> 发布 </van-button>
      </div>

    <van-popup v-model="show_stime" style="width:80%;">
        <van-datetime-picker
          v-model="stime"
          type="date"
          title="选择年月日"
          @confirm="onConfirm_stime"
          @cancel="onCancel_stime"
        />
    </van-popup>
    <van-popup v-model="show_etime" style="width:80%;">
        <van-datetime-picker
          v-model="etime"
          type="date"
          title="选择年月日"
          @confirm="onConfirm_etime"
          @cancel="onCancel_etime"
        />
    </van-popup>
    <!--选择部门-->
    <!-- <van-popup v-model="showPicker_department" round position="bottom">
      <van-picker
        show-toolbar
        :columns="department_columns"
        @confirm="onConfirm_department"
        @cancel="onCancel_department"
      />
    </van-popup> -->

  </div>

</template>

<script>
export default {
  data(){
    return{
      act_name:'',
      act_address:'',
      stime:'',//开始时间
      show_stime: false,//是否显示开始时间选择弹窗
      etime:'',//结束时间
      show_etime: false,//是否显示结束时间选择弹窗
      //department:'',//部门
      //showPicker_department:false,//初始化隐藏部门选对弹窗
      //department_columns: ['杭州', '宁波', '温州', '绍兴', '湖州', '嘉兴', '金华', '衢州'],//选择部门
      telphone:'',
      message:'',
      fileList: [],
      img_arr:'',
      is_click:false,
    };
  },
  methods:{
    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      var that = this;
      that.img_arr = [];
      if(that.fileList.length>0){
        for (var i=0;i<that.fileList.length;i++){
          var img_url = that.fileList[i].content;
          that.$axios
            .post(that.baseUrl+"/api/common/uploadBase64", {
              params:{
                base64:img_url
              }
            })
            .then(function(res) {//当请求完成时候执行then回调函数
              if(res.data.code == 1){
                that.img_arr += res.data.data.fullurl+',';
              }
            });
        }
      }
    },
    submit(){
      var that = this;
      that.is_click = true;

      var act_name = that.act_name;
      if(act_name == ''){
        that.$toast('请填写活动名称');
        that.is_click = false;
        return false;
      }
      var act_address = that.act_address;
      if(act_address == ''){
        that.$toast('请填写活动地址');
        that.is_click = false;
        return false;
      }
      var stime = that.stime;
      if(stime == ''){
        that.$toast('请选择开始时间');
        that.is_click = false;
        return false;
      }
      var etime = that.etime;
      if(etime == ''){
        that.$toast('请选择结束时间');
        that.is_click = false;
        return false;
      }
      var telphone = that.telphone;
      if(telphone == ''){
        that.$toast('请输入联系电话');
        that.is_click = false;
        return false;
      }
      var message = that.message;
      if(message == ''){
        that.$toast('请输入活动内容');
        that.is_click = false;
        return false;
      }

      var extend = [];
      extend[0] = {
        'name':'活动地址',
        'value':act_address
      };
      extend[1] = {
        'name':'开始时间',
        'value':stime
      };
      extend[2] = {
        'name':'结束时间',
        'value':etime
      };
      extend[3] = {
        'name':'联系电话',
        'value':telphone
      };
      var new_extend = JSON.stringify(extend);
      // console.log(new_extend);
      // console.log(typeof JSON.stringify(extend));

      that.$axios
        .get(that.baseUrl+"/api/news/add", {
          params:{
            site:window.localStorage.getItem('user_village_id'),
            cid:that.$route.params.id,
            huatiid:0,
            title:that.act_name,
            cover:that.img_arr,
            content:that.message,
            begin_date:stime,
            end_date:etime,
            extra:new_extend
          }
        })
        .then(function(res) {
          console.log(res);
          if(res.data.code == 1){

            that.$toast('发布成功');
            setTimeout(function(){
              that.$router.push("/myrelease");
            },2000);
          }else{
            that.is_click=false;
          }
        });
    },
    onConfirm_stime(value){
      this.show_stime = false;
      let d = value;
      this.stime = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate();
    },
    onCancel_stime(){
      this.show_stime = false;
    },
    showPopup_stime() {
      this.show_stime = true;
    },
    onConfirm_etime(value){
      this.show_etime = false;
      let d = value;
      this.etime = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate();
    },
    onCancel_etime(){
      this.show_etime = false;
    },
    showPopup_etime() {
      this.show_etime = true;
    },
    goback(){
      this.$router.go(-1);
    },
    //选择部门
    // onConfirm_department(value) {
    //   this.department = value;
    //   this.showPicker_department = false;
    // },
    // onCancel_department(){
    //   this.showPicker_department = false;
    // },
  }
};
</script>

<style lang="less" scoped>
.voluntaryactivities-container{
  height:100vh;
  .content{
    background:#fff;
  }
}
.dh{
  height:44px;
  line-height:44px;
  margin:0 15px;
  background:#fff;
  font-size:14px;
  color:#646566;
}
.upload-img{
    padding:15px;
    background:#fff;
  }
  .mr10{margin-right:10px;}
  .btnbox{
    padding:20px;
    text-align:center;
}
</style>
