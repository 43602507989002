<template>
  <div class="consultingservice-container">
      <div class="content">
        <van-field
          readonly
          clickable
          label="咨询类型"
          :value="source"
          placeholder="选择咨询类型"
          @click="showPicker_source = true"
        />
        <van-field
          v-model="message"
          rows="5"
          autosize
          label=""
          type="textarea"
          mINlength="20"
          maxlength="300"
          placeholder="在此输入咨询内容"
          show-word-limit
        />
        <div class="upload-img">
          <van-uploader :after-read="afterRead" />
        </div>
      </div>
      <div class="btnbox">
        <van-button round type="info" color="#ccc" class="mr10" @click="goback"> 取消 </van-button>
        <van-button round type="info" color="#00a752"> 发布 </van-button>
      </div>
       <!--咨询类型-->
    <van-popup v-model="showPicker_source" round position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        @confirm="onConfirm_source"
        @cancel="onCancel_source"
      />
    </van-popup>
  </div>
</template>

<script>
export default {
  data(){
    return{
      showPicker_source:false,//是否显示收入来源选择器
      source:'',
      columns: ['杭州', '宁波', '温州', '绍兴', '湖州', '嘉兴', '金华', '衢州'],
      message:'',

    };
  },
  methods:{
    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      console.log(file);
    },
    goback(){
      this.$router.go(-1);
    },
    //选择咨询类型
    onConfirm_source(value) {
      this.source = value;
      this.showPicker_source = false;
    },
    onCancel_source(){
      this.showPicker_source = false;
    },
  }
};
</script>

<style lang="less" scoped>
.upload-img{
    padding:15px;
    background:#fff;
  }
  .mr10{margin-right:10px;}
  .btnbox{
    padding:20px;
    text-align:center;
}
</style>
