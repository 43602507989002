<template>
  <div class="branchstyle-container">
    <c-title :hide="false"
      :text="`村委风采`"
      tolink=""
      :totext="``">
    </c-title>
    <v-structure :content_obj="content_obj" :cw_list_one="cw_list_one" :cw_list_two="cw_list_two" :is_show_top="true"></v-structure>
    <div class="dwgk">
      <van-row>
        <van-col span="23" offset="1" class="dwtitle">村委会概况</van-col>
      </van-row>
      <van-row>
        <van-col span="22" offset="1" class="dwcontent van-multi-ellipsis--l2">
          {{cwgk.content}}
        </van-col>
      </van-row>
      <van-row>
        <van-col span="24" class="tx-c dwckgd">
          查看更多 <van-icon name="down" class="icon-dq"/>
        </van-col>
      </van-row>
    </div>
    <div class="dwgk">
      <van-row>
        <van-col span="23" offset="1" class="dwtitle">村委会工作目标</van-col>
      </van-row>
      <van-row>
        <van-col span="22" offset="1" class="dwcontent van-multi-ellipsis--l2">
           {{gzmb.content}}
        </van-col>
      </van-row>
      <van-row>
        <van-col span="24" class="tx-c dwckgd">
          查看更多 <van-icon name="down" class="icon-dq"/>
        </van-col>
      </van-row>
    </div>
    <div class="dwgk">
      <van-row>
        <van-col span="23" offset="1" class="dwtitle">村委会大数据</van-col>
      </van-row>
      <van-row class="ptb10 dsj">
        <van-col span="6" class="tx-c">
          村委会成员
        </van-col>
        <van-col span="6" class="tx-c">
          8人
        </van-col>
        <van-col span="6" class="tx-c">
          两委日记
        </van-col>
        <van-col span="6" class="tx-c">
          15篇
        </van-col>
      </van-row>
      <van-row  class="ptb10 dsj">
        <van-col span="6" class="tx-c">
          村委通知
        </van-col>
        <van-col span="6" class="tx-c">
          4次
        </van-col>
        <van-col span="6" class="tx-c">
          财务公开
        </van-col>
        <van-col span="6" class="tx-c">
          1次
        </van-col>
      </van-row>
      <van-row>
        <van-col span="24" class="tx-c dwckgd">
          查看更多 <van-icon name="down" class="icon-dq"/>
        </van-col>
      </van-row>
    </div>
    <van-list
      v-model="loading"
      :finished="finished"
      :immediate-check="false"
      finished-text="没有更多了"
      @load="onLoad"
      :offset="10"
    >
    <home-dynamic
      v-for="(item,i) in newsList"
      :key="i"
      :news="item"
    ></home-dynamic>
    </van-list>

    <foot-bar></foot-bar>
  </div>
</template>

<script>
import cTitle from '../../components/title.vue';
import vStructure from '../../components/structure_v.vue';
import homeDynamic from '../../components/home/dynamic.vue';
import footBar from '../../components/foot.vue';

export default {
  data() {
    return {
      value: '',
      newsList:[],
      menuList:[],
      banner:[],
      page:1,
      limit:10,
      loading: false,
      total: 0,//总共的数据条数
      finished: false,
      villageInfo:'',
      content_obj:'',
      cwgk:'',//村委概况
      gzmb:'',//工作目标
      cw_list_one:[],
      cw_list_two:[]

    };
  },
  created(){
    this.getMenu();
    this.getroadList();
    this.getVillageInfo();
  },
  methods: {
    getVillageInfo(){
      var that = this;
      that.$axios
        .get(that.baseUrl+"/api/village/villageInfo", {
          params: {
            'site':3,
            'type1':'村委风采'
          }
        })
        .then(function(res) {
          //console.log(res.data);
          if(res.data.code == 1){
            that.villageInfo=res.data.data[0];
            that.content_obj = res.data.data[0].content;
            that.cwgk = res.data.data[0].extra[0];
            that.gzmb = res.data.data[0].extra[1];

            if(that.content_obj.children.length > 0){
              var arr = that.content_obj.children;
              that.cw_list_one = arr.slice(0,3);
              that.cw_list_two = arr.slice(3);
            }
          }else{
            console.log(res.data.msg);
          }
        });
    },
    getMenu(){
      var that = this;
      that.$axios
        .get(that.baseUrl+"/api/news/category", {
          params: {
            'pid':0,
            'type':'村务'
          }
        })
        .then(function(res) {
          //console.log(res);
          if(res.data.code == 1){
            that.menuList=res.data.data;
          }else{
            //console.log(res.data.msg);
          }
        });
    },
    onLoad() {
      this.page++;
      this.getroadList();

    },
    getroadList(){
      //console.log(this.page);
      let that = this;
      that.$axios
        .get(that.baseUrl+"/api/news/list", {
          params: {
            site:that.site,
            type:'村务',
            page:that.page,
            limit:that.limit
          }
        })
        .then(function(res) {
          if(res.data.code==1){
            that.loading = false;
            that.total = res.data.data.count;

            var list = res.data.data.list;
            if(list.length > 0){
              list.forEach(item => {
                if(item.extra){
                  var objet = JSON.parse(item.extra);
                  //console.log(objet);
                  var extra_item = [];
                  for (let key in objet) {
                    const obj = objet[key];
                    extra_item.push(obj);
                  }
                  item.new_extra = extra_item;

                }
              });
            }

            if (list == null || list.length === 0) {
              // 加载结束
              that.finished = true;
              return;
            }
            // 将新数据与老数据进行合并
            that.newsList = that.newsList.concat(list);

            //如果列表数据条数>=总条数，不再触发滚动加载
            if (that.newsList.length >= that.total) {
              that.finished = true;
            }

          }
        });
    },
    onSearch(val) {
      alert(val);
    },
    onCancel() {
      alert('取消');
    },
  },
  components: {
    cTitle,
    vStructure,
    homeDynamic,
    footBar,
  },
};
</script>

<style lang="less" scoped>
.branchstyle-container{
  padding-bottom:50px;
  height:100%;
}
.van-notice-bar{
  background:none;
  color:#333;
}
.dwgk{
  padding:10px;
  margin:10px 0 10px;
  background:#fff;
}
.dwtitle{
  margin-top:5px;
  margin-bottom:5px;
  font-size:15px;
  font-weight:bold;
  color:#ed1b23;
  padding-left:10px;
  border-left:solid 3px #ed1b23;
}
.dwcontent{
  margin-top:10px;
  margin-bottom:10px;
  color:#666;
  font-size:14px;
}
.dwckgd{
  color:#666;
  font-size:14px;
  padding-top:10px;
}
.ptb10{
  padding-top:10px;
  padding-bottom:10px;
}
.line30{
  line-height:30px;
}
.dsj{
  color:#666;
}
.icon-dq{
  vertical-align: -2px;
}
</style>
