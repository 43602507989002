<template>
  <div class="maillist-container">
    <c-title :hide="false"
      :text="`今日头条`"
      tolink=""
      :totext="``">
    </c-title>
    <van-list
      v-model="loading"
      :finished="finished"
      :immediate-check="false"
      finished-text="没有更多了"
      @load="onLoad"
      :offset="10"
    >
        <van-row class="item-list" @click="goDetail(1)">
            <van-col span="4">
              <img  src="../assets/images/xy3.jpg" class="fbimg"/>
            </van-col>
            <van-col span="20">
              <p class="tit">冬奥盛会推动全民健身运动蓬勃开展</p>
              <p class="dat">全民健身运动的普及是一个国家现代化程度的重要标志。党的十八大以来，以习近平同志为核心的党中央高度重视关心体育工作，将全民健身上升为国家战略。</p>
            </van-col>
        </van-row>
        <van-row class="item-list" @click="goDetail(1)">
            <van-col span="4">
              <img  src="../assets/images/xy4.jpg" class="fbimg"/>
            </van-col>
            <van-col span="20">
              <p class="tit">“放水养鱼” 助企纾困——看各地如何落实落细组合式税费支持政策</p>
              <p class="dat">6日一大早，山东金宇针织有限公司财务负责人韩世玲在电子税务局提交了留抵退税申请，仅用5分钟便完成整个退税申请流程，退税款当天就到了账。</p>
            </van-col>
        </van-row>
        <van-row class="item-list" @click="goDetail(1)">
            <van-col span="4">
              <img  src="../assets/images/xy5.jpg" class="fbimg"/>
            </van-col>
            <van-col span="20">
              <p class="tit">国家中医药局：世卫组织专家评估会报告充分肯定中医药抗疫贡献</p>
              <p class="dat">世界卫生组织在其官网有关栏目下最新发布的《世界卫生组织中医药救治新冠肺炎专家评估会报告》明确肯定了中医药救治新冠肺炎的安全性、有效性。对此，国家中医药管理局有关负责人表示，这一报告充分肯定了中医药抗击新冠肺炎疫情的贡献，体现了世卫组织对中医药等传统医学的高度重视，也表明未来中医药在抗击世纪疫情中仍将大有可为。</p>
            </van-col>
        </van-row>
    </van-list>

  </div>
</template>

<script>
import cTitle from '../components/title.vue';
export default {
  data(){
    return {
      list:[],
      page:1,
      limit:10,
      loading: false,
      total: 0,//总共的数据条数
      finished: false,
    };
  },
  created() {
    this.getroadList();
  },
  components: {
    cTitle
  },
  methods:{
    onLoad() {
      this.page++;
      this.getroadList();
    },
    getroadList(){
      let that = this;
      that.$axios
        .get(that.baseUrl+"/api/site/user", {
          params: {
            site:window.localStorage.getItem('site_id'),
            keyword:that.value,
            page:that.page,
            limit:that.limit
          }
        })
        .then(function(res) {
          console.log(res);
          if(res.data.code==1){
            that.loading = false;
            that.total = res.data.data.count;

            var list = res.data.data.list;

            if (list == null || list.length === 0) {
              // 加载结束
              that.finished = true;
              return;
            }
            // 将新数据与老数据进行合并
            that.list = that.list.concat(list);

            //如果列表数据条数>=总条数，不再触发滚动加载
            if (that.list.length >= that.total) {
              that.finished = true;
            }

          }
        });
    },
    goDetail(id){
      this.$router.push({name:'ArticleDetail',params:{id:id}});
    },
  }
};
</script>

<style lang="less" scoped>
@import "~@/assets/styles/var.less";
.item-list{
  margin-top:5px;
  padding:10px;
  background:#fff;
  .tit{font-size:14px;color:@dark;font-weight:bold;}
  .com{
    padding-top:5px;font-size:14px;color:@words;
  }
  .dat{
    padding-top:5px;font-size:14px;color:@lightWords;
    display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 3;overflow: hidden;
  }
}
.fbimg{
  width:40px;
  height:40px;
}
</style>
