<template>
  <div class="datatj-container">

    <div class="left">
      <div class="left-item">
        <p class="tjnum">{{tjData.active_count}}</p>
        <p class="tjtxt"><span class="icon1">活跃</span></p>
      </div>
      <div class="left-item">
        <p class="tjnum">{{tjData.user_count}}</p>
        <p class="tjtxt"><span class="icon2">村民数</span></p>
      </div>
      <div class="left-item">
        <p class="tjnum">{{tjData.party_count}}</p>
        <p class="tjtxt"><span class="icon3">党员数</span></p>
      </div>
      <div class="left-item">
        <p class="tjnum">{{tjData.village_count}}</p>
        <p class="tjtxt"><span class="icon4">村庄数</span></p>
      </div>
    </div>
    <div class="right" @click="gotj">
      <van-icon name="arrow" />
    </div>
  </div>
</template>

<script>
export default {
  props:['tjData'],
  methods:{
    gotj(){
      this.$router.push({name:'Statistics',params:{}});
    }
  }
};
</script>

<style lang="less" scoped>
.datatj-container{
  width:100%;height:80px;display:flex;justify-content: space-between;justify-items: center;
  background:#fff;
  margin-bottom:5px;
  .left{
    width:85%;
    display:flex;justify-content:space-around;
    .left-item{
      width:25%;text-align:center;
      .tjnum{color:#f90;font-size:18px;font-weight:bold;padding-top:20px;}
      .tjtxt{color:#666;font-size:14px;font-weight:bold;margin-top:4px;}
      .icon1{padding:15px 0 15px 20px;background:url(../../assets/images/home_tab_icon02.png) no-repeat;background-size:20px 20px;background-position:left center;}
      .icon2{padding:15px 0 15px 20px;background:url(../../assets/images/home_tab_icon03.png) no-repeat;background-size:20px 20px;background-position:left center;}
      .icon3{padding:15px 0 15px 20px;background:url(../../assets/images/home_tab_icon04.png) no-repeat;background-size:20px 20px;background-position:left center;}
      .icon4{padding:15px 0 15px 20px;background:url(../../assets/images/home_tab_icon01.png) no-repeat;background-size:20px 20px;background-position:left center;}
    }
  }
  .right{
    width:15%;
    display:flex;justify-content: center;align-items: center;
  }
}
</style>
