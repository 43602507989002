<template>
  <div>
    <c-title :hide="false" text="劳动力未采集数据" tolink="" :totext="``"> </c-title>
    <div class="searchbox">
      <van-search v-model="searchvalue" placeholder="请输入搜索关键词" />
    </div>
    <div class="text">新数据 新增总填报人数 0</div>
    <div class="empty">
      <img src="../../assets/images/0.png" class="empty_img">
      <span>暂无相关内容</span>
    </div>
    <div class="foot">
      <van-button type="primary" size="small" @click="fabu">填报劳动力采集</van-button>
    </div>
  </div>
</template>

<script>
import cTitle from "../../components/title.vue";
export default {
  components: {
    cTitle
  },
  methods:{
    fabu() {
      this.$router.push({
        name: "TownAddWorker",
        params: {

        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.view{
  background:#fff;
}
.empty{
  display:flex;justify-content: center;align-items: center;flex-direction: column;
}
.empty_img{
  width:80%;
}
.text{
  padding:15px;font-size:14px;color:#000;font-weight:bold;
}
.foot{
  width:100%;height:50px;background:#fff;position:fixed;left:0;bottom:0;
  display:flex;justify-content: center;align-items: center;
}
</style>
