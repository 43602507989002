<template>
  <div class="menu-container">
      <div class="menu-item"
        v-for="(item,index) in menuList"
        :key="index"
        @click="gotoList(item.id,item.style,item.title)"
      >
        <img :src="item.icon_image2" class="menu-icon"/>
        <p class="pt02">{{item.title}}</p>
      </div>
      <!-- <div class="menu-item" v-if="menuList.length =7" @click="goMore">
        <img src="../../assets/images/sy04.png" class="menu-icon"/>
        <p class="pt02">更多</p>
      </div> -->
  </div>
</template>

<script>

export default {
  props:['menu','type'],
  computed:{
    menuList:function(){
      return this.menu.slice(0,8);
    }
  },
  methods:{
    goMore(){
      this.$router.push({name:'SelectSite',params:{}});
    },
    gotoList(id,style,type1){
      var routername = '';
      if(style == 1){
        //普通帖子分类
        routername = "NewsList";
        this.$router.push(
          {
            name:routername,
            params:{
              type:this.type,
              type1:type1,
              id:id
            }
          });
        return false;
      }else if(style == 3){
        //微心愿类型
        routername = "MicroWishList";
      }else if(style == 5){
        //书记公开信
        routername = "PartyWork";
      }else if(style == 6){
        //书记信箱
        routername = "ShuJiEmail";
      }else if(style == 8){
        //志愿活动
        routername = "VoluntaryList";
      }else if(style == 9){
        //支部风采
        routername = "BranchStyle_p";
      }
      this.$router.push(
        {
          name:routername,
          params:{
            id:id
          }
        });
    }
  }
};
</script>

<style lang="less" scoped>
@import "~@/assets/styles/var.less";
.menu-container{
  margin:5px 0;
  background:#fff;
  padding:10px 0;

  .menu-item{
    width:25%;
    padding-top:5px;
    padding-bottom:10px;
    display:inline-block;
    text-align:center;
    .menu-icon{width:30px;height:30px;}
    p{
      font-size:14px;
    }
  }
}

.pt02{padding-top:4px;color:@words;}
</style>
