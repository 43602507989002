<template>
  <div class="stroll-container">
    <c-title :hide="false" text="选择" tolink="" :totext="``"> </c-title>
    <div class="tabbar">
      <div class="tab" :class="tabindex == 1 ? 'tab-act' : '' " @click="qhtab(1)">常去</div>
      <div class="tab" :class="tabindex == 2 ? 'tab-act' : '' " @click="qhtab(2)">我的乡镇</div>
      <div class="tab" :class="tabindex == 3 ? 'tab-act' : '' " @click="qhtab(3)">全国</div>
    </div>
    <div class="content" :class="tabindex == 1 ? 'block' : '' ">
      <div class="item" v-for="item in frequentVisits" :key="item.id" @click="changqu(item.type,item.id)">
        <div class="item-left">
          <img src="../assets/images/mlxc.png"  class="cover"/>
        </div>
        <div class="item-right">
          <div class="item-right-top">
            <span v-if="item.village == ''">{{ item.town }}</span>{{ item.village }}<span class="yjr" v-if="item.id==village_id">已加入</span>
          </div>
          <div class="item-right-foot ycyc">
            <van-icon name="location-o" class="dq"/>{{ item.province }},{{ item.city }},{{ item.area }}
          </div>
        </div>
      </div>
      <empty v-if="frequentVisits.length == 0"></empty>
    </div>
    <div class="content" :class="tabindex == 2 ? 'block' : '' ">
      <div class="item" v-for="item in townList" :key="item.id" @click="changqu(item.type,item.id)">
        <div class="item-left">
          <img src="../assets/images/mlxc.png"  class="cover"/>
        </div>
        <div class="item-right">
          <div class="item-right-top">
            {{ item.village }}<span class="yjr" v-if="item.id==village_id">已加入</span>
          </div>
          <div class="item-right-foot ycyc">
            <van-icon name="location-o" class="dq"/>{{ item.province }},{{ item.city }},{{ item.area }}
          </div>
        </div>
      </div>
      <empty v-if="townList.length == 0"></empty>
    </div>
    <div class="content" :class="tabindex == 3 ? 'block' : '' ">
      <van-steps :active="active" >
        <van-step>{{ stepsinfo[0].name ? stepsinfo[0].name :'省' }}</van-step>
        <van-step>{{ stepsinfo[1].name ? stepsinfo[1].name :'市' }}</van-step>
        <van-step>{{ stepsinfo[2].name ? stepsinfo[2].name :'县' }}</van-step>
        <van-step>{{ stepsinfo[3].name ? stepsinfo[3].name :'乡' }}</van-step>
        <van-step>村</van-step>
      </van-steps>
      <div class="cont_name">
        <div class="name_item" v-for="item in areaList" :key="item.value" @click="getArea(active+1,item.value,item.label)">{{ item.label }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import cTitle from "../components/title.vue";
import empty from "../components/empty.vue";

export default {
  data() {
    return {
      active: 0,
      tabindex:1,
      pid:'',
      areaList:[],
      townList:[],
      frequentVisits:[],
      village_id:window.localStorage.getItem('user_village_id') ? window.localStorage.getItem('user_village_id') : '',
      stepsinfo:[
        {name:'',pid:''},
        {name:'',pid:''},
        {name:'',pid:''},
        {name:'',pid:''},
        {name:'',pid:''},
      ],

    };
  },
  created(){
    this.getArea(this.active,'','');
    this.getTwon();
    this.getFrequentVisits();
  },
  methods:{
    //常去
    changqu(type,id){
      if(type == '镇'){
        this.$router.push({name:'TownHome',params:{site:id}});
      }else if(type == '村'){
        this.$router.push({name:'Home',params:{site:id}});
      }
    },
    clickstep(e){
      this.active = e;

    },
    qhtab(index){
      this.tabindex = index;
    },
    getArea(n,pid,label){
      // console.log(n);
      if(n>=5){
        this.active = 4;
        n=5;
      }else{
        this.active = n;
      }

      this.pid = pid;
      var nn = 0;
      if(n>0){
        nn=n-1;
        this.stepsinfo[nn].name = label;
        this.stepsinfo[nn].pid = pid;
      }
      if(n>=5){
        // this.$toast(label);
        this.$router.push({name:'Home',params:{site:pid}});
        return false;
      }

      let that = this;
      that.$axios
        .get(that.baseUrl+"/api/common/cnarea", {
          params: {
            pid:that.stepsinfo[nn].pid ? that.stepsinfo[nn].pid : '',
          }
        })
        .then(function(res) {
          // console.log(res);
          if(res.data.code == 1){
            that.areaList = res.data.data;

            // console.log(that.areaList);
          }
        });

    },
    getTwon(){
      var that = this;
      if (window.localStorage.getItem("user_town_id")) {
        that.$axios
          .get(that.baseUrl+"/api/town/townVillages", {
            params: {
              site_id: window.localStorage.getItem("user_town_id")
            }
          })
          .then(function(res) {
            // console.log(res);
            if(res.data.code == 1){
              that.townList = res.data.data.list;
            }

          });
      }

    },
    getFrequentVisits(){
      var that = this;
      if (window.localStorage.getItem("Authorization")) {
        that.$axios
          .get(that.baseUrl+"/api/user/siteVisit", {
            params: {}
          })
          .then(function(res) {
            console.log(res);
            if(res.data.code == 1){
              that.frequentVisits = res.data.data.list;
            }

          });
      }
    }
  },
  components: {
    cTitle,empty
  }
};
</script>

<style lang="less" scoped>
.stroll-container{
  background:#fff;
}
.item{
  padding:10px;display:flex;
}
.item-left{
  width:20%;
  .cover{
    width:60px;height:60px;border-radius:4px;
  }
}
.cont_name{
  padding:10px;display:flex;
  flex-direction:row;
  justify-content: flex-start;
  flex-wrap:wrap;
  align-items: flex-start;
}
.name_item{
  margin:10px 20px 10px 0;padding:10px 20px;font-size:14px;color:#333;background:#f8f8f8;
}
.item-right{
  width:80%;
  .item-right-top{
    height:30px;line-height:30px;font-size:16px;color:#222;
    .yjr{
      color:lightgreen;
      font-size:14px;padding:3px 5px;border:solid 1px lightgreen;border-radius:20px;margin-left:10px;
    }
  }
  .item-right-foot{
    padding-top:5px;font-size:14px;color:#888;
  }
}
.tabbar {
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .tab {
    width: 80px;
    height: 40px;
    background: #f8f8f8;
    color: #333;
    text-align: center;
    line-height: 40px;
    border-radius: 4px;
    font-size: 14px;
    margin-right: 15px;
  }
  .tab-act {
    color: lightgreen;
  }
}
.ycyc{
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
.dq{
  vertical-align: text-top;
}
.hide{
  display:none;
}
.content{
  display:none;
}
.block{
  display:block;
}
</style>
