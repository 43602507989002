<template>
  <div class="mygrid-container">
    <c-title :hide="false"
      :text="`我的网格`"
      tolink=""
      :totext="``">
    </c-title>
    <div class="avatar">
      <img src="../../assets/images/user.png" alt="">
      <p>昵称</p>
    </div>
    <div class="formbox">
      <van-form @submit="onSubmit">
        <van-field
          v-model="nickname"
          name="nickname"
          label="*昵称"
          placeholder="请输入您的昵称"
          :rules="[{ required: true, message: '请填写您的昵称' }]"
        />
        <van-field
          v-model="realname"
          name="realname"
          label="*姓名"
          placeholder="请输入您的姓名"
          :rules="[{ required: true, message: '请填写您的姓名' }]"
        />
        <van-row class="dh">
          <van-col span="8">*性别</van-col>
          <van-col span="16" class="tx-r">
            <van-radio-group v-model="radio_sex" direction="horizontal" class="mt12">
              <van-radio name="1">男</van-radio>
              <van-radio name="2">女</van-radio>
            </van-radio-group>
          </van-col>
        </van-row>

        <van-field
          v-model="telphone"
          name="telphone"
          label="*手机"
          placeholder="请输入您的手机号码"
          :rules="[{ required: true, message: '请填写您的手机号码' }]"
        />
        <van-field
          v-model="idcard"
          name="idcard"
          label="*身份证号"
          placeholder="请输入您的身份证号码"
          :rules="[{ required: true, message: '请填写您的身份证号码' }]"
        />

        <van-row class="dh">
          <van-col span="7">生日</van-col>
          <van-col span="17" class="tx-r" @click="showPopup_birthday" >
            {{ birthday ? birthday : '请选择' }}
          </van-col>
        </van-row>

        <van-field
          v-model="income"
          name="income"
          label="收入"
          placeholder="0.00"
        />

        <van-field
          readonly
          clickable
          label="收入来源"
          :value="source"
          placeholder="选择收入来源"
          @click="showPicker_source = true"
        />

        <van-field
          readonly
          clickable
          label="婚姻状况"
          :value="marriage"
          placeholder="选择婚姻状况"
          @click="showPicker_marriage = true"
        />

         <van-field
          readonly
          clickable
          label="宗教信仰"
          :value="religion"
          placeholder="选择宗教信仰"
          @click="showPicker_religion = true"
        />

        <van-field
          readonly
          clickable
          label="文化程度"
          :value="culture"
          placeholder="选择文化程度"
          @click="showPicker_culture = true"
        />
        <van-field
          v-model="nation"
          name="nation"
          label="民族"
          placeholder="请输入您的民族"
        />

        <van-row class="dh">
          <van-col span="8">身体状况</van-col>
          <van-col span="16" class="tx-r">
            <van-radio-group v-model="radio_body" direction="horizontal" class="mt12">
              <van-radio name="1">残疾</van-radio>
              <van-radio name="2">无残疾</van-radio>
            </van-radio-group>
          </van-col>
        </van-row>

        <van-field
          readonly
          clickable
          label="政治面貌"
          :value="politics"
          placeholder="选择政治面貌"
          @click="showPicker_politics = true"
        />

        <van-row class="dh">
          <van-col span="8">兵役情况</van-col>
          <van-col span="16" class="tx-r">
            <van-radio-group v-model="radio_military" direction="horizontal" class="mt12">
              <van-radio name="1">是</van-radio>
              <van-radio name="2">否</van-radio>
            </van-radio-group>
          </van-col>
        </van-row>

        <van-field
          readonly
          clickable
          label="职业身份"
          :value="occupation"
          placeholder="选择职业身份"
          @click="showPicker_occupation = true"
        />

         <van-field
          v-model="skill"
          name="skill"
          label="个人技能"
          placeholder="请填写个人技能"
        />

        <van-field
          readonly
          clickable
          label="户籍类型"
          :value="household"
          placeholder="选择户籍类型"
          @click="showPicker_household = true"
        />

         <van-row class="dh">
          <van-col span="8">房屋状况</van-col>
          <van-col span="16" class="tx-r">
            <van-radio-group v-model="radio_house" direction="horizontal" class="mt12">
              <van-radio name="1">自有</van-radio>
              <van-radio name="2">租住</van-radio>
            </van-radio-group>
          </van-col>
        </van-row>

         <van-row class="dh">
          <van-col span="8">外来人口</van-col>
          <van-col span="16" class="tx-r">
            <van-radio-group v-model="radio_foreign" direction="horizontal" class="mt12">
              <van-radio name="1">是</van-radio>
              <van-radio name="2">否</van-radio>
            </van-radio-group>
          </van-col>
        </van-row>


        <div style="margin: 16px;">
          <van-button round block type="info" native-type="submit">提交</van-button>
        </div>
      </van-form>
    </div>
    <!--出生年月-->
    <van-popup v-model="show_birthday" style="width:80%;">
      <van-datetime-picker
        v-model="birthday"
        type="date"
        title="选择年月日"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="onConfirm_birthday"
        @cancel="onCancel_birthday"
      />
    </van-popup>
    <!--收入来源-->
    <van-popup v-model="showPicker_source" round position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        @confirm="onConfirm_source"
        @cancel="onCancel_source"
      />
    </van-popup>
    <!--婚姻状况-->
    <van-popup v-model="showPicker_marriage" round position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        @confirm="onConfirm_marriage"
        @cancel="onCancel_marriage"
      />
    </van-popup>
    <!--宗教信仰-->
    <van-popup v-model="showPicker_religion" round position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        @confirm="onConfirm_religion"
        @cancel="onCancel_religion"
      />
    </van-popup>
    <!--文化程度-->
    <van-popup v-model="showPicker_culture" round position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        @confirm="onConfirm_culture"
        @cancel="onCancel_culture"
      />
    </van-popup>
    <!--政治面貌-->
    <van-popup v-model="showPicker_politics" round position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        @confirm="onConfirm_politics"
        @cancel="onCancel_politics"
      />
    </van-popup>
    <!--职业身份-->
    <van-popup v-model="showPicker_occupation" round position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        @confirm="onConfirm_occupation"
        @cancel="onCancel_occupation"
      />
    </van-popup>
    <!--户籍类型-->
    <van-popup v-model="showPicker_household" round position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        @confirm="onConfirm_household"
        @cancel="onCancel_household"
      />
    </van-popup>


  </div>
</template>

<script>
import cTitle from '../../components/title.vue';
export default {
  data() {
    return {
      nickname: '',
      realname: '',
      telphone:'',
      idcard:'',
      radio_sex: '1',
      minDate: new Date(1910, 0, 1),
      maxDate: new Date(2022, 12, 31),
      currentDate: new Date(2021, 0, 17),
      birthday:'',
      show_birthday: false,//是否显示生日选择弹窗
      income:'',
      showPicker_source:false,//是否显示收入来源选择器
      source:'',
      columns: ['杭州', '宁波', '温州', '绍兴', '湖州', '嘉兴', '金华', '衢州'],
      showPicker_marriage:false,//是否显示婚姻状况选择器
      marriage:'',
      showPicker_religion:false,//是否显示宗教信仰选择器
      religion:'',
      showPicker_culture:false,//是否显示文化程度选择器
      culture:'',
      nation:'',//民族
      radio_body:'2',
      showPicker_politics:false,//是否显示政治面貌选择器
      politics:'',
      radio_military:'2',
      showPicker_occupation:false,//是否显示职业身份选择器
      occupation:'',
      skill:'',//个人技能
      showPicker_household:false,//是否显示户籍类型选择器
      household:'',
      radio_house:'1',//房屋状况
      radio_foreign:'2',//外来人口


    };
  },
  methods: {
    onSubmit(values) {
      console.log('submit', values);
    },
    onConfirm_birthday(value){
      this.show_birthday = false;
      let d = value;
      this.birthday = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate();
    },
    onCancel_birthday(){
      this.show_birthday = false;
    },
    showPopup_birthday() {
      this.show_birthday = true;
    },
    showPopup_source(){
      this.show_ = true;
    },
    //选择收入来源
    onConfirm_source(value) {
      this.source = value;
      this.showPicker_source = false;
    },
    onCancel_source(){
      this.showPicker_source = false;
    },
    //选择婚姻状况
    onConfirm_marriage(value) {
      this.marriage = value;
      this.showPicker_marriage = false;
    },
    onCancel_marriage(){
      this.showPicker_marriage = false;
    },
    //选择宗教信仰
    onConfirm_religion(value) {
      this.religion = value;
      this.showPicker_religion = false;
    },
    onCancel_religion(){
      this.showPicker_religion = false;
    },
    //选择文化程度
    onConfirm_culture(value) {
      this.culture = value;
      this.showPicker_culture = false;
    },
    onCancel_culture(){
      this.showPicker_culture = false;
    },
    //选择政治面貌
    onConfirm_politics(value) {
      this.politics = value;
      this.showPicker_politics = false;
    },
    onCancel_politics(){
      this.showPicker_politics = false;
    },
    //选择职业身份
    onConfirm_occupation(value) {
      this.occupation = value;
      this.showPicker_occupation = false;
    },
    onCancel_occupation(){
      this.showPicker_occupation = false;
    },
    //选择户籍类型
    onConfirm_household(value) {
      this.household = value;
      this.showPicker_household = false;
    },
    onCancel_household(){
      this.showPicker_household = false;
    },

  },
  components: {
    cTitle
  },
};
</script>

<style lang="less" scoped>
.mygrid-container{
  .avatar{
    width:100%;
    height:160px;
    overflow:hidden;
    text-align:center;
    font-size:14px;
    line-height:30px;
    color:#646566;
    img{
      width:80px;
      height:80px;
      border:solid 2px #fff;
      border-radius:50%;
      margin-top:20px;
    }
  }
}
.formbox{
  background:#fff;
}
.dh{
  height:44px;
  line-height:44px;
  margin:0 15px;
  background:#fff;
  font-size:14px;
  color:#646566;
  border-bottom:solid 1px #ebedf0;
}
.mt12{margin-top:14px;justify-content: flex-end;}
</style>
