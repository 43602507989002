<template>
  <div class="baseform-container">
    <c-title :hide="false"
      :text="`参与话题`"
      tolink=""
      :totext="``">
    </c-title>
    <div class="formbox">
      <p class="top_tit">#{{ht_title}}#</p>

      <!--正文-->
      <van-field
        v-model="message"
        rows="5"
        autosize
        label=""
        type="textarea"
        maxlength="300"
        placeholder="请输入帖子正文"
        show-word-limit
      />
      <!--配图-->
      <van-row class="upimg">
        <van-col span="24">图片上传</van-col>
      </van-row>
      <van-row class="upimg">
        <van-col span="24">
          <van-uploader v-model="fileList" multiple :after-read="afterRead"/>
        </van-col>
      </van-row>
      <div class="tjht" @click="goList">
        <span class="top_tit"># 选择话题</span>
        <van-icon name="arrow" />
      </div>

    </div>
    <div class="btnbox">
      <van-button round type="info" color="#ccc" class="mr10" @click="goback"> 取消 </van-button>
      <van-button round type="info" color="#00a752" :disabled="is_click" @click="submit"> 发布 </van-button>
    </div>


    <foot-bar></foot-bar>
  </div>
</template>

<script>
import cTitle from '../../components/title.vue';
import footBar from '../../components/foot.vue';
export default {
  data() {
    return {
      message:'',
      img_arr:'',
      is_click: false,
      fileList: [],
      ht_title:''
    };
  },
  created(){
    this.getDetail();
  },
  methods: {

    getDetail(){
      let that = this;
      that.$axios
        .get(that.baseUrl+"/api/news/huatiDetail", {
          params: {
            id:that.$route.params.id,
          }
        })
        .then(function(res) {
          if(res.data.code==1){
            console.log(res);
            that.ht_title = res.data.data.news.title;
          }
        });
    },
    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      var that = this;
      that.img_arr = [];
      if(that.fileList.length>0){
        for (var i=0;i<that.fileList.length;i++){
          var img_url = that.fileList[i].content;
          that.$axios
            .post(that.baseUrl+"/api/common/uploadBase64", {
              params:{
                base64:img_url
              }
            })
            .then(function(res) {//当请求完成时候执行then回调函数

              if(res.data.code == 1){
                that.img_arr += res.data.data.fullurl+',';
              }
            });
        }
      }
    },
    submit(){
      var that = this;
      that.is_click = true;

      var message = that.message;
      if(message == ''){
        that.$toast('请填写帖子内容');
        that.is_click = false;
        return false;
      }

      that.$axios
        .get(that.baseUrl+"/api/news/add", {
          params: {
            site:window.localStorage.getItem('user_village_id'),
            cid:0,
            huatiid:that.$route.params.id,
            title:'#'+that.ht_title+'#',
            cover:that.img_arr,
            content:that.message,
          }
        })
        .then(function(res) {
          console.log(res);
          if(res.data.code == 1){

            that.$toast('发布成功');
            setTimeout(function(){
              that.$router.push("/myrelease");
            },2000);
          }else{
            that.is_click=false;
          }
        });

    },
    goList(){
      this.$router.push({name:'TopicList',params:{}});
    },
    goback(){
      this.$router.go(-1);
    }
  },
  components: {
    cTitle,
    footBar
  },
};
</script>

<style lang="less" scoped>

.baseform-container{
  min-height:100vh;
  padding-bottom:60px;
  .formbox{
    padding-bottom:20px;
    background:#fff;
    .upimg{
      padding:10px 20px;background:#fff;color:#646566;font-size:14px;
    }
    .subbox{
      padding:20px 20px;background:#fff;
      .subbtn{
        margin-top:20px;
      }
    }

  }

}
.tx-r{
  text-align:right;
}
.mr10{
  margin-right:10px;
}
.btnbox{
  padding:0 20px 40px;;
  text-align:center;
  background:#fff;
}
.top_tit{padding:15px;font-size:14px;color:#666;background:#fff;}
.tjht{
  margin-left:15px;margin-right:15px;
  border-top:solid 1px #eee;border-bottom:solid 1px #eee;
  display:flex;
  justify-content: space-between;align-items: center;
}
</style>
