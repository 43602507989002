<template>
  <div class="menu-container">
      <div tag="div" class="menu-item"
        v-for="item in menuList"
        :key="item.id"
        @click="gotoList(item.id,item.title,item.url)"
      >
        <img :src="item.icon_image" class="menu-icon"/>
        <p class="pt02 t-sbla f14 ycyc">{{item.title}}</p>
      </div>
  </div>
</template>

<script>
export default {
  props:['menuList','type'],
  created(){
    //console.log(this.menuList);
  },

  methods:{
    goMore(){
      this.$router.push({name:'Service',params:{}});
    },
    gotoList(id,title,url){
      console.log(id,title);
      if(url != ''){
        window.location.href = url;
      }else{
        var routername = '';
        if(title == '村委风采'){
          //村委风采
          routername = "BranchStyle_v";
        }else if(title == '支部风采'){
          //支部风采
          routername = "BranchStyle_p";
        }else if(title == '志愿活动'){
          //志愿活动
          routername = "VoluntaryList";
        }else if(title == '微心愿'){
          //微心愿
          routername = "MicroWishList";
        }else if(title == '书记公开信'){
          //书记公开信
          routername = "ShuJiEmail";
        }else if(title == '书记信箱'){
          //书记信箱
          routername = "ShuJiEmail";
        }else{
          //普通帖子分类
          routername = "NewsList";
          this.$router.push(
            {
              name:routername,
              params:{
                type:this.type,
                type1:title,
                id:id
              }
            });
          return false;
        }
      }


    }
  }
};
</script>

<style lang="less" scoped>
@import "~@/assets/styles/var.less";
.menu-container{
  margin:0 0 10px;
  background:#fff;
  padding:10px 0;

  .menu-item{
    width:25%;
    padding-top:10px;
    padding-bottom:15px;
    display:inline-block;
    text-align:center;
    .menu-icon{width:48px;height:48px;}
    p{

    }
  }
}

.pt02{padding-top:4px;color:@words;}
.t-sbla{color:#8e8e8e;}
.fb{font-weight:bold;}
.f14{
  font-size:16px;letter-spacing:1px;
}
.ycyc{
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
</style>
