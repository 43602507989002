<template>
  <div class="editcontainer">
    <van-tabs v-model="active">
      <van-tab title="镇情概况" name="cqgk">
        <div class="tit">镇情概况</div>
        <artedit :info.sync="cqgk_content"></artedit>

        <div class="tit">{{ cqgk_extra_title1 }}</div>
        <artedit :info.sync="cqgk_content1"></artedit>

        <div class="tit">{{ cqgk_extra_title2 }}</div>
        <artedit :info.sync="cqgk_content2"></artedit>

        <div class="tit">{{ cqgk_extra_title3 }}</div>
        <artedit :info.sync="cqgk_content3"></artedit>

        <div class="foot">
          <van-button type="primary" @click="sub_cqgk">提交</van-button>
        </div>
      </van-tab>
      <van-tab title="组织架构" name="cwfc">

        <div class="tit">{{ cwfc_extra_title1 }}</div>
        <artedit :info.sync="cwfc_content1"></artedit>

        <div class="tit">{{ cwfc_extra_title2 }}</div>
        <artedit :info.sync="cwfc_content2"></artedit>

        <div class="tit">{{ cwfc_extra_title3 }}</div>
        <artedit :info.sync="cwfc_content3"></artedit>

        <div class="foot">
          <van-button type="primary" @click="sub_cwfc">提交</van-button>
        </div>
      </van-tab>
      <van-tab title="镇容镇志" name="crcz">
        <div class="tit">镇容镇志</div>
        <artedit :info.sync="crcz_content"></artedit>

        <div class="foot">
          <van-button type="primary" @click="sub_crcz">提交</van-button>
        </div>
      </van-tab>
      <van-tab title="支部风采" name="zbfc">

        <div class="tit">{{ zbfc_extra_title1 }}</div>
        <artedit :info.sync="zbfc_content1"></artedit>

        <div class="tit">{{ zbfc_extra_title2 }}</div>
        <artedit :info.sync="zbfc_content2"></artedit>

        <div class="tit">{{ zbfc_extra_title3 }}</div>
        <artedit :info.sync="zbfc_content3"></artedit>

        <div class="foot">
          <van-button type="primary" @click="sub_zbfc">提交</van-button>
        </div>
      </van-tab>
    </van-tabs>
    <div style="height:60px;"></div>
  </div>
</template>
<script>
import artedit from "../../components/artedit.vue";
export default {
  data() {
    return {
      active: "cqgk",
      cqgk_content: "", //镇情概况
      cqgk_extra_title1: "",
      cqgk_extra_title2: "",
      cqgk_extra_title3: "",
      cqgk_content1: "",
      cqgk_content2: "",
      cqgk_content3: "",

      cwfc_extra_title1: "",
      cwfc_extra_title2: "",
      cwfc_extra_title3: "",
      cwfc_content1: "",
      cwfc_content2: "",
      cwfc_content3: "",

      crcz_content: "", //镇容镇志

      zbfc_extra_title1: "",
      zbfc_extra_title2: "",
      zbfc_extra_title3: "",
      zbfc_content1: "",
      zbfc_content2: "",
      zbfc_content3: "",
    };
  },
  created() {
    var typename = this.$route.params.type;
    if (typename) {
      this.active = typename;
    }
    this.site_id = window.localStorage.getItem("site_id");
    //获取内容
    this.initData_cqgk();
    this.initData_cwfc();
    this.initData_crcz();
    this.initData_zbfc();
  },
  methods: {
    initData_cqgk() {
      var that = this;
      that.$axios
        .get(that.baseUrl + "/api/village/villageInfo", {
          //地址是get请求地址
          params: {
            site: window.localStorage.getItem("admin_site_id"),
            type1: "村情概况"
          }
        })
        .then(function(res) {
          //当请求完成时候执行then回调函数
          console.log(res);
          if (res.data.code == 1) {
            if (res.data.data.length > 0) {
              that.cqgk_content = res.data.data[0].content;
              that.cqgk_extra_title1 = res.data.data[0].extra[0].title;
              that.cqgk_extra_title2 = res.data.data[0].extra[1].title;
              that.cqgk_extra_title3 = res.data.data[0].extra[2].title;
              that.cqgk_content1 = res.data.data[0].extra[0].content;
              that.cqgk_content2 = res.data.data[0].extra[1].content;
              that.cqgk_content3 = res.data.data[0].extra[2].content;
            }
          }
        });
    },
    initData_cwfc() {
      var that = this;
      that.$axios
        .get(that.baseUrl + "/api/village/villageInfo", {
          //地址是get请求地址
          params: {
            site: window.localStorage.getItem("admin_site_id"),
            type1: "村委风采"
          }
        })
        .then(function(res) {
          //当请求完成时候执行then回调函数
          console.log(res);
          if (res.data.code == 1) {
            if (res.data.data.length > 0) {
              that.cwfc_extra_title1 = res.data.data[0].extra[0].title;
              that.cwfc_extra_title2 = res.data.data[0].extra[1].title;
              that.cwfc_extra_title3 = res.data.data[0].extra[2].title;
              that.cwfc_content1 = res.data.data[0].extra[0].content;
              that.cwfc_content2 = res.data.data[0].extra[1].content;
              that.cwfc_content3 = res.data.data[0].extra[2].content;

            }
          }
        });
    },
    initData_crcz() {
      var that = this;
      that.$axios
        .get(that.baseUrl + "/api/town_manager/cunzhicunrong", {
          //地址是get请求地址
          params: {
            site: window.localStorage.getItem("admin_site_id"),
            type1: "村志村容"
          }
        })
        .then(function(res) {
          //当请求完成时候执行then回调函数
          // console.log(res);
          if (res.data.code == 1) {
            that.crcz_content = res.data.data.content;
            // console.log(that.crcz_content);
          }
        });
    },
    initData_zbfc() {
      var that = this;
      that.$axios
        .get(that.baseUrl + "/api/town_manager/zhibufengcai", {
          //地址是get请求地址
          params: {
            site: window.localStorage.getItem("admin_site_id"),
            type1: "支部风采"
          }
        })
        .then(function(res) {
          if(res.data.code == 1){
            console.log(res.data.data);
            //当请求完成时候执行then回调函数
            that.zbfc_extra_title1 = res.data.data.extra[0].title;
            that.zbfc_extra_title2 = res.data.data.extra[1].title;
            that.zbfc_extra_title3 = res.data.data.extra[2].title;
            that.zbfc_content1 = res.data.data.extra[0].content;
            that.zbfc_content2 = res.data.data.extra[1].content;
            that.zbfc_content3 = res.data.data.extra[2].content;
          }

        });
    },

    //提交镇情概况的内容
    sub_cqgk() {
      var that = this;
      that.$axios
        .post(that.baseUrl + "/api/town_manager/intro", {
          content: that.cqgk_content,
          content1: that.cqgk_content1,
          content2: that.cqgk_content2,
          content3: that.cqgk_content3
        })
        .then(function(res) {
          //当请求完成时候执行then回调函数
          if (res.data.code == 1) {
            // console.log(res);
            that.$toast(res.data.msg);
          }
        });
    },
    sub_cwfc() {
      var that = this;
      that.$axios
        .post(that.baseUrl + "/api/town_manager/cunweifengcai", {
          content1: that.cwfc_content1,
          content2: that.cwfc_content2,
          content3: that.cwfc_content3
        })
        .then(function(res) {
          //当请求完成时候执行then回调函数
          if (res.data.code == 1) {
            // console.log(res);
            that.$toast(res.data.msg);
          }
        });
    },
    sub_crcz() {
      var that = this;
      that.$axios
        .post(that.baseUrl + "/api/town_manager/cunzhicunrong", {
          content: that.crcz_content,
        })
        .then(function(res) {
          //当请求完成时候执行then回调函数
          if (res.data.code == 1) {
            // console.log(res);
            that.$toast(res.data.msg);
          }
        });
    },
    sub_zbfc() {
      var that = this;
      that.$axios
        .post(that.baseUrl + "/api/town_manager/zhibufengcai", {
          content1: that.zbfc_content1,
          content2: that.zbfc_content2,
          content3: that.zbfc_content3
        })
        .then(function(res) {
          //当请求完成时候执行then回调函数
          if (res.data.code == 1) {
            // console.log(res);
            that.$toast(res.data.msg);
          }
        });
    },
  },
  components: {
    artedit
  }
};
</script>
<style lang="less" scoped>
.tit {
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  color: #222;
}
.editcontainer {
  width: 100%;
}
.mr10 {
  margin-right: 20px;
}
.foot {
  width: 100%;
  height: 50px;
  background: #fff;
  position: fixed;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
