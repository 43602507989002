
import Home from '../views/Home.vue';//首页
import Service from '../views/Service.vue';//服务
import Release from '../views/Release.vue';//发布
import My from '../views/Member/My.vue';//我的
import PartyAffairs from '../views/Party/PartyAffairs.vue';//党务
import VillageAffairs from '../views/Village/VillageAffairs.vue';//村务
import BranchStyle_p from '../views/Party/BranchStyle.vue';//支部风采
import PartyWork from '../views/Party/PartyWork.vue';//党务公开
import MicroWishList from '../views/Party/MicroWish.vue';//微心愿
import BranchStyle_v from '../views/Village/BranchStyle.vue';//村委风采
import VillageWork from '../views/Village/VillageWork.vue';//村务公开
import PostsList from '../views/Posts/postsList.vue';//帖子列表页
import PostsDetail from '../views/Posts/postsDetail.vue';//帖子列表页
import PostsComment from '../views/Posts/comment.vue';//发布帖子评论
import TopicComment from '../views/Form/comment.vue';//参与话题评论
import Search from '../views/Search.vue';//搜索页
import MailList from '../views/MailList.vue';//搜索页
import MyFollow from '../views/Member/MyFollow.vue';//我的关注
import MyHeart from '../views/Member/MyHeart.vue';//我的心愿
import AddHeart from '../views/Member/AddHeart.vue';//发布心愿
import MyIntegral from '../views/Member/MyIntegral.vue';//发布心愿
import MyRelease from '../views/Member/MyRelease.vue';//我的发布
import Fabu from '../views/Fabu.vue';//总的发布入口
import MyMessage from '../views/Member/MyMessage.vue';//我的消息
import ReceiveWish from '../views/Member/ReceiveWish.vue';//领取微心愿
import VoluntaryList from '../views/Party/VoluntaryList.vue';//志愿活动
import ReceiveVoluntary from '../views/Party/ReceiveVoluntary.vue';//领取志愿活动

import MyGrid from '../views/Member/MyGrid.vue';//我的网格
import SignIn from '../views/Member/Signin.vue';//我的签到
import Login from '../views/Login.vue';//登录
import Register from '../views/Register.vue';//注册
import Resetpsd from '../views/Resetpsd.vue';//重置密码

import SecretaryMailbox from '../views/Form/SecretaryMailbox.vue';//发布书记公开信
import SupplyDemand from '../views/Form/SupplyDemand.vue';//发布供求信息
import VoluntaryActivities from '../views/Form/VoluntaryActivities.vue';//发起志愿活动
import MicroWish from '../views/Form/MicroWish.vue';//发布微心愿
import ConsultingService from '../views/Form/ConsultingService.vue';//我要咨询
import BaseForm from '../views/Form/BaseForm.vue';//发布普通帖子
import OpenLetter from '../views/Form/OpenLetter.vue';//发布书记信箱
import ShuJiEmail from '../views/Party/ShuJiEmail.vue';//书记信箱
import RichText from '../views/Form/RichText.vue';//富文本发帖页
import MyZixun from '../views/Member/MyZixun.vue';//我的咨询建议
import SetUp from '../views/Member/SetUp.vue';//设置
import ResetPsd from '../views/Member/ResetPsd.vue';//设置
import EditMobile from '../views/Member/EditMobile.vue';//设置
import UserInfo from '../views/Member/UserInfo.vue';//设置

import TopicList from '../views/TopicList.vue';//话题列表页
import TopicDetail from '../views/TopicDetail.vue';//话题详情页
import HomePage from '../views/Member/HomePage.vue';//个人主页

import VillageInfo from '../views/VillageInfo.vue';//村情概况
import TownInfo from '../views/TownInfo.vue';//镇情概况
import NewsList from '../views/NewsList.vue';//帖子分类二级列表页

import Loading from '../views/Loading.vue';//登录中

import TownHome from '../views/TownHome.vue';//镇首页
import CountyHome from '../views/CountyHome.vue';//县首页
import SelectSite from '../views/SelectSite.vue';//切换站点
import Statistics from '../views/Statistics.vue';//统计
import Default from '../views/404.vue';//404
import ArticleList from '../views/ArticleList.vue';//文章列表页
import ArticleDetail from '../views/ArticleDetail.vue';//文章详情页

import MyPlant from '../views/Member/MyPlant.vue';//我的种植
import AddPlant from '../views/Member/AddPlant.vue';//新增我的种植
import MyBreed from '../views/Member/MyBreed.vue';//我的养殖
import AddBreed from '../views/Member/AddBreed.vue';//新增我的养殖
import MySubsidy from '../views/Member/MySubsidy.vue';//我的补贴
import AddSubsidy from '../views/Member/AddSubsidy.vue';//新增我的补贴
import MyIndustry from '../views/Member/MyIndustry.vue';//我的产业
import AddIndustry from '../views/Member/AddIndustry.vue';//新增我的产业

import VillageChat from '../views/VillageChat.vue';//县首页的村聊
import Recruit from '../views/Recruit/Recruit.vue';//招聘
import RecruitDetail from '../views/Recruit/Detail.vue';//用工详情页
// import RecruitAgent from '../views/Recruit/Agent.vue';//经纪人注册页面
// import RecruitEmployer from '../views/Recruit/Employer.vue';//用人单位注册页面
import GetRich from '../views/GetRich/GetRich.vue';//致富经
import Authority from '../views/Authority/Authority.vue';//权力公开
import Share from '../views/Member/Share.vue';//分享页
import Stroll from '../views/Stroll.vue';//逛逛

//工作台
import WorkIndex from '../views/workspace/index.vue';//工作台首页
import WorkData from '../views/workspace/workData.vue';//工作台数据
import WorkTask from '../views/workspace/workTask.vue';//工作台配置
import WorkConfig from '../views/workspace/workConfig.vue';//工作台配置
import WorkArticleEdit from '../views/workspace/artEdit.vue';//工作台村情概况的编辑页
import WorkLog from '../views/workspace/worklog.vue';//工作日志
import WorkNotice from '../views/workspace/workNotice.vue';//工作日志列表
import WorkNoticeDetail from '../views/workspace/workNoticeDetail.vue';//工作日志详情
import WorkJob from '../views/workspace/workJob.vue';//就业通
import AddJob from '../views/workspace/addJob.vue';//发布招聘信息
import WorkGather from '../views/workspace/workGather.vue';//劳动力采集
import AddWorker from '../views/workspace/addWorker.vue';//劳动力采集表单
import AddNotice from '../views/workspace/addNotice.vue';//发布公告
import AddLog from '../views/workspace/addLog.vue';//发布工作日志
import AddPublicity from '../views/workspace/addPublicity.vue';//发布公示公开
import AddSecretaryMailbox from '../views/workspace/addSecretaryMailbox.vue';//发布书记信箱
import AddGuide from '../views/workspace/addGuide.vue';//发布办事指南
import WorkVillager from '../views/workspace/workvillager.vue';//返乡登记人员
import ManagingVillagers from '../views/workspace/managingVillagers.vue';//村民管理
import ManagingPartyMember from '../views/workspace/managingPartyMember.vue';//党员管理
import ManagingOrganizational from '../views/workspace/managingOrganizational.vue';//组织架构管理
import AddOrganizationalUser from '../views/workspace/addOrganizationalUser.vue';//新增组织架构人员


//镇工作台
import TownWorkIndex from '../views/workspace_town/index.vue';//工作台首页
import TownWorkData from '../views/workspace_town/workData.vue';//工作台数据
import TownWorkTask from '../views/workspace_town/workTask.vue';//工作台配置
import TownWorkConfig from '../views/workspace_town/workConfig.vue';//工作台配置
import TownWorkArticleEdit from '../views/workspace_town/artEdit.vue';//工作台村情概况的编辑页
import TownWorkLog from '../views/workspace_town/worklog.vue';//工作日志
import TownWorkNotice from '../views/workspace_town/workNotice.vue';//工作日志列表
import TownWorkNoticeDetail from '../views/workspace_town/workNoticeDetail.vue';//工作日志详情
import TownWorkJob from '../views/workspace_town/workJob.vue';//就业通
import TownAddJob from '../views/workspace_town/addJob.vue';//发布招聘信息
import TownWorkGather from '../views/workspace_town/workGather.vue';//劳动力采集
import TownAddWorker from '../views/workspace_town/addWorker.vue';//劳动力采集表单
import TownAddNotice from '../views/workspace_town/addNotice.vue';//发布公告
import TownAddLog from '../views/workspace_town/addLog.vue';//发布工作日志
import TownAddPublicity from '../views/workspace_town/addPublicity.vue';//发布公示公开
import TownAddSecretaryMailbox from '../views/workspace_town/addSecretaryMailbox.vue';//发布书记信箱
import TownAddGuide from '../views/workspace_town/addGuide.vue';//发布办事指南
import TownWorkVillager from '../views/workspace_town/workvillager.vue';//返乡登记人员
import TownManagingVillagers from '../views/workspace_town/managingVillagers.vue';//村民管理
import TownManagingPartyMember from '../views/workspace_town/managingPartyMember.vue';//党员管理
import TownManagingOrganizational from '../views/workspace_town/managingOrganizational.vue';//组织架构管理
import TownAddOrganizationalUser from '../views/workspace_town/addOrganizationalUser.vue';//新增组织架构人员

// function delay(duration) {
//   return new Promise((resolve) => {
//     setTimeout(() => {
//       resolve();
//     }, duration);
//   });
// }

// function getPageComponent(pageCompResolver) {
//   return async () => {
//     start();
//     if (process.env.NODE_ENV === "development") {
//       await delay(2000);
//     }
//     const comp = await pageCompResolver();
//     done();
//     return comp;
//   };
// }

export default [
  {//镇劳动力采集表单
    path: '/townworkspace/addworker',
    name: 'TownAddWorker',
    component: TownAddWorker,
    meta:{
      auth:true,
      is_admin:true
    }
  },
  {//镇发布招聘信息
    path: '/townworkspace/addjob',
    name: 'TownAddJob',
    component: TownAddJob,
    meta:{
      auth:true,
      is_admin:true
    }
  },
  {//镇发布办事指南
    path: '/townworkspace/addguide',
    name: 'TownAddGuide',
    component: TownAddGuide,
    meta:{
      auth:true,
      is_admin:true
    }
  },
  {//镇发布书记信箱
    path: '/townworkspace/addmailbox',
    name: 'TownAddSecretaryMailbox',
    component: TownAddSecretaryMailbox,
    meta:{
      auth:true,
      is_admin:true
    }
  },
  {//镇新增工作日志
    path: '/townworkspace/addlog',
    name: 'TownAddLog',
    component: TownAddLog,
    meta:{
      auth:true,
      is_admin:true
    }
  },
  {//镇新增组织架构人员
    path: '/townworkspace/addOrganizationalUser',
    name: 'TownAddOrganizationalUser',
    component: TownAddOrganizationalUser,
    meta:{
      auth:true,
      is_admin:true
    }
  },
  {//镇组织架构管理
    path: '/townworkspace/managingorganizational',
    name: 'TownManagingOrganizational',
    component: TownManagingOrganizational,
    meta:{
      auth:true,
      is_admin:true
    }
  },
  {//镇党员管理
    path: '/townworkspace/managingpartymember',
    name: 'TownManagingPartyMember',
    component: TownManagingPartyMember,
    meta:{
      auth:true,
      is_admin:true
    }
  },
  {//镇村民管理
    path: '/townworkspace/managingvillagers',
    name: 'TownManagingVillagers',
    component: TownManagingVillagers,
    meta:{
      auth:true,
      is_admin:true
    }
  },
  {//镇返乡登记人员
    path: '/townworkspace/villager',
    name: 'TownWorkVillager',
    component: TownWorkVillager,
    meta:{
      auth:true,
      is_admin:true
    }
  },
  {//镇发布公示公开
    path: '/townworkspace/addpublicity',
    name: 'TownAddPublicity',
    component: TownAddPublicity,
    meta:{
      auth:true
    }
  },
  {//镇发布公告
    path: '/townworkspace/addnotice',
    name: 'TownAddNotice',
    component: TownAddNotice,
    meta:{
      auth:true,
      is_admin:true
    }
  },
  {//镇劳动力采集
    path: '/townworkspace/workgather',
    name: 'TownWorkGather',
    component: TownWorkGather,
    meta:{
      auth:true,
      is_admin:true
    }
  },
  {//镇就业通
    path: '/townworkspace/workjob',
    name: 'TownWorkJob',
    component: TownWorkJob,
    meta:{
      auth:true,
      is_admin:true
    }
  },
  {//镇工作台配置
    path: '/townworkspace/workconfig',
    name: 'TownWorkConfig',
    component: TownWorkConfig,
    meta:{
      auth:true,
      is_admin:true
    }
  },
  {//工作台任务
    path: '/townworkspace/worktask',
    name: 'TownWorkTask',
    component: TownWorkTask,
    meta:{
      auth:true
    }
  },
  {//镇工作台数据
    path: '/townworkspace/workdata',
    name: 'TownWorkData',
    component: TownWorkData,
    meta:{
      auth:true,
      is_admin:true
    }
  },
  {//镇通知公告列表
    path: '/townworkspace/worknotice/list',
    name: 'TownWorkNotice',
    component: TownWorkNotice,
    meta:{
      auth:true,
      is_admin:true
    }
  },
  {//镇通知公告详情
    path: '/townworkspace/worknotice/detail/:id',
    name: 'TownWorkNoticeDetail',
    component: TownWorkNoticeDetail,
    meta:{
      auth:true,
      is_admin:true
    }
  },
  {//工作日志、工作指南
    path: '/townworkspace/worklog/:type',
    name: 'TownWorkLog',
    component: TownWorkLog,
    meta:{
      auth:true,
      is_admin:true
    }
  },
  {//镇工作台村情概况的编辑页
    path: '/townworkspace/artedit/:type',
    name: 'TownWorkArticleEdit',
    component: TownWorkArticleEdit,
    meta:{
      auth:true,
      is_admin:true
    }
  },
  {//镇工作台首页
    path: '/townworkspace/index',
    name: 'TownWorkIndex',
    component: TownWorkIndex,
    meta:{
      auth:true,
      is_admin:true
    }
  },





  {//劳动力采集表单
    path: '/workspace/addworker',
    name: 'AddWorker',
    component: AddWorker,
    meta:{
      auth:true,
      is_admin:true
    }
  },
  {//发布招聘信息
    path: '/workspace/addjob',
    name: 'AddJob',
    component: AddJob,
    meta:{
      auth:true,
      is_admin:true
    }
  },
  {//发布办事指南
    path: '/workspace/addguide',
    name: 'AddGuide',
    component: AddGuide,
    meta:{
      auth:true,
      is_admin:true
    }
  },
  {//发布书记信箱
    path: '/workspace/addmailbox',
    name: 'AddSecretaryMailbox',
    component: AddSecretaryMailbox,
    meta:{
      auth:true,
      is_admin:true
    }
  },
  {//新增工作日志
    path: '/workspace/addlog',
    name: 'AddLog',
    component: AddLog,
    meta:{
      auth:true,
      is_admin:true
    }
  },
  {//新增组织架构人员
    path: '/workspace/addOrganizationalUser',
    name: 'AddOrganizationalUser',
    component: AddOrganizationalUser,
    meta:{
      auth:true,
      is_admin:true
    }
  },
  {//组织架构管理
    path: '/workspace/managingorganizational',
    name: 'ManagingOrganizational',
    component: ManagingOrganizational,
    meta:{
      auth:true,
      is_admin:true
    }
  },
  {//党员管理
    path: '/workspace/managingpartymember',
    name: 'ManagingPartyMember',
    component: ManagingPartyMember,
    meta:{
      auth:true,
      is_admin:true
    }
  },
  {//村民管理
    path: '/workspace/managingvillagers',
    name: 'ManagingVillagers',
    component: ManagingVillagers,
    meta:{
      auth:true,
      is_admin:true
    }
  },
  {//返乡登记人员
    path: '/workspace/villager',
    name: 'WorkVillager',
    component: WorkVillager,
    meta:{
      auth:true,
      is_admin:true
    }
  },
  {//发布公示公开
    path: '/workspace/addpublicity',
    name: 'AddPublicity',
    component: AddPublicity,
    meta:{
      auth:true
    }
  },
  {//发布公告
    path: '/workspace/addnotice',
    name: 'AddNotice',
    component: AddNotice,
    meta:{
      auth:true,
      is_admin:true
    }
  },
  {//劳动力采集
    path: '/workspace/workgather',
    name: 'WorkGather',
    component: WorkGather,
    meta:{
      auth:true,
      is_admin:true
    }
  },
  {//就业通
    path: '/workspace/workjob',
    name: 'WorkJob',
    component: WorkJob,
    meta:{
      auth:true,
      is_admin:true
    }
  },
  {//工作台配置
    path: '/workspace/workconfig',
    name: 'WorkConfig',
    component: WorkConfig,
    meta:{
      auth:true,
      is_admin:true
    }
  },
  {//工作台任务
    path: '/workspace/worktask',
    name: 'WorkTask',
    component: WorkTask,
    meta:{
      auth:true
    }
  },
  {//工作台数据
    path: '/workspace/workdata',
    name: 'WorkData',
    component: WorkData,
    meta:{
      auth:true,
      is_admin:true
    }
  },
  {//通知公告列表
    path: '/workspace/worknotice/list',
    name: 'WorkNotice',
    component: WorkNotice,
    meta:{
      auth:true,
      is_admin:true
    }
  },
  {//通知公告详情
    path: '/workspace/worknotice/detail/:id',
    name: 'WorkNoticeDetail',
    component: WorkNoticeDetail,
    meta:{
      auth:true,
      is_admin:true
    }
  },
  {//工作日志、工作指南
    path: '/workspace/worklog/:type',
    name: 'WorkLog',
    component: WorkLog,
    meta:{
      auth:true,
      is_admin:true
    }
  },
  {//工作台村情概况的编辑页
    path: '/workspace/artedit/:type',
    name: 'WorkArticleEdit',
    component: WorkArticleEdit,
    meta:{
      auth:true,
      is_admin:true
    }
  },
  {//工作台首页
    path: '/workspace/index',
    name: 'WorkIndex',
    component: WorkIndex,
    meta:{
      auth:true,
      is_admin:true
    }
  },
  {//逛逛
    path: '/stroll',
    name: 'Stroll',
    component: Stroll
  },
  {//分享
    path: '/share/:site',
    name: 'Share',
    component: Share,
    meta:{
      auth:true
    }
  },
  {//权力公开
    path: '/authority/:id',
    name: 'Authority',
    component: Authority,
    meta:{
      site:true
    }
  },
  {//招聘
    path: '/recruit',
    name: 'Recruit',
    component: Recruit,
    meta:{
      site:true
    }
  },
  {//招聘用工详情页
    path: '/recruit/detail/:id',
    name: 'RecruitDetail',
    component: RecruitDetail,
    meta:{
      site:true
    }
  },
  // {//经纪人注册页面
  //   path: '/recruit/agent',
  //   name: 'RecruitAgent',
  //   component: RecruitAgent,
  //   meta:{
  //     site:true
  //   }
  // },
  // {//用人单位注册页面
  //   path: '/recruit/employer',
  //   name: 'RecruitEmployer',
  //   component: RecruitEmployer,
  //   meta:{
  //     site:true
  //   }
  // },
  {//村聊
    path: '/villagechat',
    name: 'VillageChat',
    component: VillageChat,
    meta:{
      site:true
    }
  },
  {//我的种植
    path: '/myplant',
    name: 'MyPlant',
    component: MyPlant,
    meta:{
      auth:true
    }
  },
  {//新增我的种植
    path: '/addplant',
    name: 'AddPlant',
    component: AddPlant,
    meta:{
      auth:true
    }
  },
  {//我的养殖
    path: '/mybreed',
    name: 'MyBreed',
    component: MyBreed,
    meta:{
      auth:true
    }
  },
  {//新增我的养殖
    path: '/addbreed',
    name: 'AddBreed',
    component: AddBreed,
    meta:{
      auth:true
    }
  },
  {//我的补贴
    path: '/mysubsidy',
    name: 'MySubsidy',
    component: MySubsidy,
    meta:{
      auth:true
    }
  },
  {//新增我的补贴
    path: '/addsubsidy',
    name: 'AddSubsidy',
    component: AddSubsidy,
    meta:{
      auth:true
    }
  },
  {//我的产业
    path: '/myindustry',
    name: 'MyIndustry',
    component: MyIndustry,
    meta:{
      auth:true
    }
  },
  {//新增我的产业
    path: '/addindustry',
    name: 'AddIndustry',
    component: AddIndustry,
    meta:{
      auth:true
    }
  },
  {//文章列表页
    path: '/ArticleList/:site',
    name: 'ArticleList',
    component: ArticleList,
    meta:{
      site:true
    }
  },
  {//文章详情页
    path: '/ArticleDetail/:id',
    name: 'ArticleDetail',
    component: ArticleDetail
  },
  {//404
    path: '/404',
    name: 'Default',
    component: Default
  },
  {//数据统计
    path: '/statistics',
    name: 'Statistics',
    component: Statistics,
    meta:{
      site:true
    }
  },
  {//切换站点
    path: '/selectsite',
    name: 'SelectSite',
    component: SelectSite
  },
  {//村情概况
    path: '/villageinfo/:site',
    name: 'VillageInfo',
    component: VillageInfo
  },
  {//镇情概况
    path: '/towninfo/:site',
    name: 'TownInfo',
    component: TownInfo
  },
  {//登录中
    path: '/loading',
    name: 'Loading',
    component: Loading
  },
  {//村首页
    path: '/home/:site?',
    name: 'Home',
    component: Home
  },
  {//镇首页
    path: '/townhome/:site?/:type?',
    name: 'TownHome',
    component: TownHome
  },
  {//县首页
    path: '/countyhome/:site?/:type?',
    name: 'CountyHome',
    component: CountyHome
  },
  {//服务
    path: '/service',
    name: 'Service',
    component: Service,
    meta:{
      site:true
    }
  },
  {//发布
    path: '/release',
    name: 'Release',
    component: Release,
    meta:{
      auth:true
    }
  },
  {//发布入口
    path: '/fabu',
    name: 'Fabu',
    component: Fabu,
    meta:{
      auth:true,
      shenhe:true
    }
  },
  {//我的
    path: '/my',
    name: 'My',
    component: My,
    meta:{
      auth:true
    }
  },
  {//党务
    path: '/party-affairs/:id',
    name: 'PartyAffairs',
    component: PartyAffairs,
    meta:{
      site:true
    }
  },
  {//村务
    path: '/village-affairs/:id',
    name: 'VillageAffairs',
    component: VillageAffairs,
    meta:{
      site:true
    }
  },
  {//党务－支部风采
    path: '/party/branchstyle/:id',
    name: 'BranchStyle_p',
    component: BranchStyle_p,
    meta:{
      site:true
    }
  },{//党务－书记信箱
    path: '/party/sjemail/:id',
    name: 'ShuJiEmail',
    component: ShuJiEmail,
    meta:{
      site:true
    }
  },
  {//党务－党务公开
    path: '/party/partywork/:id',
    name: 'PartyWork',
    component: PartyWork,
    meta:{
      site:true
    }
  },
  {//分类列表页
    path: '/newslist/:id',
    name: 'NewsList',
    component: NewsList,
    meta:{
      site:true
    }
  },
  {//党务－微心愿
    path: '/party/microwish/:id',
    name: 'MicroWishList',
    component: MicroWishList,
    meta:{
      site:true
    }
  },
  {//党务－领取微心愿
    path: '/receivewish/:id',
    name: 'ReceiveWish',
    component: ReceiveWish,
    meta:{
      site:true
    }
  },
  {//村务－村委风采
    path: '/village/branchstyle',
    name: 'BranchStyle_v',
    component: BranchStyle_v,
    meta:{
      site:true
    }
  },
  {//村务－村务公开
    path: '/village/villagework',
    name: 'VillageWork',
    component: VillageWork,
    meta:{
      site:true
    }
  },
  {//帖子列表页
    path: '/posts/list/:id',
    name: 'PostsList',
    component: PostsList,
    meta:{
      site:true
    }
  },
  {//帖子详情页
    path: '/posts/detail/:id',
    name: 'PostsDetail',
    component: PostsDetail,
    meta:{
      site:true
    }
  },
  {//发布帖子评论
    path: '/posts/comment/:id',
    name: 'PostsComment',
    component: PostsComment,
    meta:{
      auth:true
    }
  },
  {//发布帖子评论
    path: '/topic/comment/:id',
    name: 'TopicComment',
    component: TopicComment,
    meta:{
      auth:true
    }
  },
  {//搜索页
    path: '/search',
    name: 'Search',
    component: Search,
    meta:{
      site:true
    }
  },
  {//村民通讯录
    path: '/maillist',
    name: 'MailList',
    component: MailList,
    meta:{
      site:true
    }
  },
  {//我的关注
    path: '/myfollow',
    name: 'MyFollow',
    component: MyFollow,
    meta:{
      auth:true
    }
  },
  {//我的心愿
    path: '/myheart',
    name: 'MyHeart',
    component: MyHeart,
    meta:{
      auth:true
    }
  },
  {//发布心愿
    path: '/addheart',
    name: 'AddHeart',
    component: AddHeart,
    meta:{
      auth:true
    }
  },
  {//我的积分
    path: '/myintegral',
    name: 'MyIntegral',
    component: MyIntegral,
    meta:{
      auth:true
    }
  },
  {//我的－网格MyRelease
    path: '/my/grid',
    name: 'MyGrid',
    component: MyGrid,
    meta:{
      auth:true
    }
  },
  {//我的－设置
    path: '/my/setup',
    name: 'SetUp',
    component: SetUp,
    meta:{
      auth:true
    }
  },
  {//我的－修改个人信息
    path: '/my/userinfo',
    name: 'UserInfo',
    component: UserInfo,
    meta:{
      auth:true
    }
  },
  {//我的－修改手机
    path: '/my/editmobile',
    name: 'EditMobile',
    component: EditMobile,
    meta:{
      auth:true
    }
  },
  {//我的－重置密码
    path: '/my/resetpsd',
    name: 'ResetPsd',
    component: ResetPsd
  },
  {//我的－发布
    path: '/myrelease',
    name: 'MyRelease',
    component: MyRelease,
    meta:{
      auth:true
    }
  },
  {//我的－个人主页
    path: '/homepage/:id',
    name: 'HomePage',
    component: HomePage
  },
  {//登录
    path: '/login',
    name: 'Login',
    component: Login
  },
  {//注册
    path: '/register',
    name: 'Register',
    component: Register
  },
  {//重置密码
    path: '/resetpsd',
    name: 'Resetpsd',
    component: Resetpsd
  },
  {//签到
    path: '/signin',
    name: 'SignIn',
    component: SignIn,
    meta:{
      auth:true
    }
  },
  {//发布书记信箱
    path: '/fabu/secretarymailbox/:id',
    name: 'SecretaryMailbox',
    component: SecretaryMailbox,
    meta:{
      auth:true,
      shenhe:true
    }
  },
  {//发布供求信息
    path: '/fabu/supplydemand/:id',
    name: 'SupplyDemand',
    component: SupplyDemand,
    meta:{
      auth:true
    }
  },
  {//发起志愿活动
    path: '/fabu/voluntaryactivities/:id',
    name: 'VoluntaryActivities',
    component: VoluntaryActivities,
    meta:{
      auth:true
    }
  },
  {//发布微心愿
    path: '/fabu/microwish/:id',
    name: 'MicroWish',
    component: MicroWish,
    meta:{
      auth:true
    }
  },
  {//我要咨询
    path: '/fabu/consultingservice/:id',
    name: 'ConsultingService',
    component: ConsultingService,
    meta:{
      auth:true
    }
  },
  {//我的咨询建议
    path: '/member/myzixun',
    name: 'MyZixun',
    component: MyZixun,
    meta:{
      auth:true
    }
  },
  {//发布普通帖子
    path: '/fabu/baseform/:id',
    name: 'BaseForm',
    component: BaseForm,
    meta:{
      auth:true,
      shenhe:true
    }
  },
  {//发布书记公开信
    path: '/fabu/openletter/:id',
    name: 'OpenLetter',
    component: OpenLetter,
    meta:{
      auth:true
    }
  },
  {//发布富文本发帖页
    path: '/fabu/richtext/:id',
    name: 'RichText',
    component: RichText,
    meta:{
      auth:true
    }
  },
  {//我的消息
    path: '/mymessage',
    name: 'MyMessage',
    component: MyMessage,
    meta:{
      auth:true
    }
  },
  {//志愿活动
    path: '/voluntarylist/:id',
    name: 'VoluntaryList',
    component: VoluntaryList,
    meta:{
      site:true
    }
  },
  {//领取志愿活动
    path: '/receivevoluntary/:id',
    name: 'ReceiveVoluntary',
    component: ReceiveVoluntary,
    meta:{
      auth:true
    }
  },
  {//话题列表页
    path: '/topiclist',
    name: 'TopicList',
    component: TopicList,
    meta:{
      title:'热门话题',
      site:true
    }
  },
  {//话题详情页
    path: '/topicdetail/:id',
    name: 'TopicDetail',
    component: TopicDetail
  },
  {//致富经
    path: '/getrich/:id',
    name: 'GetRich',
    component: GetRich,
    meta:{
      site:true
    }
  },
  {
    path: '*',
    redirect: '/home/:site?'
  }
];





