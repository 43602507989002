<template>
  <div class="myzixun-container">
    <c-title :hide="false"
      :text="`咨询建议`"
      tolink="/fabu/consultingservice"
      :totext="`我要咨询`">
    </c-title>
    <van-row>
      <van-col span="8" class="tx-c p10">
        <van-button type="primary" size="small" block>全部</van-button>
      </van-col>
      <van-col span="8" class="tx-c p10">
        <van-button type="default" size="small" block>已处理</van-button>
      </van-col>
      <van-col span="8" class="tx-c p10">
        <van-button type="default" size="small" block>未处理</van-button>
      </van-col>
    </van-row>

    <div class="content">
      <div class="item">
        <van-row class="top">
          <van-col span="12" class="riqi">
            日期：2021-10-04 10:44
          </van-col>
          <van-col span="12" class="tx-r">
            <van-button type="warning" size="small">未处理</van-button>
            <van-icon name="delete-o" size="20" class="icon-dq"/>
          </van-col>
        </van-row>
        <van-row class="middle">
          这里是咨询建议的具体内容
        </van-row>
      </div>
    </div>

  </div>
</template>

<script>
import cTitle from '../../components/title.vue';
export default {
  components:{
    cTitle
  }
};
</script>

<style lang="less">
@import "~@/assets/styles/var.less";
.myzixun-container{
  .p10{
    padding:10px;
  }
  .content{
    padding:10px;
    .item{
      padding:10px;
      background:#fff;
      border-radius:4px;
      .top{
        padding-bottom:5px;
        border-bottom:solid 1px #f5f5f5;
        .riqi{
          height:36px;
          line-height:36px;
          font-size:14px;
          color:@lightWords;
        }
      }
      .middle{
        padding-top:10px;
        padding-bottom:10px;
        color:@words;
        font-size:14px;
      }
    }
  }
}

.tx-c{text-align:center;}
.tx-r{text-align:right;}
.icon-dq{
  vertical-align: -9px;
  margin-left:5px;
}
</style>
