<template>
  <div class="footbox">
    <div class="foot-item">
      <div class="fanhui">
        <van-icon name="share" size="20" class="fh" color="#f60" />
        <div>返回</div>
      </div>
    </div>
    <div class="foot-item" @click="goto('WorkIndex')">
      <img v-if="active == 'WorkIndex'" src="../assets/images/gzt/footbg11.png" class="icon" />
      <img v-else src="../assets/images/gzt/footbg1.png" class="icon" />
      <div class="foot-text " :class="active == 'WorkIndex' ? 't-org' : ''">常用</div>
    </div>
    <div class="foot-item" @click="goto('WorkData')">
      <img v-if="active == 'WorkData'" src="../assets/images/gzt/footbg22.png" class="icon" />
      <img v-else src="../assets/images/gzt/footbg2.png" class="icon" />
      <div class="foot-text" :class="active == 'WorkData' ? 't-org' : ''">数据</div>
    </div>
    <div class="foot-item" @click="goto('WorkTask')">
      <img v-if="active == 'WorkTask'" src="../assets/images/gzt/footbg33.png" class="icon" />
      <img v-else src="../assets/images/gzt/footbg3.png" class="icon" />
      <div class="foot-text" :class="active == 'WorkTask' ? 't-org' : ''">任务</div>
    </div>
    <div class="foot-item" @click="goto('WorkConfig')">
      <img v-if="active == 'WorkConfig'" src="../assets/images/gzt/footbg44.png" class="icon" />
      <img v-else src="../assets/images/gzt/footbg4.png" class="icon" />
      <div class="foot-text" :class="active == 'WorkConfig' ? 't-org' : ''">配置</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: ""
    };
  },
  created() {
    this.active = this.$route.params.type;
  },
  methods: {
    goto(name) {
      this.$router.push({
        name: name,
        params: {
          type: name
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.footbox {
  width: 100%;
  height: 50px;
  background: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  border-top:solid 1px #f8f8f8;
  .foot-item {
    width: 20%;
    height: 100%;
    text-align: center;
    position: relative;
    .fanhui {
      width: 50px;
      height: 50px;
      background: #fff;
      border-radius: 50%;
      box-shadow: 0 1px 4px 2px rgba(0, 0, 0, 0.1);
      position: absolute;
      top: -15px;
      left: 50%;
      margin-left: -15px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .fh {
        transform: RotateY(-180deg);
      }
    }
    .icon {
      width: 20px;
      height: 20px;
      margin-top: 7px;
    }
    .foot-text {
      font-size: 14px;
      color: #000;
    }
  }
}
.t-org {
  color: #f60 !important;
}
</style>
