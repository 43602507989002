<template>
  <div>
    <c-title :hide="false" text="劳动力采集表" tolink="" :totext="``"> </c-title>
    <div class="tit">
      <van-cell-group inset>
        <van-field v-model="username" label="*姓名" placeholder="请输入姓名" />
      </van-cell-group>
      <div class="m15 flex p15">
        <div class="left">*性别</div>
        <div class="right">
          <van-radio-group v-model="radio_sex" direction="horizontal">
            <van-radio name="1">男</van-radio>
            <van-radio name="2">女</van-radio>
          </van-radio-group>
        </div>
      </div>
      <van-cell-group inset>
        <van-field v-model="userage" label="*年龄" placeholder="请输入年龄" />
      </van-cell-group>
      <van-cell-group inset>
        <van-field v-model="mobile" label="电话" placeholder="请输入联系电话" />
      </van-cell-group>
      <van-cell-group inset>
        <van-field v-model="wechat" label="微信号" placeholder="请输入微信号" />
      </van-cell-group>
      <van-cell-group inset>
        <van-field v-model="address" label="常住地址" placeholder="请输入常住地址" />
      </van-cell-group>
      <van-cell-group inset>
        <van-field v-model="job" label="求职意向" placeholder="请输入求职意向" />
      </van-cell-group>
      <van-cell-group inset>
        <van-field v-model="jobstatus" label="求职状态" placeholder="例：离职" />
      </van-cell-group>
      <van-cell-group inset>
        <van-field v-model="wages" label="期待薪资" placeholder="例：4000-6000元/月" />
      </van-cell-group>
      <van-cell-group inset>
        <van-field v-model="education" label="学历信息" placeholder="例：大专" />
      </van-cell-group>
    </div>
    <div class="cont">
      <van-field name="fileList" label="就业技能">
        <template #input>
          <van-uploader v-model="fileList" multiple :after-read="afterRead" />
        </template>
      </van-field>

    </div>

    <div class="foot">
      <van-button type="primary" :disabled="is_click" block @click="fabu">立即发布</van-button>
    </div>
  </div>
</template>

<script>
import cTitle from "../../components/title.vue";
export default {
  data() {
    return {
      username:'',
      radio_sex:'1',
      userage:'',
      mobile: "",
      wechat: "",
      address: "",
      job:'',
      jobstatus:'',
      wages:'',
      education:''
    };
  },
  created() {

  },
  methods: {

    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      var that = this;
      that.img_arr = [];
      if (that.fileList.length > 0) {
        for (var i = 0; i < that.fileList.length; i++) {
          var img_url = that.fileList[i].content;
          that.$axios
            .post(that.baseUrl + "/api/common/uploadBase64", {
              params: {
                base64: img_url
              }
            })
            .then(function(res) {
              //当请求完成时候执行then回调函数

              if (res.data.code == 1) {
                that.img_arr += res.data.data.fullurl + ",";
              }
            });
        }
      }
    },
    fabu() {
      var that = this;
      that.is_click = true;
      var bbstitle = that.title;
      if (bbstitle == "") {
        that.$toast("请填写标题");
        that.is_click = false;
        return false;
      }
      var message = that.message;
      if (message == "") {
        that.$toast("请填写内容");
        that.is_click = false;
        return false;
      }
      var newjson = {
        enterprisename: that.enterprisename,
        wages: that.wages,
        contacts: that.contacts,
        mobile: that.mobile,
        address: that.address
      };
      // if (that.value == "") {
      //   that.$toast("请选择分类");
      //   that.is_click = false;
      //   return false;
      // }

      that.$axios
        .post(that.baseUrl + "/api/village_manager/editJob", {
          id: "",
          cid: that.cid,
          title: bbstitle,
          cover: that.img_arr,
          content: message,
          begin_date: that.startdate,
          end_date: that.enddate,
          extra: newjson
        })
        .then(function(res) {
          console.log(res);
          if (res.data.code == 1) {
            that.$toast("发布成功");
          } else {
            that.is_click = false;
          }
        });
    }
  },
  components: {
    cTitle
  }
};
</script>

<style lang="less" scoped>
.view {
  background: #fff;
}
.cont {
  padding: 15px;
}
.mr10 {
  margin-right: 10px;
}
.fxk {
  padding: 15px;
  .tzdx {
    margin-bottom: 10px;
    font-size: 14px;
    color: #666;
  }
}
.foot {
  padding: 15px;
}
.m15{
  margin:0 15px;
}
.p15{
  padding:10px 15px;
}
.flex{
  display:flex;font-size:14px;color:#646566;
}
.left{
  width:90px;margin-right:10px;
}
</style>
