<template>
  <div class="notic-container">
     <van-notice-bar left-icon="volume-o" :scrollable="false">
      <van-swipe
        vertical
        class="notice-swipe"
        :autoplay="3000"
        :show-indicators="false"
      >
        <van-swipe-item
          v-for="(item,i) in dongtai"
          :key="i"
          @click="goDetail(item.id)"
        >{{item.title}}</van-swipe-item>
      </van-swipe>
    </van-notice-bar>
  </div>
</template>

<script>
export default {
  props:['dongtai'],
  methods:{
    goDetail(id){
      this.$router.push({name:'PostsDetail',params:{id:id}});
    }
  }
};
</script>

<style lang="less" scoped>
@import "~@/assets/styles/var.less";
.van-notice-bar{background:none;color:@words;}
.notice-swipe {
    height: 40px;
    line-height: 40px;font-size:16px;
  }
</style>
