<template>
  <div class="workspack">
    <div class="top">
      <div class="cname">露天洼子村</div>
      <div class="tabbox">
        <div class="tabitem" :class="show_act == 1 ? 'act' : ''" @click="show_act=1">村务中心</div>
        <div class="tabitem" :class="show_act == 2 ? 'act' : ''" @click="show_act=2">村民管理</div>
      </div>
      <div class="cont1" :class="show_act == 1 ? '' : 'hide'">
        <div class="tzgg">
          <span>通知公告</span>
          <span><van-icon name="arrow"/></span>
        </div>
        <div class="sange">
          <div class="sitem bgcolor1">
            <div class="s01">昨日 <span class="t-org">0</span></div>
            <div class="s02 t-org">0</div>
            <div class="s03">累计发布</div>
          </div>
          <div class="sitem bgcolor2">
            <div class="s01">昨日 <span class="t-blue">0</span></div>
            <div class="s02 t-blue">0</div>
            <div class="s03">已送达</div>
          </div>
          <div class="sitem bgcolor3">
            <div class="s01">昨日 <span class="t-yellow">0</span></div>
            <div class="s02 t-yellow">0</div>
            <div class="s03">已查阅</div>
          </div>
        </div>
        <div class="tabcent">
          <div class="tabcent1 " v-show="tabcent_active == 1">
            发布量统计
          </div>
          <div class="tabcent1 " v-show="tabcent_active == 2">
            送达人员列表
          </div>
          <div class="tabcent1 " v-show="tabcent_active == 3">
            查阅人员列表
          </div>
        </div>
        <div class="tabnav">
          <van-button :type="tabcent_active == 1 ? 'warning' : 'default'" size="small" @click="qhtabnav(1)">警告按钮</van-button>
          <van-button :type="tabcent_active == 2 ? 'warning' : 'default'" size="small" @click="qhtabnav(2)">默认按钮</van-button>
          <van-button :type="tabcent_active == 3 ? 'warning' : 'default'" size="small" @click="qhtabnav(3)">默认按钮</van-button>
        </div>
      </div>
      <div class="cont2" :class="show_act == 2 ? '' : 'hide'"></div>
    </div>

    <foot-bar></foot-bar>
  </div>
</template>

<script>
import footBar from "../../components/w_foot.vue";

export default {
  components: {
    footBar
  },
  data() {
    return {
      tabcent_active: 1,
      show_act:1,
    };
  },
  methods: {
    qhtabnav(n){
      this.tabcent_active = n;
    }
  }
};
</script>

<style lang="less" scoped>
.view{
  background:#fff;
}
.workspack {
  .top {
    width: 100%;
    height: 114px;
    background: linear-gradient(to right, #fa6a5f, #f8866b);
    .cname {
      padding: 20px;
      font-size: 24px;
      color: #fff;
    }
    .tabbox {
      width: 100%;
      height: 40px;
      background: #f2f2f2;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 20px 20px 0 0;
      .tabitem {
        width: 50%;
        height: 40px;
        line-height: 40px;
        text-align: center;
        font-size: 14px;
        color: #444;
      }
      .act {
        color: #f60;
        background: #fff;
        border-radius: 20px 20px 0 0;
      }
    }
    .cont1 {
      width: 100%;
      background: #fff;
      position: relative;
      .tzgg {
        padding: 10px 20px;
        height: 40px;
        line-height: 40px;
        font-size: 16px;
        color: #222;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .sange {
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        .sitem {
          width: 32%;
          height: 100px;
          text-align: center;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          .s01 {
          }
          .s02 {
            line-height: 40px;
            font-size: 20px;
            font-weight: bold;
          }
          .s03 {
            font-size: 14px;
            color: #222;
            font-weight: bold;
          }
        }
        .bgcolor1 {
          background: #fff2f1;
        }
        .bgcolor2 {
          background: #f7fbfe;
        }
        .bgcolor3 {
          background: #fffdf1;
        }
      }
      .tabnav {
        width: 100%;
        height: 80px;
        background: #fff;
        position: fixed;
        bottom: 50px;
        left: 0;
        display: flex;
        justify-content: space-around;
        align-items: center;
      }
      .tabcent {
        padding: 15px 20px 100px;
      }
    }
  }
}
.tx-c {
  text-align: center;
}
.t-org {
  color: #f60;
}
.t-blue {
  color: #479cd5;
}
.t-yellow {
  color: #e7c03b;
}
.hide {
  display: none;
}
</style>
