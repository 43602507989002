<template>
  <div class="myfollow-container">
    <c-title :hide="false"
      :text="`我的关注`"
      tolink=""
      :totext="``">
    </c-title>

    <van-list
      v-model="loading"
      :finished="finished"
      :immediate-check="false"
      finished-text="没有更多了"
      @load="onLoad"
      :offset="10"
    >

      <van-tabs v-model="active">
        <van-tab title="村民/居民" name="a">
          <div class="search">
            <van-search v-model="ss_value" placeholder="请输入搜索关键词" />
          </div>
          <div class="peplist">
            <div class="pepitem" v-for="(item,index) in gzuser_list" :key="index">
              <van-row>
                <van-col span="4">
                  <img :src="item.avatar" alt="" class="avatar">
                </van-col>
                <van-col span="16">
                  <p>
                    <img src="../../assets/images/dang.png" alt="" class="uicon">
                    <span class="nickname">{{item.nickname}}</span>
                    <span class="sf">{{item.position}}</span>
                  </p>
                  <p class="subname">
                    {{item.follow_createtime_text}} <span>来自{{item.province}}{{item.city}}{{item.area}}</span>
                  </p>
                  <p class="subname">粉丝数：{{item.follow}}</p>
                </van-col>
                <van-col span="4" class="pt05 tx-r">
                  <van-button type="primary" size="mini" round @click="yc(1)">已关注</van-button>
                </van-col>
              </van-row>

            </div>

          </div>

        </van-tab>
        <van-tab title="村庄/社区" name="b">
          <div class="search">
            <van-search v-model="ss_value" placeholder="请输入搜索关键词" />
          </div>
          <div class="peplist">
            <div class="pepitem" v-for="(item,index) in gzvillage_list" :key="index">
              <van-row>
                <van-col span="4">
                  <img :src="item.icon_image ? item.icon_image : require('../../assets/images/user.png')" alt="" class="ht_avatar">
                </van-col>
                <van-col span="16">
                  <p class="ht_tit">
                    {{item.site}}
                  </p>
                  <p class="subname">
                    <span>{{item.province}}{{item.city}}{{item.area}}</span>
                  </p>
                </van-col>
                <van-col span="4" class="pt05 tx-r">
                  <van-button type="primary" size="mini" round @click="yc(1)">已关注</van-button>
                </van-col>
              </van-row>

            </div>
          </div>
        </van-tab>
        <van-tab title="话题" name="c">
          <div class="search">
            <van-search v-model="ss_value" placeholder="请输入搜索关键词" />
          </div>
          <div class="peplist">
            <div class="pepitem" v-for="(item,index) in gzhuati_list" :key="index">
              <van-row>
                <van-col span="4" v-if="item.cover_arr.length>0">
                  <img :src="item.cover_arr[0]" alt="" class="ht_avatar">
                </van-col>
                <van-col :span="item.cover_arr.length>0 ? '16' : '20'">
                  <p class="ht_tit">
                    #{{item.title}}#
                  </p>
                  <p class="subname ycyc2">
                    {{item.content}}
                  </p>
                </van-col>
                <van-col span="4" class="pt05 tx-r">
                  <van-button type="primary" size="mini" round @click="yc(1)">已关注</van-button>
                </van-col>
              </van-row>

            </div>
          </div>
        </van-tab>
      </van-tabs>

    </van-list>

  </div>
</template>

<script>
import cTitle from '../../components/title.vue';
import Vue from 'vue';
import { Dialog } from 'vant';
Vue.use(Dialog);

export default {
  data() {
    return {
      ss_value:'',
      show: false,
      active: 0,
      gzuser_list:[],
      gzvillage_list:[],
      gzhuati_list:[],
      page1:1,
      page2:1,
      page3:1,
      limit:10,
      loading: false,
      total1: 0,//总共的数据条数
      total2: 0,//总共的数据条数
      total3: 0,//总共的数据条数
      finished: false,
    };
  },
  created(){
    this.getUserLlist();
    this.getVillageList();
    this.getHuatiList();
  },
  components: {
    cTitle,
    [Dialog.Component.name]: Dialog.Component,
  },
  methods:{
    getUserLlist(){
      let that = this;
      that.$axios
        .get(that.baseUrl+"/api/follow/userFollowList", {
          params: {
            page:that.page1,
            limit:that.limit
          }
        })
        .then(function(res) {
          console.log(res);
          if(res.data.code==1){
            that.gzuser_list= res.data.data.list;
            that.total1= res.data.data.count;
          }
        });
    },
    getVillageList(){
      let that = this;
      that.$axios
        .get(that.baseUrl+"/api/follow/siteFollowList", {
          params: {
            page:that.page2,
            limit:that.limit
          }
        })
        .then(function(res) {
          console.log(res);
          if(res.data.code==1){
            that.gzvillage_list= res.data.data.list;
            that.total2= res.data.data.count;
          }
        });
    },
    getHuatiList(){
      let that = this;
      that.$axios
        .get(that.baseUrl+"/api/follow/huatiFollowList", {
          params: {
            page:that.page3,
            limit:that.limit
          }
        })
        .then(function(res) {
          console.log(res);
          if(res.data.code==1){
            that.gzhuati_list= res.data.data.list;
            that.total3= res.data.data.count;
          }
        });
    },
    onLoad() {
      if(this.active == 'a'){
        this.page1++;
      }else if(this.active == 'b'){
        this.page2++;
      }else if(this.active == 'c'){
        this.page3++;
      }

      this.getroadList();

    },
    getroadList(){

      let that = this;
      if(that.active == 'a'){
        that.$axios
          .get(that.baseUrl+"/api/follow/userFollowList", {
            params: {
              page:that.page1,
              limit:that.limit
            }
          })
          .then(function(res) {
            if(res.data.code==1){
              var newlist = res.data.data.list;
              if (newlist == null || newlist.length === 0) {
                // 加载结束
                that.finished = true;
                return;
              }

              that.gzuser_list = that.gzuser_list.concat(newlist);
              if (that.gzuser_list.length >= that.total1) {
                that.finished = true;
              }
            }
          });
      }else if(that.active == 'b'){
        that.$axios
          .get(that.baseUrl+"/api/news/myRecomment", {
            params: {
              page:that.page2,
              limit:that.limit
            }
          })
          .then(function(res) {
            if(res.data.code==1){
              var newlist = res.data.data.list;
              if (newlist == null || newlist.length === 0) {
                // 加载结束
                that.finished = true;
                return;
              }

              that.gzvillage_list = that.gzvillage_list.concat(newlist);
              if (that.gzvillage_list.length >= that.total2) {
                that.finished = true;
              }
            }
          });
      }else if(that.active == 'c'){
        that.$axios
          .get(that.baseUrl+"/api/follow/huatiFollowList", {
            params: {
              page:that.page3,
              limit:that.limit
            }
          })
          .then(function(res) {
            if(res.data.code==1){
              var newlist = res.data.data.list;
              if (newlist == null || newlist.length === 0) {
                // 加载结束
                that.finished = true;
                return;
              }

              that.gzhuati_list = that.gzhuati_list.concat(newlist);
              if (that.gzhuati_list.length >= that.total3) {
                that.finished = true;
              }
            }
          });
      }

    },
    yc(id){
      Dialog.confirm({
        title: '取消关注',
        message: '您确认取消关注吗？',
      })
        .then(() => {
          //this.datahistory.splice(this.$refs.li[index], 1);// 点击调用删除方法，在datahistory数组里删除index这个下标项，逗号后面的1就代表删除当前的1项
          console.log("已取消");
        })
        .catch(() => {
          // on cancel
        });

    }
  }
};
</script>

<style lang="less" scoped>
@import "~@/assets/styles/var.less";
.myfollow-container{
  .gzbox{
    padding:10px 0 10px;
  }
  .peplist{
    .pepitem{
      padding:15px;
      background:#fff;
      border-bottom:solid 1px #eee;
      .avatar{
        width:50px;
        height:50px;
        border-radius:50%;
      }
      .uicon{
        width:16px;height:16px;border-radius:8px;vertical-align:-16%;margin-right:4px;
      }
      .nickname{
        font-size:16px;
        color:#222;margin-right:4px;
      }
      .sf{
        font-size:14px;
        color:@words;
      }
      .subname{height:20px;line-height:22px;font-size:11px;color:@lightWords;}
    }
  }
}
.pt05{
  padding-top:10px;
}
.tx-r{text-align:right;}
.ht_tit{
  font-size:15px;
  color:#333;
}
.ht_avatar{
  width:50px;height:50px;
  border-radius:4px;
}
.ycyc2{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
</style>
