import { Store, install } from "vuex";
import Vue from "vue";
import Authorization from "./Authorization";

if (!window.Vuex) {
  install(Vue);
}

export default new Store({
  modules: {
    Authorization
  },
  strict: true,
});
