<template>
  <div>
    <c-title :hide="false" text="发布通知公告" tolink="" :totext="``"> </c-title>
    <div class="tit">
      <van-cell-group inset>
        <van-field v-model="title" label="标题" placeholder="请输入标题" />
      </van-cell-group>
      <van-cell-group inset>
        <van-field
          v-model="message"
          rows="10"
          autosize
          label="正文"
          type="textarea"
          maxlength="500"
          placeholder="请输入正文内容"
          show-word-limit
        />
      </van-cell-group>
    </div>
    <div class="cont">
      <van-field name="fileList" label="图片上传">
        <template #input>
          <van-uploader v-model="fileList" multiple :after-read="afterRead" />
        </template>
      </van-field>

      <div class="fxk">
        <div class="tzdx">反馈选项</div>
        <van-cell center title="阅读确认">
          <template #right-icon>
            <van-switch v-model="checked1" size="20" />
          </template>
        </van-cell>
        <van-cell center title="允许评论">
          <template #right-icon>
            <van-switch v-model="checked2" size="20" />
          </template>
        </van-cell>
      </div>
    </div>

    <div class="foot">
      <van-button type="primary" :disabled="is_click" block @click="fabu">立即发布</van-button>
    </div>
  </div>
</template>

<script>
import cTitle from "../../components/title.vue";
export default {
  data() {
    return {
      title: "",
      message: "",
      menuname: "",
      fileList: [],
      img_arr: "",
      cid: "",
      is_click: false,
      checked1:0,
      checked2:0,
    };
  },
  created() {
    this.menuname = this.$route.params.mname;

  },
  methods: {

    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      var that = this;
      that.img_arr = [];
      if (that.fileList.length > 0) {
        for (var i = 0; i < that.fileList.length; i++) {
          var img_url = that.fileList[i].content;
          that.$axios
            .post(that.baseUrl + "/api/common/uploadBase64", {
              params: {
                base64: img_url
              }
            })
            .then(function(res) {
              //当请求完成时候执行then回调函数

              if (res.data.code == 1) {
                that.img_arr += res.data.data.fullurl + ",";
              }
            });
        }
      }
    },
    fabu() {
      var that = this;
      that.is_click = true;
      var bbstitle = that.title;
      if (bbstitle == "") {
        that.$toast("请填写标题");
        that.is_click = false;
        return false;
      }
      var message = that.message;
      if (message == "") {
        that.$toast("请填写内容");
        that.is_click = false;
        return false;
      }


      that.$axios
        .post(that.baseUrl + "/api/town_manager/editNotice", {
          id: "",
          cid: that.cid,
          title: bbstitle,
          cover: that.img_arr,
          content: message,
          extra: "",
          need_confirm:that.checked1,
          can_comment:that.checked2,
          object:0,
          users:''
        })
        .then(function(res) {
          console.log(res);
          if (res.data.code == 1) {
            that.$toast("发布成功");
          } else {
            that.is_click = false;
          }
        });
    }
  },
  components: {
    cTitle
  }
};
</script>

<style lang="less" scoped>
.view {
  background: #fff;
}
.cont {
  padding: 15px;
}
.mr10 {
  margin-right: 10px;
}
.fxk {
  padding: 15px;
  .tzdx {
    margin-bottom: 10px;
    font-size: 14px;
    color: #666;
  }
}
.foot {
  padding: 15px;
}
</style>
