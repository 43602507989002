<template>
  <div class="supplydemand-container">
    <div class="content">
      <div class="chooese-type">
        <div class="tit">选择类别</div>
        <div class="navlist">
          <div class="navitem" :class="is_nav == 'nav1' ? 'active' : '' " @click="chooesetype(1,'nav1')">
            <div class="nav">人找车</div>
          </div>
          <div class="navitem" :class="is_nav == 'nav2' ? 'active' : '' " @click="chooesetype(2,'nav2')">
            <div class="nav">车找活</div>
          </div>
          <div class="navitem" :class="is_nav == 'nav3' ? 'active' : '' " @click="chooesetype(2,'nav3')">
            <div class="nav">农活找人</div>
          </div>
          <div class="navitem" :class="is_nav == 'nav4' ? 'active' : '' " @click="chooesetype(2,'nav4')">
            <div class="nav">农机找活</div>
          </div>
          <div class="navitem" :class="is_nav == 'nav5' ? 'active' : '' " @click="chooesetype(2,'nav5')">
            <div class="nav">我是菜贩</div>
          </div>
          <div class="navitem" :class="is_nav == 'nav6' ? 'active' : '' " @click="chooesetype(2,'nav6')">
            <div class="nav">交友征婚</div>
          </div>
          <div class="navitem" :class="is_nav == 'nav7' ? 'active' : '' " @click="chooesetype(2,'nav7')">
            <div class="nav">房屋出租</div>
          </div>
          <div class="navitem" :class="is_nav == 'nav8' ? 'active' : '' " @click="chooesetype(2,'nav8')">
            <div class="nav">土地求租</div>
          </div>
          <div class="navitem" :class="is_nav == 'nav9' ? 'active' : '' " @click="chooesetype(2,'nav9')">
            <div class="nav">二手物品</div>
          </div>
          <div class="navitem" :class="is_nav == 'nav10' ? 'active' : '' " @click="chooesetype(2,'nav10')">
            <div class="nav">产品出售</div>
          </div>
          <div class="navitem" :class="is_nav == 'nav11' ? 'active' : '' " @click="chooesetype(2,'nav11')">
            <div class="nav">二手车</div>
          </div>
          <div class="navitem" :class="is_nav == 'nav12' ? 'active' : '' " @click="chooesetype(2,'nav12')">
            <div class="nav">寻人寻物</div>
          </div>
        </div>
      </div>
      <div class="contacts">
        <van-field
          v-model="username"
          name="username"
          label="联系人"
          placeholder="请输入您的姓名"
          :rules="[{ required: true, message: '请填写您的姓名' }]"
        />
        <van-field
          v-model="telphone"
          name="telphone"
          label="联系电话"
          placeholder="请输入您的手机号码"
          :rules="[{ required: true, message: '请填写您的手机号码' }]"
        />
      </div>
      <van-field
          v-model="message"
          rows="5"
          autosize
          label=""
          type="textarea"
          mINlength="20"
          maxlength="300"
          placeholder="说点什么"
          show-word-limit
        />
      <div class="upload-img">
        <van-uploader :after-read="afterRead" />
      </div>
      <div class="line"></div>
      <template v-if="type == 1">
        <div class="contacts">
          <van-field
            v-model="start_address"
            name="start_address"
            label="起点"
            placeholder="请填写起点"
            :rules="[{ required: true, message: '请填写起点' }]"
          />
          <van-field
            v-model="end_address"
            name="end_address"
            label="终点"
            placeholder="请填写终点"
            :rules="[{ required: true, message: '请填写终点' }]"
          />
          <van-field
            v-model="user_num"
            name="user_num"
            label="几人乘车"
            placeholder="请填写乘车人数"
            :rules="[{ required: true, message: '请填写乘车人数' }]"
          />
          <van-row class="dh">
            <van-col span="7">出发时间</van-col>
            <van-col span="17" class="tx-r" @click="showPopup_cftime" >
              {{ cftime ? cftime : '请选择' }}
            </van-col>
          </van-row>

        </div>
        <van-popup v-model="show_cftime" style="width:80%;">
          <van-datetime-picker
            v-model="cftime"
            type="date"
            title="选择年月日"
            @confirm="onConfirm_cftime"
            @cancel="onCancel_cftime"
          />
        </van-popup>
      </template>
    </div>
    <div class="btnbox">
      <van-button round type="info" color="#ccc" class="mr10" @click="goback"> 取消 </van-button>
      <van-button round type="info" color="#00a752"> 发布 </van-button>
    </div>

  </div>

</template>

<script>

export default {
  data(){
    return{
      message:'',
      username:'',
      telphone:'',
      start_address:'',
      end_address:'',
      user_num:'',
      cftime:'',//出发时间
      show_cftime: false,//是否显示出发时间选择弹窗
      type:1,//默认1显示人找车，2表示其它类型
      is_nav:'nav1',

    };
  },
  methods:{
    chooesetype(type,nav){
      this.is_nav=nav;
      this.type = type;
    },
    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      console.log(file);
    },
    goback(){
      this.$router.go(-1);
    },
    onConfirm_cftime(value){
      this.show_cftime = false;
      let d = value;
      this.cftime = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate();
    },
    onCancel_cftime(){
      this.show_cftime = false;
    },
    showPopup_cftime() {
      this.show_cftime = true;
    },
  },
  components:{

  }
};
</script>

<style lang="less" scoped>
@import "~@/assets/styles/var.less";
.supplydemand-container{
  .content{
    background:#fff;
    .chooese-type{
      padding:15px;
      padding-bottom:0;
      .tit{
        font-size:16px;
        color:@words;
        padding-top:5px;
        padding-bottom:5px;
      }
      .navlist{
        width:100%;
        display:flex;
        flex-direction:row;
        justify-content: space-between;
        flex-wrap:wrap;
        align-items: flex-start;
        padding-bottom:15px;
        border-bottom:solid 1px #ebedf0;
        .navitem{
          width:24%;
          margin-top:5px;margin-bottom:5px;
          .nav{
            margin:0px auto;
            height:30px;
            line-height:30px;
            border:solid 1px #ddd;
            border-radius:4px;
            text-align:center;
            color:@words;
          }
        }
      }
    }
  }

}
.upload-img{
    padding:15px;
    background:#fff;
  }
.btnbox{
    padding:20px;
    text-align:center;
    background:#f5f5f5;
}
.mr10{margin-right:10px;}
.active .nav{
  background:@primary;
  color:#fff !important;
}
.line{
  margin:0 15px;
  border-bottom:solid 1px #ebedf0;
}
.dh{
  height:44px;
  line-height:44px;
  margin:0 15px;
  background:#fff;
  font-size:14px;
  color:#646566;
}
</style>
