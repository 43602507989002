<template>
  <div class="homepage-container">
    <c-title :hide="false"
      :text="title"
      tolink=""
      :totext="``">
    </c-title>
    <div class="top">
      <van-row class="ptb2">
        <van-col span="5" class="tx-c">
          <img :src="userinfo.avatar" alt="" class="useravatar">
        </van-col>
        <van-col span="19">
          <p class="t-wh pb05"><span class="username">{{userinfo.nickname}}</span><span class="usersf">({{userinfo.village}}村民)</span></p>
          <p class="t-wh pb05 useraddress">{{userinfo.province}}{{userinfo.city}}{{userinfo.area}}{{userinfo.town}}{{userinfo.village}}</p>
          <p class="t-wh userfs pt05">
            关注 {{userinfo.follow}} ｜ 粉丝 {{userinfo.fans}}
            <span class="gz" v-show="user_id != id">关注</span>
          </p>
        </van-col>
      </van-row>
    </div>
    <div class="fenlan" v-if="user_id == id">- 我的发布 -</div>
    <div class="fenlan" v-else>- 他的发布 -</div>

    <van-list
      v-model="loading"
      :finished="finished"
      :immediate-check="false"
      finished-text="没有更多了"
      @load="onLoad"
      :offset="10"
    >
    <home-dynamic
      v-for="(item,i) in newsList"
      :key="i"
      :news="item"
    ></home-dynamic>
    </van-list>


    <foot-bar></foot-bar>
  </div>
</template>

<script>
import cTitle from '../../components/title.vue';
import footBar from '../../components/foot.vue';
import homeDynamic from '../../components/home/dynamic.vue';
export default {
  data(){
    return {
      id:'',
      user_id:'',
      site:'',
      userinfo:'',
      newsList:[],
      page:1,
      limit:10,
      loading: false,
      total: 0,//总共的数据条数
      finished: false,
    };
  },
  created(){
    this.id=this.$route.params.id;
    this.user_id = window.localStorage.getItem('user_id');
    this.site = window.localStorage.getItem('user_village_id');
    this.getUserInfo();
    this.getroadList();
  },
  computed:{
    title:function(){
      var tit = '';
      if(this.id == this.user_id){
        tit = '我的主页';
      }else{
        tit = '他的主页';
      }
      return tit;
    }
  },
  methods:{
    getUserInfo(){
      var that = this;
      that.$axios
        .get(that.baseUrl+"/api/user/user", {
          params: {
            user_id:that.id
          }
        })
        .then(function(res) {
          if(res.data.code == 1){
            that.userinfo = res.data.data.user;
            console.log(that.userinfo);
          }

        });
    },
    onLoad() {
      this.page++;
      this.getroadList();
    },
    getroadList(){

      let that = this;
      that.$axios
        .get(that.baseUrl+"/api/news/mylist", {
          params: {
            page:that.page,
            limit:that.limit
          }
        })
        .then(function(res) {
          if(res.data.code==1){
            that.loading = false;
            that.total = res.data.data.count;

            var list = res.data.data.list;

            if (list == null || list.length === 0) {
              // 加载结束
              that.finished = true;
              return;
            }
            // 将新数据与老数据进行合并
            that.newsList = that.newsList.concat(list);

            //如果列表数据条数>=总条数，不再触发滚动加载
            if (that.newsList.length >= that.total) {
              that.finished = true;
            }

          }
        });
    },

  },
  components: {
    footBar,
    homeDynamic,
    cTitle
  },
};
</script>

<style lang="less" scoped>
  .view{height:100%;}
  .homepage-container{
    padding-bottom:50px;
    .top{
      min-height:120px;
      background:url(../../assets/images/homepagebg.png) no-repeat;
      background-size:100% auto;
      .useravatar{
        width:46px;height:46px;border:solid 2px #fff;border-radius:50%;
      }
      .username{font-size:16px;font-weight:bold;}
      .usersf{font-size:14px;}
      .userfs{font-size:14px;}
    }
  }
  .ptb2{padding-top:20px;padding-bottom:20px;}
  .tx-c{text-align:center;}
  .tx-l{text-align:left;}
  .tx-r{text-align:right;}
  .t-wh{color:#fff;}
  .pb05{padding-bottom:5px;}
  .pt05{padding-top:5px;}
  .gz{margin-left:20px;padding:4px 10px;background:#ff8030;border-radius:10px;font-size:10px;}
  .fenlan{font-size:14px;color:#333;font-weight:bold;text-align:center;height:50px;line-height:50px;}
</style>
