<template>
  <div>
    <c-title :hide="false" text="返乡登记人员" tolink="" :totext="``"> </c-title>
    <van-tabs v-model="active">
      <van-tab title="全部">
        <div class="cont">
          <empty></empty>
        </div>
      </van-tab>
      <van-tab title="已报备">
        <div class="cont">
          <empty></empty>
        </div>
      </van-tab>
      <van-tab title="待核查">
        <div class="cont">
          <empty></empty>
        </div>
      </van-tab>
      <van-tab title="已核查">
        <div class="cont">
          <empty></empty>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import cTitle from "../../components/title.vue";
import empty from "../../components/empty.vue";
export default {
  components: {
    cTitle,
    empty
  }
};
</script>

<style></style>
