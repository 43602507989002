<template>
  <div class="worklog-container">
    <c-title :hide="false" :text="typename" tolink="" :totext="``"> </c-title>
    <div class="top">
      <div class="toptit">{{ typename }}</div>
      <div class="topsearch">
        <van-search v-model="searchvalue" placeholder="请输入搜索关键词" class="cbg" />
      </div>
      <div class="topmenu">
        <van-button plain hairline round size="small" type="primary" class="mr10 mb10"
          >全部 {{ count }}</van-button
        >
        <van-button
          plain
          hairline
          round
          size="small"
          type="default"
          class="mr10 mb10"
          v-for="item in types1"
          :key="item.id"
          >{{ item.name }}</van-button
        >
      </div>
    </div>
    <div class="content">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
          <div class="list-item" v-for="item in list" :key="item">
            <div class="tit">{{ item.title }}</div>
            <div class="stit">
              <span class="mr05">类别：{{ item.type1 }}</span>
              <span class="mr05">时间：{{ item.createtime }}</span>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
    <div class="foot">
      <van-button type="primary" size="small" @click="fabu">发布</van-button>
    </div>
  </div>
</template>

<script>
import cTitle from "../../components/title.vue";
export default {
  data() {
    return {
      type: "log",
      types1: [],
      typename: "",
      searchvalue: "",
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      page: 1,
      limit: 10,
      count: 0,
      apiUrl: "",
      params: ""
    };
  },
  created() {
    var tname = this.$route.params.type;
    if (tname) {
      this.type = tname;
      if (this.type == "log") {
        this.typename = "工作日志";
        this.apiUrl = "/api/village_manager/workLog";
        this.params = {
          keyword: this.searchvalue,
          date: "",
          page: this.page,
          limit: this.limit
        };
      } else if (this.type == "guide") {
        this.typename = "办事指南";
        this.apiUrl = "/api/village_manager/guide";
      } else if (this.type == "open") {
        this.typename = "公示公开";
        this.apiUrl = "/api/village_manager/openLog";
      } else if (this.type == "worknotice") {
        this.typename = "通知公告";
        this.apiUrl = " /api/village_manager/notice";
        this.params = {
          keyword: this.searchvalue,
          date: "",
          type:1,
          page: this.page,
          limit: this.limit
        };
      } else if (this.type == "sjxx") {
        this.typename = "书记信箱";
        this.apiUrl = "/api/village_manager/clerkBox";
      }
    }
    if(this.type == "open"){
      this.getChildrenType_open();
    }if(this.type == "worknotice"){
      // that.getChildrenType_worknotice();
    }else if(this.type == "log"){
      this.getChildrenType_log();
    }
    else if(this.type == "sjxx"){
      this.getChildrenType_sjxx();
    }
    console.log(this.types1);

    //初始化数据
    this.onLoad();
  },
  methods: {
    onLoad() {
      if (this.refreshing) {
        this.list = [];
        this.refreshing = false;
      }

      var that = this;
      that.$axios.post(that.baseUrl + that.apiUrl, that.params).then(function(res) {
        console.log(res);
        if (res.data.code == 1) {
          that.count = res.data.data.count;

          var l = res.data.data.list;
          if (l && l.length > 0) {
            l.forEach(element => {
              that.list.push(element);
            });
          }
        }
      });

      that.loading = false;

      if (that.list.length >= that.count) {
        that.finished = true;
      } else {
        that.page += 1;
      }
    },
    //获取工作日志的子分类
    getChildrenType_log(){
      var that = this;
      that.$axios
        .get(that.baseUrl + "/api/village_manager/workTypes1", {
          params: {}
        })
        .then(function(res) {
          // console.log(res);
          if(res.data.code==1){
            that.types1 = res.data.data;
          }
        });
    },
    //获取书记信箱的几种状态
    getChildrenType_sjxx(){
      this.types1 = [
        {
          name:'普通信件',
          id:1,
        },
        {
          name:'重要信件',
          id:2,
        }
      ];
    },
    //获取公示公开的子分类
    getChildrenType_open(){
      var that = this;
      that.$axios
        .get(that.baseUrl + "/api/village_manager/openLogTypes1", {
          params: {

          }
        })
        .then(function(res) {
          // console.log(res);
          if(res.data.code==1){
            that.types1 = res.data.data;
          }
        });
    },
    //获取通知公告子类
    // getChildrenType_worknotice(){
    //   var that = this;
    //   that.$axios
    //     .get(that.baseUrl + "/api/village_manager/openLogTypes1", {
    //       params: {

    //       }
    //     })
    //     .then(function(res) {
    //       // console.log(res);
    //       if(res.data.code==1){
    //         that.types1 = res.data.data;
    //       }
    //     });
    // },
    onRefresh() {
      // 清空列表数据
      this.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
    fabu() {
      if (this.typename == "通知公告") {
        this.$router.push({
          name: "AddNotice",
          params: {
            mname: this.typename
          }
        });
      } else if (this.typename == "公示公开") {
        this.$router.push({
          name: "AddPublicity",
          params: {
            mname: this.typename
          }
        });
      }else if(this.typename == "工作日志"){
        this.$router.push({
          name: "AddLog",
          params: {
            mname: this.typename
          }
        });
      }else if(this.typename == "书记信箱"){
        this.$router.push({
          name: "AddSecretaryMailbox",
          params: {
            mname: this.typename
          }
        });
      }else if(this.typename == "办事指南"){
        this.$router.push({
          name: "AddGuide",
          params: {
            mname: this.typename
          }
        });
      }
    }
  },
  components: {
    cTitle
  }
};
</script>

<style lang="less" scoped>
.worklog-container {
  padding-bottom: 50px;
  background: #f8f8f8;
  .top {
    width: 100%;
    height: 190px;
    background: #fff url(../../assets/images/gzt/logbg.png) no-repeat;
    background-size: 100% 100px;
    background-position: top center;
    position: fixed;
    left: 0;
    top: 40px;
    z-index:1;
    .toptit {
      padding: 20px;
      height: 36px;
      line-height: 40px;
      font-size: 20px;
      color: #fff;
    }
    .topsearch {
      padding: 0 20px;
    }
    .topmenu {
      padding: 10px 20px;
      height: 40px;
      white-space: nowrap;
      overflow-x: scroll;
      botton {
        display: inline-block;
      }
    }
  }
  .content {
    padding: 10px;z-index:2;padding-top: 200px;
    .list-item {
      padding: 10px 15px;
      background: #fff;
      .tit {
        line-height: 20px;
        font-size: 14px;
        color: #333;
        margin-bottom: 5px;
      }
      .stit {
        color: #999;
        font-size: 14px;
      }
    }
  }
  .foot {
    width: 100%;
    height: 50px;
    background: #fff;
    position: fixed;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mr10 {
    margin-right: 10px;
  }
  .mb10 {
    margin-bottom: 10px;
  }
  .mr05 {
    margin-right: 5px;
  }
  .cbg {
    box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.1);
  }
}
</style>
