<template>
  <div class="worklog-container">
    <c-title :hide="false" text="党员管理" tolink="" :totext="``"> </c-title>
    <div class="top">
      <div class="toptit">党员管理</div>
      <div class="topsearch">
        <van-search v-model="searchvalue" placeholder="请输入搜索关键词" class="cbg" />
      </div>
      <div class="topmenu">
        <van-button plain hairline round size="small" type="warning" class="mr10"
          >全部 {{ count }}</van-button
        >
        <!-- <van-button plain hairline round size="small" type="default" class="mr10" >群众</van-button>
        <van-button plain hairline round size="small" type="default" class="mr10">党员</van-button> -->
      </div>
    </div>
    <div class="cent">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
          <div class="cent-item" v-for="item in list" :key="item.mobile">
            <div class="item-top">
              <div class="item-top-left">
                <img :src="item.avatar" />
              </div>
              <div class="item-top-right">
                <div class="flex">
                  <span class="item-name">{{ item.nickname }}</span>
                  <span class="item-time">{{ item.jointime }}</span>
                </div>
                <div class="flex">
                  <span class="item-shenfen">{{ item.position }}</span>
                  <span class="item-phone" @click="tell(item.mobile)">拨打电话</span>
                </div>
              </div>
            </div>
            <div class="item-foot">
              <div class="item-foot-san t-org ubr">
                禁言
              </div>
              <div class="item-foot-san t-org ubr">
                修改
              </div>
              <div class="item-foot-san t-gay">
                移出本村
              </div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>

    <div class="foot">
      <van-button type="primary" size="small">邀请</van-button>
    </div>
  </div>
</template>

<script>
import cTitle from "../../components/title.vue";
export default {
  data() {
    return {
      searchvalue: "",
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      page: 1,
      limit: 10,
      count: 0
    };
  },
  created() {
    this.onLoad();
  },
  methods: {
    onLoad() {
      if (this.refreshing) {
        this.list = [];
        this.refreshing = false;
      }

      var that = this;
      that.$axios
        .post(that.baseUrl + "/api/village_manager/party", {
          keyword: that.searchvalue,
          date: "",
          page: that.page,
          limit: that.limit
        })
        .then(function(res) {
          if (res.data.code == 1) {
            that.count = res.data.data.count;
            var l = res.data.data.list;
            if (l.length > 0) {
              l.forEach(element => {
                that.list.push(element);
              });
            }
          }
        });

      that.loading = false;

      if (that.list.length >= that.count) {
        that.finished = true;
      } else {
        that.page += 1;
      }
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
    tell(mobile) {
      window.location.href = "tel:" + mobile;
    },
  },
  components: {
    cTitle
  }
};
</script>

<style lang="less" scoped>
.worklog-container {
  padding-top: 180px;
  padding-bottom: 50px;
  background: #f8f8f8;
  .top {
    width: 100%;
    height: 180px;
    background: #fff url(../../assets/images/gzt/logbg.png) no-repeat;
    background-size: 100% 100px;
    background-position: top center;
    position: fixed;
    left: 0;
    top: 40px;
    z-index: 1;
    .toptit {
      padding: 20px;
      height: 36px;
      line-height: 40px;
      font-size: 20px;
      color: #fff;
    }
    .topsearch {
      padding: 0 20px;
    }
    .topmenu {
      padding: 10px 20px;
      height: 40px;
      white-space: nowrap;
      overflow-x: scroll;
      botton {
        display: inline-block;
      }
    }
  }
  .cent {
    padding: 15px;
    z-index: 2;
    .cent-item {
      padding: 10px;
      background: #fff;
      margin-bottom: 15px;
      .item-top {
        width: 100%;
        display: flex;
        .item-top-left {
          width: 25%;
          img {
            width: 60px;
            height: 60px;
            border-radius: 50%;
          }
        }
        .item-top-right {
          width: 75%;
          .flex {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 5px;
            margin-bottom: 5px;
            .item-name {
              font-size: 14px;
              color: #111;
              font-weight: bold;
            }
            .item-time {
              font-size: 14px;
              color: #888;
            }
            .item-shenfen {
              font-size: 14px;
              color: #fff;
              background: green;
              padding: 0 10px;
              border-radius: 20px;
              line-height: 16px;
            }
            .item-phone {
              font-size: 14px;
              color: green;
              padding: 0 10px;
              border: solid 1px green;
              border-radius: 20px;
            }
          }
        }
      }
      .item-foot {
        height: 30px;
        line-height: 30px;
        border-top: solid 1px #ddd;
        padding-top: 10px;
        margin-top: 10px;
        display: flex;
        .item-foot-san {
          width: 33%;
          text-align: center;
          font-size: 14px;
        }
      }
    }
  }

  .foot {
    width: 100%;
    height: 50px;
    background: #fff;
    position: fixed;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mr10 {
    margin-right: 10px;
  }
  .mb10 {
    margin-bottom: 10px;
  }
  .mr05 {
    margin-right: 5px;
  }
  .cbg {
    box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.1);
  }
  .t-gay {
    color: #888;
  }
  .t-org {
    color: #f60;
  }
  .ubr {
    border-right: solid 1px #ddd;
  }
}
</style>
