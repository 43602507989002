<template>
  <div class="village-info-container">
    <c-title :hide="false"
      text="镇情概况"
      tolink=""
      :totext="``">
    </c-title>
    <div class="navbox">
      <div class="navitem"  @click="chooese(1)">
        <div class="icon"><img src="../assets/images/cqgk_01.png"/></div>
        <div class="tit" :class="is_active == 1 ? 'active' : ''">镇简介</div>
      </div>
      <div class="navitem" @click="chooese(2)">
        <div class="icon"><img src="../assets/images/cqgk_02.png"/></div>
        <div class="tit" :class="is_active == 2 ? 'active' : ''">支部风采</div>
      </div>
      <div class="navitem" @click="chooese(3)">
        <div class="icon"><img src="../assets/images/cqgk_03.png"/></div>
        <div class="tit" :class="is_active == 3 ? 'active' : ''">组织架构</div>
      </div>
      <div class="navitem" @click="chooese(4)">
        <div class="icon"><img src="../assets/images/cqgk_04.png"/></div>
        <div class="tit" :class="is_active == 4 ? 'active' : ''">镇容镇志</div>
      </div>
    </div>
    <div class="tab" :class="is_active == 1 ? '' : 'uhide'">
      <div class="content_box">
        <div class="content" :class="is_ckgd1 ? 'ycyc5': ''" v-html="info.content"></div>
        <div class="ckgd"><span class="ckgdbtn" @click="ckgd(1)">{{ckgd1_text}}</span></div>
      </div>
      <div class="content_box">
        <div class="tit">景点、农家乐</div>
        <div class="content" :class="is_ckgd2 ? 'ycyc5': ''" v-html="info_extra_0"></div>
        <div class="ckgd"><span class="ckgdbtn" @click="ckgd(2)">{{ckgd2_text}}</span></div>
      </div>
      <div class="content_box">
        <div class="tit">特色产业</div>
        <div class="content" :class="is_ckgd3 ? 'ycyc5': ''" v-html="info_extra_1"></div>
        <div class="ckgd"><span class="ckgdbtn" @click="ckgd(3)">{{ckgd3_text}}</span></div>
      </div>
      <div class="content_box">
        <div class="tit">其它特色</div>
        <div class="content" :class="is_ckgd4 ? 'ycyc5': ''" v-html="info_extra_2"></div>
        <div class="ckgd"><span class="ckgdbtn" @click="ckgd(4)">{{ckgd4_text}}</span></div>
      </div>

    </div>
    <div class="tab" :class="is_active == 2 ? '' : 'uhide'">
      <c-structure :content_obj="content_obj" :dw_list_one="dw_list_one" :is_show_top="false"></c-structure>
      <div class="content_box">
        <div class="tit">党委会概况</div>
        <div class="content" :class="is_ckgd5 ? 'ycyc5': ''" v-html="dwgk.content"></div>
        <div class="ckgd"><span class="ckgdbtn" @click="ckgd(5)">{{ckgd5_text}}</span></div>
      </div>
      <div class="content_box">
        <div class="tit">党委会工作目标</div>
        <div class="content" :class="is_ckgd6 ? 'ycyc5': ''" v-html="gzmb.content"></div>
        <div class="ckgd"><span class="ckgdbtn" @click="ckgd(6)">{{ckgd6_text}}</span></div>
      </div>
      <div class="content_box">
        <div class="tit">党委会大数据</div>
        <div class="content" :class="is_ckgd7 ? 'ycyc5': ''" v-html="dwhdsj.content"></div>
        <div class="ckgd"><span class="ckgdbtn" @click="ckgd(7)">{{ckgd7_text}}</span></div>
        <!-- <van-row class="ptb10 dsj">
          <van-col span="6" class="tx-c">
            党委会成员
          </van-col>
          <van-col span="6" class="tx-c">
            8人
          </van-col>
          <van-col span="6" class="tx-c">
            两委日记
          </van-col>
          <van-col span="6" class="tx-c">
            15篇
          </van-col>
        </van-row>
        <van-row  class="ptb10 dsj">
          <van-col span="6" class="tx-c">
            党委通知
          </van-col>
          <van-col span="6" class="tx-c">
            4次
          </van-col>
          <van-col span="6" class="tx-c">
            财务公开
          </van-col>
          <van-col span="6" class="tx-c">
            1次
          </van-col>
        </van-row> -->
      </div>

    </div>
    <div class="tab" :class="is_active == 3 ? '' : 'uhide'">
      <v-structure :content_obj="content_obj_v" :cw_list_one="cw_list_one" :cw_list_two="cw_list_two" :is_show_top="false"></v-structure>
      <div class="content_box">
        <div class="tit">镇政府概况</div>
        <div class="content" :class="is_ckgd7 ? 'ycyc5': ''" v-html="cwgk_v.content"></div>
        <div class="ckgd"><span class="ckgdbtn" @click="ckgd(7)">{{ckgd7_text}}</span></div>
      </div>
      <div class="content_box">
        <div class="tit">镇政府工作目标</div>
        <div class="content" :class="is_ckgd8 ? 'ycyc5': ''" v-html="gzmb_v.content"></div>
        <div class="ckgd"><span class="ckgdbtn" @click="ckgd(8)">{{ckgd8_text}}</span></div>
      </div>
      <div class="content_box">
        <div class="tit">镇政府大数据</div>
        <div class="content" :class="is_ckgd9 ? 'ycyc5': ''" v-html="zwfc_v.content"></div>
        <div class="ckgd"><span class="ckgdbtn" @click="ckgd(9)">{{ckgd9_text}}</span></div>
        <!-- <van-row class="ptb10 dsj">
          <van-col span="6" class="tx-c">
            镇政府成员
          </van-col>
          <van-col span="6" class="tx-c">
            8人
          </van-col>
          <van-col span="6" class="tx-c">
            两委日记
          </van-col>
          <van-col span="6" class="tx-c">
            15篇
          </van-col>
        </van-row>
        <van-row  class="ptb10 dsj">
          <van-col span="6" class="tx-c">
            镇政府通知
          </van-col>
          <van-col span="6" class="tx-c">
            4次
          </van-col>
          <van-col span="6" class="tx-c">
            财务公开
          </van-col>
          <van-col span="6" class="tx-c">
            1次
          </van-col>
        </van-row> -->
      </div>
    </div>
    <div class="tab" :class="is_active == 4 ? '' : 'uhide'">
      <div class="content1" v-html="crcz_content"></div>
    </div>
    <foot-bar></foot-bar>
  </div>
</template>

<script>
import cTitle from '../components/title.vue';
import footBar from '../components/foot.vue';
import cStructure from '../components/structure.vue';
import vStructure from '../components/structure_v.vue';
export default {
  data(){
    return {
      is_active:1,
      info:'',
      is_ckgd1:true,
      ckgd1_text:'查看更多',
      is_ckgd2:true,
      ckgd2_text:'查看更多',
      is_ckgd3:true,
      ckgd3_text:'查看更多',
      is_ckgd4:true,
      ckgd4_text:'查看更多',
      is_ckgd5:true,
      ckgd5_text:'查看更多',
      is_ckgd6:true,
      ckgd6_text:'查看更多',
      is_ckgd7:true,
      ckgd7_text:'查看更多',
      is_ckgd8:true,
      ckgd8_text:'查看更多',
      is_ckgd9:true,
      ckgd9_text:'查看更多',
      content_obj:'',
      dwgk:'',//党委会概况
      gzmb:'',//工作目标
      dwhdsj:'',//党委会大数据
      dw_list_one:[],
      content_obj_v:'',
      cwgk_v:'',//村委概况
      gzmb_v:'',//工作目标
      zwfc_v:'',//镇委风采大数据
      cw_list_one:[],
      cw_list_two:[],
      info_extra_0:'',
      info_extra_1:'',
      info_extra_2:'',
      crcz_content:'',//镇容镇志
    };
  },
  created(){
    this.getData();//获取镇情概况
    this.init();
    this.getPartyInfo();//获取支部风采
    this.getVillageInfo();//获取组织结构
    this.getZrzz();//获取镇容镇志
  },
  methods:{
    ckgd(n){
      if(n==1){
        this.is_ckgd1 = !this.is_ckgd1;
        if(this.is_ckgd1){
          this.ckgd1_text = '查看更多';
        }else{
          this.ckgd1_text = '收起';
        }
      }else if(n==2){
        this.is_ckgd2 = !this.is_ckgd2;
        if(this.is_ckgd2){
          this.ckgd2_text = '查看更多';
        }else{
          this.ckgd2_text = '收起';
        }
      }else if(n==3){
        this.is_ckgd3 = !this.is_ckgd3;
        if(this.is_ckgd3){
          this.ckgd3_text = '查看更多';
        }else{
          this.ckgd3_text = '收起';
        }
      }else if(n==4){
        this.is_ckgd4 = !this.is_ckgd4;
        if(this.is_ckgd4){
          this.ckgd4_text = '查看更多';
        }else{
          this.ckgd4_text = '收起';
        }
      }else if(n==5){
        this.is_ckgd5 = !this.is_ckgd5;
        if(this.is_ckgd5){
          this.ckgd5_text = '查看更多';
        }else{
          this.ckgd5_text = '收起';
        }
      }else if(n==6){
        this.is_ckgd6 = !this.is_ckgd6;
        if(this.is_ckgd6){
          this.ckgd6_text = '查看更多';
        }else{
          this.ckgd6_text = '收起';
        }
      }else if(n==7){
        this.is_ckgd7 = !this.is_ckgd7;
        if(this.is_ckgd7){
          this.ckgd7_text = '查看更多';
        }else{
          this.ckgd7_text = '收起';
        }
      }else if(n==8){
        this.is_ckgd8 = !this.is_ckgd8;
        if(this.is_ckgd8){
          this.ckgd8_text = '查看更多';
        }else{
          this.ckgd8_text = '收起';
        }
      }else if(n==9){
        this.is_ckgd9 = !this.is_ckgd9;
        if(this.is_ckgd9){
          this.ckgd9_text = '查看更多';
        }else{
          this.ckgd9_text = '收起';
        }
      }
    },
    init(){
      var village = window.localStorage.getItem('village_info');
      if(village){
        this.is_join = true;
        this.villageInfo = JSON.parse(village);
      }
    },
    getPartyInfo(){
      var that = this;
      that.$axios
        .get(that.baseUrl+"/api/village/villageInfo", {
          params: {
            'site':window.localStorage.getItem('site_id'),
            'type1':'支部风采'
          }
        })
        .then(function(res) {
          console.log(res.data);
          if(res.data.code == 1){
            that.PartyInfo=res.data.data[0];
            that.content_obj = res.data.data[0].content;
            that.dwgk = res.data.data[0].extra[0];
            that.gzmb = res.data.data[0].extra[1];
            that.dwhdsj = res.data.data[0].extra[2];
            if(that.content_obj.children.length > 0){
              var arr = that.content_obj.children;
              that.dw_list_one = arr.slice(0,4);
            }
            //console.log(that.dw_list_one);
          }else{
            //console.log(res.data.msg);
          }
        });
    },
    getData(){
      let that = this;
      that.$axios
        .get(that.baseUrl+"/api/village/villageInfo", {
          params: {
            site:this.$route.params.site ? this.$route.params.site : window.localStorage.getItem("site_id"),
            type1:'村情概况',
          }
        })
        .then(function(res) {
          if(res.data.code==1){
            that.info= res.data.data[0];
            if(that.info.extra.length > 0){
              that.info_extra_0 = that.info.extra[0].content;
              that.info_extra_1 = that.info.extra[1].content;
              that.info_extra_2 = that.info.extra[2].content;

            }
          }
        });
    },
    getVillageInfo(){
      var that = this;
      that.$axios
        .get(that.baseUrl+"/api/village/villageInfo", {
          params: {
            'site':window.localStorage.getItem('site_id'),
            'type1':'村委风采'
          }
        })
        .then(function(res) {
          console.log(res.data);
          if(res.data.code == 1){
            that.villageInfo=res.data.data[0];
            that.content_obj_v = res.data.data[0].content;
            that.cwgk_v = res.data.data[0].extra[0];
            that.gzmb_v = res.data.data[0].extra[1];
            that.zwfc_v = res.data.data[0].extra[2];
            if(that.content_obj_v.children.length > 0){
              var arr = that.content_obj_v.children;
              that.cw_list_one = arr.slice(0,3);
              that.cw_list_two = arr.slice(3);
            }
          }else{
            //console.log(res.data.msg);
          }
        });
    },
    chooese(n){
      this.is_active = n;
    },
    getZrzz() {
      var that = this;
      that.$axios
        .get(that.baseUrl + "/api/village/villageInfo", {
          //地址是get请求地址
          params: {
            site: window.localStorage.getItem("admin_site_id"),
            type1: "村志村容"
          }
        })
        .then(function(res) {
          //当请求完成时候执行then回调函数
          console.log(res);
          if (res.data.code == 1 && res.data.data.length > 0) {
            that.crcz_content = res.data.data[0].content;
          }
        });
    },
  },
  components: {
    cTitle,
    footBar,
    cStructure,
    vStructure
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/styles/var.less";
.content_box{
  padding:10px;background:#fff;margin-top:5px;margin-bottom:5px;
  width:100%;overflow:hidden !important;
  .content{
      margin:15px;
      font-size:13px;line-height:24px;
      p img{
        width:100% !important;
      }
  }
  .ckgd{
    width:100%;height:40px;text-align:center;padding-top:15px;
    .ckgdbtn{
      padding:5px 15px;border:solid 1px #ddd;color:#777;font-size:14px;border-radius:20px;
    }
  }
}
.navbox{
  width:100%;background:#fff;display:flex;justify-content: space-around;align-items: center;
  padding-top:15px;padding-bottom:15px;
  margin-bottom:5px;
  .navitem{
    width:25%;
    text-align:center;
    .icon img{width:30px;height:30px;}
    .tit{font-size:13px;color:#666;line-height:24px;}
    .active{color:#222;font-weight:bold;}
  }
}
.tab{padding-bottom:100px;width:100%;}

.uhide{display:none;}

.ycyc5{
  overflow: hidden;
  -overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}
.tit{color:#0094ca;font-size:14px;}
.ptb10{
  padding-top:10px;
  padding-bottom:10px;
}
.dsj{
  color:#666;
}
.content1{padding:10px;font-size:16px;}
</style>
