<template>
  <div class="notice-container">
    <c-title :hide="false" :text="typename" tolink="" :totext="``"> </c-title>
    <div class="topmenu">
        <van-button plain hairline round size="small" type="success" class="mr10">全部 0</van-button>
        <van-button plain hairline round size="small" type="default" class="mr10">支部会议</van-button>
        <van-button plain hairline round size="small" type="default" class="mr10">两委会议</van-button>
        <van-button plain hairline round size="small" type="default" class="mr10">党员会议</van-button>
        <van-button plain hairline round size="small" type="default" class="mr10">村民会议</van-button>
        <van-button plain hairline round size="small" type="default" class="mr10">支部会议</van-button>
        <van-button plain hairline round size="small" type="default" class="mr10">两委会议</van-button>
        <van-button plain hairline round size="small" type="default" class="mr10">党员会议</van-button>
        <van-button plain hairline round size="small" type="default" class="mr10">村民会议</van-button>
    </div>
    <div class="countnum">共0人</div>
    <div class="content">
      <div class="list-item" @click="goDetail">
        <div class="item-1">
          <img src="../../assets/images/ganbu.png" class="avatar">
        </div>
        <div class="item-2">
          <div class="uname">刘晓</div>
          <div class="usf">村干部</div>
        </div>
        <div class="item-3">
          <div class="f12 t-gay st pl05">查看次数：1</div>
          <div class="f12 t-gay st pl05">送达时间：2023-04-18 09:00</div>
          <div class="f12 t-gay st pl05">最后查看时间：10分钟前</div>
        </div>
      </div>
      <div class="list-item" @click="goDetail">
        <div class="item-1">
          <img src="../../assets/images/ganbu.png" class="avatar">
        </div>
        <div class="item-2">
          <div class="uname">刘晓</div>
          <div class="usf">村干部</div>
        </div>
        <div class="item-3">
          <div class="f12 t-gay st pl05">查看次数：1</div>
          <div class="f12 t-gay st pl05">送达时间：2023-04-18 09:00</div>
          <div class="f12 t-gay st pl05">最后查看时间：10分钟前</div>
        </div>
      </div>
      <div class="list-item" @click="goDetail">
        <div class="item-1">
          <img src="../../assets/images/ganbu.png" class="avatar">
        </div>
        <div class="item-2">
          <div class="uname">刘晓</div>
          <div class="usf">村干部</div>
        </div>
        <div class="item-3">
          <div class="f12 t-gay st pl05">查看次数：1</div>
          <div class="f12 t-gay st pl05">送达时间：2023-04-18 09:00</div>
          <div class="f12 t-gay st pl05">最后查看时间：10分钟前</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cTitle from "../../components/title.vue";
export default {
  data() {
    return {
      typename: "公告列表"
    };
  },
  methods:{
    goDetail(){
      this.$router.push({name:'TownWorkNoticeDetail',params:{
        id:1
      }});
    }
  },
  components: {
    cTitle
  }
};
</script>

<style lang="less" scoped>
.notice-container{
  .topmenu{
    padding:10px 20px;white-space: nowrap;overflow-x: scroll;
    botton{
      display: inline-block
    }
  }
  .countnum{
    padding:0 20px;height:40px;line-height:40px;font-size:16px;color:#666;
  }
  .content{
   padding:0 20px;
   .list-item{
    padding:15px;border:solid 1px #ddd;border-radius:5px;
    display:flex;margin-bottom:15px;
    .item-1{
      width:20%;
      .avatar{
        width:40px;height:60px;
      }
    }
    .item-2{
      width:20%
    }
    .item-3{
      width:60%;border-left:solid 1px #ddd;
    }
   }
  }
}
.mr10{
    margin-right:10px;
  }
  .mb10{
    margin-bottom:10px;
  }
  .f12{
    font-size:14px;
  }
  .t-gay{
    color:#666;
  }
  .st{
    line-height:24px;
  }
  .usf{
    font-size:14px;color:green;
  }
  .uname{
    font-size:16px;color:#111;margin-bottom:10px;
  }
  .pl05{
    padding-left:15px;
  }

</style>
