<template>
  <div class="register-container">
    <div class="top">
      <img src="../assets/images/top2.png" alt="" class="topimg">
      <img src="../assets/images/topbg.png" alt="" class="topbg">
      <router-link to="/home">
        <van-icon name="wap-home" size="40" class="sy"/>
      </router-link>
    </div>
    <div class="formbox">
      <div class="zc">找回密码</div>
       <!--手机号码-->
      <van-field
        v-model="mobile"
        name="pattern"
        label="手机号码"
        placeholder="请输入手机号码"
        :rules="[{ pattern, message: '' }]"
      />

      <div class="f-item dw">
        <van-cell-group>
          <van-field v-model="code" label="验证码" placeholder="请输入手机验证码" />
        </van-cell-group>
        <van-button type="primary" size="small" class="yzm" @click="getCode" >{{code_text}}</van-button>
      </div>

      <!--登录密码-->
      <van-field
        v-model="password"
        type="password"
        name="登录密码"
        label="登录密码"
        placeholder="请输入登录密码"
        :rules="[{ required: true, message: '请填写密码' }]"
      />
      <!--确认密码-->
      <van-field
        v-model="password2"
        type="password"
        name="确认密码"
        label="确认密码"
        placeholder="请确认登录密码"
        :rules="[{ required: true, message: '请确认密码' }]"
      />


      <van-row class="ptb15">
        <van-col span="24" class="tx-c">
          <van-button round type="info" color="linear-gradient(to right, #0dbade, #00a752)" size="small" class="regbtn cbg">提交</van-button>
        </van-col>
      </van-row>

    </div>

    <van-row class="ptb15">
      <router-link to="/login">
        <van-col span="11" class="tx-r zjdl">
        登录
        </van-col>
      </router-link>
      <van-col span="2" class="tx-c" style="color:#ddd;line-height:38px;">|</van-col>
      <router-link to="/register">
        <van-col span="11" class="tx-l zjdl">
        注册
        </van-col>
      </router-link>
    </van-row>

  </div>
</template>

<script>
export default {
  data() {
    return {
      code:'',
      password:'',
      password2:'',
      mobile:'',
      pattern: /^1(3[0-9]|5[0-3,5-9]|7[1-3,5-8]|8[0-9])\d{8}$/,
      code_text:'获取验证码',
      code_issend:false,
    };
  },
  methods: {

    //获取验证码
    getCode(){
      if (this.code_issend) {
        return;
      }
      this.code_issend = true;
      let t = 10;
      let si = setInterval(() => {
        this.code_text = t + 's';
        t = t - 1;
        if (t == 0) {
          this.is_send = false;
          this.code_text = '获取手机验证码';
          clearInterval(si);
        }
      }, 1000);
    }
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/styles/var.less";
.register-container{
  height:100%;
  background:#fff;
  .top{
    height:220px;
    overflow:hidden;
    position:relative;
    z-index:2;
    .topimg{
      position:absolute;
      width:100%;height:auto;
      left:0;
      top:0;
      z-index:3;
    }
    .topbg{
      position:absolute;
      width:100%;height:auto;
      left:0;
      bottom:0;
      z-index:4;
    }
    .sy{
      position:absolute;
      left:10px;top:10px;
      color:#fff;
      z-index:5;
    }
  }
  .formbox{
    padding:20px;
    padding-top:0;
    .zc{
      font-size:16px;font-weight:bold;
      text-align:center;
      margin-bottom:20px;
      background:-webkit-linear-gradient(90deg,@primary,#0dbade);
      -webkit-background-clip:text;
      -webkit-text-fill-color:transparent;
    }
    .f-item{
      border-bottom:solid 1px #ebedf0;
      border-radius:5px;
    }
  }
}
.ptb15{padding-top:15px;padding-bottom:15px;}
.dw{position:relative;}
.yzm{position:absolute;top:6px;right:0px;}
.icon-dq{vertical-align: -3px;;}
.t-green{color:@primary}
.tx-r{text-align:right;}
.rzxy{height:20px;line-height:20px;font-size:14px;color:#666;}
.regbtn{width:90%;}
.cbg{box-shadow:0 0 5px rgba(0,0,0,0.5)}
.zjdl{
  color:@primary;font-size:14px;
}
</style>
