<template>
  <div class="release-container">
    <c-title :hide="false"
      :text="`发布`"
      tolink=""
      :totext="``">
    </c-title>
    <div class="formbox">
      <!--选择分类-->
      <van-field
        v-model="fieldValue"
        is-link
        readonly
        label="分类"
        placeholder="请选择帖子分类"
        @click="show = true"
      />

      <!--标题-->
      <van-cell-group>
        <van-field v-model="bbstitle" label="帖子标题" placeholder="请输入帖子标题" />
      </van-cell-group>
      <!--正文-->
      <van-field
        v-model="message"
        rows="5"
        autosize
        label="帖子正文"
        type="textarea"
        maxlength="300"
        placeholder="请输入帖子正文"
        show-word-limit
      />
      <!--配图-->
      <van-row class="upimg">
        <van-col span="24">图片上传</van-col>
      </van-row>
      <van-row class="upimg">
        <van-col span="24">
          <van-uploader v-model="fileList" multiple />
        </van-col>
      </van-row>
      <!--提交按钮-->
      <van-row class="subbox">
        <van-col span="24" class="tx-r">
          <van-button type="primary">提交</van-button>
        </van-col>
      </van-row>

    </div>

    <van-popup v-model="show" round position="bottom">
      <van-cascader
        v-model="cascaderValue"
        title="请选择帖子分类"
        :options="options"
        @close="show = false"
        @finish="onFinish"
      />
    </van-popup>
    <foot-bar></foot-bar>
  </div>
</template>

<script>
import cTitle from '../components/title.vue';
import footBar from '../components/foot.vue';
export default {
  data() {
    return {
      show: false,
      fieldValue: '',
      cascaderValue: '',
      bbstitle:'',
      message:'',
      // 选项列表，children 代表子选项，支持多级嵌套
      options: [
        {
          text: '村务公开',
          value: '1',
          children: [{ text: '村委通知', value: '4' },{ text: '两委日记', value: '4' }],
        },
        {
          text: '村内活动',
          value: '2',
          children: [{ text: '田间见闻', value: '3' }],
        },
      ],
      fileList: [
        { url: 'https://img01.yzcdn.cn/vant/leaf.jpg' },
        // Uploader 根据文件后缀来判断是否为图片文件
        // 如果图片 URL 中不包含类型信息，可以添加 isImage 标记来声明
        { url: 'https://cloud-image', isImage: true },
      ],
    };
  },
  methods: {
    // 全部选项选择完毕后，会触发 finish 事件
    onFinish({ selectedOptions }) {
      this.show = false;
      this.fieldValue = selectedOptions.map((option) => option.text).join('/');
    },
  },
  components: {
    cTitle,
    footBar
  },
};
</script>

<style lang="less" scoped>

.release-container{
  min-height:100vh;
  padding-bottom:60px;
  padding:10px;
  .formbox{
    padding-bottom:20px;
    .upimg{
      padding:10px 20px;background:#fff;color:#646566;font-size:14px;
    }
    .subbox{
      padding:20px 20px;background:#fff;
      .subbtn{
        margin-top:20px;
      }
    }

  }

}
.tx-r{
  text-align:right;
}
</style>
