<template>
  <div class="comment-container">
      <div class="dy-pinglun">
        <div class="comitem van-multi-ellipsis--l2" v-for="item in list" :key="item.id">
          <span class="com_uname"  :style="`color:`+bascC">{{item.userinfo ? item.userinfo.nickname :''}}</span>
          <span class="com_sf"></span>
          <span class="com_content">
            {{item.content}}
          </span>
        </div>

      </div>
  </div>
</template>

<script>
export default {
  props:['comment_list','basecolor','is_count'],
  data(){
    return{

    };
  },
  computed:{
    list:function(){
      var newList = this.comment_list;
      if(this.is_count == 0){
        newList = [];
      }else if(this.is_count > 0 && this.is_count < 99){
        newList = this.comment_list.slice(0,this.is_count);
      }
      return newList;
    },
    bascC:function(){
      var bcolor = '#33cc66';
      if(this.basecolor){
        bcolor = this.basecolor;
      }
      return bcolor;
    }
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/styles/var.less";
.comment-container{
    .dy-pinglun{
      float:left;
      font-size:14px;
      line-height:0.4rem;
      padding:5px;
      background:#f5f5f5;
      .comitem{
        margin-bottom:6px;
        display:inline-block;
        .com_uname{color:@jdz;}
        .com_sf{color:@lightWords;}
        .com_content{color:@words;}
      }
    }
    .dy-ck-more{
      width:100%;
      display:block;
      float:left;
      font-size:14px;
      text-align:center;
      color:@lightWords;
      padding:5px 0px 10px;
      background:#f5f5f5;
    }
}
</style>
