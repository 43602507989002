<template>
  <div class="detail-container">
    <c-title :hide="false"
      :text="`招聘`"
      tolink=""
      :totext="``">
    </c-title>
    <div class="centbox mb10">
      <div class="lineone">
        <van-button plain type="primary"  size="small" class="mr05">企业招聘</van-button>
        <span class="tit1 mr05">小车司机</span><span class="tit2">5000+</span>
      </div>
      <div class="linetwo">
        5000+/1~3年/年龄：20岁以上/两年以上驾龄/性别：男/人数：20
      </div>
      <div class="linethree">
        <span class="zwyh">职位诱惑：</span>
        <van-button type="default" size="small" class="mr05 mb05">五险一金</van-button>
        <van-button type="default" size="small" class="mr05 mb05">包吃包住</van-button>
        <van-button type="default" size="small" class="mr05 mb05">带薪年假</van-button>
        <van-button type="default" size="small" class="mr05 mb05">技能培训</van-button>
        <van-button type="default" size="small" class="mr05 mb05">环境好</van-button>
      </div>
    </div>
    <div class="centbox">
      <div class="top">
        <div class="left">
          <div class="tit1">招聘C1司机</div>
          <div class="btn2">
            <van-button plain type="primary"  size="small">企业招聘</van-button>
          </div>
          <div class="tit3">已有621人浏览</div>
        </div>
        <div class="right">
          <img src="../../assets/images/ganbu.png" class="usertx">
        </div>
      </div>
      <div class="bottom">
        <div class="bohao">
          拨号（张新力）
        </div>
      </div>
      <div class="mz">
        免责声明：本平台不对任何人做任何形式的担保，所有信息仅供参考，不承担由此产生的任何民事或法律责任。
      </div>
    </div>

    <div class="centbox mt10">
      <div class="tit4">职位描述</div>
      <div class="txt">
        <p class="tx-c"><van-button type="default" size="small" class="mr05 mb05">小车司机</van-button></p>
        今天开始招聘男司机20名（试车员和转库员工都是厂内开车），C1驾照及以上，两年以上驾龄，年龄要求18-49周岁，名额有限，抓紧时间报名，本次司机符合条件的确保入职，
        工资19元/小时，综合工资5000元以上。
      </div>
      <div class="tit4">企业简介</div>
      <div class="txt">
        户县比亚迪十一部总装车间，今天开始招聘男司机20名（试车员和转库员工都是厂内开车），C1驾照及以上，两年以上驾龄，年龄要求18-49周岁，名额有限，抓紧时间报名，本次司机符合条件的确保入职，
        工资19元/小时，综合工资5000元以上。
      </div>
      <div class="tit4">企业图片</div>
      <div class="txt">
        <img src="../../assets/images/zhaoping.png" class="qytp">
      </div>
      <div class="tit4">工作地址</div>
      <div class="txt flex">
        <div class="txt-left">
          <van-icon name="location" color="#3b8ede" size="36"/>
        </div>
        <div class="txt-right">
          <div class="txt1">宁夏吴忠市利通区</div>
          <div class="txt2">
            汽车城比亚迪有限公司
          </div>
        </div>
      </div>
      <div class="tit4">全部评价 3</div>
      <div class="txt">
        <div class="flex mtb15">
          <div class="com_tx">
            <img src="../../assets/images/user.png" class="tx">
          </div>
          <div class="com_info">
            <div class="info_top"><span class="com_name">孔敏丽</span><span class="com_sf">认证村民</span></div>
            <div class="info_two">2022-03-30 20:00</div>
            <div class="info_three">在哪里？是吴忠市内吗？</div>
          </div>
          <div class="com_zan">
            <div class="mt05"><van-icon name="good-job-o" size="22"/></div>
            <div class="zan_num">赞 0</div>
          </div>
        </div>
        <div class="flex mtb15">
          <div class="com_tx">
            <img src="../../assets/images/user.png" class="tx">
          </div>
          <div class="com_info">
            <div class="info_top"><span class="com_name">孔敏丽</span><span class="com_sf">认证村民</span></div>
            <div class="info_two">2022-03-30 20:00</div>
            <div class="info_three">在哪里？是吴忠市内吗？</div>
          </div>
          <div class="com_zan">
            <div class="mt05"><van-icon name="good-job-o" size="22"/></div>
            <div class="zan_num">赞 0</div>
          </div>
        </div>
        <div class="flex mtb15">
          <div class="com_tx">
            <img src="../../assets/images/user.png" class="tx">
          </div>
          <div class="com_info">
            <div class="info_top"><span class="com_name">孔敏丽</span><span class="com_sf">认证村民</span></div>
            <div class="info_two">2022-03-30 20:00</div>
            <div class="info_three">在哪里？是吴忠市内吗？</div>
          </div>
          <div class="com_zan">
            <div class="mt05"><van-icon name="good-job-o" size="22"/></div>
            <div class="zan_num">赞 0</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cTitle from '../../components/title.vue';
export default {
  components: {
    cTitle
  },
};
</script>

<style lang='less' scoped>
.view{height:100%;background:linear-gradient(to bottom,#effaeb,#5eb06e);}
.detail-container{
  padding:10px;
  .centbox{background:#fff;border-radius:10px;padding:10px;}
}
.lineone{display:flex;align-items: center;}
.mr05{margin-right:5px;}
.tit1, .tit2{height:32px;line-height:32px;font-size:14px;font-weight:bold;color:#333;}
.linetwo{line-height:24px;font-size:14px;color:#444;padding-top:10px;padding-bottom:10px;}
.linethree{display:flex;align-items: center;flex-wrap: wrap;}
.mt05{margin-top:5px;}
.mb05{margin-bottom:5px;}
.mb10{margin-bottom:10px;}
.mt10{margin-top:10px;}
.zwyh{font-size:13px;color:#666;padding-bottom:5px;}
.top{
  display:flex;justify-content: space-between;
  .left{width:75%;}
  .right{width:25%;text-align:right;}
}
.tit3{color:#888;font-size:13px;height:30px;line-height:30px;}
.usertx{width:60px;height:60px;border-radius:50%;box-shadow: 0px 1px 2px 1px rgba(0,0,0,0.1);}
.bohao{margin-top:10px;margin-bottom:10px;background:#5eb06e;height:36px;line-height:36px;text-align:center;font-size:14px;color:#fff;border-radius:10px;}
.mz{font-size:14px;color:#777;line-height:20px;margin-top:15px;}
.tit4{
  height:50px;line-height:50px;text-align:center;font-size:14px;font-weight:bold;color:#222;
  background:url(../../assets/images/linebg.png) no-repeat;background-size:80% 80%;background-position:center center;
}
.txt{color:#666;font-size:13px;margin-bottom:15px;}
.qytp{width:100%;}
.flex{display:flex;justify-content: space-between;}
.txt-left{width:15%;}
.txt-right{width:85%;}
.txt1{font-size:14px;color:#222;}
.txt2{font-size:14px;color:#777}
.com_tx{width:15%;}
.tx{width:40px;height:40px;border-radius:5px;}
.com_info{width:70%;}
.com_zan{width:15%;}
.com_name{font-size:14px;color:#222;font-weight:bold;}
.com_sf{font-size:14px;color:#888;margin-left:3px;}
.info_two{font-size:14px;color:#888;margin-top:10px;}
.info_three{font-size:13px;color:#333;line-height:20px;}
.zan_num{font-size:14px;color:#888;}
.mtb15{margin-top:15px;margin-bottom:15px;}
</style>
