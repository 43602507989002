<template>
  <div class="postslist-container">
    <c-title :hide="false"
      :text="`帖子详情页`"
      tolink=""
      :totext="``">
    </c-title>
    <div class="top c-wh">
      <van-row>
        <van-col span="4">
          <img :src="userinfo.avatar" alt="" class="useravatar">
        </van-col>
        <van-col span="20">
          <div class="fbuser">
            <img src="../../assets/images/dang.png" alt="" class="partyuser">
            发布人：{{userinfo.nickname}}
            <span class="guanzhu active">已关注</span>
          </div>
          <div class="subtxt">{{newsDetail.createtime}} <span>来自{{userinfo.province}}{{userinfo.city}}{{userinfo.area}}</span></div>
        </van-col>
      </van-row>
    </div>
    <div class="postsbox mb05 c-wh">
      <div class="bbs-title">
        {{newsDetail.title}}
      </div>
      <div  class="extra_style" v-for="(ite,i) in newsDetail.new_extra" :key="i">{{ite}}</div>
      <div class="bbs-content" v-html="newsDetail.content">

      </div>
      <div class="bbs-image" v-if="newsDetail.cover_arr">
        <img :src="item" alt="" v-for="(item, i) in newsDetail.cover_arr" :key="i">
      </div>
      <div class="joinbox" v-if="join_list.length > 0">
        <p>参与人：</p>
        <!-- <img :src="item.avatar" class="join_tx" v-for="(item,index) in join_list" :key="index"/> -->
        <span class="join_name" v-for="(item,index) in join_list" :key="index">{{item.name}}，</span>
      </div>
    </div>
    <div class="comment c-wh">
      <van-row>
        <van-col span="24" class="tx-l p10">
          <span class="liulan">浏览 {{newsDetail.views}}</span>
          <span class="pinlun">评论 {{newsDetail.comments}}</span>
          <span class="zannum">赞 {{newsDetail.ups}}</span>

        </van-col>
      </van-row>

    </div>
    <van-list
      v-model="loading"
      :finished="finished"
      :immediate-check="false"
      finished-text="没有更多了"
      @load="onLoad"
      :offset="10"
    >
      <comment-item :comment="citem" v-for="citem in commentList" :key="citem.id"></comment-item>
    </van-list>

    <van-goods-action>
      <van-goods-action-icon icon="home-o" text="首页" @click="goHome" />
      <van-goods-action-icon icon="edit" text="发布" @click="onClickIcon('/fabu')" />
      <van-goods-action-icon icon="user-o" text="我的" @click="onClickIcon('/my')" />
      <van-goods-action-button
        type="danger"
        text="我要评论"
        @click="showPopup"
      />
    </van-goods-action>

    <van-popup v-model="show" round  position="bottom" closeable close-icon="close" :style="{ height: '40%' }" >
      <div class="p10">

        <div class="poputbox">
          <van-field
            v-model="message"
            rows="8"
            autosize
            label="评论"
            type="textarea"
            maxlength="300"
            placeholder="请输入您的评论"
            show-word-limit
          />
          <van-row class="subbox">
            <van-col span="24" class="tx-c">
                <div class="subbtn" @click="submit">提交评论</div>
            </van-col>
          </van-row>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import cTitle from '../../components/title.vue';
import CommentItem from '../../components/comment.vue';

export default {
  data(){
    return {
      newsDetail:'',
      userinfo:'',
      commentList:[],
      page:1,
      limit:10,
      loading: false,
      total: 0,//总共的数据条数
      finished: false,
      id:'',
      join_list:[],
      show:false,
      is_click: false,
      message:'',
      fileList: [],
      img_arr:''
    };
  },
  components: {
    cTitle,
    CommentItem
  },
  created() {
    const id = this.$route.params.id;
    this.id = id;
    this.getNewsDetail();
    this.getroadList();
    this.getJoinList();
  },
  methods:{
    goHome(){
      var type = window.localStorage.getItem('type');
      var site = this.newsDetail.site;
      var user_town_id = window.localStorage.getItem('user_town_id');
      var user_village_id = window.localStorage.getItem('user_village_id');
      if(type == '县'){
        if(user_town_id){
          this.$router.push({name:'TownHome',params:{site:user_town_id}});
        }else{
          this.$router.push({name:'Login'});
        }
      }else if(type == '镇'){
        if(user_village_id){
          this.$router.push({name:'Home',params:{site:user_village_id}});
        }else{
          this.$router.push({name:'Login'});
        }
      }else if(type == '村'){
        this.$router.push({name:'TownHome',params:{site:user_town_id}});
      }else{
        this.$router.push({name:'Home',params:{
          site:site
        }});
      }
    },
    getroadList(){
      let that = this;
      that.$axios
        .get(that.baseUrl+"/api/comments/list", {
          params: {
            news_id:that.$route.params.id,
            page:that.page,
            limit:that.limit
          }
        })
        .then(function(res) {
          //console.log(res);
          if(res.data.code==1){
            that.loading = false;
            that.total = res.data.data.count;

            var list = res.data.data.list;

            if (list == null || list.length === 0) {
              // 加载结束
              that.finished = true;
              return;
            }
            // 将新数据与老数据进行合并
            that.commentList = that.commentList.concat(list);

            //如果列表数据条数>=总条数，不再触发滚动加载
            if (that.commentList.length >= that.total) {
              that.finished = true;
            }

          }
        });
    },
    getNewsDetail(){

      let that = this;
      that.$axios
        .get(that.baseUrl+"/api/news/detail", {//地址是get请求地址
          params: {
            news_id:that.id,
          }
        })
        .then(function(res) {//当请求完成时候执行then回调函数
          console.log(res);
          if(res.data.code==1){
            that.newsDetail= res.data.data.news;
            that.userinfo= res.data.data.userinfo;
            if(that.newsDetail.extra){
              var objet = JSON.parse(that.newsDetail.extra);
              //console.log(objet);
              var extra_item = [];
              for (let key in objet) {
                const obj = objet[key];
                extra_item.push(obj);
              }
              that.newsDetail.new_extra = extra_item;

            }

          }
        });
    },
    getJoinList(){
      var that = this;

      that.$axios
        .get(that.baseUrl+"/api/join/joinList", {
          params: {
            news_id:that.$route.params.id
          }
        })
        .then(function(res) {
          //console.log(res.data.code);
          if(res.data.code == 1){
            that.join_list = res.data.data.list;
          }
        });
    },
    onClickIcon(name){
      this.$router.push(name);
    },
    onClickButton(id){
      this.$router.push({name:'PostsComment',params:{id:this.$route.params.id}});
    },
    onLoad() {
      this.page++;
      this.getroadList();
    },
    showPopup() {
      this.show = true;
    },
    submit(){
      this.is_click = true;
      var message = this.message;
      if(message == ''){
        that.$toast('评论内容不能为空');
        this.is_click = false;
        return false;
      }
      var that = this;
      that.$axios
        .get(that.baseUrl+"/api/comments/sendComment", {//地址是get请求地址
          params: {
            news_id:that.id,
            content:that.message,
            replay_userid:localStorage.getItem("user_id"),
            comment_id:0,
            imgurl:''
          }
        })
        .then(function(res) {//当请求完成时候执行then回调函数
          console.log(res);
          if(res.data.code == 1){
            that.$toast(res.data.msg);
            that.message='';
            that.show=false;
            setTimeout(function(){
              that.$router.go(0);
            });

          }else{
            that.$toast(res.data.msg);
            that.is_click=true;
            return false;

          }
        });


    }

  }
};
</script>

<style lang="less" scoped>
@import "~@/assets/styles/var.less";
.view{
  height:100%;
}
.joinbox{
  color:#888;font-size:14px;
  display:flex;
  flex-direction:row;
  justify-content: flex-start;
  flex-wrap:wrap;
  align-items: center;
}
.join_tx{
  width:30px;height:30px;border-radius:50%;margin-right:5px;
}
.join_name{color:@primary}
.postslist-container{
  padding-bottom:55px;

  .top{
    padding:10px 10px 6px;
    border-bottom:solid 1px #f8f8f8;
    .useravatar{
      width:44px;
      height:44px;
      border-radius:50%;
      display:block;
    }
    .fbuser{line-height:24px;font-size:14px;color:#333;}
    .subtxt{line-height:20px;font-size:14px;color:#888;}
  }
  .postsbox{
    padding:10px;
    .bbs-title{
      width:100%;
      font-size:16px;
      display: inline-block;
      color:#333;text-align:center;padding:10px;line-height:20px;
    }
    .bbs-content{
      width:100%;
      display: inline-block;
      color:#333;
      line-height:24px;
      font-size:16px;
    }
    .bbs-image{
      width:100%;
      text-align:center;
      img{
        width:96%;
        height:auto;
        margin:2%;
      }
    }
  }
}
.mb05{
  border-bottom:solid 5px #f5f5f5;
}
.tx-l{text-align:left;}
.tx-r{text-align:right;}
.partyuser{width:16px;height:16px;border-radius:50%;vertical-align: middle;}
.guanzhu{
  float:right;
  font-size:8px;
  line-height:18px;
  padding:0px 10px;
  border:solid 1px @primary;
  color:@primary;
  border-radius:15px;
}
.guanzhu.active{
  background:@primary;
  color:#fff;
}
.liulan{
  color:@lightWords;
  font-size:14px;
  margin-right:15px;
}
.pinlun{
  color:@primary;
  font-size:14px;
  margin-right:15px;
}
.zannum{
  color:@lightWords;
  font-size:14px;
}
.p10{padding:10px;}
.blockline{
  height:10px;background:#f5f5f5;
}
.c-wh{background:#fff;}
.extra_style{height:26px;line-height:26px;color:#333;}
.p10{
  padding:20px;
}
.poputtit{
  font-size:14px;color:#000;font-weight:bold;margin-bottom:15px;
}
.poputbox{
  font-size:14px;color:#333;
}
.subbox{
  background:#fff;padding-top:10px;
}
.subbtn{
  width:90%;height:40px;line-height:40px;font-size:15px;color:#fff;background:@primary;
  border-radius:15px;margin:0px auto;
}
</style>
