<template>
  <div class="comment-container">
    <c-title :hide="false"
      :text="`发布评论`"
      tolink=""
      :totext="``">
    </c-title>
    <div class="formbox">

      <!--正文-->
      <van-field
        v-model="message"
        rows="8"
        autosize
        label="评论"
        type="textarea"
        maxlength="300"
        placeholder="请输入您的评论"
        show-word-limit
      />
      <!-- <van-row class="upimg">
        <van-col span="24">图片上传</van-col>
      </van-row>
      <van-row class="upimg">
        <van-col span="24">
          <van-uploader v-model="fileList" multiple :after-read="afterRead"/>
        </van-col>
      </van-row> -->

      <!--提交按钮-->
      <van-row class="subbox">
        <van-col span="24" class="tx-c">
            <div class="subbtn" @click="submit">提交评论</div>
        </van-col>
      </van-row>

    </div>

  </div>
</template>

<script>
import cTitle from '../../components/title.vue';

export default {
  data() {
    return {
      message:'',
      is_click: false,
      news_id:'',
      fileList: [],
      img_arr:''
    };
  },
  created(){
    // this.news_id = this.$router.params.id ? this.$router.params.id : 0;
    console.log(this.$router.params);
  },
  methods: {
    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      var that = this;
      if(file.length>0){
        for (var i=0;i<that.fileList.length;i++){
          var img_url = that.fileList[i].content;
          let params = new URLSearchParams();
          params.append('base64', img_url);
          that.$axios
            .post(that.baseUrl+"/api/common/uploadBase64", {
              params:{
                base64:img_url
              }
            })
            .then(function(res) {//当请求完成时候执行then回调函数
              if(res.data.code == 1){
                that.img_arr += res.data.data.fullurl+',';
              }
            });
        }
      }
    },
    submit(){
      this.is_click = true;
      var message = this.message;
      if(message == ''){
        that.$toast('评论内容不能为空');
        this.is_click = false;
        return false;
      }
      var that = this;
      that.$axios
        .get(that.baseUrl+"/api/comments/sendComment", {//地址是get请求地址
          params: {
            news_id:that.$route.params.id,
            content:that.message,
            replay_userid:localStorage.getItem("user_id"),
            comment_id:0,
            imgurl:''
          }
        })
        .then(function(res) {//当请求完成时候执行then回调函数
          console.log(res);
          if(res.data.code == 1){
            that.$toast(res.data.msg);
            setTimeout(function(){
              that.$router.go(-1);
            },2000);

          }else{
            that.$toast(res.data.msg);
            that.is_click=true;
            return false;

          }
        });


    }
  },
  components: {
    cTitle
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/styles/var.less";
.upimg{
  padding:10px 20px;background:#fff;color:#646566;font-size:14px;
}
.comment-container{
  .formbox{
    background:#fff;
    .subbox{
      padding:20px 20px;
    }
  }
}
.tx-r{
  text-align:right;
}
.subbox{
  background:#fff;padding-top:30px;
}
.subbtn{
  width:90%;height:40px;line-height:40px;font-size:15px;color:#fff;background:@primary;
  border-radius:15px;margin:0px auto;
}

</style>
