<template>
  <div class="branchstyle-container">
    <c-title :hide="false"
      :text="category_name"
      tolink=""
      :totext="``">
    </c-title>
    <home-slide></home-slide>
    <scroll-menu :menuList="menuList" @getMenuId='chooeseMenuId'></scroll-menu>
    <van-list
      v-model="loading"
      :finished="finished"
      :immediate-check="false"
      finished-text="没有更多了"
      @load="onLoad"
      :offset="10"
    >
    <home-dynamic
      v-for="(item,i) in newsList"
      :key="i"
      :news="item"
      :is_count="5"
    ></home-dynamic>
    </van-list>

    <foot-bar></foot-bar>
  </div>
</template>

<script>
import cTitle from '../components/title.vue';
import HomeSlide from '../components/home/slide.vue';
import ScrollMenu from '../components/ScrollMenu.vue';
import homeDynamic from '../components/home/dynamic.vue';
import footBar from '../components/foot.vue';

export default {
  data() {
    return {
      value: '',
      newsList:[],
      menuList: [
        {
          children: [],
          extra: "",
          icon_image: "",
          id: '',
          style: "",
          title: "全部",
        }
      ],
      type2:'',
      page:1,
      limit:10,
      loading: false,
      total: 0,//总共的数据条数
      finished: false,
      category_name:'',//分类名称
      category_id:'',
      params_id:''
    };
  },
  created(){
    this.category_id=this.$route.params.id;
    this.params_id=this.$route.params.id;
    this.getCategory();
    this.getroadList();
  },
  methods: {

    chooeseMenuId(cat_name,cat_id,style){
      if(cat_name == '全部'){
        style=1;
      }
      if(style == 1 ){
        if(cat_name == '全部'){
          this.category_id=this.params_id;
        }else{
          this.category_id=cat_id;
        }
        console.log(this.category_id);
        this.page = 1;
        this.newsList = [];
        this.getroadList();

      }else{
        var routername ='';
        if(style == 3){
          //微心愿类型
          routername = "MicroWishList";
        }else if(style == 5){
          //书记公开信
          routername = "PartyWork";
        }else if(style == 6){
          //书记信箱
          routername = "ShuJiEmail";
        }else if(style == 8){
          //志愿活动
          routername = "VoluntaryList";
        }else if(style == 9){
          //支部风采
          routername = "BranchStyle_p";
        }
        this.$router.push(
          {
            name:routername,
            params:{
              id:cat_id
            }
          });

      }



    },
    getCategory(){
      var that = this;
      that.$axios
        .get(that.baseUrl+"/api/news/categoryDetail", {
          params: {
            'id':that.category_id,
          }
        })
        .then(function(res) {
          if(res.data.code == 1){
            that.category_name = res.data.data.title;
            if(res.data.data.children.length > 0){
              that.menuList = that.menuList.concat(res.data.data.children);
            }else{
              that.menuList = [];
            }
          }else{
            //console.log(res.data.msg);
          }
        });
    },
    onLoad() {
      this.page++;
      this.getroadList();
    },
    getroadList(){
      //console.log(this.category_id);
      let that = this;
      that.$axios
        .get(that.baseUrl+"/api/news/list", {
          params: {
            site:window.localStorage.getItem('site_id'),
            category_id:that.category_id,
            all_site:1,
            page:that.page,
            limit:that.limit
          }
        })
        .then(function(res) {
          if(res.data.code==1){
            that.loading = false;
            that.total = res.data.data.count;

            var list = res.data.data.list;
            if(list.length > 0){
              list.forEach(item => {
                if(item.extra){
                  var objet = JSON.parse(item.extra);
                  //console.log(objet);
                  var extra_item = [];
                  for (let key in objet) {
                    const obj = objet[key];
                    extra_item.push(obj);
                  }
                  item.new_extra = extra_item;

                }
              });
            }

            if (list == null || list.length === 0) {
              // 加载结束
              that.finished = true;
              return;
            }
            // 将新数据与老数据进行合并
            that.newsList = that.newsList.concat(list);

            //如果列表数据条数>=总条数，不再触发滚动加载
            if (that.newsList.length >= that.total) {
              that.finished = true;
            }

          }
        });
    },
    onSearch(val) {
      alert(val);
    },
    onCancel() {
      alert('取消');
    },
  },
  components: {
    cTitle,
    HomeSlide,
    ScrollMenu,
    homeDynamic,
    footBar,
  },
};
</script>

<style lang="less" scoped>
.home-container{
  padding-bottom:50px;
}
.van-notice-bar{
  background:none;
  color:#333;
}
.dwgk{
  padding:10px;
  margin:10px 0 10px;
  background:#fff;
}
.dwtitle{
  margin-top:5px;
  margin-bottom:5px;
  font-size:15px;
  font-weight:bold;
  color:#ed1b23;
  padding-left:10px;
  border-left:solid 3px #ed1b23;
}
.dwcontent{
  margin-top:10px;
  margin-bottom:10px;
  color:#666;
  font-size:14px;
}
.dwckgd{
  color:#666;
  font-size:14px;
  padding-top:10px;
}
.ptb10{
  padding-top:10px;
  padding-bottom:10px;
}
.line30{
  line-height:30px;
}
.dsj{
  color:#666;
}
</style>
