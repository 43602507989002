<template>
  <div>
    <c-title :hide="false" text="新增管理人员" tolink="" :totext="``"> </c-title>
    <div class="addtype">
      新增人员：
      <van-button
        :type="usertype == 1 ? 'primary' : 'default'"
        size="small"
        class="mr20"
        @click="qhtype(1)"
        >村委</van-button
      >
      <van-button :type="usertype == 2 ? 'primary' : 'default'" size="small" @click="qhtype(2)"
        >党委</van-button
      >
    </div>
    <div class="userform">
      <div class="userform-left">
        <van-uploader image-fit="cover" :after-read="afterRead">
          <img class="uploadImg" :src="img_url" />
        </van-uploader>
      </div>
      <div class="userform-right">
        <van-field v-model="username" label="姓名" />
        <van-cell is-link :title="jobname" @click="show = true" />

        <van-field v-model="mobile" label="电话" />
        <div class="flex">
          <van-radio-group v-model="party_radio" direction="horizontal" class="mtb10">
            <van-radio name="1" checked-color="#07c160" class="mr20">党员</van-radio>
            <van-radio name="2" checked-color="#07c160">不是党员</van-radio>
          </van-radio-group>
        </div>
      </div>
    </div>
    <div class="btn">
      <van-button type="primary" class="mr20" @click="submit">确定</van-button>
      <van-button type="default" @click="reset">清除</van-button>
    </div>
    <van-action-sheet v-model="show" :actions="actions" @select="onSelect" />
  </div>
</template>

<script>
import cTitle from "../../components/title.vue";
import { Toast } from "vant";
export default {
  data() {
    return {
      id:'',
      usertype: 1,
      username: "",
      img_url: require("../../assets/images/touxiang_kong.png"),
      jobname: "职务",
      department_id: "",
      mobile: "",
      party_radio: "1",
      show: false,
      villageJobList: [],
      partyJobList: [],
      actions: []
    };
  },
  created() {
    //获取职务列表
    this.getVillageJob();
    this.getPartyJob();
    if(this.$route.params.id){
      this.id = this.$route.params.id;
      this.getUserInfo();
    }
  },
  methods: {
    getUserInfo(){
      var that = this;
      that.$axios
        .get(that.baseUrl + "/api/village_manager/departmentMemberInfo", {
          params: {
            id: that.id
          }
        })
        .then(function(res) {
          console.log(res);
          if (res.data.code == 1) {
            var info = res.data.data;
            that.usertype=info.organise_id;
            that.username=info.nickname;
            that.img_url= info.avatar;
            that.jobname= info.position;
            that.department_id= info.department_id;
            that.mobile= info.mobile;
            that.party_radio= info.is_party_member;
          }
        });
    },
    getVillageJob() {
      var that = this;
      that.$axios
        .get(that.baseUrl + "/api/village_manager/department", {
          //地址是get请求地址
          params: {
            organise_id: 1
          }
        })
        .then(function(res) {
          //当请求完成时候执行then回调函数
          // console.log(res);
          if (res.data.code == 1) {
            that.villageJobList = res.data.data;
            that.actions = that.villageJobList;
          }
        });
    },
    getPartyJob() {
      var that = this;
      that.$axios
        .get(that.baseUrl + "/api/village_manager/department", {
          params: {
            organise_id: 2
          }
        })
        .then(function(res) {
          // console.log(res);
          if (res.data.code == 1) {
            that.partyJobList = res.data.data;
          }
        });
    },

    qhtype(n) {
      this.usertype = n;
      if (n == 1) {
        this.actions = this.villageJobList;
      } else {
        this.actions = this.partyJobList;
      }
    },
    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      // file.status = 'uploading';
      // file.message = '上传中...';
      var that = this;
      that.img_arr = [];
      var img_url = file.content;
      that.$axios
        .post(that.baseUrl + "/api/common/uploadBase64", {
          params: {
            base64: img_url
          }
        })
        .then(function(res) {
          if (res.data.code == 1) {
            that.img_url = res.data.data.fullurl;
            // console.log(that.img_url);
          }
        });
    },
    onSelect(item) {
      // 默认情况下点击选项时不会自动收起
      // 可以通过 close-on-click-action 属性开启自动收起
      this.show = false;
      // Toast(item.name);
      this.jobname = item.name;
      this.department_id = item.id;
    },
    reset() {
      this.username = "";
      this.img_url = require("../../assets/images/touxiang_kong.png");
      this.jobname = "职务";
      this.department_id = "";
      this.mobile = "";
      this.party_radio = "1";
    },
    submit() {
      if (this.username == "") {
        Toast("请输入姓名");
      }
      if (this.department_id == "") {
        Toast("请选择职务");
      }
      if (this.mobile == "") {
        Toast("请输入手机号");
      }
      var that = this;
      that.$axios
        .post(that.baseUrl + "/api/village_manager/editDepartmentMember", {
          id:that.id ? that.id : '',
          department_id: that.department_id,
          position:that.jobname,
          mobile: that.mobile,
          nickname: that.username,
          avatar: that.img_url,
          is_party_member:that.party_radio,
        })
        .then(function(res) {
          //当请求完成时候执行then回调函数
          Toast(res.data.msg);
        });
    }
  },
  components: {
    cTitle
  }
};
</script>

<style lang="less" scoped>
.uploadImg {
  width: 80px;
  height: 100px;
}
.addtype {
  padding: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.mr20 {
  margin-right: 20px;
}
.userform {
  margin: 0 20px;
  background: #fff;
  padding: 10px;
  border-radius: 2px;
  display: flex;
  justify-content: space-between;
  .userform-left {
    width: 30%;
    padding-top: 15px;
  }
  .userform-right {
    width: 70%;
  }
  .mtb10 {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
