import Vue from 'vue';
import Vant from 'vant';
import { Toast,Empty,Step, Steps } from 'vant';

import App from './App.vue';
import router from './router';
import store from './store';
import 'lib-flexible';
import 'vant/lib/index.css';

import VueQuillEditor from 'vue-quill-editor';
// require styles
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
Vue.use(VueQuillEditor, {
  placeholder: '请输入正文内容',
});

Vue.use(Vant);
Vue.use(Toast);
Vue.use(Empty);
Vue.use(Step);
Vue.use(Steps);
Vue.config.productionTip = false;


var axios = require('axios');//引用axios
axios.interceptors.request.use((request) => {

  if( request.method == 'post'){ // 判断请求是否为post请求
    // request.headers['Content-Type'] = 'application/x-www-form-urlencoded'; // 通过在请求头内加入Content-Type解决
    request.headers['Content-Type'] = 'application/json';
  }
  if (localStorage.Authorization) {  // 判断是否存在token，如果存在的话，则每个http header都加上token
    request.headers.token = localStorage.Authorization;
  }
  //再发送给后台
  return request;

}, function (error) {
  return Promise.reject(error);
});
Vue.prototype.$axios = axios;
// http request 拦截器


// 注册全局指令
import vLoading from "./directives/loading";
import vLazy from "./directives/lazy";
Vue.directive("loading", vLoading);
Vue.directive("lazy", vLazy);

Vue.prototype.baseUrl = process.env.NODE_ENV==="production"?"https://canlian2.it-zx.com":"";
Vue.prototype.timeout = 20000;

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');
