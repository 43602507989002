<template>
  <div class="home-container">
    <div class="welcome">
      {{villageInfo.site}}
      <van-icon name="map-marked" class="selectsite" size="20" @click="goSelectSite"/>
    </div>
    <top-bar is_active='jingxuan'></top-bar>
    <home-slide :banner="banner"></home-slide>
    <townhome-datatj :tjData="tjData"></townhome-datatj>
    <!-- <div class="menu-container">
      <router-link tag="div" class="menu-item" to="/party-affairs">
        <img src="../assets/images/sy01.png" class="menu-icon"/>
        <p class="pt02 fb t-sbla">党建动态</p>
      </router-link>
      <div class="menu-item">
        <img src="../assets/images/sy07.jpg" class="menu-icon"/>
        <p class="pt02 fb t-sbla">政策要闻</p>
      </div>
      <div class="menu-item">
        <img src="../assets/images/sy08.jpg" class="menu-icon"/>
        <p class="pt02 fb t-sbla">乡村振兴</p>
      </div>
      <div class="menu-item">
        <img src="../assets/images/sy09.jpg" class="menu-icon"/>
        <p class="pt02 fb t-sbla">学新知</p>
      </div>

    </div> -->
    <div class="menu-container">
      <div class="menu-item" v-for="(item,index) in menu" :key="index" @click="toList(item)">
        <img :src="item.icon_image2" class="menu-icon"/>
        <p class="pt02 fb t-sbla">{{item.title}}</p>
      </div>
    </div>
    <countyhome-villageslide :list_v="list_v" @toDetail="gotoDeatil"></countyhome-villageslide>
    <div class="menubox">
      <scroll-menu :menuList="menuList" @getMenuId='chooeseMenuId'></scroll-menu>
    </div>

    <van-list
      v-model="loading"
      :finished="finished"
      :immediate-check="false"
      finished-text="没有更多了"
      @load="onLoad"
      :offset="10"
    >
    <home-dynamic
      v-for="(item,i) in newsList"
      :key="i"
      :news="item"
      :is_count="0"
    ></home-dynamic>
    </van-list>

    <foot-bar></foot-bar>
  </div>
</template>

<script>

import topBar from '../components/countyhome/topBar.vue';
import homeSlide from '../components/home/slide.vue';
import townhomeDatatj from '../components/townhome/datatj.vue';
import countyhomeVillageslide from '../components/countyhome/villageSlide.vue';
import ScrollMenu from '../components/countyhome/ScrollMenu.vue';
import homeDynamic from '../components/home/dynamic.vue';
import footBar from '../components/foot_c.vue';



export default {
  data(){
    return{
      // loading:true,
      site:'',
      banner:[],
      bannerList:[],
      newsList:[],//帖子列表
      menuList: [
        {
          children: [],
          extra: "",
          icon_image: "",
          id: '',
          style: "",
          title: "全部",
        }
      ],
      type:'',
      type1:'',
      type2:'',
      page:1,
      limit:10,
      loading: false,
      total: 0,//总共的数据条数
      finished: false,
      villageInfo:'',

      tjData:'',
      list_v:[],
      menu:[]

    };
  },
  created() {
    this.getSite();
  },
  methods:{
    getSite(){
      if(this.$route.params.site){
        window.localStorage.setItem('site_id',this.$route.params.site);
      }


      if(this.$route.params.site || window.localStorage.getItem("site_id") ){
        this.is_site = true;
        this.site = this.$route.params.site ? this.$route.params.site : window.localStorage.getItem("site_id");
        let that = this;
        that.$axios
          .get(that.baseUrl+"/api/site/index", {
            params: {
              site_id:that.site,
            }
          })
          .then(function(res) {
            //console.log(res);
            if(res.data.code==1){
              //通过站点详情判断是否与当前级别对应，否则弹出提示信息
              var site_type = res.data.data.type;
              var is_entry = false;
              if(site_type == '县'){
                is_entry = true;
              }
              if(is_entry){
                that.villageInfo= res.data.data;
                window.localStorage.setItem('village_info',JSON.stringify(res.data.data));
                window.localStorage.setItem('site_id',res.data.data.id);
                window.localStorage.setItem('type',res.data.data.type);
                that.getIndexInfo();
                that.getroadList();
                that.getBanner();
                that.getMenu();
                that.getTjdata();
                that.getVillageSlide();
              }else{
                that.villageInfo = '';
                that.$router.push({name:'Default',params:{}});
              }


            }
          });
      }else{
        this.villageInfo = '';
        this.$router.push({name:'Default',params:{}});
      }


    },
    //跳转到村庄简介
    gotoDeatil(obj){
      // console.log(obj);
      this.$router.push({name:'Home',params:{site:obj.id}});
    },
    getVillageSlide(){
      var that = this;
      that.$axios
        .get(that.baseUrl+"/api/county/statistics", {
          params: {
            site_id:window.localStorage.getItem('site_id'),
          }
        })
        .then(function(res) {
          if(res.data.code == 1){
            that.list_v = res.data.data.list;
          }else{
            console.log(res.data.msg);
          }
        });
    },
    getTjdata(){
      var that = this;
      that.$axios
        .get(that.baseUrl+"/api/county/home", {
          params: {
            site_id:window.localStorage.getItem('site_id'),
          }
        })
        .then(function(res) {
          if(res.data.code == 1){
            that.tjData = res.data.data;
          }else{
            console.log(res.data.msg);
          }
        });
    },
    chooeseMenuId(cat_name){
      console.log(cat_name);
      if(cat_name == '全部'){
        this.type1 = '';
      }else{
        this.type1 = cat_name;
      }

      this.page = 1;
      this.newsList = [];
      this.getroadList();

    },
    getMenu(){
      var that = this;
      that.$axios
        .get(that.baseUrl+"/api/news/category", {
          params: {
            site:that.site,
          }
        })
        .then(function(res) {
          if(res.data.code == 1){
            if(res.data.data.length > 0){
              that.menuList = that.menuList.concat(res.data.data);
            }else{
              that.menuList = [];
            }
          }else{
            console.log(res.data.msg);
          }
        });
    },
    getBanner(){
      let that = this;
      that.$axios
        .get(that.baseUrl+"/api/banner/index", {
          params: {
            site:that.site,
            type:'首页',
          }
        })
        .then(function(res) {
          if(res.data.code==1){
            var arr = res.data.data;
            if(arr.length > 0){
              that.banner = res.data.data;
              // arr.forEach(element => {
              //   that.banner.push(element.imgurl);
              // });
            }
          }
        });
    },

    getIndexInfo(){
      let that = this;
      that.$axios
        .get(that.baseUrl+"/api/index/index", {//地址是get请求地址
          params: {
            site:that.site
          }
        })
        .then(function(res) {//当请求完成时候执行then回调函数
          if(res.data.code==1){

            that.dongtai= res.data.data.dongtai;
            that.huati= res.data.data.huati;
            that.loading = false;
            that.menu= res.data.data.category;
            that.bannerList =res.data.data.banner;
            //console.log(that.menu);
          }
        });
    },

    onLoad() {
      this.page++;
      this.getroadList();
    },
    getroadList(){

      let that = this;
      that.$axios
        .get(that.baseUrl+"/api/news/list", {
          params: {
            site:that.site,
            all_site:1,
            type1:that.type1,
            page:that.page,
            limit:that.limit
          }
        })
        .then(function(res) {
          if(res.data.code==1){
            that.loading = false;
            that.total = res.data.data.count;

            var list = res.data.data.list;

            if (list == null || list.length === 0) {
              // 加载结束
              that.finished = true;
              return;
            }
            // 将新数据与老数据进行合并
            that.newsList = that.newsList.concat(list);

            //如果列表数据条数>=总条数，不再触发滚动加载
            if (that.newsList.length >= that.total) {
              that.finished = true;
            }

          }
        });
    },
    goSelectSite(){
      // this.$router.push({name:'SelectSite',params:{}});
      this.$router.push({ name: "Stroll", params: {} });
    },
    toList(obj){
      if(obj.title == '党建动态'){
        this.$router.push({name:'PartyAffairs',params:{}});
      }else if(obj.title == '致富经'){
        this.$router.push({name:'GetRich',params:{
          id:obj.id
        }});
      }else{
        console.log(333);
        this.$router.push({name:'NewsList',params:{
          id:obj.id
        }});
      }
    }

  },
  components: {
    topBar,
    homeSlide,
    townhomeDatatj,
    countyhomeVillageslide,
    ScrollMenu,
    homeDynamic,
    footBar,
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/styles/var.less";
.home-container{
  padding-bottom:50px;
  height:100%;
  .welcome{
    width:100%;height:40px;line-height:40px;text-align:center;background:#33cc66;color:#ffffff;font-size:16px;
    position:relative;
    .selectsite{position:absolute;right:15px;top:10px;}
  }
  .home-village{
    margin:0 10px 10px;
    padding:10px 0px;
    background:#fff;
    color:@dark;
    border-radius:10px;
    font-size:14px;
    .active{
      color:@primary;
      font-weight:bold;
    }
    .icon-dq{
      vertical-align: -2px;
    }
  }
}
.van-notice-bar{
  background:none;
  color:#333;
}
.menu-container{
  margin:0 0 10px;
  background:#fff;
  padding:10px 0;

  .menu-item{
    width:25%;
    padding-top:10px;
    padding-bottom:10px;
    display:inline-block;
    text-align:center;
    .menu-icon{width:30px;height:30px;}

    p{
      color:#666;font-size:15px;
    }
  }
}

.pt02{padding-top:4px;color:@words;}
.t-sbla{color:#8e8e8e;}
.fb{font-weight:bold;}
.dsp{
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .dsp_icon{
    width:30px;
    height:30px;
  }
  .dsp_txt{font-size:14px;}
}
.jz{
  font-size:16px;
  height:48px;
  display:flex;
  justify-content: center;
  align-items: center;
}
.menubox{height:38px;background:#fff;padding-top:5px;}
</style>
