<template>
  <div class="maillist-container">
    <c-title :hide="false"
      :text="`通讯录`"
      tolink=""
      :totext="``">
    </c-title>
    <form action="/">
      <van-search
        v-model="value"
        placeholder="请输入搜索关键词"
        @search="onSearch"
        @cancel="onCancel"
      />
    </form>
    <van-list
      v-model="loading"
      :finished="finished"
      :immediate-check="false"
      finished-text="没有更多了"
      @load="onLoad"
      :offset="10"
    >
    <van-contact-card
      v-for="(item,i) in list"
      :key="i"
      type="edit"
      :name="item.nickname"
      :tel="item.mobile"
      @click="tel(item.mobile)"
      />
    </van-list>

  </div>
</template>

<script>
import cTitle from '../components/title.vue';
export default {
  data(){
    return {
      value:'',
      list:[],
      page:1,
      limit:10,
      loading: false,
      total: 0,//总共的数据条数
      finished: false,
    };
  },
  created() {
    this.getroadList();
  },
  components: {
    cTitle
  },
  methods:{
    onLoad() {
      this.page++;
      this.getroadList();
    },
    getroadList(){
      let that = this;
      that.$axios
        .get(that.baseUrl+"/api/site/user", {
          params: {
            site:window.localStorage.getItem('site_id'),
            keyword:that.value,
            page:that.page,
            limit:that.limit
          }
        })
        .then(function(res) {
          console.log(res);
          if(res.data.code==1){
            that.loading = false;
            that.total = res.data.data.count;

            var list = res.data.data.list;

            if (list == null || list.length === 0) {
              // 加载结束
              that.finished = true;
              return;
            }
            // 将新数据与老数据进行合并
            that.list = that.list.concat(list);

            //如果列表数据条数>=总条数，不再触发滚动加载
            if (that.list.length >= that.total) {
              that.finished = true;
            }

          }
        });
    },
    onSearch(val) {
      this.value = val;
      this.page = 1;
      this.list = [];
      this.getroadList();
    },
    onCancel() {
      alert('取消');
    },
    tel(phoneNumber){
      window.location.href = 'tel://' + phoneNumber;
    }
  }
};
</script>

<style>

</style>
