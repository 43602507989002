<template>
  <div class="home-container">
    <div class="welcome">
      {{villageInfo.site}}
      <van-icon name="map-marked" class="selectsite" size="20" @click="goSelectSite"/>
    </div>
    <top-bar is_active='cunliao'></top-bar>
    <div style="margin-top:5px;">
      <home-conversation :huati = "huati "></home-conversation>
    </div>

    <van-list
      v-model="loading"
      :finished="finished"
      :immediate-check="false"
      finished-text="没有更多了"
      @load="onLoad"
      :offset="10"
    >
    <home-dynamic
      v-for="(item,i) in newsList"
      :key="i"
      :news="item"
    ></home-dynamic>
    </van-list>

    <foot-bar></foot-bar>
  </div>
</template>

<script>

import topBar from '../components/countyhome/topBar.vue';
import homeConversation from '../components/home/conversation.vue';
import homeDynamic from '../components/home/dynamic.vue';
import footBar from '../components/foot_t.vue';



export default {
  data(){
    return{
      site:'',
      dongtai:[],//动态
      huati:[],//话题
      newsList:[],//帖子列表
      page:1,
      limit:10,
      loading: false,
      total: 0,//总共的数据条数
      finished: false,
      is_site:false,
      villageInfo:'',

    };
  },
  created() {
    this.getSite();
  },
  methods:{
    getSite(){
      if(this.$route.params.site){
        window.localStorage.setItem('site_id',this.$route.params.site);
      }


      if(this.$route.params.site || window.localStorage.getItem("site_id") ){
        this.is_site = true;
        this.site = this.$route.params.site ? this.$route.params.site : window.localStorage.getItem("site_id");
        let that = this;
        that.$axios
          .get(that.baseUrl+"/api/site/index", {
            params: {
              site_id:that.site,
            }
          })
          .then(function(res) {
            //console.log(res);
            if(res.data.code==1){
              //通过站点详情判断是否与当前级别对应，否则弹出提示信息
              var site_type = res.data.data.type;
              var is_entry = false;
              if(site_type == '县'){
                is_entry = true;
              }
              if(is_entry){
                that.villageInfo= res.data.data;
                window.localStorage.setItem('village_info',JSON.stringify(res.data.data));
                window.localStorage.setItem('site_id',res.data.data.id);
                window.localStorage.setItem('type',res.data.data.type);
                that.getroadList();

              }else{
                that.villageInfo = '';
                that.$router.push({name:'Default',params:{}});
              }


            }
          });
      }else{
        this.villageInfo = '';
        this.$router.push({name:'Default',params:{}});
      }


    },

    onLoad() {
      this.page++;
      this.getroadList();
    },
    getroadList(){

      let that = this;
      that.$axios
        .get(that.baseUrl+"/api/news/list", {
          params: {
            site:that.site,
            all_site:1,
            page:that.page,
            limit:that.limit
          }
        })
        .then(function(res) {
          if(res.data.code==1){
            that.loading = false;
            that.total = res.data.data.count;

            var list = res.data.data.list;

            if (list == null || list.length === 0) {
              // 加载结束
              that.finished = true;
              return;
            }
            // 将新数据与老数据进行合并
            that.newsList = that.newsList.concat(list);

            //如果列表数据条数>=总条数，不再触发滚动加载
            if (that.newsList.length >= that.total) {
              that.finished = true;
            }

          }
        });
    },
    goSelectSite(){
      this.$router.push({name:'SelectSite',params:{}});
    },
    toList(obj){
      if(obj.title == '党建动态'){
        this.$router.push({name:'PartyAffairs',params:{
          id:81
        }});
      }else if(obj.title == '致富经'){
        this.$router.push({name:'GetRich',params:{}});
      }else{
        this.$router.push({name:'NewsList',params:{
          id:obj.id
        }});
      }
    }

  },
  components: {
    topBar,
    homeConversation,
    homeDynamic,
    footBar,
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/styles/var.less";
.home-container{
  padding-bottom:50px;
  height:100%;
  .welcome{
    width:100%;height:40px;line-height:40px;text-align:center;background:#33cc66;color:#ffffff;font-size:16px;
    position:relative;
    .selectsite{position:absolute;right:15px;top:10px;}
  }
  .home-village{
    margin:0 0 10px;
    padding:10px 0px;
    background:#fff;
    color:@dark;
    border-radius:10px;
    font-size:14px;
    .active{
      color:@primary;
      font-weight:bold;
    }
    .icon-dq{
      vertical-align: -2px;
    }
  }
}
.van-notice-bar{
  background:none;
  color:#333;
}
.menu-container{
  margin:0 0 5px;
  background:#fff;
  padding:10px 0;

  .menu-item{
    width:20%;
    padding-top:10px;
    padding-bottom:10px;
    display:inline-block;
    text-align:center;
    .menu-icon{width:30px;height:30px;}
  }
}

.pt02{padding-top:4px;color:@words;}
.t-sbla{color:#222;}
.fb{font-weight:bold;}
.dsp{
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .dsp_icon{
    width:30px;
    height:30px;
  }
  .dsp_txt{font-size:14px;}
}
.jz{
  font-size:16px;
  height:48px;
  display:flex;
  justify-content: center;
  align-items: center;
}
</style>
