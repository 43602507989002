<template>
  <div class="mymessage-container">
    <c-title :hide="false"
      :text="`我的补贴`"
      tolink=""
      :totext="``">
    </c-title>
    <div class="content pb15">
      <div class="topbox cbg mb15">
        <div class="oneline ubb"><span class="t-red">* </span>村组：枣园村</div>
        <div class="oneline ubb"><span class="t-red">* </span>户主姓名：周兴波</div>
        <div class="oneline"><span class="t-red">* </span>联系电话：13800138000</div>
      </div>
      <div class="topbox cbg mb15">
        <div class="oneline ubb">转移性收入：100元</div>
        <div class="oneline ubb">总收入：100元</div>
        <div class="oneline ubb">退耕还林：100元</div>
        <div class="oneline ubb">草原奖补：100元</div>
        <div class="oneline ubb">公益林补助：100元</div>
        <div class="oneline ubb">农资补种：100元</div>
        <div class="oneline ubb">良种补贴：100元</div>
        <div class="oneline ubb">农村低保：100元</div>
        <div class="oneline ubb">计生奖补：100元</div>
        <div class="oneline ubb">扶贫资金：100元</div>
        <div class="oneline ubb">保险理赔：100元</div>
        <div class="oneline ubb">养老金：100元</div>
        <div class="oneline ubb">高额津贴：100元</div>
        <div class="oneline ubb">残疾津贴：100元</div>
        <div class="oneline ubb">救济救灾：100元</div>
        <div class="oneline">社会帮扶：100元</div>
      </div>
    </div>
  </div>
</template>

<script>
import cTitle from '../../components/title.vue';
export default {
  data(){
    return {
      info:''
    };
  },
  created(){
    this.getList();
  },
  methods:{

    getList(){

      let that = this;
      that.$axios
        .get(that.baseUrl+"/api/user_config/getConfig", {
          params: {
            type:'补贴'
          }
        })
        .then(function(res) {
          if(res.data.code==1){
            console.log(res);
            that.info = res;
          }
        });
    },
  },
  components:{
    cTitle
  }
};
</script>

<style lang="less" scoped>
@import "~@/assets/styles/var.less";
.mymessage-container{
  padding:10px;
  .content{
    .topbox{
      padding:10px;background:#fff;border-radius:10px;
    }
  }
}
.oneline{line-height:40px;font-size:14px;color:#222;}
.ubb{border-bottom:solid 1px #eee;}
.t-red{
  color:#f30;
  font-size:20px;
  vertical-align: middle;
}
.mb15{margin-bottom:15px;}
.pb15{padding-bottom:15px;}
.cbg{box-shadow: 1px 1px 2px rgba(0,0,0,0.1);}
</style>
