<template>
  <div class="microwish-container">
    <c-title :hide="false"
      :text="`我的种植`"
      tolink=""
      :totext="``">
    </c-title>
    <div class="content">
      <van-field
        v-model="cunzhu"
        name="cunzhu"
        label="村组"
        placeholder="请填写村组名称"
      />
      <van-field
        v-model="user_name"
        name="user_name"
        label="姓名"
        placeholder="请填写您的姓名"
      />
      <van-field
          v-model="telphone"
          name="telphone"
          label="联系电话"
          placeholder="请输入您的联系电话"
          :rules="[{ required: true, message: '联系电话不能为空' }]"
      />
      <van-field
          v-model="jiatingyinshou"
          name="jiatingyinshou"
          label="家庭营收"
          placeholder="请输入家庭营销性收入"
      />
      <van-field
        v-model="fieldValue"
        is-link
        readonly
        label="品种"
        placeholder="请选择品种分类"
        @click="show = true"
      />
      <van-field
          v-model="bozhongmianji"
          name="bozhongmianji"
          label="播种面积"
          placeholder="单位：亩"
      />
      <van-field
          v-model="weizhi"
          name="weizhi"
          label="获取位置"
          placeholder="自动获取位置"
      />
      <div class="upload-img">
        <van-uploader v-model="fileList" multiple :after-read="afterRead"/>
      </div>
      <van-field
          v-model="zongchanliang"
          name="zongchanliang"
          label="总产量"
          placeholder="单位：斤"
      />
      <van-field
          v-model="shijia"
          name="shijia"
          label="市场价格"
          placeholder="单位：元/斤"
      />
      <van-field
          v-model="zongshouru"
          name="zongshouru"
          label="总收入"
          placeholder="单位：元"
      />
      <van-field
          v-model="chengben"
          name="chengben"
          label="成本支出"
          placeholder="单位：元"
      />
      <van-field
          v-model="zongcunshouru"
          name="zongcunshouru"
          label="总纯收入"
          placeholder="单位：元"
      />



    </div>
    <div class="btnbox">
      <van-button round type="info" color="#ccc" class="mr10" @click="goback"> 取消 </van-button>
      <van-button round type="info" color="#00a752" :disabled="is_click" @click="submit"> 发布 </van-button>
    </div>
    <van-popup v-model="show" round position="bottom">
      <van-cascader
        v-model="cascaderValue"
        title="请选择品种分类"
        :options="options"
        @close="show = false"
        @finish="onFinish"
      />
    </van-popup>
  </div>
</template>

<script>
import cTitle from '../../components/title.vue';
export default {
  data(){
    return{
      show: false,
      fieldValue:'',
      options: [
        {text: '稻谷',value: '1',},
        {text: '玉米',value: '2',},
        {text: '小麦',value: '3',},
        {text: '甘蔗',value: '4',},
        {text: '高粱',value: '5',},
        {text: '西瓜',value: '6',},
        {text: '大豆',value: '7',},
        {text: '其它',value: '8',},
      ],
      cascaderValue:'',
      categroy_ids:'',
      cunzhu:'',
      user_name:'',
      telphone:'',
      jiatingyinshou:'',
      fileList: [],
      img_arr:'',
      is_click:false,
      bozhongmianji:'',
      weizhi:'',
      zongchanliang:'',
      shijia:'',
      zongshouru:'',
      chengben:'',
      zongcunshouru:''

    };
  },
  created(){
    // this.get_options();
  },
  methods:{
    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      var that = this;
      that.img_arr = [];
      if(that.fileList.length>0){
        for (var i=0;i<that.fileList.length;i++){
          var img_url = that.fileList[i].content;
          that.$axios
            .post(that.baseUrl+"/api/common/uploadBase64", {
              params:{
                base64:img_url
              }
            })
            .then(function(res) {//当请求完成时候执行then回调函数

              if(res.data.code == 1){
                that.img_arr += res.data.data.fullurl+',';
              }
            });
        }
      }
    },
    // 全部选项选择完毕后，会触发 finish 事件
    onFinish({ selectedOptions }) {
      this.show = false;
      this.fieldValue = selectedOptions.map((option) => option.text).join('/');
      this.categroy_ids = selectedOptions.map((option) => option.value).join('/');
      console.log(this.categroy_ids);
    },
    submit(){
      var that = this;
      that.is_click = true;

      var extend = [];
      extend[0] = {
        name:"村组",
        value:that.cunzhu
      };
      extend[1] = {
        name:"姓名",
        value:that.user_name
      };
      extend[2] = {
        name:"联系电话",
        value:this.telphone
      };
      extend[3] = {
        name:"品种",
        value:this.fieldValue
      };
      extend[4] = {
        name:"家庭营收",
        value:this.jiatingyinshou
      };
      extend[5] = {
        name:"播种面积",
        value:this.bozhongmianji
      };
      extend[6] = {
        name:"位置",
        value:this.weizhi
      };
      extend[7] = {
        name:"总产量",
        value:this.zongchanliang
      };
      extend[8] = {
        name:"市场价格",
        value:this.shijia+"元"
      };
      extend[9] = {
        name:"总纯收入",
        value:this.zongcunshouru+"元"
      };
      extend[10] = {
        name:"成本支出",
        value:this.chengben+"元"
      };
      extend[11] = {
        name:"总纯收入",
        value:this.zongcunshouru+"元"
      };
      extend[12] = {
        name:"图片",
        value:that.img_arr
      };
      var new_extend = JSON.stringify(extend);
      console.log(typeof new_extend);
      that.$axios
        .get(that.baseUrl+"/api/user_config/saveConfig", {
          params: {
            id:0,
            type:"种植",
            extra:new_extend,
          }
        })
        .then(function(res) {
          console.log(res);
          if(res.data.code == 1){

            that.$toast('发布成功');
            setTimeout(function(){
              that.$router.push("/myplant");
            },2000);
          }else{
            that.is_click=false;
          }
        });

    },

    goback(){
      this.$router.go(-1);
    },

  },
  components: {
    cTitle
  },
};
</script>

<style lang="less" scoped>

.upload-img{
    padding:15px;
    background:#fff;
  }
  .mr10{margin-right:10px;}
  .btnbox{
    padding:20px;
    text-align:center;
}
</style>
