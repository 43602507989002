<template>
  <div>
    <quill-editor
      v-model="info_n"
      ref="myQuillEditor"
      @blur="onEditorBlur($event)"
      @focus="onEditorFocus($event)"
      @ready="onEditorReady($event)"
    >
    </quill-editor>
  </div>
</template>

<script>
export default {
  props: ["info"],
  data() {
    return {};
  },
  computed: {
    info_n: {
      get() {
        return this.info;
      },
      set(val) {
        this.$emit("update:info", val);
      }
    }
  },

  created() {},
  methods: {
    onEditorBlur() {
      //console.log('blur',this.content);
    },

    onEditorFocus() {
      //console.log('focus',this.content);
    },

    onEditorReady() {
      //console.log('ready',this.content);
    },
    reset(){
      this.info_n = '';
    },

  }
};
</script>

<style lang="less" scoped>

</style>
