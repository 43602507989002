<template>
  <div class="conversation-container">
    <div class="ht_top">
      <div class="title">
        热门话题
      </div>
      <div class="mui-ellipsis">

      </div>
      <router-link tag="div" class="more" to="/topiclist">更多</router-link>
    </div>

    <div class="htlist">
      <div
        class="list mui-ellipsis"
        v-for="item in huati"
        :key="item.id"
        @click="goDetail(item.id)"
      >
        <img :src="item.cover" class="list-cover"/>
        #{{item.title}}#
      </div>

    </div>
  </div>
</template>

<script>


export default {
  props:['huati'],
  methods:{
    goDetail(id){
      this.$router.push({name:'TopicDetail',params:{id:id}});
    }
  }


};
</script>

<style lang="less" scoped>
@import "~@/assets/styles/var.less";
.conversation-container{
  margin:0 0 5px;
  background:#fff;
  padding:10px;
  // border-radius:10px;
  .ht_top{
    width:100%;
    display:flex;
    justify-content: space-between;
    align-content: center;height:30px;line-height:30px;
    .title{
      color:@dark;
      font-size:14px;
      font-weight:bold;
      width:20%;
    }
    .hot-text{
      width:66%;
      margin-left:4%;
      font-size:14px;
      color:@dark;
      padding-left:30px;
      background:url(../../assets/images/pk.jpg) no-repeat;
      background-size:20px 20px;
      background-position:left center;

    }
    .more{
      width:10%;
      font-size:10px;color:#999;
      text-align:right;
    }
  }
  .htlist{
    padding:10px;
    overflow:auto;
    .list{
      width:50%;
      height:30px;
      line-height:30px;
      float:left;
      align-items: center;
      color:@dark;
      vertical-align: middle;
      font-size:14px;
      img{
        width:24px;
        height:24px;
        border-radius:14px;
        margin-right:4px;
        margin-left:5px;
        vertical-align: -24%;
      }
    }
  }
}
.icon-dq{vertical-align:-13%}
.mui-ellipsis{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
</style>
