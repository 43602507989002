<template>
  <div class="home-container">
    <div class="welcome">
      <van-icon name="apps-o" class="goleft" size="20" @click="goCountyHome"/>
      {{villageInfo.site}}
      <van-icon name="map-marked" class="selectsite" size="20" @click="goSelectSite"/>
    </div>
    <top-bar></top-bar>
    <home-slide :banner="banner"></home-slide>
    <townhome-datatj :tjData="tjData"></townhome-datatj>

    <div class="menu-container">
      <div class="menu-item" v-for="(item,index) in menu" :key="index" @click="toList(item)">
        <img :src="item.icon_image2" class="menu-icon"/>
        <p class="pt02 fb">{{item.title}}</p>
      </div>
    </div>
    <tou-tiao></tou-tiao>
    <!-- <home-conversation :huati = "huati"></home-conversation> -->
    <townhome-toutiao v-show="false"></townhome-toutiao>
    <div class="home-village">
      <van-row>
      <van-col span="12" class="tx-c jz" :class="is_site ? 'active' : '' " @click="qh_area(1)">
        本镇 <van-icon name="arrow-down" class="icon-dq" v-if="is_site"/>
      </van-col>

      <van-col span="12" class="tx-c jz" :class="is_site == false ? 'active' : '' "  @click="qh_area(2)">
        全县 <van-icon name="arrow-down" class="icon-dq" v-if="is_site == '' "/>
      </van-col>
    </van-row>
    </div>

    <van-list
      v-model="loading"
      :finished="finished"
      :immediate-check="false"
      finished-text="没有更多了"
      @load="onLoad"
      :offset="10"
    >
    <home-dynamic
      v-for="(item,i) in newsList"
      :key="i"
      :news="item"
      :is_count="3"
    ></home-dynamic>
    </van-list>

    <foot-bar></foot-bar>
  </div>
</template>

<script>

import topBar from '../components/townhome/topBar.vue';
import homeSlide from '../components/home/slide.vue';
import townhomeDatatj from '../components/townhome/datatj.vue';
import townhomeToutiao from '../components/townhome/toutiao.vue';
// import homeConversation from '../components/home/conversation.vue';
import homeDynamic from '../components/home/dynamic.vue';
import footBar from '../components/foot_t.vue';
import touTiao from '../components/townhome/toutiao.vue';


export default {
  data(){
    return{
      // loading:true,
      site:'',
      banner:[],
      dongtai:[],//动态
      huati:[],//话题
      newsList:[],//帖子列表
      page:1,
      limit:10,
      loading: false,
      total: 0,//总共的数据条数
      finished: false,
      is_site:false,
      villageInfo:'',
      menu:[],
      tjData:'',
      is_all:false

    };
  },
  created() {
    this.getSite();
  },
  methods:{
    getSite(){
      if(this.$route.params.site){
        window.localStorage.setItem('site_id',this.$route.params.site);
      }


      if(this.$route.params.site || window.localStorage.getItem("site_id") ){
        this.is_site = true;
        this.site = this.$route.params.site ? this.$route.params.site : window.localStorage.getItem("site_id");
        let that = this;
        that.$axios
          .get(that.baseUrl+"/api/site/index", {
            params: {
              site_id:that.site,
            }
          })
          .then(function(res) {
            //console.log(res);
            if(res.data.code==1){
              //通过站点详情判断是否与当前级别对应，否则弹出提示信息
              var site_type = res.data.data.type;
              var is_entry = false;
              if(site_type == '镇'){
                is_entry = true;
              }
              if(is_entry){
                that.villageInfo= res.data.data;
                window.localStorage.setItem('village_info',JSON.stringify(res.data.data));
                window.localStorage.setItem('site_id',res.data.data.id);
                window.localStorage.setItem('type',res.data.data.type);
                that.getIndexInfo();
                that.getroadList();
                that.getBanner();
                that.getTjdata();
              }else{
                that.villageInfo = '';
                that.$router.push({name:'Default',params:{}});
              }


            }
          });
      }else{
        this.villageInfo = '';
        this.$router.push({name:'Default',params:{}});
      }


    },
    //获取首页统计接口数据
    getTjdata(){
      var that = this;
      that.$axios
        .get(that.baseUrl+"/api/town/home", {
          params: {
            site_id:window.localStorage.getItem('site_id'),
          }
        })
        .then(function(res) {
          if(res.data.code == 1){
            that.tjData = res.data.data;
          }else{
            console.log(res.data.msg);
          }
        });
    },
    getBanner(){
      let that = this;
      that.$axios
        .get(that.baseUrl+"/api/banner/index", {
          params: {
            site:that.site,
            type:'首页',
          }
        })
        .then(function(res) {
          if(res.data.code==1){
            var arr  = res.data.data;
            if(arr.length > 0){
              that.banner= res.data.data;
            }
          }
        });
    },
    qh_area(n){
      if(n==1){
        this.is_all = false;
        this.is_site = true;
      }else{
        this.is_all = true;
        this.is_site = false;
      }
      this.page = 1;
      this.totla = 0;
      this.finished = false;
      this.newsList = [];
      this.getroadList();
    },
    getIndexInfo(){
      let that = this;
      that.$axios
        .get(that.baseUrl+"/api/index/index", {//地址是get请求地址
          params: {
            site:that.site
          }
        })
        .then(function(res) {//当请求完成时候执行then回调函数
          if(res.data.code==1){

            that.dongtai= res.data.data.dongtai;
            that.huati= res.data.data.huati;
            that.loading = false;
            that.menu= res.data.data.category;
            //console.log(that.menu);
          }
        });
    },

    onLoad() {
      this.page++;
      this.getroadList();
    },
    getroadList(){
      var site = this.site;
      if(this.is_all){
        site = window.localStorage.getItem('county_id');
      }

      let that = this;
      that.$axios
        .get(that.baseUrl+"/api/news/list", {
          params: {
            site:site,
            all_site:1,
            page:that.page,
            limit:that.limit
          }
        })
        .then(function(res) {
          if(res.data.code==1){
            that.loading = false;
            that.total = res.data.data.count;

            var list = res.data.data.list;

            if (list == null || list.length === 0) {
              // 加载结束
              that.finished = true;
              return;
            }
            // 将新数据与老数据进行合并
            that.newsList = that.newsList.concat(list);

            //如果列表数据条数>=总条数，不再触发滚动加载
            if (that.newsList.length >= that.total) {
              that.finished = true;
            }

          }
        });
    },
    goSelectSite(){
      // this.$router.push({name:'SelectSite',params:{}});
      this.$router.push({ name: "Stroll", params: {} });
    },
    toList(obj){
      if(obj.title == '党建动态'){
        this.$router.push({name:'PartyAffairs',params:{
          id:81
        }});
      }else if(obj.title == '致富经'){
        this.$router.push({name:'GetRich',params:{
          id:obj.id
        }});
      }else if(obj.title=='权力公开'){
        this.$router.push({name:'Authority',params:{
          id:obj.id
        }});
      }else{
        this.$router.push({name:'NewsList',params:{
          id:obj.id
        }});
      }
    },
    goCountyHome(){
      var county_id = window.localStorage.getItem("county_id");
      if(county_id){
        this.$router.push({name:'CountyHome',params:{
          site:county_id
        }});
      }else{
        this.$toast('请先登录');
        setTimeout(function(){
          this.$router.push({name:'Login',params:{

          }});
        },1000);
      }
    }

  },
  components: {
    topBar,
    homeSlide,
    townhomeDatatj,
    townhomeToutiao,
    // homeConversation,
    homeDynamic,
    footBar,
    touTiao
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/styles/var.less";
.home-container{
  padding-bottom:50px;
  height:100%;
  .welcome{
    width:100%;height:40px;line-height:40px;text-align:center;background:#33cc66;color:#ffffff;font-size:16px;
    position:relative;
    .selectsite{position:absolute;right:15px;top:10px;}
    .goleft{position:absolute;left:15px;top:10px;}
  }
  .home-village{
    margin:0 0 5px;
    padding:10px 0px;
    background:#fff;
    color:@dark;
    font-size:16px;
    .active{
      color:@primary;
      font-weight:bold;
    }
    .icon-dq{
      vertical-align: -2px;
    }
  }
}
.van-notice-bar{
  background:none;
  color:#333;
}
.menu-container{
  margin:0 0 5px;
  background:#fff;
  padding:10px 0;

  .menu-item{
    width:20%;
    padding-top:10px;
    padding-bottom:10px;
    display:inline-block;
    text-align:center;
    .menu-icon{width:30px;height:30px;}

    p{
      font-size:15px;color:#8e8e8e;
    }
  }
}

.pt02{padding-top:4px;color:@words;}
.t-sbla{color:#222;}
.fb{font-weight:bold;}
.dsp{
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .dsp_icon{
    width:30px;
    height:30px;
  }
  .dsp_txt{font-size:14px;}
}
.jz{
  font-size:16px;
  height:48px;
  display:flex;
  justify-content: center;
  align-items: center;
}
</style>
