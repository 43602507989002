<template>
  <div class="det-container">
    <c-title :hide="false" text="" tolink="" :totext="``"> </c-title>
    <div class="content">
      <div class="title">疫情防控任务艰巨</div>
      <div class="timed">2023-04-18 09:00</div>
      <div class="sj">
        <div class="sj-item">
          <div class="num">0</div>
          <div class="ntext">送达人数</div>
        </div>
        <div class="sj-item">
          <div class="num">0</div>
          <div class="ntext">查询人数</div>
        </div>
        <div class="sj-item">
          <div class="num">0</div>
          <div class="ntext">未送达人数</div>
        </div>
        <div class="sj-item">
          <div class="num">0</div>
          <div class="ntext">未读人数</div>
        </div>
      </div>
      <div class="tab">
        <van-button round size="small" type="warning" class="mr10">通知内容</van-button>
        <van-button round size="small" type="default">通知名单</van-button>
      </div>
      <div class="cont">
        这里是正文内容

      </div>
    </div>
  </div>
</template>

<script>
import cTitle from "../../components/title.vue";
export default {
  components: {
    cTitle
  }
};
</script>

<style lang="less" scoped>
.det-container {
  padding: 10px;
  .content {
    .title {
      width: 100%;
      font-size: 16px;
      color: #000;
      line-height: 30px;
    }
    .timed {
      width: 100%;
      font-size: 14px;
      color: #999;
      line-height: 20px;
    }
    .sj {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .sj-item {
        width: 25%;
        padding-top:10px;padding-bottom:10px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .num{
          font-size:16px;color:#f60;
        }
        .ntext{
          font-size:14px;color:#666;line-height:20px;
        }
      }
    }
    .tab{
      margin-top:5px;margin-bottom:5px;
    }
    .cont{
      padding:10px 0;color:#666;
    }
  }
}
.mr10{
  margin-right:10px;
}
</style>
