<template>
  <div class="branchstyle-container">
    <c-title :hide="false"
      :text="`书记信箱`"
      tolink=""
      :totext="``">
    </c-title>
    <div class="sjinfo" v-if="si_info.userinfo">
      <div class="sj-left">
        <img src="../../assets/images/user.png" class="sj_tx" />
      </div>
      <div class="sj-right">
        <div class="sj_top sj_name">
          <img :src="si_info.userinfo.avatar" alt="">
          <span class="nickname">{{si_info.userinfo.nickname}}</span>
          <span class="sf">(村支书)</span>
        </div>
        <div class="sj_mid">
          <span class="t-gay">来信处理人：</span><span>{{si_info.userinfo.nickname}}</span>
        </div>
        <div class="sj_fot">
          <span class="t-gay">书记公开信：</span><span class="mr05">{{si_info.sjgkx}}</span>
          <span class="t-gay">群众来信：</span><span class="mr05">{{si_info.qzlx}}</span>
          <span class="t-gay">已回复(10)</span>
        </div>
      </div>
    </div>
    <home-slide></home-slide>
    <scroll-menu :menuList="menuList" @getMenuId='chooeseMenuId'></scroll-menu>
    <van-list
      v-model="loading"
      :finished="finished"
      :immediate-check="false"
      finished-text="没有更多了"
      @load="onLoad"
      :offset="10"
    >
    <home-dynamic
      v-for="(item,i) in newsList"
      :key="i"
      :news="item"
      :is_count="5"
    ></home-dynamic>
    </van-list>

    <foot-bar></foot-bar>
  </div>
</template>

<script>
import cTitle from '../../components/title.vue';
import HomeSlide from '../../components/home/slide.vue';
import ScrollMenu from '../../components/ScrollMenu.vue';
import homeDynamic from '../../components/home/dynamic.vue';
import footBar from '../../components/foot.vue';

export default {
  data() {
    return {
      value: '',
      newsList:[],
      menuList: [
        {
          children: [],
          extra: "",
          icon_image: "",
          id: '',
          style: "",
          title: "全部",
        }
      ],
      type2:'',
      page:1,
      limit:10,
      loading: false,
      total: 0,//总共的数据条数
      finished: false,
      si_info:'',
      category_id:''
    };
  },
  created(){
    this.category_id=this.$route.params.id;
    this.getCategory();
    this.getroadList();
    this.getSjInfo();
  },
  methods: {
    getSjInfo(){
      var that = this;
      that.$axios
        .get(that.baseUrl+"/api/news/mail", {
          params: {
            site:window.localStorage.getItem('site_id')
          }
        })
        .then(function(res) {
          console.log(res);
          if(res.data.code == 1 && res.data.data.userinfo){
            that.si_info=res.data.data;
          }else{
            console.log(res.data.msg);
          }
        });
    },
    chooeseMenuId(cat_name,cat_id){
      if(cat_name == '全部'){
        this.category_id=this.$route.params.id;
      }else{
        this.category_id=cat_id;
      }

      this.page = 1;
      this.newsList = [];
      this.getroadList();

    },
    getCategory(){
      var that = this;
      that.$axios
        .get(that.baseUrl+"/api/news/category", {
          params: {
            site:window.localStorage.getItem('site_id'),
            pid:that.category_id
          }
        })
        .then(function(res) {
          if(res.data.code == 1){
            if(res.data.data.length > 0){
              that.type2 = res.data.data[0]['title'];
              that.menuList = that.menuList.concat(res.data.data);
            }else{
              that.menuList = [];
            }
          }else{
            console.log(res.data.msg);
          }
        });
    },
    onLoad() {
      this.page++;
      this.getroadList();
    },
    getroadList(){
      let that = this;
      that.$axios
        .get(that.baseUrl+"/api/news/list", {
          params: {
            site:window.localStorage.getItem('site_id'),
            category_id:that.category_id,
            all_site:1,
            page:that.page,
            limit:that.limit
          }
        })
        .then(function(res) {
          if(res.data.code==1){
            that.loading = false;
            that.total = res.data.data.count;

            var list = res.data.data.list;
            if(list.length > 0){
              list.forEach(item => {
                if(item.extra){
                  var objet = JSON.parse(item.extra);
                  //console.log(objet);
                  var extra_item = [];
                  for (let key in objet) {
                    const obj = objet[key];
                    extra_item.push(obj);
                  }
                  item.new_extra = extra_item;

                }
              });
            }

            if (list == null || list.length === 0) {
              // 加载结束
              that.finished = true;
              return;
            }
            // 将新数据与老数据进行合并
            that.newsList = that.newsList.concat(list);

            //如果列表数据条数>=总条数，不再触发滚动加载
            if (that.newsList.length >= that.total) {
              that.finished = true;
            }

          }
        });
    },
    onSearch(val) {
      alert(val);
    },
    onCancel() {
      alert('取消');
    },
  },
  components: {
    cTitle,
    HomeSlide,
    ScrollMenu,
    homeDynamic,
    footBar,
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/styles/var.less";
.sjinfo{padding:15px;display:flex;justify-content: space-between;background:#fff;border-bottom:solid 5px #f5f5f5;}
.sj-left{width:25%;}
.sj_tx{width:80px;height:100px;}
.sj-right{width:75%;}
.sj_mid{height:40px;line-height:40px;}
.t-gay{color:@gray;}
.mr05{margin-right:5px;}
.sj_name{
  height:20px;line-height:20px;
  .nickname{
    font-size:16px;
    color:#222;margin-right:4px;
  }
  .sf{
    font-size:14px;
    color:@words;
  }
  img{width:16px;height:16px;border-radius:8px;vertical-align:-16%;margin-right:4px;}
}
.home-container{
  padding-bottom:50px;
}
.van-notice-bar{
  background:none;
  color:#333;
}
.dwgk{
  padding:10px;
  margin:10px 0 10px;
  background:#fff;
}
.dwtitle{
  margin-top:5px;
  margin-bottom:5px;
  font-size:15px;
  font-weight:bold;
  color:#ed1b23;
  padding-left:10px;
  border-left:solid 3px #ed1b23;
}
.dwcontent{
  margin-top:10px;
  margin-bottom:10px;
  color:#666;
  font-size:14px;
}
.dwckgd{
  color:#666;
  font-size:14px;
  padding-top:10px;
}
.ptb10{
  padding-top:10px;
  padding-bottom:10px;
}
.line30{
  line-height:30px;
}
.dsj{
  color:#666;
}
</style>
