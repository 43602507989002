<template>
  <div class="mymessage-container">
    <c-title :hide="false"
      :text="`我的养殖`"
      tolink="/addbreed"
      :totext="`新增养殖`">
    </c-title>
    <div class="content">

      <div class="item" v-for="(item,index) in list" :key="index">

        <van-row class="fot">
          <van-col span="24" class="dat">
          创建时间：{{news.createtime}}
          </van-col>
        </van-row>
        <van-row class="title">
          <van-col span="24">
            <div class="stit" v-for="(it,i) in item.new_extra" :key="i">
              <div class="cover_box" v-if="it.name == '图片'">
                  <img :src="a" v-for="(a,b) in it.cover" :key="b" class="cover" @click="openimg(a)"/>
                </div>
                <div class="stit" v-else>
                  <span>{{it.name}}：</span><span>{{it.value}}</span>
                </div>
            </div>
          </van-col>
        </van-row>

      </div>

    </div>
    <div class="empty" v-if="list.length == 0">
      <img src="../../assets/images/empty.png" class="empty_img">
    </div>
  </div>
</template>

<script>
import cTitle from '../../components/title.vue';
export default {
  data(){
    return {
      list:[],
    };
  },
  created(){
    this.getList();
  },
  methods:{


    onLoad() {
      this.getList();
    },
    getList(){

      let that = this;
      that.$axios
        .get(that.baseUrl+"/api/user_config/getConfig", {
          params: {
            type:'养殖'
          }
        })
        .then(function(res) {
          if(res.data.code==1){
            that.list = res.data.data;
            if(that.list.length > 0){
              that.list.forEach(item => {
                if(item.extra){
                  var objet = item.extra;
                  var extra_item = [];
                  for (let key in objet) {
                    const obj = objet[key];
                    if(obj.name == '图片'){
                      if(obj.value != ''){
                        obj.value = obj.value.substr(0,obj.value.length-1);
                        obj.cover = obj.value.split(',');
                      }
                    }
                    extra_item.push(obj);
                  }
                  item.new_extra = extra_item;
                }
              });
            }
            console.log(that.list);
          }
        });
    },
  },
  components:{
    cTitle
  }
};
</script>

<style lang="less" scoped>
@import "~@/assets/styles/var.less";
.empty{width:100%;height:500px;display:flex;justify-content: center;align-items: center;}
.empty_img{
  width:60%;
}
.cover_box{
  width:100%;
  display:flex;
  flex-direction:row;
  flex-wrap:wrap;
  align-items: flex-start;
}
.stit{width:100%;line-height:30px;font-size:14px;display:flex;justify-content: space-between;align-items: center;}
.cover{width:100px;height:100px;margin:5px;box-shadow: 1px 1px 2px rgba(0,0,0,0.1);}
.mymessage-container{
  padding:10px;
  .content{
    .item{
      padding:10px;
      background:#fff;
      border-radius:4px;
      margin-bottom:8px;
      .title{
        color:@dark;
        font-size:14px;
        padding-top:5px;
        padding-bottom:4px;

      }
      .fot{
        padding-top:4px;
        padding-bottom:5px;
        border-bottom:solid 1px #eee;
        .dat{
          color:@gray;
          line-height:30px;
          font-size:13px;
        }
      }
    }
  }
}
.t-red{
  color:#f30;
  font-size:20px;
  vertical-align: middle;
}
</style>
