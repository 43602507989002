<template>
  <div class="myheart-container">
    <c-title :hide="false"
      :text="`我的心愿`"
      tolink=""
      :totext="``">
    </c-title>
    <div class="heartlist">
      <van-list
        v-model="loading"
        :finished="finished"
        :immediate-check="false"
        finished-text="没有更多了"
        @load="onLoad"
        :offset="10"
      >
      <div class="heart-item" v-for="(item,index) in newsList" :key="index" @click="goDetail(item.news.id)">
        <van-row class="tit">
          <van-col span="16">发布时间：{{item.createtime_text}}</van-col>
          <van-col span="8" class="tx-r fb" v-if="item.news.status == 0">待审核</van-col>
          <van-col span="8" class="tx-r fb t-green" v-if="item.news.status == 1">已审核</van-col>
        </van-row>
        <van-row class="txt">
          <van-col span="24" v-html="item.news.content">
          </van-col>
        </van-row>
      </div>
      </van-list>



    </div>
  </div>
</template>

<script>
import cTitle from '../../components/title.vue';
export default {
  data(){
    return{
      newsList:[],
      page:1,
      limit:10,
      loading: false,
      total: 0,//总共的数据条数
      finished: false,
    };
  },
  created(){
    this.getroadList();
  },
  methods:{
    onLoad() {
      this.page++;
      this.getroadList();
    },
    getroadList(){

      let that = this;
      that.$axios
        .get(that.baseUrl+"/api/join/myJoin", {
          params: {
            page:that.page,
            limit:that.limit
          }
        })
        .then(function(res) {
          if(res.data.code==1){
            that.loading = false;
            that.total = res.data.data.count;

            var list = res.data.data.list;

            if (list == null || list.length === 0) {
              // 加载结束
              that.finished = true;
              return;
            }
            // 将新数据与老数据进行合并
            that.newsList = that.newsList.concat(list);

            //如果列表数据条数>=总条数，不再触发滚动加载
            if (that.newsList.length >= that.total) {
              that.finished = true;
            }

          }
        });
    },
    goDetail(id){
      this.$router.push({name:'PostsDetail',params:{id:id}});
    }
  },
  components: {
    cTitle
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/styles/var.less";
.myheart-container{
  padding:10px;
  .heartlist{
    .heart-item{
      padding:10px;
      background:#fff;
      margin-bottom:10px;
      border-radius:15px;
      .tit{
        padding-top:5px;padding-bottom:5px;
        color:@words;
        border-bottom:solid 1px #f5f5f5;
      }
      .txt{
        padding-top:5px;
        line-height:24px;
        color:@words;
        font-size:14px;
      }
    }
  }
}
.tx-r{
  text-align:right;
}
.fb{
  font-weight:bold;
}
.t-green{color:@primary}
</style>
