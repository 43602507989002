<template>
  <div class="voluntarylist-container">
    <c-title :hide="false"
      :text="`志愿活动`"
      tolink=""
      :totext="``">
    </c-title>
    <div class="topimg">
      <img src="../../assets/images/zyhd_img.jpg" alt="" class="zyhd">
    </div>

    <van-row class="xy">
      <van-col span="12" :class="is_join == 2 ? 'active' :''" @click="tab">
        进行中
      </van-col>
      <van-col span="12" :class="is_join == 3 ? 'active' :''" @click="tab">
        已结束
      </van-col>
    </van-row>
    <div class="content">

      <van-list
        v-model="loading"
        :finished="finished"
        :immediate-check="false"
        finished-text="没有更多了"
        @load="onLoad"
        :offset="70"
      >

      <div class="item"  v-for="(item,index) in newsList" :key="index">
        <van-row class="pd">
          <van-col span="4">
            <img :src="item.user_avatar" alt="" class="useravatar">
          </van-col>
          <van-col span="20">
            <p class="uname">{{item.user_nickname}}({{item.user_position}})</p>
            <p class="udat">{{news.createtime}}</p>
          </van-col>
        </van-row>
        <van-row @click="goDetail(item.id)">
          <van-col span="24" class="xyinfo">
            <p style="color:#222;font-weight:bold;">{{item.title}}</p>
            <p v-for="(ite,i) in item.new_extra" :key="i">{{ite.name}}：{{ite.value}}</p>
            <div v-html="item.content" style="font-size:14px;color:#333;"></div>
          </van-col>
        </van-row>
        <van-row >
          <van-col span="24" class="lqbtn">
             <van-button type="primary" v-if="item.activity_status == 2" size="small" block @click="join_activety(item.id)">已有0人参加，点此参与此活动</van-button>
            <van-button type="warning"  v-if="item.activity_status == 3" size="small" block @click="goDetail(item.id)">已有{{item.join_count}}人参加,查看详情</van-button>
          </van-col>
        </van-row>
        <van-row class="tx-c ptb10">
          <van-col span="8">
            <div class="browse">
              <van-icon name="eye-o" class="icon-dq"/>
              {{item.views}}
            </div>
          </van-col>
          <van-col span="8">
            <div class="comment" @click="onClickButton(item.id)">
              <van-icon name="chat-o" class="icon-dq"/>
              {{item.comments}}
            </div>
          </van-col>
          <van-col span="8">
            <div class="fabulous">
              <van-icon name="good-job-o" class="icon-dq"/>
              {{item.ups}}
            </div>
          </van-col>
        </van-row>
        <div class="pd">
          <dian-zan></dian-zan>
        </div>
        <div class="pd">
          <com-ment></com-ment>
        </div>

      </div>

      </van-list>
    </div>
  </div>
</template>

<script>
import cTitle from '../../components/title.vue';
import dianZan from '../../components/home/zan.vue';
import comMent from '../../components/home/comment.vue';
export default {
  data(){
    return {
      newsList:[],
      site:'',
      type:0,//0表示进行中1表示已结束
      page:1,
      limit:10,
      loading: false,
      total: 0,//总共的数据条数
      finished: false,
      is_join:2,
      activity_status:2,//1表示未开始，2进行中，3已结束，不传查询全部
    };
  },
  created(){
    this.site = localStorage.getItem('site_id');
    console.log(this.site);
    //获取活动列表
    this.getroadList();
  },
  methods:{
    goDetail(id){
      this.$router.push({name:'PostsDetail',params:{id:id}});
    },
    tab(){
      if(this.is_join == 2){
        this.is_join = 3;
        this.activity_status = 3;

      }else if(this.is_join == 3){
        this.is_join = 2;
        this.activity_status = 2;
      }
      this.page = 1;
      this.newsList = [];
      this.getroadList();
    },
    getroadList(){
      let that = this;
      that.$axios
        .get(that.baseUrl+"/api/news/list", {
          params: {
            site:that.site,
            all_site:1,
            category_id:that.$route.params.id,
            page:that.page,
            limit:that.limit,
            join:that.is_join,
            activity_status:that.activity_status
          }
        })
        .then(function(res) {
          //console.log(res);
          if(res.data.code==1){
            that.loading = false;
            that.total = res.data.data.count;

            var list = res.data.data.list;
            if(list.length > 0){
              list.forEach(item => {
                if(item.extra){
                  var objet = JSON.parse(item.extra);
                  //console.log(objet);
                  var extra_item = [];
                  for (let key in objet) {
                    const obj = objet[key];
                    extra_item.push(obj);
                  }
                  item.new_extra = extra_item;

                }
              });
            }

            if (list == null || list.length === 0) {
              // 加载结束
              that.finished = true;
              return;
            }
            // 将新数据与老数据进行合并
            that.newsList = that.newsList.concat(list);

            //如果列表数据条数>=总条数，不再触发滚动加载
            if (that.newsList.length >= that.total) {
              that.finished = true;
            }

          }
        });
    },
    onLoad() {
      this.page++;
      this.getroadList();
    },
    join_activety(id){
      this.$router.push({name:'ReceiveVoluntary',params:{id:id}});
    },
    onClickButton(id){
      this.$router.push({name:'PostsComment',params:{id:id}});
    },
  },
  components: {
    cTitle,
    dianZan,
    comMent,
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/styles/var.less";
.view{
  height:auto;
}
.voluntarylist-container{
  .xy{
    padding:10px;
    text-align:center;
    background:#fff;
    margin-top:5px;
    margin-bottom:5px;
    font-size:14px;
    color:@words;
    font-weight:bold;
  }
  .content{
    .item{
      width:100%;
      display:inline-block;
      background:#fff;
      margin-bottom:5px;
      padding-bottom:10px;
      .useravatar{
        width:40px;
        height:40px;
        border-radius:50%;
      }
      .uname{
        color:@words;
        font-size:14px;
        padding-top:3px;
      }
      .udat{
        color:@lightWords;
        font-size:14px;
        padding-top:5px;
      }
      .xyinfo{
        padding:10px;
        p{
          color:@words;
          font-size:13px;
          line-height:20px;
          margin-bottom:5px;
        }
      }
      .lqbtn{
        padding:10px;
      }
    }
  }
}
.active{
  color:#f30;
}
.lqw{
  font-size:14px;
  font-weight:bold;
  color:#f30;
}
.icon-dq{vertical-align:-16%}
.dy-tj{
      width:100%;
      height:auto;
      float:left;
      display:inline-flex;
      justify-content: space-between;
      div{
        width:33%;text-align:center;
        height:30px;line-height:30px;
      }
    }
    .ptb10{
      padding-bottom:10px;
      color:@words;
      font-size:14px;
    }
    .pd{
      display:block;
      padding:0 10px;
    }
    .zyhd{
      width:100%;
    }
</style>
