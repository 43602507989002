<template>
  <div class="structure-container">
    <div class="zbfcbg" :style="{backgroundImage: is_show_top ? 'url(' + imgUrl[0].src + ')': '#fff',backgroundSize:'100% auto',backgroundRepeat:'no-repeat'}" >
      <div class="structure" :style="{paddingTop: is_show_top ? '120px':'0'}">
        <van-row>
          <van-col span="24" class="tx-c">
            <img :src="content_obj.img" alt="" class="avatar">
            <p class="fb f14">{{content_obj.position}}</p>
            <p class="f14">{{content_obj.name}}</p>
          </van-col>
        </van-row>
        <van-row v-show="dw_list_one.length > 0">
          <van-col span="12" class="ubr mt05">&nbsp;</van-col>
        </van-row>
        <van-row  v-show="dw_list_one.length > 0">
          <van-col span="3" offset="3" class="ubt ubl">&nbsp;</van-col>
          <van-col span="3" class="ubt ubr">&nbsp;</van-col>
          <van-col span="3" class="ubt">&nbsp;</van-col>
          <van-col span="3" class="ubt ubr">&nbsp;</van-col>
          <van-col span="3" class="ubt">&nbsp;</van-col>
          <van-col span="3" class="ubt ubr">&nbsp;</van-col>
        </van-row>
        <van-row  v-show="dw_list_one.length > 0">
          <van-col span="6" class="tx-c" v-for="(item,i) in dw_list_one" :key="i">
            <img :src="item.img" class="avatar1">
            <p class="fb f14">{{item.position}}</p>
            <p class="f14">{{item.name}}</p>
          </van-col>
        </van-row>

      </div>
    </div>
  </div>
</template>

<script>

export default {
  props:['content_obj','dw_list_one','is_show_top'],
  data() {
    return {
      imgUrl:[{src:require('../assets/images/zbfc.jpg')}],
    };
  },
  components:{

  }
};
</script>

<style lang="less" scoped>
.fb{
  font-weight:bold;
}
.f14{
  font-size:14px;
}
.structure-container{
  width:100%;
  height:auto;
  margin-bottom:10px;
  background-color:#fff;
  .zbfcbg{
    z-index:1;
    width:100%;
    padding-bottom:30px;
    .structure{
      width:100%;
      padding-top:120px;
      height:auto;
    }
  }
}
.tx-c{
  text-align:center;
}
.avatar{
  width:50px;
  height:75px;
  object-fit: cover;
}
.avatar1{
  width:40px;
  height:60px;object-fit: cover;
}
.mt05{margin-top:5px;padding-top:5px;}
.ubt{border-top:solid 1px #ccc;}
.ubl{border-left:solid 1px #ccc;}
.ubr{border-right:solid 1px #ccc;}
</style>
