<template>
  <div class="structure-container">
    <div class="zbfcbg" :style="{backgroundImage: is_show_top ? 'url(' + imgUrl[0].src + ')' : '',backgroundSize:'100% auto',backgroundRepeat:'no-repeat'}" >
      <div class="structure" :style="{paddingTop: is_show_top ? '120px':'0'}">
        <van-row>
          <van-col span="24" class="tx-c">
            <img src="../assets/images/ganbu.png" alt="" class="avatar">
            <p class="fb">{{content_obj.position}}</p>
            <p>{{content_obj.name}}</p>
          </van-col>
        </van-row>
        <van-row>
          <van-col span="12" class="ubr mt05">&nbsp;</van-col>
        </van-row>

        <van-row v-show="cw_list_one.length > 0">
          <van-col span="4" class="">&nbsp;</van-col>
          <van-col span="4" class="ubt ubl">&nbsp;</van-col>
          <van-col span="4" class="ubt ubr">&nbsp;</van-col>
          <van-col span="4" class="ubt">&nbsp;</van-col>
          <van-col span="4" class="ubt ubr">&nbsp;</van-col>
          <van-col span="4" class="">&nbsp;</van-col>
        </van-row>
        <van-row  class="mb1" v-show="cw_list_one.length > 0">
          <van-col span="8" class="tx-c" v-for="(item,i) in cw_list_one" :key="i">
            <img src="../assets/images/ganbu.png" alt="" class="avatar1">
            <p class="fb f14">{{item.position}}</p>
            <p class="f14">{{item.name}}</p>
          </van-col>
        </van-row>

        <van-row v-show="cw_list_two.length > 0">
          <van-col span="3" offset="3" class="ubt ubl">&nbsp;</van-col>
          <van-col span="3" class="ubt ubr">&nbsp;</van-col>
          <van-col span="3" class="ubt">&nbsp;</van-col>
          <van-col span="3" class="ubt ubr">&nbsp;</van-col>
          <van-col span="3" class="ubt">&nbsp;</van-col>
          <van-col span="3" class="ubt ubr">&nbsp;</van-col>
        </van-row>
        <van-row v-show="cw_list_two.length > 0">
          <van-col span="6" class="tx-c" v-for="(item,i) in cw_list_two" :key="i">
            <img src="../assets/images/ganbu.png" alt="" class="avatar1">
            <p class="fb f14">{{item.position}}</p>
            <p class="f14">{{item.name}}</p>
          </van-col>
        </van-row>

      </div>
    </div>
  </div>
</template>

<script>

export default {
  props:['content_obj','cw_list_one','cw_list_two','is_show_top'],
  data() {
    return {
      imgUrl:[{src:require('../assets/images/cwfc.jpg')}],
    };
  },
  created(){
    // console.log(this.cw_list_two);
  }

};
</script>

<style lang="less" scoped>
.f14{
  font-size:14px;
}
.fb{
  font-weight:bold;
}
.structure-container{
  width:100%;
  height:auto;
  margin-bottom:10px;
  background-color:#fff;
  .zbfcbg{
    z-index:1;
    width:100%;
    padding-bottom:30px;
    .structure{
      width:100%;
      padding-top:130px;
      height:auto;
    }
  }
}
.tx-c{
  text-align:center;
}
.avatar{
  width:50px;
  height:75px;
}
.avatar1{
  width:40px;
  height:60px;
}
.mt05{margin-top:5px;padding-top:5px;}
.ubt{border-top:solid 1px #ccc;}
.ubl{border-left:solid 1px #ccc;}
.ubr{border-right:solid 1px #ccc;}
.mb1{margin-bottom:10px;}
</style>
