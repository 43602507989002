<template>
  <div class="zan-container">
    <div class="dy-zan" v-if="num>0">
      <span class="picker-arrow"></span>
      <van-icon name="good-job" class="icon-dq jdz mr05"  :color="basecolor"/>
      <span v-for="(item,i) in zanList" :key="i">{{item.userinfo ? item.userinfo.nickname : ''}}，</span>
      <span class="jdz" :style="`color:`+basecolor">
        等{{num}}人觉得赞
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props:['zanList','num','basecolor'],
  created(){
    // console.log(this.zanList);
  }
};
</script>

<style lang="less" scoped>
@import "~@/assets/styles/var.less";

.dy-zan{
    position: relative;
    display:block;
    float:left;
    font-size:14px;
    line-height:18px;
    color:@words;
    padding:5px;

    .picker-arrow {
      position: absolute;
      top: -14px;
      left: 50px;
      width: 0;
      height: 0;
      border: 6px solid transparent;
      border-bottom-color: #f5f5f5;
    }
    .picker-arrow::after {
        position: absolute;
        left: -6px;
        top: 1px;
        content: '';
        display: block;
        width: 0;
        height: 0;
        border: 6px solid transparent;
        border-bottom-color: #f5f5f5;
        border-top-width: 0;
      }
    .jdz{
      color:@jdz
    }
  }
  .icon-dq{
    vertical-align: -2px;;
  }
</style>
