import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
// import { titleController } from "@/util";

if (!window.VueRouter) {
  Vue.use(VueRouter); // 使用一个vue插件
}
VueRouter.prototype.back = false;
VueRouter.prototype.goBack = function goBack() {
  this.back = true;
  this.go(-1);
};



const router = new VueRouter({
  // 配置
  routes, // 路由匹配规则
  mode: "hash",

});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

//拦截器
router.beforeEach((to, from,next) => {
  if(to.meta.title){
    document.title = to.meta.title;
  }else{
    document.title = '数字乡村';
  }
  if(to.meta.site){
    //console.log(123);
    let site = localStorage.getItem('site_id');
    if(!site){
      next('/selectsite');
    }
  }

  if (to.meta.auth) {
    //需要鉴权，进入鉴权流程
    let token = localStorage.getItem('Authorization');
    if (token == null || token == '') {
      next('/login');
      setTimeout('console.clear()',300);
    } else {
      if(to.meta.shenhe){
        //需要用户身份审核后才有权限操作
        let verification = localStorage.getItem('verification');
        //console.log(verification);
        if (verification == null || verification == '' || verification == 0 || verification == 1) {
          next('/my');
        } else {
          next();
        }
      }else if(to.meta.is_admin){
        let is_admin = localStorage.getItem('is_admin');
        if(is_admin == 1){
          next();
        }else{
          console.log('暂无权限');
          return false;
        }
      }else{
        next();
      }
    }
  }else{
    next();
  }
});

export default router;
