<template>
  <div class="register-container">
    <div class="top">
      <img src="../assets/images/top3.jpg" alt="" class="topimg">
      <img src="../assets/images/topbg.png" alt="" class="topbg">
      <router-link to="/home">
        <van-icon name="wap-home" size="40" class="sy"/>
      </router-link>
    </div>
    <div class="formbox">
      <van-row>
        <van-col span="12" class="tx-c" @click="login_type =  true ">
          <div class="zc" :class="login_type ? 'active' : '' ">普通登录</div>
        </van-col>
        <van-col span="12" class="tx-c" @click=" login_type = false">
          <div class="zc" :class="!login_type ? 'active' : '' ">手机登录</div>
        </van-col>
      </van-row>

      <div class="base_login" v-if="login_type">
      <!--真实姓名-->
      <van-cell-group>
        <van-field v-model="realname" label="登录账号" placeholder="请输入手机号或真实姓名" />
      </van-cell-group>
      <!--登录密码-->
      <van-field
        v-model="password"
        type="password"
        name="登录密码"
        label="登录密码"
        placeholder="请输入登录密码"
        :rules="[{ required: true, message: '请填写密码' }]"
      />
      <van-row class="ptb10">
        <router-link to="/my/resetpsd">
          <van-col span="24" class="tx-r zhmm">
          重置密码？
          </van-col>
        </router-link>
      </van-row>


      </div>

      <div class="mobile_login" v-if="!login_type">
      <!--手机号码-->
      <van-field
        v-model="mobile"
        name="pattern"
        label="手机号码"
        placeholder="请输入手机号码"
        :rules="[{ pattern, message: '' }]"
      />
      <div class="f-item dw">
        <van-cell-group>
          <van-field v-model="code" label="验证码" placeholder="请输入手机验证码" />
        </van-cell-group>
        <van-button type="primary" size="small" class="yzm" @click="getCode" >{{code_text}}</van-button>
      </div>



      </div>
      <van-row class="ptb15">
          <van-col span="24" class="tx-c">
            <van-button round type="info" color="linear-gradient(to right, #0dbade, #00a752)" size="small" class="regbtn cbg"
              :disabled="false" @click="handleSubmit"
            > 登录</van-button>
          </van-col>
      </van-row>

      <van-row class="ptb15">
        <router-link to="/register">
          <van-col span="24" class="tx-c zjdl">
          没有账号，去注册
          </van-col>
        </router-link>
      </van-row>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
export default {
  data() {
    return {
      realname:'',
      password:'',
      login_type:true,
      mobile:'',
      code_mobile:'',
      pattern: /^1(3[0-9]|5[0-3,5-9]|7[1-3,5-8]|8[0-9])\d{8}$/,
      code:'',
      code_text:'获取验证码',
      code_issend:false,
      is_click:true,
    };
  },

  methods: {
    ...mapMutations(['changeLogin']),
    //获取验证码
    getCode(){
      if (this.code_issend) {
        return;
      }
      this.code_issend = true;

      //验证手机号码
      var mobile_num = this.mobile;
      if(!(/^1[3456789]\d{9}$/.test(mobile_num))){
        that.$toast('手机号码不合法，请重新输入');
        this.code_issend = false;
        return false;
      }

      let t = 10;
      let si = setInterval(() => {
        this.code_text = t + 's';
        t = t - 1;
        if (t == 0) {
          this.is_send = false;
          this.code_text = '获取手机验证码';
          clearInterval(si);
        }
      }, 1000);
      var that = this;
      //调用验证码接口
      that.$axios
        .get(that.baseUrl+"/api/sms/send", {//地址是get请求地址
          params: {
            mobile:mobile_num,
            event:'mobilelogin'
          }
        })
        .then(function(res) {//当请求完成时候执行then回调函数
          if(res.data.code == 1){
            that.$toast(res.data.msg);
            that.code_mobile = mobile_num;
          }else{
            that.$toast(res.data.msg);
            clearInterval(si);
            that.code_text = '获取验证码';
            that.code_issend = false;
            return false;
          }
        });


    },
    handleSubmit(){
      var that = this;
      that.is_click = false;
      if(that.login_type){
        //验证用户名
        if(that.username == ''){
          that.$toast('手机号或真实姓名不能为空');
          that.is_click=true;
          return false;
        }
        //验证登录密码
        if(that.password == ''){
          that.$toast('密码不能为空');
          that.is_click=true;
          return false;
        }

        that.$axios
          .get(that.baseUrl+"/api/user/login", {//地址是get请求地址
            params: {
              account:that.realname,
              password:that.password,
            }
          })
          .then(function(res) {//当请求完成时候执行then回调函数
            console.log(res);
            that.$toast(res.data.msg);
            if(res.data.code == 0){
              that.is_click=true;
            }else{
              that.$toast(res.data.msg);
              //缓存登录数据
              window.localStorage.setItem("Authorization",res.data.data.userinfo.token);
              window.localStorage.setItem("user_id",res.data.data.userinfo.user_id);
              window.localStorage.setItem("avatar",res.data.data.userinfo.avatar);
              window.localStorage.setItem("username",res.data.data.userinfo.username);
              window.localStorage.setItem("mobile",res.data.data.userinfo.mobile);
              window.localStorage.setItem("site_id",res.data.data.userinfo.site_id);
              window.localStorage.setItem("verification",res.data.data.userinfo.verification);
              window.localStorage.setItem("userinfo",JSON.stringify(res.data.data.userinfo));
              window.localStorage.setItem("user_village_id",res.data.data.userinfo.site_id);
              window.localStorage.setItem("user_town_id",res.data.data.userinfo.town_id);
              window.localStorage.setItem("county_id",res.data.data.userinfo.area_id);

              //新增村或镇管理员权限缓存
              if(res.data.data.userinfo.is_admin==1){
                window.localStorage.setItem("is_admin",res.data.data.userinfo.is_admin);
                window.localStorage.setItem("admin_type",res.data.data.userinfo.admin_type);
              }

              that.changeLogin({ Authorization: res.data.data.userinfo.token });
              setTimeout(function(){
                that.$router.push("/my");
              },2000);

            }
          });

      }else{
        //手机号码
        if(that.mobile == ''){
          that.$toast('请输入手机号码');
          that.is_click=true;
          return false;
        }
        if(that.mobile != that.code_mobile){
          that.$toast('手机号与获取验证码手机号不一致');
          that.is_click=true;
          return false;
        }
        //验证登录密码
        if(that.code == ''){
          that.$toast('验证码不能为空');
          that.is_click=true;
          return false;
        }

        that.$axios
          .get(that.baseUrl+"/api/user/mobilelogin", {//地址是get请求地址
            params: {
              mobile:that.mobile,
              captcha:that.code,
            }
          })
          .then(function(res) {//当请求完成时候执行then回调函数
            console.log(res);
            if(res.data.code == 1){
              that.$toast(res.data.msg);
              //缓存登录数据
              window.localStorage.setItem("Authorization",res.data.data.userinfo.token);
              window.localStorage.setItem("user_id",res.data.data.userinfo.user_id);
              window.localStorage.setItem("avatar",res.data.data.userinfo.avatar);
              window.localStorage.setItem("username",res.data.data.userinfo.username);
              window.localStorage.setItem("mobile",res.data.data.userinfo.mobile);
              window.localStorage.setItem("site_id",res.data.data.userinfo.site_id);
              window.localStorage.setItem("verification",res.data.data.userinfo.verification);
              window.localStorage.setItem("userinfo",JSON.stringify(res.data.data.userinfo));
              window.localStorage.setItem("user_village_id",res.data.data.userinfo.site_id);
              window.localStorage.setItem("user_town_id",res.data.data.userinfo.town_id);
              window.localStorage.setItem("county_id",res.data.data.userinfo.area_id);

              that.changeLogin({ Authorization: res.data.data.userinfo.token });
              setTimeout(function(){
                that.$router.push("/my");
              },2000);
            }else{
              that.$toast(res.data.msg);
              that.is_click=true;
              return false;

            }
          });

      }

    },

  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/styles/var.less";
.register-container{
  height:100%;
  background:#fff;
  .top{
    height:220px;
    overflow:hidden;
    position:relative;
    z-index:2;
    .topimg{
      position:absolute;
      width:100%;height:218px;
      left:0;
      top:0;
      z-index:3;
    }
    .topbg{
      position:absolute;
      width:100%;height:auto;
      left:0;
      bottom:0;
      z-index:4;
    }
    .sy{
      position:absolute;
      left:10px;top:10px;
      color:#fff;
      z-index:5;
    }
  }
  .formbox{
    padding:20px;
    padding-top:0;
    .zc{
      font-size:16px;
      color:#666;
      text-align:center;
      margin-bottom:40px;

    }
    .active{
      font-weight:bold;
      background:-webkit-linear-gradient(90deg,@primary,#0dbade);
      -webkit-background-clip:text;
      -webkit-text-fill-color:transparent;
    }
    .f-item{
      border-bottom:solid 1px #ebedf0;
      border-radius:5px;
    }
  }
}
.zhmm{color:#666;font-size:14px;}
.ptb10{padding-top:10px;padding-bottom:10px;}
.ptb15{padding-top:15px;padding-bottom:15px;}
.dw{position:relative;}
.yzm{position:absolute;top:6px;right:0px;}
.icon-dq{vertical-align: -3px;;}
.t-green{color:@primary}
.tx-r{text-align:right;}
.tx-l{text-align:left;}
.tx-c{text-align:center;}
.rzxy{height:20px;line-height:20px;font-size:14px;color:#666;}
.regbtn{width:90%;}
.cbg{box-shadow:0 0 5px rgba(0,0,0,0.5)}
.zjdl{
  color:@primary;font-size:14px;
}
</style>
