<template>
  <div class="service-container">
    <c-title :hide="false" :text="`服务`" tolink="" :totext="``"> </c-title>

    <div v-for="item in list" :key="item.id">
      <div class="blockline"></div>
      <div class="menuType">{{ item.title }}</div>
      <home-menu :menuList = "item.children"></home-menu>
    </div>
    <div class="blockline"></div>

    <foot-bar></foot-bar>
  </div>
</template>

<script>
import cTitle from "../components/title.vue";
import homeMenu from '../components/menu_s.vue';
import footBar from "../components/foot.vue";
export default {
  data() {
    return {
      list: []
    };
  },
  created() {

    var site_id = window.localStorage.getItem("site_id");
    if (site_id) {
      this.getService();
    }
  },
  methods: {
    getService() {
      var that = this;
      that.$axios
        .get(that.baseUrl + "/api/site/nav", {
          params: {
            site: window.localStorage.getItem("site_id")
          }
        })
        .then(function(res) {
          console.log(res);
          if (res.data.code == 1) {
            that.list = res.data.data;
          } else {
            that.$toast("请先认证");

            return false;
          }
        });
    },


  },
  components: {
    cTitle,
    footBar,
    homeMenu
  }
};
</script>

<style lang="less" scoped>
.view {
  height: 100%;
}
.service-container {
  padding-bottom: 70px;
}
.blockline {
  height: 10px;
}
.menuType {
  padding: 15px;
  background: #fff;
  font-size: 16px;
  font-weight: bold;
  color: #666;
}
</style>
