<template>
  <div class="foot-container">
    <van-tabbar  fixed route >
      <van-tabbar-item @click="gohome" class="foot_txt">乡音</van-tabbar-item>
      <van-tabbar-item  to="/service" class="foot_txt">服务</van-tabbar-item>
      <van-tabbar-item  @click="fabu">
      <img :src="fabu_icon" class="fabu"/>
      </van-tabbar-item>
      <van-tabbar-item @click="jishi" class="foot_txt">集市</van-tabbar-item>
      <van-tabbar-item @click="tomy" class="foot_txt">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>

export default {
  data() {
    return {
      youping:'',
      shouye:'',
      fabu_icon:require('../assets/images/fabu-f.png'),
      user_villageid:''
    };
  },
  methods:{
    fabu(){
      var verification = window.localStorage.getItem('verification');
      if(verification == 0 || verification == 1 || verification == 1 || verification=='' || verification==null){
        this.$toast('身份信息审核后可发布');
      }else if(verification == 2){
        this.$router.push("/fabu");
      }
    },
    tomy(){
      this.$router.push("/my").catch(()=>{});
    },
    gohome(){
      var type =  window.localStorage.getItem('type');
      var site =  window.localStorage.getItem('site_id');
      var user_town_id = window.localStorage.getItem('user_town_id');
      var user_village_id = window.localStorage.getItem('user_village_id');
      if(type == '县'){
        if(user_town_id){
          this.$router.push({name:'TownHome',params:{site:user_town_id}});
        }else{
          this.$router.push({name:'Login'});
        }
      }else if(type == '镇'){
        if(user_village_id){
          this.$router.push({name:'Home',params:{site:user_village_id}});
        }else{
          this.$router.push({name:'Login'});
        }
      }else if(type == '村'){
        this.$router.push({name:'TownHome',params:{site:user_town_id}});
      }else{
        this.$router.push({name:'Home',params:{site:site}});
      }
    },
    jishi(){
      window.location.href="https://itshop.it-zx.com/app/index.php?i=3&c=entry&m=ewei_shopv2_new&do=mobile&r=merch&merchid=4";
    }
  }
};
</script>

<style lang="less" scoped>
@import "~@/assets/styles/var.less";
.van-tabbar-item{
  font-size:18px;letter-spacing:2px;
}
.van-tabbar-item--active{
  color:@primary;
  font-size:18px;font-weight:bold;
}
.fabu{width:36px;height:36px;}
.foot_txt{
  font-size:20px;letter-spacing:2px;
}
</style>
