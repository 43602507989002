<template>
<div>
  <div class="share-container">
    <div class="bgimg">
      <img src="../../assets/images/top3.jpg" class="img100">
    </div>
    <div class="content_text">
      <div class="contbox">
        <div class="title">长塘镇</div>
        <div class="info">
          <div class="address"><van-icon name="location" />江西省吉安市吉州区</div>
          <div class="content ycyc3">
            长塘镇占地面积3666平米，建筑面积12000平米，村委会有6个自然村，8个村民小组，共260余户，村民1090人，水田面积1124.68亩。
          </div>
        </div>
      </div>
    </div>
    <div class="tjc">
      <div class="tjc-item">
        <p class="f14b">300</p>
        <p>入驻居民</p>
      </div>
      <div class="tjc-item">
        <p class="f14b">300</p>
        <p>活跃指数</p>
      </div>
      <div class="tjc-item">
        <p class="f14b">300</p>
        <p>行政满意度</p>
      </div>
    </div>
    <div class="foot">
      <div class="f-left">
        <p>长按二维码关注</p>
        <p class="fb">长塘镇 长塘村</p>
        <p>推荐一个村民注册</p>
        <p class="fb">奖励 积分</p>
      </div>
      <div class="f-right">
        <img src="../../assets/images/qrcode.png" class="qrcode">
        <p class="fx_txt">分享赠送积分</p>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {

};
</script>

<style lang="less" scoped>
.share-container{
  width:100%;height:100%;position:relative;z-index:2;
  .bgimg{
    width:100%;height:100%;position:absolute;left:0;top:0;z-index:3;
    .img100{width:auto;height:100%;}
  }
  .content_text{
    width:100%;height:200px;position:absolute;left:0;bottom:220px;z-index:4;background:rgba(255,255,255,0.2);
    .contbox{
      padding:20px;
      .title{font-size:16px;font-weight:bold;color:#fff;}
      .address{padding-top:15px;padding-bottom:15px;color:#fff;font-size:13px;display:flex;align-items: center;}
      .content{
        font-size:14px;color:#fff;line-height:20px;
      }
    }
  }
  .tjc{
    width:100%;height:60px;background:rgba(0,0,0,0.3);position:absolute;z-index:6;left:0;bottom:160px;
    display:flex;justify-content: flex-start;
    .tjc-item{
      width:25%;height:40px;line-height:20px;margin-top:10px;text-align:center;font-size:14px;color:#fff;
    }
  }
  .foot{
    width:100%;height:160px;background:#d9d5d4;position:absolute;left:0;bottom:0;z-index:5;
    display:flex;justify-content: space-between;
    .f-left{
      width:60%;
      line-height:24px;
      text-align:center;
      display:flex;
      margin-top:20px;
      flex-direction:column;
      align-items: center;
      color:#b46369;
    }
    .f-right{
      padding-top:20px;padding-left:20px;
      width:40%;
      .qrcode{width:80px;height:80px;padding:3px;background:#fff;}
      .fx_txt{font-size:13px;line-height:20px;color:#b46369;}
    }
  }
}
.f14b{font-size:16px;font-weight:bold;color:#d0aa93;}
.fb{font-size:14px;font-weight:bold;}
.ycyc3{
  overflow: hidden;
  -overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
</style>
