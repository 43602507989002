<template>
    <div class="tab-wrapper" ref="oneTab">
      <div class="tab-item"
      v-for = "(item,i) in menuList"
      :key ="item.title"
      :class = "{active: index == i}"
      @touchend="scrollTo(i,$event,item.title,item.id,item.style)"
      @touchstart="move = false"
      @touchmove="move = true"
      >
        <div class="title-box">{{ item.title }}</div>
      </div>
  </div>
</template>

<script>
// import { mapActions } from 'vuex';
import tool from '../util/tool';

export default {
  props:{
    menuList:{
      type:Array,
      default(){
        return [];
      }
    }
  },
  data() {
    return {
      move: false,
      index: 0,
    };
  },

  methods: {
    scrollTo(i, e,title,cat_id,style) {
      if (this.move) {
        return;
      }
      this.index = i;

      const { oneTab } = this.$refs;
      const itemWith = e.target.offsetWidth;
      const itemLeft = e.target.getBoundingClientRect().left;
      const wrapperWidth = oneTab.offsetWidth;

      tool.moveTo(oneTab.scrollLeft, itemWith / 2 + itemLeft - wrapperWidth / 2, oneTab, 'scrollLeft');
      // 向父级传递参数
      this.$emit('getMenuId',title,cat_id,style);
    },

  },
  mounted() {

  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/styles/var.less";
.tab-wrapper {
    display: flex;
    // height: 44px;
    overflow-x: auto;
    overflow-y: visible;
    padding-left:10px;padding-right:10px;font-size:16px;
    .tab-item {
      flex-shrink: 0;
      min-width: 60px;
      padding: 10px 10px;
      text-align: center;
      .title-box {
        margin-top: 5px;
        font-size: 16px;
      }
    }
    .active {
      .title-box {
        color: @primary;
        font-weight: bold;

      }
    }
  }
  .tab-wrapper::-webkit-scrollbar{
    width: 0px;
    background: none;
  }
</style>
