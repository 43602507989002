<template>
  <div class="top-container">
    <span class="val-name" @click="goTownInfo">
      <van-icon name="location" class="icon-dq-new" color="#33cc66" size="16"/> <span v-if="is_join">{{villageInfo ? villageInfo.site : ''}}简介</span><span v-else>选择村庄</span>
    </span>
    <span class="fans-num">
      <van-icon name="friends" class="icon-dq-new" color="#33cc66" size="16"/> 粉丝数 {{villageInfo.follow}}
    </span>
    <span class="follow" @click="guanzhu">
      <van-icon :name="is_follow ? 'like' : 'like-o'" class="icon-dq-new" :color="is_follow ? '#f30' : ''" size="16"/> <span v-if="is_follow ==1">已关注</span><span v-else>关注</span>
    </span>
    <span class="follow"  @click="share">
      <van-icon :name="is_share ? 'gift' : 'gift-o'"  color="#33cc66" class="icon-dq-new" size="16"/> 分享有礼
    </span>
    <!-- <router-link tag="span" class="follow pr10" to="/search">
      <van-icon name="search" class="icon-dq"/>
    </router-link> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      is_follow: false,
      is_share:true,
      site_name:'',
      is_join:false,
      villageInfo:{}
    };
  },
  mounted(){
    this.getGzStatus();
    this.init();
  },
  methods:{
    init(){
      let that = this;
      that.$axios
        .get(that.baseUrl+"/api/site/index", {
          params: {
            site_id:window.localStorage.getItem("site_id"),
          }
        })
        .then(function(res) {
          //console.log(res);
          if(res.data.code==1){
            that.is_join = true;
            that.villageInfo = res.data.data;
          }
        });
    },

    guanzhu(){
      var that = this;
      that.$axios
        .get(that.baseUrl+"/api/follow/siteFollow", {
          params: {
            site_id:window.localStorage.getItem("site_id")
          }
        })
        .then(function(res) {
          console.log(res);
          if(res.data.code == 1){
            that.is_follow= !that.is_follow;
            if(that.is_follow==1){
              that.$toast("已关注");
            }
            if(that.is_follow==0){
              that.$toast("已取消关注");
            }
          }else{
            that.$toast(res.data.msg);
          }

        });
    },
    getGzStatus(){
      var that = this;
      //调用验证码接口
      that.$axios
        .get(that.baseUrl+"/api/follow/siteIsFollow", {
          params: {
            site_id:window.localStorage.getItem("site_id") ? window.localStorage.getItem("site_id") : ''
          }
        })
        .then(function(res) {
          if(res.data.code == 1){
            that.is_follow=res.data.data.is_follow;
          }

        });
    },
    goTownInfo(){
      this.$router.push({name:'TownInfo',params:{
        site:window.localStorage.getItem('site_id')
      }});
    },
    share(){
      this.$router.push({name:'Share',params:{
        site:window.localStorage.getItem('user_town_id')
      }});
    }
  }

};
</script>

<style lang="less" scoped>
@import "~@/assets/styles/var.less";
.top-container{
  width:100%;
  height:40px;
  line-height:40px;
  background:#fff;
  color:#666;
  display:inline-flex;
  justify-content: space-around;
  align-items: center;
  font-size:16px;
}
.icon-dq-new{vertical-align:middle;}
.pl10{padding-left:20px;}
.pr10{padding-right:20px;}
</style>
