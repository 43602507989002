<template>
  <div class="postslist-container">
    <c-title :hide="false"
      :text="`详情页`"
      tolink=""
      :totext="``">
    </c-title>

    <div class="postsbox mb05 c-wh">
      <div class="bbs-title">
        国家中医药局：世卫组织专家评估会报告充分肯定中医药抗疫贡献
      </div>

      <div class="bbs-content" >
        <p>世界卫生组织在其官网有关栏目下最新发布的《世界卫生组织中医药救治新冠肺炎专家评估会报告》明确肯定了中医药救治新冠肺炎的安全性、有效性。对此，国家中医药管理局有关负责人表示，这一报告充分肯定了中医药抗击新冠肺炎疫情的贡献，体现了世卫组织对中医药等传统医学的高度重视，也表明未来中医药在抗击世纪疫情中仍将大有可为。</p>
        <p>两年多来，中医药全面参与疫情防控救治显示出独特优势，在近来应对奥密克戎变异毒株新冠肺炎疫情中再显身手。对此，报告予以充分肯定——中医药能有效治疗新冠肺炎，且根据临床疗效判定指标显示对轻型和普通型病例尤其有效，有利于降低轻型或普通型病例转为重症的风险；对于轻型与普通型病例，与单纯的常规治疗相比，中医药在作为附加干预措施时，
          可缩短病毒清除时间、临床症状缓解时间和住院时间；尽早使用中医药可改善轻型和普通型新冠肺炎患者的临床预后。</p>
        <p>中医药治疗的安全性如何？报告明确指出：在进行常规治疗的同时，使用中医药方法进行干预，不仅耐受性良好，其安全性与单纯的常规治疗亦相仿。</p>
        <p>国家中医药管理局有关负责人表示，报告对中国中医药抗击新冠肺炎的防控救治实践和“中国方案”给予客观、公正的评价，也必将推动包括中医药在内的世界传统医学更好、更深入地参与全球新冠肺炎疫情防控，维护各国人民健康。从应对突发疫情到常态化疫情防控，中国不断修订完善中医诊疗方案。最新发布的《新型冠状病毒肺炎诊疗方案（试行第九版）》表明，
          “三药三方”等方药对治疗奥密克戎变异毒株新冠肺炎无症状感染者和确诊患者依然有效。
          而此前，国家中医药管理局坚持中西医结合，推动中医药早介入、全程深度参与疫情防控，推进防控救治临床科研一体化，筛选出以“三药三方”为代表的有效方药并挖掘分析其疗效证据，科学阐释中医药疗效和作用机制，在湖北保卫战、武汉保卫战和应对局部聚集性疫情中均取得了良好效果。</p>
        <p>在重大疫情防治和突发公共事件医疗救治中，中医药一直发挥着特殊的重要作用。此次发布的报告不仅肯定了中医药的价值，还建议世卫组织鼓励其会员国在其卫生保健系统和监管框架内考虑使用中医药治疗新冠肺炎的可能性。</p>
        <p>事实上，为国际抗疫贡献中医药力量，中国一直在行动。疫情发生以来，国家中医药管理局积极推动中医药参与全球疫情防控，同国际社会分享中医药抗疫经验，据不完全统计，截至目前已支持举办了110余场抗疫专家视频交流和直播活动，向150多个国家和地区介绍中医药诊疗方案，向10多个有需求的国家和地区提供中医药产品，选派中医专家赴29个国家和地区帮助指导抗疫。</p>
      </div>


    </div>
    <div class="comment c-wh">
      <van-row>
        <van-col span="24" class="tx-l p10">
          <span class="liulan">浏览 3</span>
          <!-- <span class="pinlun">评论 {{newsDetail.comments}}</span>
          <span class="zannum">赞 {{newsDetail.ups}}</span> -->

        </van-col>
      </van-row>

    </div>

  </div>
</template>

<script>
import cTitle from '../components/title.vue';


export default {
  data(){
    return {
      newsDetail:'',
    };
  },
  components: {
    cTitle,
  },
  created() {
    const id = this.$route.params.id;
    this.id = id;
    this.getNewsDetail();
  },
  methods:{

    getNewsDetail(){


    },

  }
};
</script>

<style lang="less" scoped>
@import "~@/assets/styles/var.less";
.view{
  height:100%;
}
.joinbox{
  color:#888;font-size:14px;
  display:flex;
  flex-direction:row;
  justify-content: flex-start;
  flex-wrap:wrap;
  align-items: center;
}
.join_tx{
  width:30px;height:30px;border-radius:50%;margin-right:5px;
}
.join_name{color:@primary}
.postslist-container{
  padding-bottom:55px;

  .top{
    padding:10px 10px 6px;
    border-bottom:solid 1px #f8f8f8;
    .useravatar{
      width:44px;
      height:44px;
      border-radius:50%;
      display:block;
    }
    .fbuser{line-height:24px;font-size:14px;color:#333;}
    .subtxt{line-height:20px;font-size:14px;color:#888;}
  }
  .postsbox{
    padding:15px;
    .bbs-title{
      font-size:16px;
      display: inline-block;
      color:#222;text-align:center;padding:10px;line-height:24px;
      font-weight:bold;
      margin-bottom:10px;
      border-bottom:dashed 1px #ddd;
    }
    .bbs-content{
      display: inline-block;
      color:#333;
      font-size:16px;
      line-height:28px;
    }
    .bbs-image{
      width:100%;
      text-align:center;
      img{
        width:96%;
        height:auto;
        margin:2%;
      }
    }
  }
}
.mb05{
  border-bottom:solid 5px #f5f5f5;
}
.tx-l{text-align:left;}
.tx-r{text-align:right;}
.partyuser{width:16px;height:16px;border-radius:50%;vertical-align: middle;}
.guanzhu{
  float:right;
  font-size:8px;
  line-height:18px;
  padding:0px 10px;
  border:solid 1px @primary;
  color:@primary;
  border-radius:15px;
}
.guanzhu.active{
  background:@primary;
  color:#fff;
}
.liulan{
  color:@lightWords;
  font-size:14px;
  margin-right:15px;
}
.pinlun{
  color:@primary;
  font-size:14px;
  margin-right:15px;
}
.zannum{
  color:@lightWords;
  font-size:14px;
}
.p10{padding:10px;}
.blockline{
  height:10px;background:#f5f5f5;
}
.c-wh{background:#fff;}

.extra_style{height:26px;line-height:26px;color:#333;}
</style>
