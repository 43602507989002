<template>
  <div class="toutiao-container">
    <div class="left">
      <img src="../../assets/images/toutiao.png" class="left_img"/>
    </div>
    <div class="middle" @click="goList">
      <div class="toutiao_list">● 冬奥盛会推动全民健身运动蓬勃开展</div>
      <div class="toutiao_list">● “放水养鱼” 助企纾困——看各地如何落实落细组合式税费支持政策</div>

    </div>
    <div class="right" @click="goList">
      <van-icon name="arrow" />
    </div>
  </div>
</template>

<script>
export default {
  methods:{
    goList(){
      this.$router.push({name:'ArticleList',params:{
        site:window.localStorage.getItem('site_id')
      }});
    }
  }
};
</script>

<style lang="less" scoped>
.toutiao-container{
  width:100%;height:100px;display:flex;justify-content: space-between;align-items: center;background:#fff;margin-bottom:5px;

  .left{
    width:15%;
    .left_img{width:50px;height:80px;}
  }
  .middle{
    width:75%;
    .toutiao_list{
      width:100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      height:40px;line-height:40px;color:#666;
      font-size:16px;
    }
  }
  .right{
    width:10%;
    display:flex;justify-content: center;align-items: center;
  }
}
</style>
