<template>
  <div class="home-container">
    <c-title :hide="false"
      :text="`权力公开`"
      tolink=""
      :totext="``">
    </c-title>
    <home-slide :banner="banner"></home-slide>
    <form action="/">
      <van-search
        v-model="value"
        placeholder="请输入搜索关键词"
        @search="onSearch"
        @cancel="onCancel"
      />
    </form>
    <party-menu :menu="menuList" type="权力公开"></party-menu>

    <van-list
      v-model="loading"
      :finished="finished"
      :immediate-check="false"
      finished-text="没有更多了"
      @load="onLoad"
      :offset="10"
    >
    <home-dynamic
      v-for="(item,i) in newsList"
      :key="i"
      :news="item"
      :basecolor="basecolor"
    ></home-dynamic>
    </van-list>



    <foot-bar></foot-bar>
  </div>
</template>

<script>
import cTitle from '../../components/title.vue';
import homeSlide from '../../components/home/slide.vue';
import partyMenu from '../../components/home/partymenu.vue';
import homeDynamic from '../../components/home/dynamic.vue';
// import Empty from '../../components/empty.vue';
import footBar from '../../components/foot.vue';

export default {
  data() {
    return {
      value: '',
      site:window.localStorage.getItem("site_id"),
      newsList:[],
      menuList:[],
      banner:[],
      page:1,
      limit:10,
      loading: false,
      total: 0,//总共的数据条数
      finished: false,
      basecolor:'#ec1d25'
    };
  },
  created(){
    this.getMenu();
    this.getroadList();
    this.getBanner();
  },
  methods: {
    getMenu(){
      var that = this;
      that.$axios
        .get(that.baseUrl+"/api/news/category", {
          params: {
            'pid':that.$route.params.id,
            'site':window.localStorage.getItem('site_id'),
            'type':'权力公开'
          }
        })
        .then(function(res) {
          console.log(res);
          if(res.data.code == 1){
            that.menuList=res.data.data;
          }else{
            console.log(res.data.msg);
          }
        });
    },
    getBanner(){
      let that = this;
      that.$axios
        .get(that.baseUrl+"/api/banner/index", {
          params: {
            site:window.localStorage.getItem('site_id'),
            type:'权力公开',
          }
        })
        .then(function(res) {
          if(res.data.code==1){
            var arr = res.data.data;
            if(arr.length > 0){
              arr.forEach(element => {
                that.banner.push(element.imgurl);
              });
            }
          }
        });
    },
    onLoad() {
      this.page++;
      this.getroadList();
    },
    getroadList(){
      //lconsole.log(this.page);
      let that = this;
      that.$axios
        .get(that.baseUrl+"/api/news/list", {
          params: {
            site:that.site,
            keyword:that.value,
            all_site:1,
            type:'权力公开',
            page:that.page,
            limit:that.limit
          }
        })
        .then(function(res) {
          if(res.data.code==1){
            that.loading = false;
            that.total = res.data.data.count;

            var list = res.data.data.list;
            if(list.length > 0){
              list.forEach(item => {
                if(item.extra){
                  var objet = JSON.parse(item.extra);
                  //console.log(objet);
                  var extra_item = [];
                  for (let key in objet) {
                    const obj = objet[key];
                    extra_item.push(obj);
                  }
                  item.new_extra = extra_item;

                }
              });
            }

            if (list == null || list.length === 0) {
              // 加载结束
              that.finished = true;
              return;
            }
            // 将新数据与老数据进行合并
            that.newsList = that.newsList.concat(list);

            //如果列表数据条数>=总条数，不再触发滚动加载
            if (that.newsList.length >= that.total) {
              that.finished = true;
            }

          }
        });
    },
    onSearch(val) {
      this.value = val;
      this.page = 1;
      this.newsList = [];
      this.getroadList();
    },
    onCancel() {
      alert('取消');
    },
  },
  components: {
    cTitle,
    homeSlide,
    partyMenu,
    homeDynamic,
    footBar,
    // Empty
  },
};
</script>

<style lang="less" scoped>
.home-container{
  padding-bottom:50px;
  height:100%;
}
.van-notice-bar{
  background:none;
  color:#333;
}
</style>
