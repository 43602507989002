<template>
  <div class="sjxx-container">
    <div class="content">
      <van-field
        v-model="lotter_title"
        name="lotter_title"
        label="标题"
        placeholder="标题"
        :rules="[{ required: true, message: '请填写标题' }]"
      />
      <van-field
        v-model="message"
        rows="8"
        autosize
        label=""
        type="textarea"
        mINlength="20"
        maxlength="300"
        placeholder="为了能让书记更好的了解问题，建议您将问题描述的尽量清楚详细，最少20个字"
        show-word-limit
      />
    </div>
    <div class="uploat-img">
      <van-uploader v-model="fileList" multiple :after-read="afterRead"/>
    </div>
    <div class="location" @click="location">
      <div class="address">
        <van-icon name="location-o" class="icon-dq" size="18"/> 所在位置
      </div>
      <div class="jt">
        <van-icon name="arrow" />
      </div>
    </div>
    <div class="isopen">
      <van-radio-group v-model="radio" direction="horizontal">
        <van-radio name="1" >公开</van-radio>
        <van-radio name="2">仅对书记可见</van-radio>
      </van-radio-group>
    </div>
    <div class="btnbox">
      <van-button round type="info" color="#ccc" class="mr10" @click="goback"> 取消 </van-button>
      <van-button round type="info" color="#00a752" :disabled="is_click" @click="submit"> 发布 </van-button>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      lotter_title:'',
      message:'',
      img_arr:[],
      is_click:false,
      radio:'',
      fileList:[],
      location_arrdess:''
    };
  },
  methods: {
    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      var that = this;
      that.img_arr = [];
      if(that.fileList.length>0){
        for (var i=0;i<that.fileList.length;i++){
          var img_url = that.fileList[i].content;
          that.$axios
            .post(that.baseUrl+"/api/common/uploadBase64", {
              params:{
                base64:img_url
              }
            })
            .then(function(res) {//当请求完成时候执行then回调函数
              if(res.data.code == 1){
                that.img_arr += res.data.data.fullurl+',';
              }
            });
        }
      }
    },
    location(){
      this.location_arrdess = '';
    },
    submit(){
      var that = this;
      that.is_click = true;
      var lotter_title = that.lotter_title;
      if(lotter_title == ''){
        that.$toast('请填写标题');
        that.is_click = false;
        return false;
      }
      var message = that.message;
      if(message == ''){
        that.$toast('请输入您的内容');
        that.is_click = false;
        return false;
      }
      var radio_val = that.radio;
      if(radio_val == ''){
        that.$toast('请选择是否公开');
        that.is_click = false;
        return false;
      }
      var extend = [];
      extend[0] = {
        'name':'是否公开',
        'value':that.radio
      };
      extend[1] = {
        'name':'定位信息',
        'value':that.location
      };

      var new_extend = JSON.stringify(extend);

      that.$axios
        .get(that.baseUrl+"/api/news/add", {
          params: {
            site:window.localStorage.getItem('user_village_id'),
            cid:that.$route.params.id,
            huatiid:0,
            title:lotter_title,
            cover:that.img_arr,
            content:that.message,
            extra:new_extend
          }
        })
        .then(function(res) {
          console.log(res);
          if(res.data.code == 1){

            that.$toast('发布成功');
            setTimeout(function(){
              that.$router.push("/myrelease");
            },2000);
          }else{
            that.is_click=false;
          }
        });

    },
    goback(){
      this.$router.go(-1);
    }
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/styles/var.less";
.sjxx-container{
  .uploat-img{
    padding:15px;
    background:#fff;
    border-top:solid 1px #f8f8f8;
    border-bottom:solid 1px #f8f8f8;
  }
  .location{
    padding:15px;
    background:#fff;
    display:flex;
    justify-content: space-between;
    color:@words;
    font-size:14px;
  }
  .isopen{
    padding:15px;
    margin-top:10px;
    background:#fff;
  }
  .btnbox{
    padding:20px;
    text-align:center;

  }
}
.mr10{
  margin-right:10px;
}
.icon-dq{
  vertical-align: -3px;
}
</style>
