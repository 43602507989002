<template>
  <div class="recruit">
    <c-title :hide="false"
      :text="`招聘`"
      tolink=""
      :totext="``">
    </c-title>
    <div class="topbox">
      <div class="topitem" :class="is_active==1 ? 'baise': ''" @click="qiehuan(1)">个人就业服务</div>
      <div class="topitem" :class="is_active==2 ? 'baise': ''" @click="qiehuan(2)">用人单位服务</div>
      <div class="topitem" :class="is_active==3 ? 'baise': ''" @click="qiehuan(3)">经纪人服务</div>
    </div>
    <div class="content">
      <div class="tab1" :class="is_active==1 ? '': 'uhide'">
        <home-slide :banner="banner"></home-slide>
        <div class="p10 mt05">
          <div class="navbox">
            <div class="navitem"><img src="../../assets/images/zp01.png"/></div>
            <div class="navitem"><img src="../../assets/images/zp02.png"/></div>
            <div class="navitem"><img src="../../assets/images/zp03.png"/></div>
            <div class="navitem"><img src="../../assets/images/zp04.png"/></div>
            <div class="navitem"><img src="../../assets/images/zp05.png"/></div>
          </div>
          <div class="searchbox">
            <van-search v-model="value" placeholder="请输入搜索关键词" class="ss"/>
          </div>

          <div class="list">

            <div class="listitem" @click="goDetail">
              <div class="listtop">
                <div class="top-left">
                  <img src="../../assets/images/zpimg.png" />
                </div>
                <div class="top-right">
                  <div class="tright1">
                    <div class="tright-l">招聘C1司机</div>
                    <div class="tright-lr"><van-icon name="star-o" size="20"/></div>
                  </div>
                  <div class="tright2">宁夏吴忠市利通区</div>
                  <div class="tright3">月薪5000+，包吃住</div>
                </div>
              </div>
              <div class="listmid">
                职位简介：缝纫机车工多名！管理2名！质检2名！
              </div>
              <div class="listfot">
                <div class="fbsj">02月15日发布</div>
                <div class="lxrs">5人联系过TA</div>
              </div>
            </div>
            <div class="listitem" @click="goDetail">
              <div class="listtop">
                <div class="top-left">
                  <img src="../../assets/images/zpimg.png" />
                </div>
                <div class="top-right">
                  <div class="tright1">
                    <div class="tright-l">招聘C1司机</div>
                    <div class="tright-lr"><van-icon name="star-o" size="20"/></div>
                  </div>
                  <div class="tright2">宁夏吴忠市利通区</div>
                  <div class="tright3">月薪5000+，包吃住</div>
                </div>
              </div>
              <div class="listmid">
                职位简介：缝纫机车工多名！管理2名！质检2名！
              </div>
              <div class="listfot">
                <div class="fbsj">02月15日发布</div>
                <div class="lxrs">5人联系过TA</div>
              </div>
            </div>
            <div class="listitem" @click="goDetail">
              <div class="listtop">
                <div class="top-left">
                  <img src="../../assets/images/zpimg.png" />
                </div>
                <div class="top-right">
                  <div class="tright1">
                    <div class="tright-l">招聘C1司机</div>
                    <div class="tright-lr"><van-icon name="star-o" size="20"/></div>
                  </div>
                  <div class="tright2">宁夏吴忠市利通区</div>
                  <div class="tright3">月薪5000+，包吃住</div>
                </div>
              </div>
              <div class="listmid">
                职位简介：缝纫机车工多名！管理2名！质检2名！
              </div>
              <div class="listfot">
                <div class="fbsj">02月15日发布</div>
                <div class="lxrs">5人联系过TA</div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="tab2" :class="is_active==2 ? '': 'uhide'">
        <div class="tabtit">用工单位注册</div>
        <div class="tabbox">
          <div class="tabboxtit p16">营业执照</div>
          <div class="zhizhao tx-c pb10">
            <img :src="zhizhao" class="shangchuan"/>
          </div>
          <van-field v-model="qiyename" label="企业名称" placeholder="请输入企业名称" />
          <van-field v-model="jigoucode" label="机构代码" placeholder="请输入机构代码" />
          <van-field v-model="workaddress" label="工作地址" placeholder="请输入工作地址" />
        </div>
        <div class="tabbox mt10">
          <van-field v-model="contacts" label="负责人" placeholder="请输入招工负责人姓名" />
          <van-field v-model="mobile" label="联系电话" placeholder="请输入负责人联系电话" />
        </div>

        <div class="agreebox">
          <van-checkbox v-model="checked" icon-size="18px" checked-color="#30910e"></van-checkbox>
          <span style="margin-left:5px;">人资公司注册协议</span>
        </div>
        <div class="regbtn">
          立即注册
        </div>

      </div>
      <div class="tab3" :class="is_active==3 ? '': 'uhide'">
        <div class="tabtit">经纪人注册</div>
        <div class="tabbox">
          <div class="tabboxtit p16">营业执照</div>
          <div class="zhizhao tx-c pb10">
            <img :src="zhizhao" class="shangchuan"/>
          </div>
          <van-field v-model="qiyename" label="企业名称" placeholder="请输入企业名称" />
          <van-field v-model="jigoucode" label="机构代码" placeholder="请输入机构代码" />
          <van-field v-model="workaddress" label="工作地址" placeholder="请输入工作地址" />
        </div>
        <div class="tabbox mt10">
          <div class="top">
            <div class="leftbox">
              <div class="mb10"><img src="../../assets/images/jjrimg1.png" class="jjrimg"></div>
              <div class="mb10"><img src="../../assets/images/jjrimg2.png" class="jjrimg"></div>
            </div>
            <div class="rightbox">
              <van-field v-model="qiyename" label="负责人" placeholder="请输入姓名" />
              <van-field v-model="workaddress" label="职务" placeholder="请输入工作地址" />
              <div class="van-cell">
                <div class="van-cell__title van-field__label">
                  <span>性别</span>
                </div>
                <div style="padding-top:3px;">
                    <van-radio-group v-model="checked_sex" direction="horizontal" style="flex-wrap: inherit;">
                      <van-radio name="1"> 男</van-radio>
                      <van-radio name="2"> 女</van-radio>
                    </van-radio-group>
                </div>
              </div>


            </div>
          </div>
          <div class="bot">
            <van-field v-model="telphone" label="联系电话" placeholder="请输入联系电话" />
          </div>

        </div>
        <div class="agreebox">
          <van-checkbox v-model="checked" icon-size="18px" checked-color="#30910e"></van-checkbox>
          <span style="margin-left:5px;">人资公司注册协议</span>
        </div>
        <div class="regbtn">
          立即注册
        </div>
      </div>
    </div>
    <foot-bar></foot-bar>
  </div>
</template>

<script>
import cTitle from '../../components/title.vue';
import footBar from '../../components/foot.vue';
import homeSlide from '../../components/home/slide.vue';
export default {
  data(){
    return {
      is_active:1,
      banner:[require('../../assets/images/zhaoping.png')],
      value:'',
      zhizhao:require('../../assets/images/shangchuan.png'),
      zz_img:'',//提交上传的营来执照图片地址
      qiyename:'',//企业名称
      jigoucode:'',//企业机构代码
      workaddress:'',//企业工作地址
      contacts:'',//联系人
      mobile:'',//联系人手机号码
      is_agree:false,//是否同意人资公司注册协议
      checked:false,
      checked_sex:false
    };
  },
  created(){
    this.getBanner();
  },
  methods:{
    getBanner(){
      let that = this;
      that.$axios
        .get(that.baseUrl+"/api/banner/index", {
          params: {
            site:localStorage.getItem('site_id'),
            type:'招聘',
          }
        })
        .then(function(res) {
          //console.log(res);
          if(res.data.code==1){
            var arr = res.data.data;
            if(arr.length > 0){
              arr.forEach(element => {
                // that.banner.push(element.imgurl);
              });
            }
          }
        });
    },
    qiehuan(n){
      this.is_active = n;
    },
    goDetail(){
      this.$router.push({name:'RecruitDetail',params:{id:1}});
    }
  },
  components: {
    cTitle,
    footBar,
    homeSlide
  },
};
</script>

<style lang="less" scoped>
.view{height:100%;background:linear-gradient(to bottom,#effaeb,#5eb06e);}
.sex{padding-top:15px;padding-left:30px;}
.top{
  padding-left:10px;padding-right:10%;display:flex;justify-content: space-between;
  .leftbox{width:30%;}
  .rightbox{width:70%;}
}
.jjrimg{width:100%;}
.tab2, .tab3{padding:10px;}
.tabtit{width:100%;height:40px;line-height:40px;text-align:center;color:#333;font-size:15px;}
.tabbox{background:#fff;border-radius:10px;padding-bottom:10px;padding-top:10px;}
.p16{padding:5px 16px;}
.tabboxtit{
  font-size:14px;color:#444;height:30px;line-height:30px;
}
.agreebox{width:100%;height:50px;display:flex;justify-content: center;align-items: center;font-size:14px;color:#fff;}
.shangchuan{width:80%;}
.regbtn{background:#fff;height:36px;line-height:36px;text-align:center;font-size:14px;color:#5eb06e;border-radius:10px;}
.recruit{
  .topbox{
    width:100%;height:44px;background:#6cb26a;color:#fff;position:fixed;top:40px;left:0;z-index:999;
    display:flex;
    justify-content: space-around;
    align-items: center;
    .topitem{width:33%;text-align:center;height:38px;line-height:44px;font-size:14px;}
    .baise{background:rgba(200,200,200,0.1) url(../../assets/images/baise.png) no-repeat;background-size:40px 2px;background-position:bottom center;}
  }
  .content{
    padding-top:44px;
    padding-bottom:60px;
    .navbox{
      width:100%;display:flex;justify-content: space-around;align-items: center;
      background:rgba(255,255,255,0.8);border-radius:10px;padding:4px 0;
      .navitem{
        width:20%;
        img{width:100%;}
      }
    }
    .searchbox{
      width:100%;border-radius:10px;margin-top:5px;
    }
    .list{
      .listitem{
        border-radius:10px;margin-top:5px;background:#fff;padding:10px;
        .listtop{
          width:100%;display:flex;justify-content: space-between;
          .top-left{
            width:40%;
            img{width:120px;height:80px;border-radius:5px;}
          }
          .top-right{
            width:60%;
            .tright1{
              width:100%;display:flex;justify-content: space-between;
              .tright-l{width:90%;font-size:14px;font-weight:bold;color:#222;}
              .tright-r{width:10%;text-align:right;}
            }
            .tright2{color:#888;font-size:14px;}
            .tright3{font-size:16px;color:#6cb26a;margin-top:15px;}
          }

        }
        .listmid{width:100%;font-size:14px;color:#222;margin-top:10px;}
        .listfot{
          width:100%;display:flex;justify-content: space-between;
          margin-top:15px;
          .fbsj{width:50%;color:#888;font-size:14px;}
          .lxrs{width:50%;color:#333;font-size:14px;}
        }
      }
    }
  }
}
.p10{padding-left:5px;padding-right:5px;}
.ss{border-radius:10px;padding:8px !important;}
.ycyc2{
  overflow: hidden;
  -overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
// .van-search__content{background:#d0eacf;}
.uhide{display:none;}
.tx-c{text-align:center;}
.pb10{padding-bottom:10px;}
.mt05{margin-top:5px;}
.mt10{margin-top:10px;}
.mb10{margin-bottom:10px;}
</style>
