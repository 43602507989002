<template>
  <div>
    <c-title :hide="false" text="发布招工招聘" tolink="" :totext="``"> </c-title>
    <div class="tit">
      <van-cell-group inset>
        <van-field v-model="title" label="标题" placeholder="请输入标题" />
      </van-cell-group>
      <van-cell-group inset>
        <van-field
          v-model="message"
          rows="10"
          autosize
          label="正文"
          type="textarea"
          maxlength="500"
          placeholder="请输入正文内容"
          show-word-limit
        />
      </van-cell-group>
    </div>
    <div class="cont">
      <van-field name="fileList" label="图片上传">
        <template #input>
          <van-uploader v-model="fileList" multiple :after-read="afterRead" />
        </template>
      </van-field>
      <!-- <van-field
        readonly
        clickable
        name="picker"
        :value="value"
        label="分类选择"
        placeholder="点击选择分类"
        @click="showPicker = true"
      /> -->
    </div>
    <div class="tit">
      <van-cell-group inset>
        <van-cell title="招聘日期" :value="date" @click="show = true" />
        <van-calendar v-model="show" type="range" @confirm="onConfirm1" />
      </van-cell-group>
    </div>
    <div class="tit">
      <van-cell-group inset>
        <van-field v-model="enterprisename" label="企业名称" placeholder="请输入企业名称" />
      </van-cell-group>
      <van-cell-group inset>
        <van-field v-model="wages" label="薪资待遇" placeholder="例：4000-6000元/月" />
      </van-cell-group>
      <van-cell-group inset>
        <van-field v-model="contacts" label="联系人" placeholder="请输入联系人姓名" />
      </van-cell-group>
      <van-cell-group inset>
        <van-field v-model="mobile" label="电话" placeholder="请输入联系电话" />
      </van-cell-group>
      <van-cell-group inset>
        <van-field v-model="address" label="地址" placeholder="请输入工作地址" />
      </van-cell-group>
    </div>

    <!-- <van-popup v-model="showPicker" position="bottom">
      <van-picker
        value-key="name"
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup> -->

    <div class="foot">
      <van-button type="primary" :disabled="is_click" block @click="fabu">立即发布</van-button>
    </div>
  </div>
</template>

<script>
import cTitle from "../../components/title.vue";
export default {
  data() {
    return {
      title: "",
      message: "",
      menuname: "",
      fileList: [],
      img_arr: "",
      value: "",
      cid: "",
      columns: [],
      showPicker: false,
      is_click: false,

      enterprisename: "",
      wages: "",
      contacts: "",
      mobile: "",
      address: "",
      date: "",
      startdate: "",
      enddate: "",
      show: false
    };
  },
  created() {
    this.menuname = this.$route.params.mname;
    //获取当前分类下的子分类
    this.getChildrenType();
  },
  methods: {
    formatDate(date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    },
    onConfirm1(date) {
      const [start, end] = date;
      this.show = false;
      this.date = `${this.formatDate(start)} - ${this.formatDate(end)}`;
      this.startdate = `${this.formatDate(start)}`;
      this.enddate = `${this.formatDate(end)}`;
    },
    getChildrenType() {
      var that = this;
      that.$axios
        .get(that.baseUrl + "/api/village_manager/workTypes1", {
          params: {}
        })
        .then(function(res) {
          console.log(res);
          if (res.data.code == 1) {
            that.columns = res.data.data;
          }
        });
    },
    onConfirm(value) {
      this.value = value.name;
      this.cid = value.id;
      this.showPicker = false;
    },
    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      var that = this;
      that.img_arr = [];
      if (that.fileList.length > 0) {
        for (var i = 0; i < that.fileList.length; i++) {
          var img_url = that.fileList[i].content;
          that.$axios
            .post(that.baseUrl + "/api/common/uploadBase64", {
              params: {
                base64: img_url
              }
            })
            .then(function(res) {
              //当请求完成时候执行then回调函数

              if (res.data.code == 1) {
                that.img_arr += res.data.data.fullurl + ",";
              }
            });
        }
      }
    },
    fabu() {
      var that = this;
      that.is_click = true;
      var bbstitle = that.title;
      if (bbstitle == "") {
        that.$toast("请填写标题");
        that.is_click = false;
        return false;
      }
      var message = that.message;
      if (message == "") {
        that.$toast("请填写内容");
        that.is_click = false;
        return false;
      }
      var newjson = {
        enterprisename: that.enterprisename,
        wages: that.wages,
        contacts: that.contacts,
        mobile: that.mobile,
        address: that.address
      };
      // if (that.value == "") {
      //   that.$toast("请选择分类");
      //   that.is_click = false;
      //   return false;
      // }

      that.$axios
        .post(that.baseUrl + "/api/village_manager/editJob", {
          id: "",
          cid: that.cid,
          title: bbstitle,
          cover: that.img_arr,
          content: message,
          begin_date: that.startdate,
          end_date: that.enddate,
          extra: newjson
        })
        .then(function(res) {
          console.log(res);
          if (res.data.code == 1) {
            that.$toast("发布成功");
          } else {
            that.is_click = false;
          }
        });
    }
  },
  components: {
    cTitle
  }
};
</script>

<style lang="less" scoped>
.view {
  background: #fff;
}
.cont {
  padding: 15px;
}
.mr10 {
  margin-right: 10px;
}
.fxk {
  padding: 15px;
  .tzdx {
    margin-bottom: 10px;
    font-size: 14px;
    color: #666;
  }
}
.foot {
  padding: 15px;
}
</style>
