<template>
  <div class="editmobile-container">
    <c-title :hide="false" :text="`修改手机`" tolink="" :totext="``"> </c-title>
    <div class="formbox">
      <van-field v-model="mobile" required label="手机号" placeholder="请输入手机号" />

      <van-field v-model="sms" center clearable label="短信验证码" placeholder="请输入短信验证码">
        <template #button>
          <van-button size="small" type="primary" @click="getCode">{{ code_text }}</van-button>
        </template>
      </van-field>
    </div>

    <div class="btnbox">
      <van-button type="primary" size="small" block @click="submit">提交<u> </u></van-button>
    </div>
  </div>
</template>

<script>
import cTitle from "../../components/title.vue";
import { Toast } from "vant";
export default {
  data() {
    return {
      mobile: "",
      sms: "",
      code_mobile: "", //请求验证码的手机号码
      code_issend: false,
      is_send: true,
      code_text: "获取验证码"
    };
  },
  methods: {
    //获取验证码
    getCode() {
      if (this.code_issend) {
        return;
      }
      this.code_issend = true;

      //验证手机号码
      var mobile_num = this.mobile;
      if (!/^1[3456789]\d{9}$/.test(mobile_num)) {
        Toast("手机号码不合法，请重新输入");
        this.code_issend = false;
        return false;
      }

      let t = 10;
      let si = setInterval(() => {
        this.code_text = t + "s";
        t = t - 1;
        if (t == 0) {
          this.is_send = false;
          this.code_text = "获取手机验证码";
          clearInterval(si);
        }
      }, 1000);
      var that = this;
      //调用验证码接口
      that.$axios
        .get(that.baseUrl + "/api/sms/send", {
          //地址是get请求地址
          params: {
            mobile: mobile_num,
            event: "changemobile"
          }
        })
        .then(function(res) {
          //当请求完成时候执行then回调函数
          console.log(res);
          if (res.data.code == 1) {
            Toast(res.data.msg);
            that.code_mobile = mobile_num;
          } else {
            Toast(res.data.msg);
            clearInterval(si);
            that.code_text = "获取验证码";
            that.code_issend = false;
            return false;
          }
        });
    },
    submit() {
      var that = this;
      that.$axios
        .get(that.baseUrl + "/api/user/changemobile", {
          //地址是get请求地址
          params: {
            mobile: that.mobile,
            captcha: that.sms
          }
        })
        .then(function(res) {
          //当请求完成时候执行then回调函数
          console.log(res);
          if (res.data.code == 1) {
            Toast("修改成功");
            setTimeout(function() {
              that.$router.push("/my/setup");
            }, 2000);
          } else {
            Toast(res.data.msg);
            return false;
          }
        });
    }
  },
  components: {
    cTitle
  }
};
</script>

<style lang="less" scoped>
.dw {
  position: relative;
}
.input-loc-img {
  width: 50px;
  height: 50px;
  opacity: 0.5;
  position: absolute;
  right: 0;
  top: 0;
}
.img-radius {
  border-radius: 50%;
}
.van-uploader__upload {
  width: 50px !important;
  height: 50px !important;
  float: right !important;
}
.utx_tit {
  color: #646566;
  font-size: 0.38rem;
  line-height: 50px;
}
.tx-r {
  text-align: right;
}
.utxbox {
  padding: 10px 16px;
  background: #fff;
  height: 50px;
  border-bottom: solid 1px #f5f5f5;
}
.utx {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.editmobile-container {
  min-height: 100vh;
  padding-bottom: 60px;
  .formbox {
    padding-bottom: 20px;
    .upimg {
      padding: 10px 20px;
      background: #fff;
      color: #646566;
      font-size: 14px;
    }
    .subbox {
      padding: 20px 20px;
      background: #fff;
      .subbtn {
        margin-top: 20px;
      }
    }
  }
}
.tx-r {
  text-align: right;
}
.mr10 {
  margin-right: 10px;
}
.btnbox {
  padding: 0 20px;
  text-align: center;
}
</style>
